import React, {useEffect, useState} from "react";
import MessageTemplateService from "./MessageTemplateService";
import {Card, DialogTitle, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import MessageTemplate from "./MessageTemplate";
import LanguageService from "./LanguageService";
import {LoadingComponent} from "../../../components/loading/LoadingComponent";
import {Backdrop, Button, Fade, Modal, Table, TableHead} from "@material-ui/core";
import {Edit, EventAvailable, InfoRounded} from "@material-ui/icons";
import StyledButton from "../../../components/buttons/StyledButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import theme from "../../../theme/theme";
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../../../routes/PrivateRoute";
import MessageTemplateForm from "./MessageTemplateForm";


function MessageTemplatesPage(props) {


    const {t} = useTranslation();

    const history = useHistory();

    const [openLegenda, setOpenLegenda] = useState(false);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [refreshAll, setRefreshAll] = useState(false);


    function addTemplate() {


        let template = {};

        template.bodyText = '';
        template.contractId = props.contractId;
        template.id = null;
        template.language = null;
        template.messageType = '';
        template.notificationType = '';

        let temp = templates;

        temp.push(template);

        setTemplates(temp);

        setRefresh(!refresh);

    }

    function goToForm(id) {
        if (id) {
            history.push(history.location.pathname +`/templates/${id}`)
        } else {
            history.push(history.location.pathname + `/templates/new`)
        }

    }

    function refreshCallback() {
        setRefreshAll(!refreshAll);
    }

    async function fetchMessageTemplates() {

        setLoading(true);

        if (props.contractId !== null) {

            let filters = {};

            filters.contract = props.contractId;

            let temp = await new MessageTemplateService().getTemplates(filters)
                .catch(error => {

                })

            setTemplates(temp);
        }

        setLoading(false);

    }

    async function fetchLanguages() {

        let lang = await new LanguageService().getLanguages()
            .catch(error => {

            });

        setLanguages(lang);
    }

    function deleteEntry(index){

        let array = templates;
        if (index !== -1) {

            array.splice(index, 1);
            setTemplates(array);
        }
        setRefresh(!refresh)
    }

    useEffect(() => {
        fetchLanguages();
        fetchMessageTemplates();
    }, [refreshAll])

    useEffect(() => {

    }, [refresh])

    return <div>

        <Switch>
            <PrivateRoute exact path={"/contracts/:contractId/edit"}>
                {loading ?
                    <LoadingComponent/>

                    :

                    <Card>

                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t("notification_type")}
                                    </TableCell>
                                    <TableCell>
                                        {t("message_type")}
                                    </TableCell>
                                    <TableCell>
                                        {t("body_text")}
                                    </TableCell>
                                    <TableCell>
                                        {t("language")}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {templates.map((template, index) => {
                                return <TableRow>
                                    <TableCell>
                                        {t(template.notificationType)}
                                    </TableCell>
                                    <TableCell>
                                        {t(template.messageType)}
                                    </TableCell>
                                    <TableCell>
                                        {template.bodyText}
                                    </TableCell>
                                    <TableCell>
                                        {t(template.language)}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => goToForm(template.id)}><Edit
                                            style={{color: theme.palette.primary.main}}/></Button>
                                    </TableCell>
                                </TableRow>
                            })}
                        </Table>
                        <StyledButton
                            icon={<AddCircleIcon/>}
                            onClick={()=>{goToForm()}}>{t("add_template")}</StyledButton>
                    </Card>

                }
            </PrivateRoute>
            <PrivateRoute exact path={`/contracts/:contractId/edit/templates/:templateId`}>
                <MessageTemplateForm/>
            </PrivateRoute>
        </Switch>

        {/*<Button style={{backgroundColor: "transparent", color: "#4899f0"}} onClick={()=>setOpenLegenda(true)}><InfoRounded/></Button>*/}




    </div>

}

export default MessageTemplatesPage;