import {Button, Menu, MenuItem, TableCell, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import {TableRow, Typography} from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import {Upload} from "@mui/icons-material";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Notification from "../../../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {useStyles} from "../../../theme/useStyles";
import {useHistory} from "react-router-dom";


export default function SubContract(props){


    let subContract = props.subContract;

    const [anchorEl, setAnchorEl] = useState(null);
    const {t} = useTranslation();

    const classes = useStyles();
    const history = useHistory();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);
    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function editSubContract(subContract) {
        history.push(`/sub-contracts/${subContract.id}/edit`);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    return <TableRow>
        <TableCell>
            {subContract.code}
        </TableCell>
        <TableCell>
            {subContract.contract.code}
        </TableCell>
        <TableCell>
            {subContract.contract.accountCorporateCode}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editSubContract(subContract)}><EditIcon className={classes.icon}/>
                <Typography variant={"subtitle2"}
                            style={{marginLeft: "4%"}}> {t("edit")} </Typography>
            </MenuItem>
        </Menu>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </TableRow>







}