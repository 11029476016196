import {TableCell, TableRow, Typography} from "@mui/material";
import React, {useState} from "react";
import {formatDateTime} from "../../utils/utils";
import {useHistory} from "react-router-dom";
import {Button, Menu, MenuItem} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {Download, GppBad, StopCircleOutlined} from "@mui/icons-material";
import {CancelOutlined, Delete, Map} from "@material-ui/icons";
import {useTranslation} from "react-i18next";


function WarehouseCollection(props){

    let item = props.item;

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const {t} = useTranslation();


    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToDocumentPage(){
        history.push(`warehouse-collection/${item.id}/document`)
    }

    return <TableRow>
        <TableCell>
            <Typography variant={"inherit"}>  {item.warehouseCode} </Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"inherit"}>  {formatDateTime(item.dateTime)} </Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"inherit"}>  {item.referenceName} </Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"inherit"}>  {item.documentType} </Typography>
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={goToDocumentPage}><Download/>&nbsp;{t("download_pickup_document")}</MenuItem>
            </Menu>
        </TableCell>
    </TableRow>



} export default WarehouseCollection;