import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Modal,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ArrowBackIos, ArrowBackRounded, ArrowForwardIos, EventAvailable, Warning} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import ServiceService from "./services/ServiceService";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ShipmentService from "./ShipmentService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {
    AddCircleOutlineOutlined,
    CancelOutlined,
    ContentCopy,
    Edit,
    RemoveCircleOutlineOutlined
} from "@mui/icons-material";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import SubContractService from "../accounts/contracts/sub-contracts/SubContractService";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {makeStyles} from "@material-ui/core/styles";
import ParcelValues from "./ParcelValues";
import {Backdrop, CardActionArea, Fade, MenuItem, Paper, Select, Table, TableCell} from "@material-ui/core";
import {format} from "date-fns";
import SlotService from "../districts/slot/SlotService";
import theme from "../theme/theme";
import Notification from "../components/notifications/Notification";
import StyledButton from "../components/buttons/StyledButton";
import DateField from "../components/fields/DateField";
import WarningChip from "../components/chips/WarningChip";
import PudoService from "../pudos/PudoService";
import WarehouseService from "../warehouses/WarehouseService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%"
    },
    paper: {
        width: 600,
        marginBottom: "2%"
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        width: '100%',
        maxHeight: 840,
    },
    table: {
        width: '80%',
        margin: "auto",
    },
    button: {
        marginTop: "3%",
        marginBottom: "3%"
    },
    accordion: {
        margin: "2%"
    },
    calendar: {
        width: "80%",
        marginInline: "auto",
        marginBlock: "15%",
        height: "100%",
        overflow: "hidden"
    },
    calendarTable: {
        width: "100%",
        marginInline: "auto",
        marginBlock: "5%",
        height: "100%",
        overflow: "hidden"
    },
}));


function NewShipmentForm(props) {

    const classes = useStyles();


    const history = useHistory();
    const {t} = useTranslation();
    const [refresh, setRefresh] = useState(false);

    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const [loadingFetch, setLoadingFetch] = useState(true);

    const [pudos, setPudos] = useState([]);
    const [pudo, setPudo] = useState(null);

    const [exWarehouse, setExWarehouse] = useState(false);

    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [pickupDate, setPickupDate] = useState(null);
    const [openBooking, setOpenBooking] = useState(false);
    const [bookingDate, setBookingDate] = useState('');
    const [bookingSlot, setBookingSlot] = useState(null);
    const [calendar, setCalendar] = useState([]);
    const [calendarBatchCount, setCalendarBatchCount] = useState(0);
    const [today, setToday] = useState(Date.now());
    const [calendarLoading, setCalendarLoading] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [districtId, setDistrictId] = useState('');

    const [loadingCod, setLoadingCod] = useState(false);
    const [loadingInsurance, setLoadingInsurance] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [loadingPickupAddress, setLoadingPickupAddress] = useState(false);
    const [loadingDeliveryAddress, setLoadingDeliveryAddress] = useState(false);
    const [loadingSenderAddress, setLoadingSenderAddress] = useState(false);
    const [loadingEndUserAddress, setLoadingEndUserAddress] = useState(false);

    const [disabledPickupAddress, setDisabledPickupAddress] = useState(false);
    const [disabledDeliveryAddress, setDisabledDeliveryAddress] = useState(false);
    const [disabledSenderAddress, setDisabledSenderAddress] = useState(false);
    const [disabledEndUserAddress, setDisabledEndUserAddress] = useState(false);

    const [senderEqualPickup, setSenderEqualPickup] = useState(true);
    const [endUserEqualDelivery, setEndUserEqualDelivery] = useState(true);


    const [reference, setReference] = useState('');
    const [customerReference, setCustomerReference] = useState('');
    const [customerCode, setCustomerCode] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [codValue, setCodValue] = useState(0);
    const [codCurrency, setCodCurrency] = useState(null);
    const [insuranceCurrency, setInsuranceCurrency] = useState(null);
    const [insuranceValue, setInsuranceValue] = useState(0);
    const [authMethod, setAuthMethod] = useState('');
    const [authMethods, setAuthMethods] = useState(["PIN"]);
    const [authPin, setAuthPin] = useState('');

    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [codModes, setCodModes] = useState([]);
    const [codMode, setCodMode] = useState(null);
    const [hasCod, setCod] = useState(false);
    const [hasInsurance, setInsurance] = useState(false);
    const [hasAuth, setAuth] = useState(false);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [subContract, setSubContract] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [subContracts, setSubContracts] = useState([]);

    /** Notifications */
    const [registeredEmailChecked, setRegisteredEmailChecked] = useState(false);
    const [registeredTextChecked, setRegisteredTextChecked] = useState(false);
    const [takenInChargeEmailChecked, setTakenInChargeEmailChecked] = useState(false);
    const [takenInChargeTextChecked, setTakenInChargeTextChecked] = useState(false);
    const [inDeliveryEmailChecked, setInDeliveryEmailChecked] = useState(false);
    const [inDeliveryTextChecked, setInDeliveryTextChecked] = useState(false);
    const [deliveredEmailChecked, setDeliveredEmailChecked] = useState(false);
    const [deliveredTextChecked, setDeliveredTextChecked] = useState(false);
    const [deliveryKOEmailChecked, setDeliveryKOEmailChecked] = useState(false);
    const [deliveryKOTextChecked, setDeliveryKOTextChecked] = useState(false);
    const [warehouseStorageEmailChecked, setWarehouseStorageEmailChecked] = useState(false);
    const [warehouseStorageTextChecked, setWarehouseStorageTextChecked] = useState(false);
    const [pinCodeEmailChecked, setPinCodeEmailChecked] = useState(false);
    const [pinCodeTextChecked, setPinCodeTextChecked] = useState(false);
    const [returnRegisteredEmailChecked, setReturnRegisteredEmailChecked] = useState(false);
    const [returnRegisteredTextChecked, setReturnRegisteredTextChecked] = useState(false);
    const [returnCollectedEmailChecked, setReturnCollectedEmailChecked] = useState(false);
    const [returnCollectedTextChecked, setReturnCollectedTextChecked] = useState(false);
    const [returnDeliveredEmailChecked, setReturnDeliveredEmailChecked] = useState(false);
    const [returnDeliveredTextChecked, setReturnDeliveredTextChecked] = useState(false);
    const [pudoCheckInTextChecked, setPudoCheckInTextChecked] = useState(false);
    const [pudoCheckInEmailChecked, setPudoCheckInEmailChecked] = useState(false);


    /**  Arrays */
    const [countries, setCountries] = useState([]);
    const [administrativeLevel3, setAdministrativeLevel3] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    /**  PickupAddress */
    const [pickupCountry, setPickupCountry] = useState(null);
    const [pickupAdministrativeLevel3, setPickupAdministrativeLevel3] = useState(null);
    const [pickupCity, setPickupCity] = useState(null);
    const [pickupPostalCode, setPickupPostalCode] = useState(null);
    const [freePickupAdministrativeLevel1, setFreePickupAdministrativeLevel1] = useState('');
    const [freePickupAdministrativeLevel2, setFreePickupAdministrativeLevel2] = useState('');
    const [freePickupAdministrativeLevel3, setFreePickupAdministrativeLevel3] = useState('');
    const [freePickupCity, setFreePickupCity] = useState('');
    const [freePickupPostalCode, setFreePickupPostalCode] = useState('');
    const [pickupStreet, setPickupStreet] = useState('');
    const [pickupBuildingNr, setPickupBuildingNr] = useState('');
    const [pickupCompanyName, setPickupCompanyName] = useState('');
    const [pickupReferencePerson, setPickupReferencePerson] = useState('');
    const [pickupEmail, setPickupEmail] = useState('');
    const [pickupPhone, setPickupPhone] = useState('');
    const [pickupMobile, setPickupMobile] = useState('');

    /**  DeliveryAddress */
    const [deliveryCountry, setDeliveryCountry] = useState(null);
    const [deliveryAdministrativeLevel3, setDeliveryAdministrativeLevel3] = useState(null);
    const [deliveryCity, setDeliveryCity] = useState(null);
    const [deliveryPostalCode, setDeliveryPostalCode] = useState(null);
    const [freeDeliveryAdministrativeLevel1, setFreeDeliveryAdministrativeLevel1] = useState('');
    const [freeDeliveryAdministrativeLevel2, setFreeDeliveryAdministrativeLevel2] = useState('');
    const [freeDeliveryAdministrativeLevel3, setFreeDeliveryAdministrativeLevel3] = useState('');
    const [freeDeliveryCity, setFreeDeliveryCity] = useState('');
    const [freeDeliveryPostalCode, setFreeDeliveryPostalCode] = useState('');
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryBuildingNr, setDeliveryBuildingNr] = useState('');
    const [deliveryCompanyName, setDeliveryCompanyName] = useState('');
    const [deliveryReferencePerson, setDeliveryReferencePerson] = useState('');
    const [deliveryEmail, setDeliveryEmail] = useState('');
    const [deliveryPhone, setDeliveryPhone] = useState('');
    const [deliveryMobile, setDeliveryMobile] = useState('');

    /**  SenderAddress */

    const [senderCountry, setSenderCountry] = useState(null);
    const [senderAdministrativeLevel3, setSenderAdministrativeLevel3] = useState(null);
    const [senderCity, setSenderCity] = useState(null);
    const [senderPostalCode, setSenderPostalCode] = useState(null);
    const [freeSenderAdministrativeLevel1, setFreeSenderAdministrativeLevel1] = useState('');
    const [freeSenderAdministrativeLevel2, setFreeSenderAdministrativeLevel2] = useState('');
    const [freeSenderAdministrativeLevel3, setFreeSenderAdministrativeLevel3] = useState('');
    const [freeSenderCity, setFreeSenderCity] = useState('');
    const [freeSenderPostalCode, setFreeSenderPostalCode] = useState('');
    const [senderStreet, setSenderStreet] = useState('');
    const [senderBuildingNr, setSenderBuildingNr] = useState('');
    const [senderCompanyName, setSenderCompanyName] = useState('');
    const [senderReferencePerson, setSenderReferencePerson] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');
    const [senderMobile, setSenderMobile] = useState('');


    /**  EndUserAddress */

    const [endUserCountry, setEndUserCountry] = useState(null);
    const [endUserAdministrativeLevel3, setEndUserAdministrativeLevel3] = useState(null);
    const [endUserCity, setEndUserCity] = useState(null);
    const [endUserPostalCode, setEndUserPostalCode] = useState(null);
    const [freeEndUserAdministrativeLevel1, setFreeEndUserAdministrativeLevel1] = useState('');
    const [freeEndUserAdministrativeLevel2, setFreeEndUserAdministrativeLevel2] = useState('');
    const [freeEndUserAdministrativeLevel3, setFreeEndUserAdministrativeLevel3] = useState('');
    const [freeEndUserCity, setFreeEndUserCity] = useState('');
    const [freeEndUserPostalCode, setFreeEndUserPostalCode] = useState('');
    const [endUserStreet, setEndUserStreet] = useState('');
    const [endUserBuildingNr, setEndUserBuildingNr] = useState('');
    const [endUserCompanyName, setEndUserCompanyName] = useState('');
    const [endUserReferencePerson, setEndUserReferencePerson] = useState('');
    const [endUserEmail, setEndUserEmail] = useState('');
    const [endUserPhone, setEndUserPhone] = useState('');
    const [endUserMobile, setEndUserMobile] = useState('');

    /** Notes */

    const [pickupNotes, setPickupNotes] = useState('');
    const [deliveryNotes, setDeliveryNotes] = useState('');


    const [serviceHelperText, setServiceHelperText] = useState("select_contract_first");
    const [contractHelperText, setContractHelperText] = useState("select_account_first");

    // const parcelValues = { weight: null, length : null, height: null, depth:null, customer_barcode: null};

    const [parcels, setParcels] = useState([new ParcelValues()]);

    function closeNotification() {
        setOpenNotify(false);
    }

    function isPudo() {
        return service?.code === "CP";
    }

    function isPudoReturn() {
        return service?.code === "CR";
    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses)
            })
            .catch(error => {

            })
    }

    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
    }

    function handleExWarehouseChange(event) {
        setExWarehouse(event.target.checked);
        clearPickUpAddress();
        clearSenderAddress();
        setDepartureWarehouse(null);

    }

    function checkBooking() {

        if (!service) {
            return false;
        }

        if (!deliveryCountry) {
            return false;
        }

        if (deliveryCountry) {
            if (!deliveryStreet || !deliveryPostalCode) {
                return false;
            }
        }

        return true;
    }

    function showError(message) {
        setNotificationMessage(message)
        setNotifySeverity('error');
        setOpenNotify(true);
    }

    function checkRequiredFieldsMissing() {

        if (service?.code !== "CP") {
            if ('' === reference) {
                showError(t("reference_is_mandatory"))
                return true;
            }

            if ('' === deliveryReferencePerson && '' === deliveryCompanyName) {
                showError(t("delivery_reference_company_is_mandatory"))
                return true;
            }
            if (!exWarehouse && '' === pickupReferencePerson && '' === pickupCompanyName) {
                showError(t("pickup_reference_company_is_mandatory"))
                return true;
            }
            if (!exWarehouse && '' === senderReferencePerson && '' === senderCompanyName) {
                showError(t("sender_reference_company_is_mandatory"))
                return true;
            }
            if ('' === endUserReferencePerson && '' === endUserCompanyName) {
                showError(t("end_user_reference_company_is_mandatory"))
                return true;
            }
        }

        if (exWarehouse && !departureWarehouse) {
            showError(t("departure_warehouse_code_is_mandatory"))
            return true;
        }


    }


    function createShipment() {

        if (checkRequiredFieldsMissing()) {
            return;
        }

        setLoadingConfirm(true);

        let shipment = {};

        shipment.channel = "TMS WEBPORTAL";
        if (account !== null) {
            shipment.accountCorporateCode = account.accountCorporateCode;
        }
        if (reference !== '') {
            shipment.reference = reference;
        }
        if (customerReference !== '') {
            shipment.customerReference = customerReference;
        }
        if (customerCode !== '') {
            shipment.customerCode = customerCode;
        }
        if (contract !== null) {
            shipment.contractCode = contract.code;
        }
        if (subContract !== null) {
            shipment.subContractCode = subContract.code;
        }
        if (service !== null) {
            shipment.serviceCode = service.code;
        }
        if (orderNumber !== '') {
            shipment.orderNumber = orderNumber;
        }
        if (hasCod) {
            let cod = {};
            cod.value = codValue;
            cod.modeId = codMode.id;
            cod.currency = codCurrency.code;

            shipment.cod = cod;
        }

        if (hasInsurance) {
            let insurance = {};
            insurance.value = insuranceValue;
            insurance.currency = insuranceCurrency.code;

            shipment.insurance = insurance;
        }

        let senderAddress = {};

        if (senderCountry !== null) {
            senderAddress.country = senderCountry.iso3166Alpha2;

            if (freeSenderAdministrativeLevel1 !== '') {
                senderAddress.administrativeLevel1 = freeSenderAdministrativeLevel1;
            }

            if (freeSenderAdministrativeLevel2 !== '') {
                senderAddress.administrativeLevel2 = freeSenderAdministrativeLevel2;
            }

            if (freeSenderAdministrativeLevel3 !== '') {
                senderAddress.administrativeLevel3 = freeSenderAdministrativeLevel3;
            } else if (senderAdministrativeLevel3 !== null && !senderAdministrativeLevel3) {
                senderAddress.administrativeLevel3 = senderAdministrativeLevel3?.code;
            }

            if (freeSenderCity !== '') {
                senderAddress.city = freeSenderCity;
            } else if (senderCity !== null) {
                senderAddress.city = senderCity.name;
            }

            if (freeSenderPostalCode !== '') {
                senderAddress.postalCode = freeSenderPostalCode;
            } else if (senderPostalCode !== null) {
                senderAddress.postalCode = senderPostalCode.postalCode;
            }

            if (senderStreet !== '') {
                senderAddress.street = senderStreet;
            }

            if (senderBuildingNr !== '') {
                senderAddress.buildingNr = senderBuildingNr;
            }

            if (senderCompanyName !== '') {
                senderAddress.companyName = senderCompanyName;
            }

            if (senderReferencePerson !== '') {
                senderAddress.referencePerson = senderReferencePerson;
            }

            if (senderPhone !== '') {
                senderAddress.phone = senderPhone;
            }

            if (senderMobile !== '') {
                senderAddress.mobile = senderMobile;
            }

            if (senderEmail !== '') {
                senderAddress.email = senderEmail;
            }

            shipment.senderAddress = senderAddress;
        }

        let deliveryAddress = {};


        if (service.code === "CP") {
            deliveryAddress.country = pudo.address.country;
            deliveryAddress.administrativeLevel2 = pudo.address.administrative_level_2;
            deliveryAddress.administrativeLevel3 = pudo.address.administrative_level_3;
            deliveryAddress.city = pudo.address.city;
            deliveryAddress.postalCode = pudo.address.postalCode;
            deliveryAddress.street = pudo.address.street;
            deliveryAddress.referencePerson = pudo.address.referencePerson;
            deliveryAddress.companyName = pudo.address.companyName;
            deliveryAddress.email = pudo.address.email;
            deliveryAddress.mobile = pudo.address.mobile;
            deliveryAddress.phone = pudo.address.phone;
        } else {
            if (deliveryCountry !== null) {
                deliveryAddress.country = deliveryCountry.iso3166Alpha2;
            }
            if (freeDeliveryAdministrativeLevel1 !== '') {
                deliveryAddress.administrativeLevel1 = freeDeliveryAdministrativeLevel1;
            }

            if (freeDeliveryAdministrativeLevel2 !== '') {
                deliveryAddress.administrativeLevel2 = freeDeliveryAdministrativeLevel2;
            }

            if (freeDeliveryAdministrativeLevel3 !== '') {
                deliveryAddress.administrativeLevel3 = freeDeliveryAdministrativeLevel3;
            } else if (deliveryAdministrativeLevel3 !== null  && !deliveryAdministrativeLevel3) {
                deliveryAddress.administrativeLevel3 = deliveryAdministrativeLevel3?.code;
            }

            if (freeDeliveryCity !== '') {
                deliveryAddress.city = freeDeliveryCity;
            } else if (deliveryCity !== null) {
                deliveryAddress.city = deliveryCity.name;
            }

            if (freeDeliveryPostalCode !== '') {
                deliveryAddress.postalCode = freeDeliveryPostalCode;
            } else if (deliveryPostalCode !== null) {
                deliveryAddress.postalCode = deliveryPostalCode.postalCode;
            }

            if (deliveryStreet !== '') {
                deliveryAddress.street = deliveryStreet;
            }

            if (deliveryBuildingNr !== '') {
                deliveryAddress.buildingNr = deliveryBuildingNr;
            }

            if (deliveryCompanyName !== '') {
                deliveryAddress.companyName = deliveryCompanyName;
            }

            if (deliveryReferencePerson !== '') {
                deliveryAddress.referencePerson = deliveryReferencePerson;
            }

            if (deliveryPhone !== '') {
                deliveryAddress.phone = deliveryPhone;
            }

            if (deliveryMobile !== '') {
                deliveryAddress.mobile = deliveryMobile;
            }

            if (deliveryEmail !== '') {
                deliveryAddress.email = deliveryEmail;
            }
        }


        shipment.deliveryAddress = deliveryAddress;

        let pickupAddress = {};

        if (service.code === "CR") {
            pickupAddress.country = pudo.address.country;
            pickupAddress.administrativeLevel2 = pudo.address.administrative_level_2;
            pickupAddress.administrativeLevel3 = pudo.address.administrative_level_3;
            pickupAddress.city = pudo.address.city;
            pickupAddress.postalCode = pudo.address.postalCode;
            pickupAddress.street = pudo.address.street;
            pickupAddress.referencePerson = pudo.address.referencePerson;
            pickupAddress.companyName = pudo.address.companyName;
            pickupAddress.email = pudo.address.email;
            pickupAddress.mobile = pudo.address.mobile;
            pickupAddress.phone = pudo.address.phone;

            shipment.pickupAddress = pickupAddress;
        } else {
            if (pickupCountry !== null) {
                pickupAddress.country = pickupCountry.iso3166Alpha2;
                if (freePickupAdministrativeLevel1 !== '') {
                    pickupAddress.administrativeLevel1 = freePickupAdministrativeLevel1;
                }

                if (freePickupAdministrativeLevel2 !== '') {
                    pickupAddress.administrativeLevel2 = freePickupAdministrativeLevel2;
                }

                if (freePickupAdministrativeLevel3 !== '') {
                    pickupAddress.administrativeLevel3 = freePickupAdministrativeLevel3;
                } else if (pickupAdministrativeLevel3 !== null && !pickupAdministrativeLevel3) {
                    pickupAddress.administrativeLevel3 = pickupAdministrativeLevel3?.code;
                }

                if (freePickupCity !== '') {
                    pickupAddress.city = freePickupCity;
                } else if (pickupCity !== null) {
                    pickupAddress.city = pickupCity.name;
                }

                if (freePickupPostalCode !== '') {
                    pickupAddress.postalCode = freePickupPostalCode;
                } else if (pickupPostalCode !== null) {
                    pickupAddress.postalCode = pickupPostalCode.postalCode;
                }

                if (pickupStreet !== '') {
                    pickupAddress.street = pickupStreet;
                }

                if (pickupBuildingNr !== '') {
                    pickupAddress.buildingNr = pickupBuildingNr;
                }

                if (pickupCompanyName !== '') {
                    pickupAddress.companyName = pickupCompanyName;
                }

                if (pickupReferencePerson !== '') {
                    pickupAddress.referencePerson = pickupReferencePerson;
                }

                if (pickupPhone !== '') {
                    pickupAddress.phone = pickupPhone;
                }

                if (pickupMobile !== '') {
                    pickupAddress.mobile = pickupMobile;
                }

                if (pickupEmail !== '') {
                    pickupAddress.email = pickupEmail;
                }

                shipment.pickupAddress = pickupAddress;
            }
        }

        let endUserAddress = {};

        if (endUserCountry !== null) {
            endUserAddress.country = endUserCountry.iso3166Alpha2;
        }
        if (freeEndUserAdministrativeLevel1 !== '') {
            endUserAddress.administrativeLevel1 = freeEndUserAdministrativeLevel1;
        }

        if (freeEndUserAdministrativeLevel2 !== '') {
            endUserAddress.administrativeLevel2 = freeEndUserAdministrativeLevel2;
        }

        if (freeEndUserAdministrativeLevel3 !== '') {
            endUserAddress.administrativeLevel3 = freeEndUserAdministrativeLevel3;
        } else if (endUserAdministrativeLevel3 !== null && !endUserAdministrativeLevel3) {
            endUserAddress.administrativeLevel3 = endUserAdministrativeLevel3?.code;
        }

        if (freeEndUserCity !== '') {
            endUserAddress.city = freeEndUserCity;
        } else if (endUserCity !== null) {
            endUserAddress.city = endUserCity.name;
        }

        if (freeEndUserPostalCode !== '') {
            endUserAddress.postalCode = freeEndUserPostalCode;
        } else if (endUserPostalCode !== null) {
            endUserAddress.postalCode = endUserPostalCode.postalCode;
        }

        if (endUserStreet !== '') {
            endUserAddress.street = endUserStreet;
        }

        if (endUserBuildingNr !== '') {
            endUserAddress.buildingNr = endUserBuildingNr;
        }

        if (endUserCompanyName !== '') {
            endUserAddress.companyName = endUserCompanyName;
        }

        if (endUserReferencePerson !== '') {
            endUserAddress.referencePerson = endUserReferencePerson;
        }

        if (endUserPhone !== '') {
            endUserAddress.phone = endUserPhone;
        }

        if (endUserMobile !== '') {
            endUserAddress.mobile = endUserMobile;
        }

        if (endUserEmail !== '') {
            endUserAddress.email = endUserEmail;
        }

        if (pudo) {
            shipment.pudoId = pudo.id;
        }

        shipment.endUserAddress = endUserAddress;

        let notifications = [];

        let notification1 = {};
        notification1.type = "SHIPMENT_REGISTERED";
        notification1.email = registeredEmailChecked;
        notification1.textMessage = registeredTextChecked;

        notifications.push(notification1);

        let notification2 = {};
        notification2.type = "SHIPMENT_TAKEN_IN_CHARGE";
        notification2.email = takenInChargeEmailChecked;
        notification2.textMessage = takenInChargeTextChecked;

        notifications.push(notification2);

        let notification3 = {};
        notification3.type = "IN_DELIVERY";
        notification3.email = inDeliveryEmailChecked;
        notification3.textMessage = inDeliveryTextChecked;

        notifications.push(notification3);

        let notification4 = {};
        notification4.type = "DELIVERED";
        notification4.email = deliveredEmailChecked;
        notification4.textMessage = deliveredTextChecked;

        notifications.push(notification4);

        let notification5 = {};
        notification5.type = "DELIVERY_KO";
        notification5.email = deliveryKOEmailChecked;
        notification5.textMessage = deliveryKOTextChecked;

        notifications.push(notification5);

        let notification6 = {};
        notification6.type = "WAREHOUSE_STORAGE";
        notification6.email = warehouseStorageEmailChecked;
        notification6.textMessage = warehouseStorageTextChecked;

        notifications.push(notification6);

        let notification7 = {};
        notification7.type = "RETURN_REGISTERED";
        notification7.email = returnRegisteredEmailChecked;
        notification7.textMessage = returnRegisteredTextChecked;

        notifications.push(notification7);


        let notification8 = {};
        notification8.type = "RETURN_COLLECTED";
        notification8.email = returnCollectedEmailChecked;
        notification8.textMessage = returnCollectedTextChecked;

        notifications.push(notification8);


        let notification9 = {};
        notification9.type = "RETURN_DELIVERED";
        notification9.email = returnDeliveredEmailChecked;
        notification9.textMessage = returnDeliveredTextChecked;

        notifications.push(notification9);


        let notification10 = {};
        notification10.type = "PUDO_CHECK_IN";
        notification10.email = pudoCheckInEmailChecked;
        notification10.textMessage = pudoCheckInTextChecked;

        notifications.push(notification10);

        shipment.notifications = notifications;

        if (hasAuth) {
            let authentication = {};
            authentication.method = authMethod;
            if (authPin !== '') {
                authentication.key = authPin;
            }


            shipment.authentication = authentication;
        }

        shipment.notes = [];

        if (deliveryNotes !== '') {
            let note = {};
            note.noteType = "DELIVERY";
            note.note = deliveryNotes;

            shipment.notes.push(note);
        }

        if (pickupNotes !== '') {
            let note = {};
            note.noteType = "PICKUP";
            note.note = pickupNotes;

            shipment.notes.push(note);
        }

        shipment.parcels = [];

        for (let i = 0; i < parcels.length; i++) {
            let parcel = {};
            parcel.weight = parcels[i].weight;
            parcel.depth = parcels[i].depth;
            parcel.length = parcels[i].length;
            parcel.height = parcels[i].height;
            parcel.customerBarcode = parcels[i].customer_barcode;

            shipment.parcels.push(parcel);
        }

        shipment.autoClose = true;

        if (bookingDate !== '') {
            let booking = {};
            booking.date = bookingDate;
            booking.slotId = bookingSlot.id;
            booking.districtId = districtId;

            shipment.booking = booking;
        }

        if (exWarehouse) {
            shipment.departureWarehouseCode = departureWarehouse?.code;
        }


        if (null !== pickupDate) {
            shipment.pickupDate = pickupDate;
        }

        let shipmentService = new ShipmentService();
        shipmentService.createNewShipment(shipment)
            .then(response => {
                setTimeout(() => {
                    history.push(`/shipments/${response.shipmentId}/label`)
                    setLoadingConfirm(false);
                }, 1500)

            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoadingConfirm(false);
                });
            })


    }

    function clearPickUpAddress() {
        setPickupCountry(null);
        setPickupAdministrativeLevel3(null);
        setPickupCity(null);
        setPickupPostalCode(null);
        setFreePickupAdministrativeLevel1('');
        setFreePickupAdministrativeLevel2('');
        setFreePickupAdministrativeLevel3('');
        setFreePickupCity('');
        setFreePickupPostalCode('');
        setPickupStreet('');
        setPickupBuildingNr('');
        setPickupCompanyName('');
        setPickupReferencePerson('');
        setPickupEmail('');
        setPickupPhone('');
        setPickupMobile('');
    }


    async function setPickUpAddress(service) {

        if ((subContract || contract) && !service.reverse && !exWarehouse) {
            setLoadingPickupAddress(true);

            if (senderEqualPickup) {
                setLoadingSenderAddress(true);
            }
            let pickUpAddress = null;

            if (subContract && subContract.addresses.length !== 0) {
                pickUpAddress = subContract.addresses.filter(address => address.type === "OPERATING_CENTER" && address.default === true)[0]?.address;
            } else if (contract && contract.addresses.length !== 0) {
                pickUpAddress = contract.addresses.filter(address => address.type === "OPERATING_CENTER" && address.default === true)[0]?.address;

                if (!pickUpAddress) {
                    pickUpAddress = contract.addresses.filter(address => address.type === "REGISTERED_OFFICE" && address.default === true)[0]?.address;
                }
            }

            if (!pickUpAddress) {
                setLoadingPickupAddress(false);
                setLoadingSenderAddress(false);
                return;
            }


            let country = {};
            let countryService = new CountryService();
            await countryService.getCountriesByFilters(null, null, null, null)
                .then(data => {
                    if (pickUpAddress.country.length === 2) {
                        country = data.countries.filter(country => country.iso3166Alpha2 === pickUpAddress.country)[0];
                    } else {
                        country = data.countries.filter(country => country.iso3166Alpha3 === pickUpAddress.country)[0]
                    }

                })

            setPickupCountry(country);

            let city = null;
            let administrative3 = null;
            let postalCode = null;


            let cityService = new CityService();
            await cityService.getCities(null, null, null, null)
                .then(data => {
                    city = data.cities.filter(city => city.name.toLowerCase() === pickUpAddress.city.toLowerCase())[0]
                })


            let admin3Service = new AdministrativeLevel3Service();
            await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(data => {
                    if (pickUpAddress.administrativeLevel3 !== null) {
                        administrative3 = data.admins3.filter(admin3 => admin3.code === pickUpAddress.administrativeLevel3)[0]
                    } else {
                        administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                    }
                })


            let postalCodeService = new PostalCodeService();
            await postalCodeService.getAllPostalCodes(null, null, null, null)
                .then(data => {
                    postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === pickUpAddress.postalCode)[0];
                })


            setPickupAdministrativeLevel3(administrative3)
            setPickupCity(city)
            setPickupPostalCode(postalCode);

            setPickupCompanyName(pickUpAddress.companyName);
            setPickupStreet(pickUpAddress.street);
            setPickupBuildingNr(pickUpAddress.buildingNr);
            setPickupPhone(pickUpAddress.phone);
            setPickupMobile(pickUpAddress.mobile);
            setPickupEmail(pickUpAddress.email);
            setPickupReferencePerson(pickUpAddress.referencePerson);
            setPickupCompanyName(pickUpAddress.companyName);

            if (senderEqualPickup) {
                setSenderCountry(country);

                setFreeSenderCity('');
                setFreeSenderAdministrativeLevel1('');
                setFreeSenderAdministrativeLevel2('');
                setFreeSenderAdministrativeLevel3('');
                setFreeSenderPostalCode('');
                setSenderAdministrativeLevel3(administrative3);
                setSenderCity(city);
                setSenderPostalCode(postalCode);

                setSenderCompanyName(pickUpAddress.companyName);
                setSenderStreet(pickUpAddress.street);
                setSenderBuildingNr(pickUpAddress.buildingNr);
                setSenderPhone(pickUpAddress.phone);
                setSenderMobile(pickUpAddress.mobile);
                setSenderEmail(pickUpAddress.email);
                setSenderReferencePerson(pickUpAddress.referencePerson);
                setSenderCompanyName(pickUpAddress.companyName);
            }


            setDisabledPickupAddress(true);
        }

        setTimeout(() => {
            setLoadingPickupAddress(false)
            setLoadingSenderAddress(false)

        }, 1000);

    }

    function setSenderAddress() {
        setSenderCountry(pickupCountry);
        setSenderStreet(pickupStreet);
        setSenderBuildingNr(pickupBuildingNr);
        setSenderCompanyName(pickupCompanyName);
        setSenderReferencePerson(pickupReferencePerson);
        setSenderEmail(pickupEmail);
        setSenderPhone(pickupPhone);
        setSenderMobile(pickupMobile);
        setFreeSenderAdministrativeLevel1('');
        setFreeSenderAdministrativeLevel2('');
        setFreeSenderAdministrativeLevel3('');
        setFreeSenderCity('');
        setFreeSenderPostalCode('');
        setSenderPostalCode(pickupPostalCode);
        setSenderCity(pickupCity);
        setSenderAdministrativeLevel3(pickupAdministrativeLevel3)
    }

    function clearSenderAddress() {
        setSenderCountry(null);
        setSenderAdministrativeLevel3(null);
        setSenderCity(null);
        setSenderPostalCode(null);
        setSenderStreet('');
        setSenderBuildingNr('');
        setSenderCompanyName('');
        setSenderReferencePerson('');
        setSenderEmail('');
        setSenderPhone('');
        setSenderMobile('');

        setFreeSenderAdministrativeLevel1('');
        setFreeSenderAdministrativeLevel2('');
        setFreeSenderAdministrativeLevel3('');
        setFreeSenderCity('');
        setFreeSenderPostalCode('');

    }

    function setEndUserAddress() {
        setEndUserCountry(deliveryCountry);
        setEndUserStreet(deliveryStreet);
        setEndUserBuildingNr(deliveryBuildingNr);
        setEndUserCompanyName(deliveryCompanyName);
        setEndUserReferencePerson(deliveryReferencePerson);
        setEndUserEmail(deliveryEmail);
        setEndUserPhone(deliveryPhone);
        setEndUserMobile(deliveryMobile);

        setFreeEndUserAdministrativeLevel1('');
        setFreeEndUserAdministrativeLevel2('');
        setFreeEndUserAdministrativeLevel3('');
        setFreeEndUserCity('');
        setFreeEndUserPostalCode('');
        setEndUserPostalCode(deliveryPostalCode);
        setEndUserCity(deliveryCity);
        setEndUserAdministrativeLevel3(deliveryAdministrativeLevel3)

    }

    function clearEndUserAddress() {
        setEndUserCountry(null);
        setEndUserAdministrativeLevel3(null);
        setEndUserCity(null);
        setEndUserPostalCode(null);
        setEndUserStreet('');
        setEndUserBuildingNr('');
        setEndUserCompanyName('');
        setEndUserReferencePerson('');
        setEndUserEmail('');
        setEndUserPhone('');
        setEndUserMobile('');
        setFreeEndUserAdministrativeLevel1('');
        setFreeEndUserAdministrativeLevel2('');
        setFreeEndUserAdministrativeLevel3('');
        setFreeEndUserCity('');
        setFreeEndUserPostalCode('');
    }

    async function changeSenderEqualPickup() {
        setSenderEqualPickup(!senderEqualPickup);
        if (!senderEqualPickup) {
            setLoadingSenderAddress(true)
            await setSenderAddress();
            setLoadingSenderAddress(false)

        }
        // else {
        //     clearSenderAddress();
        // }
    }

    async function changeEndUserEqualDelivery() {

        setEndUserEqualDelivery(!endUserEqualDelivery);
        if (!endUserEqualDelivery) {
            setLoadingEndUserAddress(true)
            await setEndUserAddress();
            setLoadingEndUserAddress(false)
        }
        // else {
        //     clearEndUserAddress();
        // }

    }


    /** parcel functions */


    function addParcel() {
        setParcels([...parcels, new ParcelValues()]);
    }

    function removeParcel() {
        if (parcels.length > 1) {
            let newParcels = parcels;
            newParcels.splice(newParcels.length - 1, 1);
            setParcels(newParcels);
            setRefresh(!refresh);
        }

    }

    function handleParcelValueChange(index, event, key, _value) {
        let newParcels = [...parcels];
        newParcels[index][key] = _value;
        setParcels(newParcels);
    }

    function handleParcelBarcodeValueChange(index, event, key) {
        let newParcels = [...parcels];
        newParcels[index][key] = event.target.value;
        setParcels(newParcels);
    }

    /** notification functions */

    function handleRegisteredEmailChecked(event) {
        setRegisteredEmailChecked(event.target.checked);
    }

    function handleRegisteredTextChecked(event) {
        setRegisteredTextChecked(event.target.checked);
    }

    function handleRegisteredBothChecked(event) {
        setRegisteredEmailChecked(event.target.checked);
        setRegisteredTextChecked(event.target.checked);
    }

    function handleTakenInChargeEmailChecked(event) {
        setTakenInChargeEmailChecked(event.target.checked);
    }

    function handleTakenInChargeTextChecked(event) {
        setTakenInChargeTextChecked(event.target.checked);
    }

    function handleTakenInChargeBothChecked(event) {
        setTakenInChargeEmailChecked(event.target.checked);
        setTakenInChargeTextChecked(event.target.checked);
    }

    function handleInDeliveryEmailChecked(event) {
        setInDeliveryEmailChecked(event.target.checked);
    }

    function handleInDeliveryTextChecked(event) {
        setInDeliveryTextChecked(event.target.checked);
    }

    function handleInDeliveryBothChecked(event) {
        setInDeliveryEmailChecked(event.target.checked);
        setInDeliveryTextChecked(event.target.checked);
    }

    function handleDeliveredEmailChecked(event) {
        setDeliveredEmailChecked(event.target.checked);
    }

    function handleDeliveredTextChecked(event) {
        setDeliveredTextChecked(event.target.checked);
    }

    function handleDeliveredBothChecked(event) {
        setDeliveredEmailChecked(event.target.checked);
        setDeliveredTextChecked(event.target.checked);
    }

    function handleDeliveryKOEmailChecked(event) {
        setDeliveryKOEmailChecked(event.target.checked);
    }

    function handleDeliveryKOTextChecked(event) {
        setDeliveryKOTextChecked(event.target.checked);
    }

    function handleDeliveryKOBothChecked(event) {
        setDeliveryKOEmailChecked(event.target.checked);
        setDeliveryKOTextChecked(event.target.checked);
    }

    function handleWarehouseStorageEmailChecked(event) {
        setWarehouseStorageEmailChecked(event.target.checked);
    }

    function handleWarehouseStorageTextChecked(event) {
        setWarehouseStorageTextChecked(event.target.checked);
    }

    function handleWarehouseStorageBothChecked(event) {
        setWarehouseStorageEmailChecked(event.target.checked);
        setWarehouseStorageTextChecked(event.target.checked);
    }

    function handlePinCodeEmailChecked(event) {
        setPinCodeEmailChecked(event.target.checked);
    }

    function handlePinCodeTextChecked(event) {
        setPinCodeTextChecked(event.target.checked);
    }

    function handlePinCodeBothChecked(event) {
        setPinCodeEmailChecked(event.target.checked);
        setPinCodeTextChecked(event.target.checked);
    }


    function handleReturnRegisteredEmailChecked(event) {
        setReturnRegisteredEmailChecked(event.target.checked);
    }

    function handleReturnRegisteredTextChecked(event) {
        setReturnRegisteredTextChecked(event.target.checked);
    }

    function handleReturnRegisteredBothChecked(event) {
        setReturnRegisteredEmailChecked(event.target.checked);
        setReturnRegisteredTextChecked(event.target.checked);
    }

    function handleReturnCollectedEmailChecked(event) {
        setReturnCollectedEmailChecked(event.target.checked);
    }

    function handleReturnCollectedTextChecked(event) {
        setReturnCollectedTextChecked(event.target.checked);
    }

    function handleReturnCollectedBothChecked(event) {
        setReturnCollectedEmailChecked(event.target.checked);
        setReturnCollectedTextChecked(event.target.checked);
    }


    function handleReturnDeliveredEmailChecked(event) {
        setReturnDeliveredEmailChecked(event.target.checked);
    }

    function handleReturnDeliveredTextChecked(event) {
        setReturnDeliveredTextChecked(event.target.checked);
    }

    function handleReturnDeliveredBothChecked(event) {
        setReturnDeliveredEmailChecked(event.target.checked);
        setReturnDeliveredTextChecked(event.target.checked);
    }

    function handlePudoCheckInEmailChecked(event) {
        setPudoCheckInEmailChecked(event.target.checked);
    }

    function handlePudoCheckInTextChecked(event) {
        setPudoCheckInTextChecked(event.target.checked);
    }

    function handlePudoCheckInBothChecked(event) {
        setPudoCheckInEmailChecked(event.target.checked);
        setPudoCheckInTextChecked(event.target.checked);
    }


    /** pickup functions */
    function handlePickupCountryChange(event, country) {
        setLoadingPickupAddress(true)
        setPickupCountry(country);
        setPickupAdministrativeLevel3(null);
        setPickupCity(null);
        setPickupPostalCode(null);


        if (senderEqualPickup) {
            setLoadingSenderAddress(true)
            setSenderCountry(country);
            setSenderAdministrativeLevel3(null);
            setSenderCity(null);
            setSenderPostalCode(null);
        }

        setTimeout(() => {
            setLoadingPickupAddress(false);
            if (senderEqualPickup) {
                setLoadingSenderAddress(false)
            }
        }, 1000)
    }

    function handlePickupAdministrativeLevel3Change(event, adminl3) {
        setPickupAdministrativeLevel3(adminl3);
        setPickupCity(null);
        setPickupPostalCode(null);

        if (senderEqualPickup) {
            setSenderAdministrativeLevel3(adminl3);
            setSenderCity(null);
            setSenderPostalCode(null);
        }
    }

    function handleFreePickupAdministrativeLevel1Change(event) {
        setFreePickupAdministrativeLevel1(event.target.value);
        if (senderEqualPickup) {
            setFreeSenderAdministrativeLevel1(event.target.value);
        }
    }

    function handleFreePickupAdministrativeLevel2Change(event) {
        setFreePickupAdministrativeLevel2(event.target.value);
        if (senderEqualPickup) {
            setFreeSenderAdministrativeLevel2(event.target.value);
        }
    }

    function handleFreePickupAdministrativeLevel3Change(event) {
        setFreePickupAdministrativeLevel3(event.target.value);
        if (senderEqualPickup) {
            setFreeSenderAdministrativeLevel3(event.target.value);
        }
    }

    function handlePickupCityChange(event, city) {
        setPickupCity(city);
        setPickupPostalCode(null);
        if (senderEqualPickup) {
            setSenderCity(city);
            setSenderPostalCode(null);
        }
    }

    function handleFreePickupCityChange(event) {
        setFreePickupCity(event.target.value);
        if (senderEqualPickup) {
            setFreeSenderCity(event.target.value);
        }
    }

    function handleFreePickupPostalCodeChange(event) {
        setFreePickupPostalCode(event.target.value);
        if (senderEqualPickup) {
            setFreeSenderPostalCode(event.target.value);
        }
    }

    function handlePickupPostalCodeChange(event, postalCode) {
        setPickupPostalCode(postalCode);
        if (senderEqualPickup) {
            setSenderPostalCode(postalCode);
        }
    }

    function handlePickupStreetChange(event) {
        setPickupStreet(event.target.value);
        if (senderEqualPickup) {
            setSenderStreet(event.target.value);
        }
    }

    function handlePickupBuildingNrChange(event) {
        setPickupBuildingNr(event.target.value);
        if (senderEqualPickup) {
            setSenderBuildingNr(event.target.value);
        }
    }

    function handlePickupCompanyNameChange(event) {
        setPickupCompanyName(event.target.value);
        if (senderEqualPickup) {
            setSenderCompanyName(event.target.value);
        }
    }

    function handlePickupReferencePerson(event) {
        setPickupReferencePerson(event.target.value)
        if (senderEqualPickup) {
            setSenderReferencePerson(event.target.value);
        }
    }

    function handlePickupEmail(event) {
        setPickupEmail(event.target.value);
        if (senderEqualPickup) {
            setSenderEmail(event.target.value);
        }
    }

    function handlePickupPhone(event) {
        setPickupPhone(event.target.value);
        if (senderEqualPickup) {
            setSenderPhone(event.target.value);
        }
    }

    function handlePickupMobile(event) {
        setPickupMobile(event.target.value);
        if (senderEqualPickup) {
            setSenderMobile(event.target.value);
        }
    }

    /** delivery functions */
    function handleDeliveryCountryChange(event, country) {
        setLoadingDeliveryAddress(true)
        setDeliveryCountry(country);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (endUserEqualDelivery) {
            setLoadingEndUserAddress(true)
            setEndUserCountry(country);
            setEndUserAdministrativeLevel3(null);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }

        setTimeout(() => {
            setLoadingDeliveryAddress(false);
            if (endUserEqualDelivery) {
                setLoadingEndUserAddress(false)
            }
        }, 1000)
    }

    function handleDeliveryAdministrativeLevel3Change(event, adminl3) {
        setDeliveryAdministrativeLevel3(adminl3);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (endUserEqualDelivery) {
            setEndUserAdministrativeLevel3(adminl3);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }

    }

    function handleFreeDeliveryAdministrativeLevel1Change(event) {
        setFreeDeliveryAdministrativeLevel1(event.target.value);
        if (endUserEqualDelivery) {
            setFreeEndUserAdministrativeLevel1(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel2Change(event) {
        setFreeDeliveryAdministrativeLevel2(event.target.value);
        if (endUserEqualDelivery) {
            setFreeEndUserAdministrativeLevel2(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel3Change(event) {
        setFreeDeliveryAdministrativeLevel3(event.target.value);
        if (endUserEqualDelivery) {
            setFreeEndUserAdministrativeLevel3(event.target.value);
        }
    }

    function handleDeliveryCityChange(event, city) {
        setDeliveryCity(city);
        setDeliveryPostalCode(null);
        if (endUserEqualDelivery) {
            setEndUserCity(city);
            setEndUserPostalCode(null);
        }
    }

    function handleFreeDeliveryCityChange(event) {
        setFreeDeliveryCity(event.target.value);
        if (endUserEqualDelivery) {
            setFreeEndUserCity(event.target.value);
        }
    }

    function handleFreeDeliveryPostalCodeChange(event) {
        setFreeDeliveryPostalCode(event.target.value);
        if (endUserEqualDelivery) {
            setFreeEndUserPostalCode(event.target.value);
        }
    }

    function handleDeliveryPostalCodeChange(event, postalCode) {
        setDeliveryPostalCode(postalCode);
        if (endUserEqualDelivery) {
            setEndUserPostalCode(postalCode);
        }
    }

    function handleDeliveryStreetChange(event) {
        setDeliveryStreet(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserStreet(event.target.value);
        }
    }

    function handleDeliveryBuildingNrChange(event) {
        setDeliveryBuildingNr(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserBuildingNr(event.target.value);
        }
    }

    function handleDeliveryCompanyNameChange(event) {
        setDeliveryCompanyName(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserCompanyName(event.target.value);
        }
    }

    function handleDeliveryReferencePerson(event) {
        setDeliveryReferencePerson(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserReferencePerson(event.target.value);
        }
    }

    function handleDeliveryEmail(event) {
        setDeliveryEmail(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserEmail(event.target.value);
        }
    }

    function handleDeliveryPhone(event) {
        setDeliveryPhone(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserPhone(event.target.value);
        }
    }

    function handleDeliveryMobile(event) {
        setDeliveryMobile(event.target.value);
        if (endUserEqualDelivery) {
            setEndUserMobile(event.target.value);
        }
    }

    /** sender functions */

    function handleSenderCountryChange(event, country) {
        setLoadingSenderAddress(true)
        setSenderCountry(country);
        setSenderAdministrativeLevel3(null);
        setSenderCity(null);
        setSenderPostalCode(null);
        setTimeout(() => {
            setLoadingSenderAddress(false);
        }, 1000)
    }

    function handleSenderAdministrativeLevel3Change(event, adminl3) {
        setSenderAdministrativeLevel3(adminl3);
        setSenderCity(null);
        setSenderPostalCode(null);
    }

    function handleFreeSenderAdministrativeLevel1Change(event) {
        setFreeSenderAdministrativeLevel1(event.target.value);
    }

    function handleFreeSenderAdministrativeLevel2Change(event) {
        setFreeSenderAdministrativeLevel2(event.target.value);
    }

    function handleFreeSenderAdministrativeLevel3Change(event) {
        setFreeSenderAdministrativeLevel3(event.target.value);
    }

    function handleSenderCityChange(event, city) {
        setSenderCity(city);
        setSenderPostalCode(null);
    }

    function handleFreeSenderCityChange(event) {
        setFreeSenderCity(event.target.value);
    }

    function handleFreeSenderPostalCodeChange(event) {
        setFreeSenderPostalCode(event.target.value);
    }

    function handleSenderPostalCodeChange(event, postalCode) {
        setSenderPostalCode(postalCode);
    }

    function handleSenderStreetChange(event) {
        setSenderStreet(event.target.value);
    }

    function handleSenderBuildingNrChange(event) {
        setSenderBuildingNr(event.target.value);
    }

    function handleSenderCompanyNameChange(event) {
        setSenderCompanyName(event.target.value);
    }

    function handleSenderReferencePerson(event) {
        setSenderReferencePerson(event.target.value);
    }

    function handleSenderEmail(event) {
        setSenderEmail(event.target.value);
    }

    function handleSenderPhone(event) {
        setSenderPhone(event.target.value);
    }

    function handleSenderMobile(event) {
        setSenderMobile(event.target.value);
    }

    /** endUser functions */


    function handleEndUserCountryChange(event, country) {
        setLoadingEndUserAddress(true)
        setEndUserCountry(country);
        setEndUserAdministrativeLevel3(null);
        setEndUserCity(null);
        setEndUserPostalCode(null);
        setTimeout(() => {
            setLoadingEndUserAddress(false);
        }, 1000)
    }

    function handleEndUserAdministrativeLevel3Change(event, adminl3) {
        setEndUserAdministrativeLevel3(adminl3);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserAdministrativeLevel1Change(event) {
        setFreeEndUserAdministrativeLevel1(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel2Change(event) {
        setFreeEndUserAdministrativeLevel2(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel3Change(event) {
        setFreeEndUserAdministrativeLevel3(event.target.value);
    }

    function handleEndUserCityChange(event, city) {
        setEndUserCity(city);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserCityChange(event, city) {
        setFreeEndUserCity(event.target.value);
    }

    function handleFreeEndUserPostalCodeChange(event, postalCode) {
        setFreeEndUserPostalCode(event.target.value);
    }

    function handleEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(postalCode);
    }

    function handleEndUserStreetChange(event) {
        setEndUserStreet(event.target.value);
    }

    function handleEndUserBuildingNrChange(event) {
        setEndUserBuildingNr(event.target.value);
    }

    function handleEndUserCompanyNameChange(event) {
        setEndUserCompanyName(event.target.value);
    }

    function handleEndUserReferencePerson(event) {
        setEndUserReferencePerson(event.target.value);
    }

    function handleEndUserEmail(event) {
        setEndUserEmail(event.target.value);
    }

    function handleEndUserPhone(event) {
        setEndUserPhone(event.target.value);
    }

    function handleEndUserMobile(event) {
        setEndUserMobile(event.target.value);
    }

    /** other functions */

    function handleReferenceChange(event) {
        setReference(event.target.value);
    }

    function handleCustomerReferenceChange(event) {
        setCustomerReference(event.target.value);
    }

    function handleCustomerCodeChange(event) {
        setCustomerCode(event.target.value);
    }

    function handleOrderNumberChange(event) {
        setOrderNumber(event.target.value);
    }

    function handleCodValueChange(event, value) {
        setCodValue(value)
    }

    function handleInsuranceValueChange(event, value) {
        setInsuranceValue(value)
    }

    function handlePudoChange(event, pudo) {
        setPudo(pudo);
    }

    function handleServiceChange(event, service) {
        setLoadingDeliveryAddress(true);
        setLoadingEndUserAddress(true);
        setService(service);
        setPudo(null);
        setPudos([]);
        if (service === null) {
            setServiceHelperText("required")
            clearPickUpAddress();
            clearSenderAddress();
            setDeliveryCountry(null);
            setEndUserCountry(null);
            setLoadingDeliveryAddress(false);
            setLoadingEndUserAddress(false);
        } else {
            setEndUserEqualDelivery(service.code !== "CP");
            setSenderEqualPickup(service.code !== "CR")
            setServiceHelperText(null);
            setPickUpAddress(service);
            setDeliveryCountry(countries.filter(country => country.iso3166Alpha3 === contract.companyCountry)[0]);
            setEndUserCountry(countries.filter(country => country.iso3166Alpha3 === contract.companyCountry)[0]);
            setLoadingDeliveryAddress(false);
            setLoadingEndUserAddress(false);
        }

    }

    function handleCodCurrencyChange(event, currency) {
        setCodCurrency(currency);
    }

    function handleInsuranceCurrencyChange(event, currency) {
        setInsuranceCurrency(currency);
    }

    function handleCodModeChange(event, codMode) {
        setCodMode(codMode);
    }

    function handleHasCodChange() {
        setCod(!hasCod);
        if (hasCod === true) {
            setCodMode(null);
            setCodValue(null);
            setCodCurrency(null);
        } else {
            setCodMode(codModes[0]);
            setCodValue(0);
            setCodCurrency(currencies[0]);
        }
    }

    function handleHasInsuranceChange() {
        setInsurance(!hasInsurance);
        if (hasInsurance === true) {
            setInsuranceValue(null);
            setInsuranceCurrency(null);
        } else {
            setInsuranceValue(0)
            setInsuranceCurrency(currencies[0]);
        }
    }

    function handleHasAuthChange() {
        setAuth(!hasAuth);
        setAuthMethod('');
        setAuthPin('');
    }

    function handleAccountChange(event, account) {
        setAccount(account)
        setContract(null);
        setSubContract(null);
        setService(null);
        setContracts([]);
        setSubContracts([]);
        setServices([]);
        clearPickUpAddress();
        clearSenderAddress();
        if (account === null) {
            setServiceHelperText("select_contract_first");
            setContractHelperText("select_account_first")
        } else {
            setServiceHelperText("required")
            setContractHelperText("required")
        }
    }

    function clearNotifications() {
        setRegisteredEmailChecked(false);
        setRegisteredTextChecked(false);

        setInDeliveryEmailChecked(false);
        setInDeliveryTextChecked(false);

        setDeliveredEmailChecked(false);
        setDeliveredTextChecked(false);

        setDeliveryKOEmailChecked(false);
        setDeliveryKOTextChecked(false);

        setWarehouseStorageEmailChecked(false);
        setWarehouseStorageTextChecked(false);

        setPinCodeEmailChecked(false);
        setPinCodeTextChecked(false);

        setReturnDeliveredTextChecked(false)
        setReturnDeliveredEmailChecked(false)

        setReturnRegisteredTextChecked(false);
        setReturnRegisteredEmailChecked(false);

        setReturnCollectedTextChecked(false);
        setReturnCollectedEmailChecked(false);
    }

    function clearCod() {
        setCodMode(null);
        setCodCurrency(null);
        setCodValue(null);
        setCod(false);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
        setSubContract(null);
        setService(null);
        setSubContracts([]);
        setServices([]);
        clearCod();
        clearPickUpAddress();
        clearSenderAddress();
        clearNotifications();
        if (contract === null) {
            setContractHelperText("required")
        } else {
            setContractHelperText(null)
            for (let i = 0; i < contract.notifications.length; ++i) {
                const notification = contract.notifications[i];
                switch (notification.type) {
                    case "SHIPMENT_REGISTERED":
                        setRegisteredEmailChecked(notification.email);
                        setRegisteredTextChecked(notification.textMessage);
                        break;
                    case "IN_DELIVERY":
                        setInDeliveryEmailChecked(notification.email);
                        setInDeliveryTextChecked(notification.textMessage);
                        break;
                    case "DELIVERED":
                        setDeliveredEmailChecked(notification.email);
                        setDeliveredTextChecked(notification.textMessage);
                        break;
                    case "DELIVERY_KO":
                        setDeliveryKOEmailChecked(notification.email);
                        setDeliveryKOTextChecked(notification.textMessage);
                        break;
                    case "WAREHOUSE_STORAGE":
                        setWarehouseStorageEmailChecked(notification.email);
                        setWarehouseStorageTextChecked(notification.textMessage);
                        break;
                    case "PIN_CODE":
                        setPinCodeEmailChecked(notification.email);
                        setPinCodeTextChecked(notification.textMessage);
                        break;
                    case "RETURN_REGISTERED":
                        setReturnRegisteredEmailChecked(notification.email);
                        setReturnRegisteredTextChecked(notification.textMessage);
                        break;
                    case "RETURN_COLLECTED":
                        setReturnCollectedEmailChecked(notification.email);
                        setReturnCollectedTextChecked(notification.textMessage);
                        break;
                    case "RETURN_DELIVERED":
                        setReturnDeliveredEmailChecked(notification.email);
                        setReturnDeliveredTextChecked(notification.textMessage);
                        break;
                    case "PUDO_CHECK_IN":
                        setPudoCheckInEmailChecked(notification.email);
                        setPudoCheckInTextChecked(notification.textMessage);
                        break;
                }
            }
        }
    }

    function handleSubContractChange(event, subContract) {
        setSubContract(subContract);
        setService(null);
        setServices([]);
        clearPickUpAddress();
        clearSenderAddress();
    }


    function handlePickupNotesChange(event) {
        setPickupNotes(event.target.value);
    }

    function handleDeliveryNotesChange(event) {
        setDeliveryNotes(event.target.value);
    }

    function fetchCurrencies() {
        let shipmentService = new ShipmentService();
        shipmentService.getCurrencies()
            .then(currencies => {
                setCurrencies(currencies);
                setCodCurrency(currencies[0])
                setInsuranceCurrency(currencies[0])
            })
            .then(error => {

            })
    }

    async function fetchPudos() {

        if (pudos.length < 1) {
            setLoadingFetch(true);

            let filters = {};

            if (service.code === "CP") {
                filters.type = "PRIMAEDICOLA";
            }

            if (service.code === "U") {
                filters.type = "URBANE";
            }

            await new PudoService().getPudos()
                .then(result => {
                    setPudos(result.pudos);
                    setLoadingFetch(false);
                })
                .catch(error => {
                    setLoadingFetch(false);
                })
        }

    }


    function fetchCodModes() {

        let filters = {};

        if (contract) {
            filters.contractId = contract?.id;
        }


        let shipmentService = new ShipmentService();
        shipmentService.getCodModes(filters)
            .then(codModes => {
                setCodModes(codModes);
                setCodMode(codModes[0])
            })
            .then(error => {

            })
    }

    function fetchServices() {
        setLoadingFetch(true);
        let filters = {};

        filters.accountCorporateCode = account.accountCorporateCode;
        filters.contractCode = contract.code;

        let serviceService = new ServiceService();
        serviceService.getServicesByFilters(filters)
            .then(data => {
                setServices(data);
                setLoadingFetch(false);
            })
            .catch(error => {
                setLoadingFetch(false);
            })
    }

    function fetchAccounts() {
        setLoadingFetch(true);
        let accountService = new AccountService();
        accountService.getAccounts()
            .then(accounts => {
                setAccounts(accounts);
                setLoadingFetch(false);
            })
            .catch(error => {
                setLoadingFetch(false);
            })
    }

    function fetchContracts() {
        setLoadingFetch(true);
        let contractService = new ContractService();
        let filters = {}
        if (account) {
            filters.accountId = account.id
        }
        contractService.getContractsByFilters(filters)
            .then(contracts => {
                setContracts(contracts)
                setLoadingFetch(false);
            })
            .catch(error => {
                setLoadingFetch(false);
            })
    }

    function fetchSubContracts() {
        setLoadingFetch(true);
        let subContractService = new SubContractService();
        let filters = {}
        if (contract) {
            filters.contractId = contract.id;
        }
        subContractService.getSubContractsByFilters(filters)
            .then(subContracts => {
                setSubContracts(subContracts);
                setLoadingFetch(false);
            })
            .catch(error => {
                setLoadingFetch(false);
            })
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    function fetchAdministrativeLevel3(country) {

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdministrativeLevel3(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function openCalendar() {
        setOpenBooking(true);
        fetchNewWeek(calendarBatchCount);
    }

    function closeCalendar() {
        setOpenBooking(false);
        setCalendarBatchCount(0);
    }

    async function fetchNewWeek(batch) {
        setCalendarLoading(true);

        let dates = [];

        for (let i = 0; i < 7; i++) {
            let date = today + (7 * batch * 86400000) + 86400000 + i * 86400000;
            let formattedDate = format(date, "yyyy-MM-dd");
            dates.push(formattedDate);
        }

        let address = {};

        if (service.code === "R") {
            if (pickupCountry !== null) {
                address.country = pickupCountry.iso3166Alpha2;
            }
            if (pickupPostalCode !== null) {
                address.postalCode = pickupPostalCode.postalCode;
            } else if (freePickupPostalCode !== '') {
                address.postalCode = freePickupPostalCode;
            }

            if (pickupCity !== null) {
                address.city = pickupCity.name;
            } else if (freePickupCity !== '') {
                address.city = freePickupCity;
            }

            if (pickupStreet !== '') {
                address.street = pickupStreet;
            }
        } else {
            if (deliveryCountry !== null) {
                address.country = deliveryCountry.iso3166Alpha2;
            }
            if (deliveryPostalCode !== null) {
                address.postalCode = deliveryPostalCode.postalCode;
            } else if (freeDeliveryPostalCode !== '') {
                address.postalCode = freeDeliveryPostalCode;
            }

            if (deliveryCity !== null) {
                address.city = deliveryCity.name;
            } else if (freeDeliveryCity !== '') {
                address.city = freeDeliveryCity;
            }

            if (deliveryStreet !== '') {
                address.street = deliveryStreet;
            }
        }


        let slotService = new SlotService();

        await slotService.getBookingSlotsByAddress(dates, service.code, address, true)
            .then(data => {

                setCalendar(data.dateAndSlots);
                setDistrictId(data.district.id);

                // let datesAndSlotsInputDto = [];
                //
                //
                // data.dateAndSlots.map(value => {
                //     let auxDateAndSlots = {};
                //     auxDateAndSlots.date = value.date;
                //     auxDateAndSlots.slots = [];
                //     value.slots.map(slot => {
                //         let auxSlot = {};
                //         auxSlot.fromTime = slot.fromTime;
                //         auxSlot.toTime = slot.toTime;
                //
                //         auxDateAndSlots.slots.push(auxSlot);
                //
                //     })
                //     datesAndSlotsInputDto.push(auxDateAndSlots);
                // })
                //
                // setDistrictId(data.district.id)
                //
                // slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                //     .then(responseData => {
                //         setCalendar(responseData);
                //     })
                //     .catch(error => {
                //
                //     })
            })
            .catch(error => {
                error.response.json().then(response => {

                })
            })

        setTimeout(() => {
            setCalendarLoading(false);
        }, 1000);

    }

    function fetchPreviousWeek() {
        let batch = calendarBatchCount;
        batch -= 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function fetchNextWeek() {
        let batch = calendarBatchCount;
        batch += 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function formatDate(date) {
        let parsedDate = new Date(date);

        return parsedDate.toLocaleDateString();

    }

    function confirmBooking(date, slot) {
        setBookingDate(date);
        setBookingSlot(slot);
        setOpenBooking(false);
    }

    function generatePickupAddress(country) {

        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!pickupCountry || disabledPickupAddress}
                    getOptionLabel={option => option.name}
                    value={pickupAdministrativeLevel3}
                    defaultValue={pickupAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handlePickupAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(pickupCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={pickupAdministrativeLevel3}
                            defaultValue={pickupAdministrativeLevel3}
                            required
                            error={!pickupAdministrativeLevel3}
                            helperText={!pickupAdministrativeLevel3 ? t("required") : null}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!pickupAdministrativeLevel3 || disabledPickupAddress}
                    getOptionLabel={option => option.name}
                    value={pickupCity}
                    defaultValue={pickupCity}
                    noOptionsText={t("no_options")}
                    onChange={handlePickupCityChange}
                    onOpen={() => fetchCities(pickupAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={pickupCity}
                            defaultValue={pickupCity}
                            required
                            error={!pickupCity}
                            helperText={!pickupAdministrativeLevel3 ? t("select_admin3_first") : (!pickupCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    disabled={disabledPickupAddress || !pickupCity}
                    getOptionLabel={option => option.postalCode}
                    value={pickupPostalCode}
                    defaultValue={pickupPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handlePickupPostalCodeChange}
                    onOpen={() => fetchPostalCodes(pickupCity, pickupAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={pickupPostalCode}
                            defaultValue={pickupPostalCode}
                            required
                            error={!pickupPostalCode}
                            helperText={!pickupPostalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        disabled={disabledPickupAddress}
                        variant={"outlined"}
                        size={"small"}
                        value={freePickupAdministrativeLevel1}
                        onChange={handleFreePickupAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        disabled={disabledPickupAddress}
                        variant={"outlined"}
                        size={"small"}
                        value={freePickupAdministrativeLevel2}
                        onChange={handleFreePickupAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>


                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        disabled={disabledPickupAddress}
                        size={"small"}
                        value={freePickupAdministrativeLevel3}
                        onChange={handleFreePickupAdministrativeLevel3Change}
                    />


                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        disabled={disabledPickupAddress}
                        size={"small"}
                        value={freePickupCity}
                        onChange={handleFreePickupCityChange}
                        required
                        error={!freePickupCity}
                        helperText={!freePickupCity ? t("required") : null}
                    />

                    <TextField
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        style={{marginInline: "2%"}}
                        value={freePickupPostalCode}
                        disabled={disabledPickupAddress}
                        onChange={handleFreePickupPostalCodeChange}
                        required
                        error={!freePickupPostalCode}
                        helperText={!freePickupPostalCode ? t("required") : null}
                    />


                </div>
            </>
        }

    }

    function generateDeliveryAddress(country) {

        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!deliveryCountry}
                    getOptionLabel={option => option.name}
                    value={deliveryAdministrativeLevel3}
                    defaultValue={deliveryAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(deliveryCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryAdministrativeLevel3}
                            defaultValue={deliveryAdministrativeLevel3}
                            required
                            error={!deliveryAdministrativeLevel3}
                            helperText={!deliveryAdministrativeLevel3 ? t("required") : null}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!deliveryAdministrativeLevel3}
                    getOptionLabel={option => option.name}
                    value={deliveryCity}
                    defaultValue={deliveryCity}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryCityChange}
                    onOpen={() => fetchCities(deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryCity}
                            defaultValue={deliveryCity}
                            required
                            error={!deliveryCity}
                            helperText={!deliveryAdministrativeLevel3 ? t("select_admin3_first") : (!deliveryCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={deliveryPostalCode}
                    disabled={!deliveryCity}
                    defaultValue={deliveryPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryPostalCodeChange}
                    onOpen={() => fetchPostalCodes(deliveryCity, deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryPostalCode}
                            defaultValue={deliveryPostalCode}
                            required
                            error={!deliveryPostalCode}
                            helperText={!deliveryPostalCode ? t("required") : null}
                        />
                    )}
                />
            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel1}
                        onChange={handleFreeDeliveryAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel2}
                        onChange={handleFreeDeliveryAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel3}
                        onChange={handleFreeDeliveryAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryCity}
                        onChange={handleFreeDeliveryCityChange}
                        required
                        error={!freeDeliveryCity}
                        helperText={!freeDeliveryCity ? t("required") : null}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryPostalCode}
                        onChange={handleFreeDeliveryPostalCodeChange}
                        required
                        error={!freeDeliveryPostalCode}
                        helperText={!freeDeliveryPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }

    }

    function generateSenderAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!senderCountry || senderEqualPickup}
                    getOptionLabel={option => option.name}
                    value={senderAdministrativeLevel3}
                    defaultValue={senderAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleSenderAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(senderCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={senderAdministrativeLevel3}
                            defaultValue={senderAdministrativeLevel3}
                            required
                            error={!senderAdministrativeLevel3}
                            helperText={!senderAdministrativeLevel3 ? t("required") : null}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!senderAdministrativeLevel3 || senderEqualPickup}
                    getOptionLabel={option => option.name}
                    value={senderCity}
                    defaultValue={senderCity}
                    noOptionsText={t("no_options")}
                    onChange={handleSenderCityChange}
                    onOpen={() => fetchCities(senderAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={senderCity}
                            defaultValue={senderCity}
                            required
                            error={!senderCity}
                            helperText={!senderAdministrativeLevel3 ? t("select_admin3_first") : (!senderCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    disabled={senderEqualPickup || !senderCity}
                    getOptionLabel={option => option.postalCode}
                    value={senderPostalCode}
                    defaultValue={senderPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleSenderPostalCodeChange}
                    onOpen={() => fetchPostalCodes(senderCity, senderAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={senderPostalCode}
                            defaultValue={senderPostalCode}
                            required
                            error={!senderPostalCode}
                            helperText={!senderPostalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        disabled={senderEqualPickup}
                        variant={"outlined"}
                        size={"small"}
                        value={freeSenderAdministrativeLevel1}
                        onChange={handleFreeSenderAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        disabled={senderEqualPickup}
                        variant={"outlined"}
                        size={"small"}
                        value={freeSenderAdministrativeLevel2}
                        onChange={handleFreeSenderAdministrativeLevel2Change}
                    />
                </div>

                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        disabled={senderEqualPickup}
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeSenderAdministrativeLevel3}
                        onChange={handleFreeSenderAdministrativeLevel3Change}
                    />

                    <TextField
                        disabled={senderEqualPickup}
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeSenderCity}
                        required
                        error={!freeSenderCity}
                        helperText={!freeSenderCity ? t("required") : null}
                        onChange={handleFreeSenderCityChange}
                    />

                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        disabled={senderEqualPickup}
                        variant={"outlined"}
                        size={"small"}
                        value={freeSenderPostalCode}
                        onChange={handleFreeSenderPostalCodeChange}
                        required
                        error={!freeSenderPostalCode}
                        helperText={!freeSenderPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function generateEndUserAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!endUserCountry || endUserEqualDelivery}
                    getOptionLabel={option => option.name}
                    value={endUserAdministrativeLevel3}
                    defaultValue={endUserAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(endUserCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={endUserAdministrativeLevel3}
                            defaultValue={endUserAdministrativeLevel3}
                            required
                            error={!endUserAdministrativeLevel3}
                            helperText={!endUserAdministrativeLevel3 ? t("required") : null}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!endUserAdministrativeLevel3 || endUserEqualDelivery}
                    getOptionLabel={option => option.name}
                    value={endUserCity}
                    defaultValue={endUserCity}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserCityChange}
                    onOpen={() => fetchCities(endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={endUserCity}
                            defaultValue={endUserCity}
                            required
                            error={!endUserCity}
                            helperText={!endUserAdministrativeLevel3 ? t("select_admin3_first") : (!endUserCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    disabled={endUserEqualDelivery || !endUserCity}
                    getOptionLabel={option => option.postalCode}
                    value={endUserPostalCode}
                    defaultValue={endUserPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserPostalCodeChange}
                    onOpen={() => fetchPostalCodes(endUserCity, endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={endUserPostalCode}
                            defaultValue={endUserPostalCode}
                            required
                            error={!endUserPostalCode}
                            helperText={!endUserPostalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        disabled={endUserEqualDelivery}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel1}
                        onChange={handleFreeEndUserAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        disabled={endUserEqualDelivery}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel2}
                        onChange={handleFreeEndUserAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        disabled={endUserEqualDelivery}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel3}
                        onChange={handleFreeEndUserAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        disabled={endUserEqualDelivery}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserCity}
                        required
                        error={!freeEndUserCity}
                        helperText={!freeEndUserCity ? t("required") : null}
                        onChange={handleFreeEndUserCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        disabled={endUserEqualDelivery}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserPostalCode}
                        onChange={handleFreeEndUserPostalCodeChange}
                        required
                        error={!freeEndUserPostalCode}
                        helperText={!freeEndUserPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function generateContractForm() {
        return <Card elevation={0} style={{display: "flex", margin: "2%"}}>
            <Autocomplete
                id="account"
                size="small"
                style={{minWidth: "20%", width: "fit-content", marginLeft: "auto"}}
                options={accounts}
                required
                getOptionLabel={option => option.companyName}
                value={account}
                defaultValue={account}
                onOpen={fetchAccounts}
                loading={loadingFetch}
                loadingText={<CircularProgress size={24}/>}
                onChange={handleAccountChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("account")}
                        variant="outlined"
                        margin="dense"
                        value={account}
                        defaultValue={account}
                        required
                        error={!account}
                        helperText={!account ? t("required") : null}
                    />
                )}
            />
            <Autocomplete
                id="contract"
                size="small"
                disabled={!account}
                required
                style={{minWidth: "20%", width: "fit-content", marginInline: "2%"}}
                options={contracts}
                getOptionLabel={option => option.code}
                value={contract}
                defaultValue={contract}
                loading={loadingFetch}
                loadingText={<CircularProgress size={24}/>}
                onOpen={fetchContracts}
                onChange={handleContractChange}
                noOptionsText={t("no_contracts_for_account")}
                blurOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        value={contract}
                        defaultValue={contract}
                        required
                        error={!contract}
                        helperText={t(contractHelperText)}
                    />
                )}
            />
            <Autocomplete
                id="subContract"
                size="small"
                disabled={!contract}
                style={{minWidth: "20%", width: "fit-content", marginRight: "auto"}}
                options={subContracts}
                getOptionLabel={option => option.code}
                noOptionsText={t("no_sub-contracts_for_contract")}
                value={subContract}
                defaultValue={subContract}
                loading={loadingFetch}
                loadingText={<CircularProgress size={24}/>}
                onChange={handleSubContractChange}
                onOpen={fetchSubContracts}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("sub-contract")}
                        variant="outlined"
                        margin="dense"
                        value={subContract}
                        defaultValue={subContract}
                        helperText={!contract ? t("select_contract_first") : null}
                    />
                )}
            />

        </Card>
    }


    function generateInsuranceForm() {
        return <Card elevation={0}>

            <CurrencyTextField
                label={t("value")}
                variant={"outlined"}
                margin={"dense"}
                decimalPlaces={2}
                minimumValue={0}
                value={insuranceValue}
                digitGroupSeparator={"."}
                decimalCharacter={","}
                currencySymbol={insuranceCurrency ? insuranceCurrency.symbol : null}
                onChange={handleInsuranceValueChange}
            />
            <Autocomplete
                id="currencies"
                size="small"
                style={{minWidth: "40%", width: "fit-content", margin: "auto"}}
                options={currencies}
                getOptionLabel={option => option.name + " (" + option.symbol + ")"}
                value={insuranceCurrency}
                defaultValue={insuranceCurrency}
                noOptionsText={t("no_options")}
                onChange={handleInsuranceCurrencyChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("currency")}
                        variant="outlined"
                        margin="dense"
                        value={insuranceCurrency}
                        defaultValue={insuranceCurrency}
                    />
                )}
            />
        </Card>
    }

    function generateAuthForm() {
        return <Card elevation={0}>

            <Select style={{minWidth: "40%"}}
                    labelId="method"
                    variant={"outlined"}
                    value={authMethod}
                    onChange={(event) => setAuthMethod(event.target.value)}
                    options={option => option.method}
                    defaultValue={authMethod}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        classes: {paper: classes.menuPaper}
                    }}
            >
                {authMethods.map((method, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={method}
                            >
                                {method}
                            </MenuItem>
                        )
                    }
                )}
            </Select>

        </Card>
    }

    function generateCodForm() {
        return <Card elevation={0}>

            <CurrencyTextField
                label={t("value")}
                variant={"outlined"}
                margin={"dense"}
                decimalPlaces={2}
                digitGroupSeparator={"."}
                decimalCharacter={","}
                minimumValue={0}
                value={codValue}
                currencySymbol={codCurrency ? codCurrency.symbol : null}
                onChange={handleCodValueChange}
            />
            <Autocomplete
                id="currencies"
                size="small"
                style={{minWidth: "40%", width: "fit-content", margin: "auto"}}
                options={currencies}
                getOptionLabel={option => option.name + " (" + option.symbol + ")"}
                value={codCurrency}
                defaultValue={codCurrency}
                noOptionsText={t("no_options")}
                onChange={handleCodCurrencyChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("currency")}
                        variant="outlined"
                        margin="dense"
                        value={codCurrency}
                        defaultValue={codCurrency}
                    />
                )}
            />

            <Autocomplete
                id="codMode"
                size="small"
                style={{width: "60%", margin: "auto"}}
                options={codModes}
                getOptionLabel={option => option.name}
                value={codMode}
                defaultValue={codMode}
                onChange={handleCodModeChange}
                noOptionsText={t("no_options")}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("cod_mode")}
                        variant="outlined"
                        margin="dense"
                        multiline
                        value={codMode}
                        defaultValue={codMode}
                    />
                )}
            />

        </Card>

    }


    function generateNotificationCheckboxes() {

        return <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: "center", marginBlock: "5%", ml: 1}}>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_registered")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={registeredEmailChecked && registeredTextChecked}
                            indeterminate={registeredEmailChecked !== registeredTextChecked}
                            onChange={handleRegisteredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={registeredEmailChecked}
                                           onChange={handleRegisteredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={registeredTextChecked}
                                           onChange={handleRegisteredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_taken_in_charge")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={takenInChargeEmailChecked && takenInChargeTextChecked}
                            indeterminate={takenInChargeEmailChecked !== takenInChargeTextChecked}
                            onChange={handleTakenInChargeBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={takenInChargeEmailChecked}
                                           onChange={handleTakenInChargeEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} A
                                           checked={takenInChargeTextChecked}
                                           onChange={handleTakenInChargeTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_in_delivery")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={inDeliveryEmailChecked && inDeliveryTextChecked}
                            indeterminate={inDeliveryEmailChecked !== inDeliveryTextChecked}
                            onChange={handleInDeliveryBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={inDeliveryEmailChecked}
                                           onChange={handleInDeliveryEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={inDeliveryTextChecked}
                                           onChange={handleInDeliveryTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_delivered")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={deliveredEmailChecked && deliveredTextChecked}
                            indeterminate={deliveredEmailChecked !== deliveredTextChecked}
                            onChange={handleDeliveredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={deliveredEmailChecked}
                                           onChange={handleDeliveredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={deliveredTextChecked}
                                           onChange={handleDeliveredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_delivery_ko")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={deliveryKOEmailChecked && deliveryKOTextChecked}
                            indeterminate={deliveryKOEmailChecked !== deliveryKOTextChecked}
                            onChange={handleDeliveryKOBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={deliveryKOEmailChecked}
                                           onChange={handleDeliveryKOEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={deliveryKOTextChecked}
                                           onChange={handleDeliveryKOTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("shipment_pin_code")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={pinCodeEmailChecked && pinCodeTextChecked}
                            indeterminate={pinCodeEmailChecked !== pinCodeTextChecked}
                            onChange={handlePinCodeBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={pinCodeEmailChecked}
                                           onChange={handlePinCodeEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}} checked={pinCodeTextChecked}
                                           onChange={handlePinCodeTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("in_warehouse_storage")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={warehouseStorageEmailChecked && warehouseStorageTextChecked}
                            indeterminate={warehouseStorageEmailChecked !== warehouseStorageTextChecked}
                            onChange={handleWarehouseStorageBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={warehouseStorageEmailChecked}
                                           onChange={handleWarehouseStorageEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={warehouseStorageTextChecked}
                                           onChange={handleWarehouseStorageTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("return_registered")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={returnRegisteredEmailChecked && returnRegisteredTextChecked}
                            indeterminate={returnRegisteredEmailChecked !== returnRegisteredTextChecked}
                            onChange={handleReturnRegisteredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnRegisteredEmailChecked}
                                           onChange={handleReturnRegisteredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnRegisteredTextChecked}
                                           onChange={handleReturnRegisteredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("return_collected")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={returnCollectedEmailChecked && returnCollectedTextChecked}
                            indeterminate={returnCollectedEmailChecked !== returnCollectedTextChecked}
                            onChange={handleReturnCollectedBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnCollectedEmailChecked}
                                           onChange={handleReturnCollectedEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnCollectedTextChecked}
                                           onChange={handleReturnCollectedTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("return_delivered")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={returnDeliveredEmailChecked && returnDeliveredTextChecked}
                            indeterminate={returnDeliveredEmailChecked !== returnDeliveredTextChecked}
                            onChange={handleReturnDeliveredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnDeliveredEmailChecked}
                                           onChange={handleReturnDeliveredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={returnDeliveredTextChecked}
                                           onChange={handleReturnDeliveredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={12} variant={"button"}>{t("pudo_check_in")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={pudoCheckInEmailChecked && pudoCheckInTextChecked}
                            indeterminate={pudoCheckInEmailChecked !== pudoCheckInTextChecked}
                            onChange={handlePudoCheckInBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={pudoCheckInEmailChecked}
                                           onChange={handlePudoCheckInEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox style={{color: theme.palette.primary.main}}
                                           checked={pudoCheckInTextChecked}
                                           onChange={handlePudoCheckInTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>

        </Box>
    }


    useEffect(() => {

        if (hasCod === true) {
            setLoadingCod(true);
            fetchCurrencies();
            fetchCodModes()

            setTimeout(() => {
                setLoadingCod(false);
            }, 1000)
        }

    }, [hasCod])

    useEffect(() => {

        if (hasInsurance === true) {
            setLoadingInsurance(true);
            fetchCurrencies();

            setTimeout(() => {
                setLoadingInsurance(false);
            }, 1000)
        }

    }, [hasInsurance])

    useEffect(() => {
        fetchCountries();
    }, [refresh])

    return <>

        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={() => history.goBack()}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>


        {/*<div style={{marginBottom: "5%", marginTop: "5%", height: "20%", backgroundColor: "#C8102E"}}>*/}
        {/*    <DialogTitle><Typography variant={"button"} color={"white"}>{t("new_shipment_title")}</Typography></DialogTitle>*/}
        {/*</div>*/}
        <DialogTitle><Typography variant={"button"}
                                 style={{fontSize: "large"}}>{t("new_shipment_title")}</Typography></DialogTitle>

        <div style={{
            marginTop: "5%",
            marginBottom: "2%",
            height: "20%",
            backgroundColor: theme.palette.primary.main,
            display: "flex"
        }}>
            <DialogTitle><Typography variant={"button"} color={"white"}>{t("general_data")}</Typography></DialogTitle>
        </div>
        <Card elevation={3}>

            {generateContractForm()}

            <div style={{display: "flex", margin: "2%"}}>
                <Autocomplete
                    id="service"
                    size="small"
                    style={{margin: "auto", width: "fit-content", minWidth: "20%"}}
                    disabled={!account || !contract}
                    options={services}
                    onOpen={fetchServices}
                    getOptionLabel={option => option.name}
                    value={service}
                    loading={loadingFetch}
                    loadingText={<CircularProgress size={24}/>}
                    defaultValue={service}
                    noOptionsText={t("no_options")}
                    onChange={handleServiceChange}
                    renderInput={(params) => <TextField
                        {...params}
                        // style={{
                        //     width: "25%",
                        //     margin: "2%"
                        // }}
                        label={t("service")}
                        variant="outlined"
                        margin="dense"
                        required
                        value={service}
                        error={!service}
                        helperText={t(serviceHelperText)}
                        defaultValue={service}
                    />}
                />
            </div>


            <div style={{display: "flex", margin: "2%"}}>
                <TextField
                    style={{marginLeft: "auto", minWidth: "20%", width: "fit-content"}}
                    required
                    error={!reference}
                    helperText={!reference ? t("required") : null}
                    label={t("reference")}
                    variant={"outlined"}
                    size={"small"}
                    value={reference}
                    onChange={handleReferenceChange}
                />
                <TextField
                    style={{marginInline: "2%", minWidth: "20%", width: "fit-content"}}
                    label={t("customerReference")}
                    variant={"outlined"}
                    size={"small"}
                    value={customerReference}
                    onChange={handleCustomerReferenceChange}
                />
                <TextField
                    style={{marginRight: "2%", minWidth: "20%", width: "fit-content"}}
                    label={t("customerCode")}
                    variant={"outlined"}
                    size={"small"}
                    value={customerCode}
                    onChange={handleCustomerCodeChange}
                />
                <TextField
                    style={{marginRight: "auto", minWidth: "20%", width: "fit-content"}}
                    label={t("orderNumber")}
                    variant={"outlined"}
                    size={"small"}
                    value={orderNumber}
                    onChange={handleOrderNumberChange}
                />
            </div>

        </Card>
        <div style={{marginBlock: "2%", height: "20%", backgroundColor: theme.palette.primary.main, display: "flex"}}>
            <DialogTitle><Typography variant={"button"} color={"white"}>{t("addresses")}</Typography></DialogTitle>
        </div>
        <Grid container spacing={2} columns={16} style={{marginBottom: "2%"}}>
            <Grid item xs={8}>
                <Card elevation={3}>

                    {/*<DialogTitle style={{display: "flex"}}><Typography style={{marginTop: "2%"}}*/}
                    {/*                                                   variant={"button"}>{t("pickup")}</Typography>*/}
                    {/*    {!disabledPickupAddress ? null :*/}
                    {/*        <IconButton style={{marginLeft: "1%"}}*/}
                    {/*                    onClick={() => setDisabledPickupAddress(false)}><Edit/></IconButton>*/}
                    {/*    }*/}

                    {/*</DialogTitle>*/}

                    <DialogTitle style={{display: "flex", width: "100%", justifyContent: "space-between"}}>

                        <Typography style={{marginTop: "2%"}}
                                    variant={"button"}>{exWarehouse ? t("warehouse") : t("pickup")}</Typography>
                        {!disabledPickupAddress || exWarehouse ? null :
                            <IconButton style={{marginLeft: "1%"}}
                                        onClick={() => setDisabledPickupAddress(false)}><Edit/></IconButton>
                        }

                        {isPudoReturn() ?
                            null
                            :
                            <FormControlLabel
                                control={<Switch
                                    style={{color: "primary"}}
                                    checked={exWarehouse}
                                    disabled={isPudoReturn}
                                    onClick={handleExWarehouseChange}
                                    inputProps={{'aria-label': 'ant design'}}/>}
                                label={<Typography variant={"subtitle2"}>{t("ex_warehouse")}</Typography>}
                            />
                        }
                    </DialogTitle>


                    {isPudoReturn() ?
                        <div>
                            <Autocomplete
                                id="pudo"
                                size="small"
                                style={{minWidth: "70%", width: "fit-content", margin: "auto", marginBottom: "5%"}}
                                options={pudos}
                                disabled={!isPudoReturn()}
                                getOptionLabel={option => option.code + " - " + option.address.companyName + " - " + option.address.street + ", " + option.address.postalCode + " " + option.address.city + " (" + option.address.administrative_level_3 + ")"}
                                value={pudo}
                                defaultValue={pudo}
                                loading={loadingFetch}
                                loadingText={<CircularProgress size={24}/>}
                                onChange={handlePudoChange}
                                onOpen={fetchPudos}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("pudo")}
                                    variant="outlined"
                                    margin="dense"
                                    value={pudo}
                                    defaultValue={pudo}
                                    required
                                    error={!pudo}
                                    helperText={!pudo ? t("required") : null}
                                />}
                            />
                        </div>
                        :
                        <div>
                            {exWarehouse ?
                                <Autocomplete
                                    id="departureWarehouse"
                                    size="small"
                                    style={{
                                        minWidth: "35%",
                                        width: "fit-content",
                                        marginBottom: "2%",
                                        marginLeft: "2%"
                                    }}
                                    options={warehouses}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={departureWarehouse}
                                    defaultValue={departureWarehouse}
                                    disabled={!exWarehouse}
                                    onChange={handleDepartureWarehouseChange}
                                    onOpen={fetchWarehouses}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("departure_warehouse")}
                                        variant="outlined"
                                        margin="dense"
                                        value={departureWarehouse}
                                        defaultValue={departureWarehouse}
                                        required
                                        error={!departureWarehouse}
                                        helperText={!departureWarehouse ? t("required") : null}
                                    />}
                                />

                                :

                                <div>
                                    {loadingPickupAddress ?


                                        <LoadingComponent/>

                                        :

                                        <div>

                                            <Autocomplete
                                                id="country"
                                                size="small"
                                                style={{
                                                    minWidth: "35%",
                                                    width: "fit-content",
                                                    marginBottom: "2%",
                                                    marginLeft: "2%"
                                                }}
                                                options={countries}
                                                getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                value={pickupCountry}
                                                defaultValue={pickupCountry}
                                                disabled={disabledPickupAddress}
                                                onChange={handlePickupCountryChange}
                                                onSelect={fetchCountries}
                                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                                noOptionsText={t("no_options")}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={t("country")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={pickupCountry}
                                                    defaultValue={pickupCountry}
                                                    required
                                                    error={!pickupCountry}
                                                    helperText={!pickupCountry ? t("required") : null}
                                                />}
                                            />

                                            {generatePickupAddress(pickupCountry)}

                                            <div style={{display: "flex", marginBottom: "5%"}}>

                                                <TextField
                                                    style={{marginInline: "2%"}}
                                                    label={t("street")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupStreet}
                                                    required
                                                    error={!pickupStreet}
                                                    helperText={!pickupStreet ? t("required") : null}
                                                    onChange={handlePickupStreetChange}
                                                />
                                                <TextField
                                                    style={{marginInline: "2%", width: "10%"}}
                                                    label={t("buildingNr")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupBuildingNr}
                                                    onChange={handlePickupBuildingNrChange}
                                                />

                                            </div>

                                            <div style={{display: "flex", marginBottom: "5%"}}>
                                                <TextField
                                                    required
                                                    error={pickupCompanyName === "" && pickupReferencePerson === ""}
                                                    style={{marginInline: "2%"}}
                                                    label={t("shipment_company_name")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupCompanyName}
                                                    onChange={handlePickupCompanyNameChange}
                                                />


                                                <TextField
                                                    required
                                                    error={pickupCompanyName === "" && pickupReferencePerson === ""}
                                                    style={{marginInline: "2%"}}
                                                    label={t("shipment_reference_person")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupReferencePerson}
                                                    onChange={handlePickupReferencePerson}
                                                />
                                            </div>

                                            <div style={{display: "flex", marginBottom: "5%"}}>
                                                <TextField
                                                    style={{marginInline: "2%"}}
                                                    label={t("email")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    disabled={disabledPickupAddress}
                                                    value={pickupEmail}
                                                    onChange={handlePickupEmail}
                                                />


                                                <TextField
                                                    style={{marginInline: "2%"}}
                                                    label={t("phone")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupPhone}
                                                    onChange={handlePickupPhone}
                                                />

                                                <TextField
                                                    style={{marginInline: "2%"}}
                                                    label={t("mobile")}
                                                    variant={"outlined"}
                                                    disabled={disabledPickupAddress}
                                                    size={"small"}
                                                    value={pickupMobile}
                                                    onChange={handlePickupMobile}
                                                />
                                            </div>
                                            <div style={{display: "flex", marginBottom: "5%"}}>
                                                <TextField
                                                    style={{width: "100%", marginInline: "2%"}}
                                                    size={"small"}
                                                    id="pickup-multiline-notes"
                                                    label={t("pickup_notes")}
                                                    multiline
                                                    maxRows={5}
                                                    value={pickupNotes}
                                                    onChange={handlePickupNotesChange}
                                                />
                                            </div>


                                        </div>


                                    }
                                </div>
                            }
                        </div>
                    }

                </Card>
                {/*</Card>*/}
            </Grid>
            <Grid item xs={8}>
                {/*<Card elevation={3} style={{margin: "2%"}}>*/}
                {/*</Card>*/}
                <Card defaultExpanded elevation={3}>

                    <DialogTitle style={{display: "flex"}}><Typography style={{marginTop: "2%"}}
                                                                       variant={"button"}>{t("delivery")}</Typography></DialogTitle>

                    {!isPudo() ?
                        <div>
                            {loadingDeliveryAddress ?


                                <LoadingComponent/>

                                :

                                <div>

                                    <Autocomplete
                                        id="country"
                                        size="small"
                                        style={{
                                            minWidth: "35%",
                                            width: "fit-content",
                                            marginBottom: "2%",
                                            marginLeft: "2%"
                                        }}
                                        options={countries}
                                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                        value={deliveryCountry}
                                        defaultValue={deliveryCountry}
                                        onChange={handleDeliveryCountryChange}
                                        onSelect={fetchCountries}
                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                        noOptionsText={t("no_options")}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label={t("country")}
                                            variant="outlined"
                                            margin="dense"
                                            value={deliveryCountry}
                                            defaultValue={deliveryCountry}
                                            required
                                            error={!deliveryCountry}
                                            helperText={!deliveryCountry ? t("required") : null}
                                        />}
                                    />

                                    {generateDeliveryAddress(deliveryCountry)}

                                    <div style={{display: "flex", marginBottom: "5%"}}>

                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("street")}
                                            variant={"outlined"}
                                            disabled={disabledDeliveryAddress}
                                            size={"small"}
                                            value={deliveryStreet}
                                            required
                                            error={!deliveryStreet}
                                            helperText={!deliveryStreet ? t("required") : null}
                                            onChange={handleDeliveryStreetChange}
                                        />
                                        <TextField
                                            style={{marginInline: "2%", width: "10%"}}
                                            label={t("buildingNr")}
                                            variant={"outlined"}
                                            disabled={disabledDeliveryAddress}
                                            size={"small"}
                                            value={deliveryBuildingNr}
                                            onChange={handleDeliveryBuildingNrChange}
                                        />

                                    </div>
                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                        <TextField
                                            required
                                            error={deliveryCompanyName === "" && deliveryReferencePerson === ""}
                                            style={{marginInline: "2%"}}
                                            label={t("shipment_company_name")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={deliveryCompanyName}
                                            onChange={handleDeliveryCompanyNameChange}
                                        />
                                        <TextField
                                            required
                                            error={deliveryCompanyName === "" && deliveryReferencePerson === ""}
                                            style={{marginInline: "2%"}}
                                            label={t("shipment_reference_person")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={deliveryReferencePerson}
                                            onChange={handleDeliveryReferencePerson}
                                        />
                                    </div>
                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("email")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={deliveryEmail}
                                            onChange={handleDeliveryEmail}
                                        />
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("phone")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={deliveryPhone}
                                            onChange={handleDeliveryPhone}
                                        />
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("mobile")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={deliveryMobile}
                                            onChange={handleDeliveryMobile}
                                        />
                                    </div>
                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                        <TextField
                                            style={{width: "100%", marginInline: "2%"}}
                                            size={"small"}
                                            id="pickup-multiline-notes"
                                            label={t("delivery_notes")}
                                            multiline
                                            maxRows={5}
                                            value={deliveryNotes}
                                            onChange={handleDeliveryNotesChange}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        :
                        <div>
                            <Autocomplete
                                id="pudo"
                                size="small"
                                style={{minWidth: "70%", width: "fit-content", margin: "auto", marginBottom: "5%"}}
                                options={pudos}
                                disabled={!isPudo()}
                                getOptionLabel={option => option.code + " - " + option.address.companyName + " - " + option.address.street + ", " + option.address.postalCode + " " + option.address.city + " (" + option.address.administrative_level_3 + ")"}
                                value={pudo}
                                defaultValue={pudo}
                                loading={loadingFetch}
                                loadingText={<CircularProgress size={24}/>}
                                onChange={handlePudoChange}
                                onOpen={fetchPudos}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("pudo")}
                                    variant="outlined"
                                    margin="dense"
                                    value={pudo}
                                    defaultValue={pudo}
                                    required
                                    error={!pudo}
                                    helperText={!pudo ? t("required") : null}
                                />}
                            />
                        </div>
                    }

                </Card>
            </Grid>
            <Grid item xs={8}>
                {exWarehouse ?
                    null
                    :
                    <Card elevation={3}>

                        <DialogTitle style={{display: "flex", width: "100%", justifyContent: "space-between"}}>

                            <Typography style={{marginTop: "2%"}} variant={"button"}>
                                {t("sender")}
                            </Typography>

                            {isPudoReturn() ?
                                null
                                :
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Tooltip title={t("Imposta mittente uguale a ritiro")}>
                                        <ContentCopy/>
                                    </Tooltip>

                                    <FormControlLabel
                                        control={<Switch
                                            style={{color: "primary"}}
                                            checked={!senderEqualPickup}
                                            disabled={isPudoReturn}
                                            onClick={changeSenderEqualPickup}
                                            inputProps={{'aria-label': 'ant design'}}/>}
                                        label={""}
                                    />

                                    <Tooltip title={t("Modifica mittente")}>
                                        <Edit/>
                                    </Tooltip>
                                </Stack>
                            }
                        </DialogTitle>

                        {loadingSenderAddress ?


                            <LoadingComponent/>

                            :


                            <div>

                                <Autocomplete
                                    id="country"
                                    size="small"
                                    style={{
                                        minWidth: "35%",
                                        width: "fit-content",
                                        marginBottom: "2%",
                                        marginLeft: "2%"
                                    }}
                                    options={countries}
                                    disabled={senderEqualPickup}
                                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                    value={senderCountry}
                                    defaultValue={senderCountry}
                                    onChange={handleSenderCountryChange}
                                    onSelect={fetchCountries}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("country")}
                                        variant="outlined"
                                        margin="dense"
                                        value={senderCountry}
                                        defaultValue={senderCountry}
                                        required
                                        error={!senderCountry}
                                        helperText={!senderCountry ? t("required") : null}
                                    />}
                                />

                                {generateSenderAddress(senderCountry)}

                                <div style={{display: "flex", marginBottom: "5%"}}>
                                    <TextField
                                        style={{marginInline: "2%"}}
                                        label={t("street")}
                                        disabled={senderEqualPickup}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderStreet}
                                        required
                                        error={!senderStreet}
                                        helperText={!senderStreet ? t("required") : null}
                                        onChange={handleSenderStreetChange}
                                    />
                                    <TextField
                                        style={{marginInline: "2%", width: "10%"}}
                                        label={t("buildingNr")}
                                        disabled={senderEqualPickup}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderBuildingNr}
                                        onChange={handleSenderBuildingNrChange}
                                    />
                                </div>
                                <div style={{display: "flex", marginBottom: "5%"}}>
                                    <TextField
                                        required
                                        error={senderCompanyName === "" && senderReferencePerson === ""}
                                        style={{marginInline: "2%"}}
                                        disabled={senderEqualPickup}
                                        label={t("shipment_company_name")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderCompanyName}
                                        onChange={handleSenderCompanyNameChange}
                                    />
                                    <TextField
                                        required
                                        error={senderCompanyName === "" && senderReferencePerson === ""}
                                        style={{marginInline: "2%"}}
                                        disabled={senderEqualPickup}
                                        label={t("shipment_reference_person")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderReferencePerson}
                                        onChange={handleSenderReferencePerson}
                                    />
                                </div>
                                <div style={{display: "flex", marginBottom: "5%"}}>
                                    <TextField
                                        style={{marginInline: "2%"}}
                                        disabled={senderEqualPickup}
                                        label={t("email")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderEmail}
                                        onChange={handleSenderEmail}
                                    />
                                    <TextField
                                        style={{marginInline: "2%"}}
                                        disabled={senderEqualPickup}
                                        label={t("phone")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderPhone}
                                        onChange={handleSenderPhone}
                                    />
                                    <TextField
                                        style={{marginInline: "2%"}}
                                        disabled={senderEqualPickup}
                                        label={t("mobile")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={senderMobile}
                                        onChange={handleSenderMobile}
                                    />
                                </div>
                            </div>
                        }
                    </Card>
                }
            </Grid>
            <Grid item xs={8}>
                {/*<Card elevation={3} style={{margin: "2%"}}>*/}
                {/*</Card>*/}
                <Card elevation={3}>
                    <DialogTitle style={{display: "flex", width: "100%", justifyContent: "space-between"}}>

                        <Typography style={{marginTop: "2%"}} variant={"button"}>
                            {t("end_user")}
                        </Typography>

                        {!isPudo() ?
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Tooltip title={t("Imposta destinatario uguale a consegna")}>
                                    <ContentCopy/>
                                </Tooltip>

                                <FormControlLabel
                                    control={<Switch style={{color: "primary"}}
                                                     disabled={service?.code === "CP"}
                                                     checked={!endUserEqualDelivery}
                                                     onClick={changeEndUserEqualDelivery}
                                                     inputProps={{'aria-label': 'ant design'}}/>}

                                    // <Typography style={{width : "30%", textAlign: "left"}} variant={"caption"}>{t("Imposta indirizzo destinatario uguale all'indirizzo di consegna")}</Typography>
                                    label={""}/>

                                <Tooltip title={t("Modifica destinatario")}>
                                    <Edit/>
                                </Tooltip>
                            </Stack>
                            :
                            null
                        }
                    </DialogTitle>
                    {loadingEndUserAddress ?


                        <LoadingComponent/>

                        :

                        <div>

                            <Autocomplete
                                id="country"
                                size="small"
                                style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", marginLeft: "2%"}}
                                options={countries}
                                disabled={endUserEqualDelivery}
                                getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                value={endUserCountry}
                                defaultValue={endUserCountry}
                                onChange={handleEndUserCountryChange}
                                onSelect={fetchCountries}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("country")}
                                    variant="outlined"
                                    margin="dense"
                                    value={endUserCountry}
                                    defaultValue={endUserCountry}
                                    required
                                    error={!endUserCountry}
                                    helperText={!endUserCountry ? t("required") : null}
                                />}
                            />

                            {generateEndUserAddress(endUserCountry)}

                            <div style={{display: "flex", marginBottom: "5%"}}>
                                <TextField
                                    style={{marginInline: "2%"}}
                                    disabled={endUserEqualDelivery}
                                    label={t("street")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserStreet}
                                    required
                                    error={!endUserStreet}
                                    helperText={!endUserStreet ? t("required") : null}
                                    onChange={handleEndUserStreetChange}
                                />
                                <TextField
                                    style={{marginInline: "2%", width: "10%"}}
                                    label={t("buildingNr")}
                                    disabled={endUserEqualDelivery}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserBuildingNr}
                                    onChange={handleEndUserBuildingNrChange}
                                />
                            </div>
                            <div style={{display: "flex", marginBottom: "5%"}}>
                                <TextField
                                    required
                                    error={endUserCompanyName === "" && endUserReferencePerson === ""}
                                    style={{marginInline: "2%"}}
                                    disabled={endUserEqualDelivery}
                                    label={t("shipment_company_name")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserCompanyName}
                                    onChange={handleEndUserCompanyNameChange}
                                />

                                <TextField
                                    required
                                    error={endUserCompanyName === "" && endUserReferencePerson === ""}
                                    style={{marginInline: "2%"}}
                                    disabled={endUserEqualDelivery}
                                    label={t("shipment_reference_person")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserReferencePerson}
                                    onChange={handleEndUserReferencePerson}
                                />
                            </div>
                            <div style={{display: "flex", marginBottom: "5%"}}>
                                <TextField
                                    disabled={endUserEqualDelivery}
                                    style={{marginInline: "2%"}}
                                    label={t("email")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserEmail}
                                    onChange={handleEndUserEmail}
                                />
                                <TextField
                                    disabled={endUserEqualDelivery}
                                    style={{marginInline: "2%"}}
                                    label={t("phone")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserPhone}
                                    onChange={handleEndUserPhone}
                                />
                                <TextField
                                    disabled={endUserEqualDelivery}
                                    style={{marginInline: "2%"}}
                                    label={t("mobile")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={endUserMobile}
                                    onChange={handleEndUserMobile}
                                />
                            </div>
                        </div>
                    }

                </Card>
                {/*</Card>*/}
            </Grid>
        </Grid>
        <div style={{marginBlock: "2%", height: "20%", backgroundColor: theme.palette.primary.main, display: "flex"}}>
            <DialogTitle><Typography variant={"button"} color={"white"}>{t("parcels")}</Typography></DialogTitle></div>

        <Card>
            <div style={{display: "flex", marginLeft: "5%", marginBlock: "2%"}}>
                <IconButton disabled={parcels.length < 2} onClick={removeParcel}>
                    <RemoveCircleOutlineOutlined/>
                </IconButton>

                <IconButton onClick={addParcel}>
                    <AddCircleOutlineOutlined/>
                </IconButton>

                {/*<Button onClick={() => console.log(parcels)}>{"TEST"}</Button>*/}
            </div>


            {parcels.map((parcel, index) => {
                return <div style={{marginInline: "2%", display: "flex"}}>
                    <DialogTitle style={{marginInline: "1%"}}>{t("parcel") + " # " + (index + 1)}</DialogTitle>
                    {Object.entries(parcel).map(([key, value]) => {
                        if (key === "weight") {
                            return <CurrencyTextField
                                style={{marginInline: "1%", width: "10%"}}
                                label={t(key)}
                                variant={"outlined"}
                                margin={"dense"}
                                decimalPlaces={2}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                minimumValue={0}
                                value={value}
                                currencySymbol={"Kg"}
                                onChange={(event, _value) => handleParcelValueChange(index, event, key, _value)}
                            />
                        } else if (key !== "customer_barcode") {
                            return <CurrencyTextField
                                style={{marginInline: "1%", width: "10%"}}
                                label={t(key)}
                                variant={"outlined"}
                                margin={"dense"}
                                decimalPlaces={2}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                minimumValue={0}
                                value={value}
                                currencySymbol={"Cm"}
                                onChange={(event, _value) => handleParcelValueChange(index, event, key, _value)}
                            />
                        } else {
                            return <TextField
                                style={{marginInline: "1%", width: "20%"}}
                                label={t(key)}
                                variant={"outlined"}
                                size={"small"}
                                margin={"dense"}
                                value={value}
                                type={key !== "customer_barcode" ? "number" : "text"}
                                onChange={event => handleParcelBarcodeValueChange(index, event, key)}
                            />
                        }

                    })}
                </div>

            })}

        </Card>

        <div style={{marginBlock: "2%", height: "20%", backgroundColor: theme.palette.primary.main, display: "flex"}}>
            <DialogTitle><Typography variant={"button"}
                                     color={"white"}>{t("additional_data")}</Typography></DialogTitle></div>
        <Card>
            <DateField
                style={{minWidth: "5%", width: "fit-content", margin: "auto"}}
                label={t("pickup_date")}
                value={pickupDate}
                onChange={(event) => setPickupDate(event)}
                format={"dd/MM/yyyy"}
                disablePast={true}
            />
            <Grid container spacing={2} columns={12} style={{marginBottom: "10%"}}>
                <Grid item xs={4}>
                    <Button
                        disabled={!contract}
                        onClick={handleHasCodChange}
                        endIcon={hasCod ? <CancelOutlined/> : <AddCircleOutlineOutlined/>}
                        style={{margin: "5%", color: theme.palette.primary.main}}
                    >
                        {hasCod ? t("remove_cod") : t("add_cod")}
                    </Button>
                    {!contract ?
                        <WarningChip><Typography>{t("select_contract_first")}</Typography></WarningChip> : null}
                    {hasCod && !loadingCod ? generateCodForm() : null}
                    {loadingCod ? <LoadingComponent/> : null}
                </Grid>
                <Grid item xs={4}>
                    <Button
                        onClick={handleHasInsuranceChange}
                        endIcon={hasInsurance ? <CancelOutlined/> : <AddCircleOutlineOutlined/>}
                        style={{margin: "5%", color: theme.palette.primary.main}}
                    >
                        {hasInsurance ? t("remove_insurance") : t("add_insurance")}
                    </Button>
                    {hasInsurance && !loadingInsurance ? generateInsuranceForm() : null}
                    {loadingInsurance ? <LoadingComponent/> : null}
                </Grid>
                <Grid item xs={4}>
                    <Button
                        onClick={handleHasAuthChange}
                        endIcon={hasAuth ? <CancelOutlined/> : <AddCircleOutlineOutlined/>}
                        style={{margin: "5%", color: theme.palette.primary.main}}
                    >
                        {hasAuth ? t("remove_auth") : t("add_auth")}
                    </Button>
                    {hasAuth && !loadingAuth ? generateAuthForm() : null}
                    {loadingAuth ? <LoadingComponent/> : null}
                </Grid>
                {/*<Grid item xs={4}/>*/}

                {/*<Grid item xs={4}>*/}
                {/*    <Typography variant={"button"}>{t("shipment_notifications")}</Typography>*/}
                {/*    {generateNotificationCheckboxes()}*/}
                {/*</Grid>*/}
            </Grid>
            <div>
                <Typography variant={"button"}>{t("shipment_notifications")}</Typography>
                {generateNotificationCheckboxes()}
            </div>
        </Card>

        <div style={{marginBlock: "2%", height: "20%", backgroundColor: theme.palette.primary.main, display: "flex"}}>
            <DialogTitle><Typography variant={"button"}
                                     color={"white"}>{t("booking")}</Typography></DialogTitle></div>
        <Card>
            <div>
                {checkBooking() ?

                    <StyledButton
                        icon={<EventAvailable/>}
                        disabled={!checkBooking()}
                        onClick={openCalendar}>{t("select_booking_date")}</StyledButton>
                    :
                    <Typography style={{marginBlock: "5%"}}
                                variant={"subtitle2"}>{t("cannot_select_booking_date_msg")}</Typography>
                }
            </div>
            {bookingDate && bookingSlot ?
                <div>
                    <Typography variant={"subtitle2"}>{t("booking") + ":"}</Typography>

                    <div style={{marginBlock: "3%"}}>
                        <Typography variant={"subtitle2"}>{t("date") + ": " + formatDate(bookingDate)}</Typography>
                        <Typography
                            variant={"subtitle2"}>{t("time_slot") + ": " + bookingSlot.fromTime + " - " + bookingSlot.toTime}</Typography>
                    </div>
                </div>


                :
                null

            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openBooking}
                onClose={closeCalendar}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {/*aaa*/}
                <Fade in={openBooking}>
                    <div className={classes.calendar}>

                        <Paper elevation={3}>
                            <div style={{
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle style={{width: "fit-content", margin: "auto"}}><Typography
                                    variant={"caption"} fontSize={22}
                                    color={"white"}>{t("calendar_title")}</Typography></DialogTitle></div>
                            {/*<DialogTitle style={{*/}
                            {/*    textAlign: "center",*/}
                            {/*    paddingTop: "3%"*/}
                            {/*}}><Typography*/}
                            {/*    variant={"caption"} fontSize={22}>{t("calendar_title")}</Typography>*/}
                            {/*</DialogTitle>*/}
                            {calendarLoading ?
                                <LoadingComponent/>
                                :
                                <div style={{display: "flex"}}>
                                    <Button
                                        style={{
                                            height: "20%",
                                            margin: "auto",
                                            color: calendarBatchCount !== 0 ? theme.palette.primary.main : null,
                                            backgroundColor: calendarBatchCount !== 0 ? 'transparent' : null
                                        }}
                                        disabled={calendarBatchCount === 0}
                                        onClick={fetchPreviousWeek}><ArrowBackIos/></Button>
                                    <Table
                                        className={classes.calendarTable}>
                                        {calendar.map((dateAndSlot, i) =>
                                            <TableCell
                                                style={{width: "5%"}}>
                                                <div>
                                                    <Typography
                                                        align="center">{formatDate(dateAndSlot.date)}</Typography>
                                                </div>
                                                {dateAndSlot.slots.map((item, index) => {
                                                        return <Card
                                                            style={{
                                                                marginTop: "15%",
                                                                backgroundColor: item.available ? `#35BC64` : "lightgrey"
                                                            }}>
                                                            <CardActionArea
                                                                onClick={() => confirmBooking(dateAndSlot.date, item)}
                                                                disabled={!item.available}>
                                                                <div>
                                                                    <Typography
                                                                        variant={"subtitle1"}
                                                                        style={{marginInline: "5%"}}
                                                                        align={"center"}>
                                                                        {item.fromTime + ":00"}
                                                                    </Typography>
                                                                </div>

                                                                <Typography
                                                                    variant={"subtitle1"}
                                                                    style={{marginInline: "5%"}}
                                                                    align={"center"}>
                                                                    {item.toTime + ":00"}
                                                                </Typography>
                                                            </CardActionArea>
                                                        </Card>
                                                    }
                                                )}
                                            </TableCell>
                                        )}
                                    </Table>
                                    <Button style={{
                                        backgroundColor: "transparent",
                                        color: theme.palette.primary.main,
                                        height: "20%",
                                        margin: "auto"
                                    }} onClick={fetchNextWeek}><ArrowForwardIos/></Button>

                                </div>
                            }
                        </Paper>

                    </div>
                </Fade>
            </Modal>
        </Card>
        <StyledButton
            // className={classes.button}
            // style={{margin: "5%", color: theme.palette.primary.main}}
            onClick={createShipment}
        >
            {loadingConfirm ?
                <CircularProgress size={24} style={{color: "white"}}/> :
                <div>{t("confirm")}</div>
            }
        </StyledButton>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
        {/*<Button*/}
        {/*    className={classes.button}*/}
        {/*    style={{margin: "5%"}}*/}
        {/*    onClick={createShipment}>{t("confirm")}</Button>*/}

    </>

}


export default NewShipmentForm;