import {TextField} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


function MyTimeField(props) {

    const {t} = useTranslation();

    return <div style={{margin: "auto", width: "fit-content"}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                label={props.label? props.label : t("time")}
                disabled={props.disabled}
                disableOpenPicker={true}
                onChange={props.onChange}
                value={props.value}
                format={"HH:mm"}
                validationError={props.error}
                renderInput={(props) => <TextField {...props} />}
                rawValue={props.value}/>

        </MuiPickersUtilsProvider>

    </div>
}

export default MyTimeField;