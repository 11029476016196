
import {TextField} from "@mui/material";

import React, {useState} from "react";
import itLocale from "date-fns/locale/it";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {DateTimePicker} from '@material-ui/pickers';



function DateTimeField(props) {

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");

    return <div style={props.style}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                disableToolbar
                autoOk
                ampm={false}
                format={"dd/MM/yyyy HH:mm"}
                renderInput={(props) => <TextField {...props} />}
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                disableFuture={props.disableFuture}
            />
        </MuiPickersUtilsProvider>
    </div>

} export default DateTimeField;