import {Button, CardActions, Dialog, DialogTitle, TextField, useTheme} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import ServiceService from "../shipments/services/ServiceService";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import RoutingRuleService from "./RoutingRuleService";
import Notification from "../components/notifications/Notification";
import CountryService from "../geo/countries/CountryService";
import HamletService from "../geo/hamlets/HamletService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    name_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    div: {
        display: "inline"
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        marginLeft: "auto",
        marginRight: "auto",
        margin: theme.spacing(1),
        width: "75%",
    },
    chips: {
        display: 'block',
        // flexWrap: 'wrap',
    },
    chip: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "2.5%"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));

function RoutingRuleForm(props){

    const {t} = useTranslation();
    const classes = useStyles();

    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [services, setServices] = useState([]);
    const [hamlets, setHamlets] = useState([]);

    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [adminL2, setAdminL2] = useState(null);
    const [adminL1, setAdminL1] = useState(null);
    const [city, setCity] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [service, setService] = useState(null);
    const [hamlet, setHamlet] = useState(null);

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }


    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();
        if(country){
            let filters = {}
            filters.countryId = country.id;
            adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin1List(data.admins1);
                })
        }
    }

    function fetchHamlets(){
        let hamletService = new HamletService();

        if(city) {
            let filters = {}
            filters.cityId = city.id;

            hamletService.getHamlets(null, null, filters, null)
                .then(data => {
                    setHamlets(data.hamlets);
                })

        } else {
            hamletService.getHamlets()
                .then(data => {
                    setHamlets(data.hamlets);
                })
        }
    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        if (adminL1) {
            let filters = {}
            filters.adminL1 = adminL1.id;
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        } else {
            adminL2Service.getAllAdminLevel2WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        }
    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();
        if (adminL2) {
            let filters = {};
            filters.adminL2 = adminL2.id;
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        } else {
            adminL3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        }
    }

    function fetchCities() {
        let cityService = new CityService();

        if (postalCode) {
            let filters = {}
            filters.postalCodeId = postalCode.id;
            cityService.getCities(null, null, filters, null)
                .then(data => {
                    setCities(data.cities)
                })
        } else {
            cityService.getCities(null, null, null, null)
                .then(data => {
                    setCities(data.cities)
                })
        }
    }

    function fetchCAP() {
        let postalCodeService = new PostalCodeService();

        if (adminL3) {
            let filters = {}
            filters.administrativeLevel3Id = adminL3.id;
            postalCodeService.getAllPostalCodes(null, null, filters, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        } else {
            postalCodeService.getAllPostalCodes(null, null, null, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        }
    }

    function handleHamletChange(event, hamlet){
        setHamlet(hamlet);
    }



    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setAdminL2(null);
        setAdminL1(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdminL1Change(event, adminL1) {

        setAdminL1(adminL1);
        setAdminL2(null)
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2(adminL2);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);


    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);


    }

    function handleCityChange(event, city) {
        setCity(city);
        setHamlet(null);
    }

    function handlePostalCodeChange(event, postalCode) {
        setHamlet(null);
        setCity(null);
        setPostalCode(postalCode);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function submitNewArea(event) {
        event.preventDefault();

        let countryId = null;
        let admin1Id = null;
        let admin2Id = null;
        let admin3Id = null;
        let cityId = null;
        let postalCodeId = null;
        let hamletId = null;
        let serviceId = null;

        let areaService = new RoutingRuleService();
        if (null != country) {
            countryId = country.id
        }
        if (null != adminL1) {
            admin1Id = adminL1.id
        }
        if (null != adminL2) {
            admin2Id = adminL2.id
        }
        if (null != adminL3) {
            admin3Id = adminL3.id
        }
        if (null != city) {
            cityId = city.id
        }
        if (null != postalCode) {
            postalCodeId = postalCode.id
        }
        if(null!= hamlet){
            hamletId = hamlet.id
        }
        if (null != service) {
            serviceId = service.id
            console.log(service.id)
        }
        areaService.saveNewRoutingRule(countryId, admin1Id, admin2Id, admin3Id, cityId, postalCodeId,hamletId, serviceId, null)
            .then(() => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setTimeout(()=>{
                    history.push("/areas");
                }, 2000)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    function cancelNewArea(){
        history.push("/areas");
    }

    useEffect(()=>{
        fetchCountries();
    }, [location])

    return <form>
            <DialogTitle>{t("area_data")}</DialogTitle>

        <Autocomplete
            id="service"

            className={classes.field}
            options={services}
            getOptionLabel={option => option.name}
            value={service}
            defaultValue={service}
            onOpen={fetchServices}
            onChange={handleServiceChange}
            loading={true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("service")}
                    variant="outlined"
                    margin="dense"
                    value={service}
                    defaultValue={service}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <Autocomplete
            id="country"
            className={classes.field}
            options={countries}
            classes={{
                option: classes.option,
            }}
            getOptionLabel={(option) => option.name}
            value={country}
            defaultValue={country}
            onChange={handleCountryChange}
            onSelect={fetchAdminL1}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{countryToFlag(option.iso3166Alpha2)}</span>
                    {option.name} ({option.iso3166Alpha3}) +{option.iso3166Numeric}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("country")}
                    variant="outlined"
                    margin="dense"
                    value={option => option.iso3166Alpha3}
                    defaultValue={country}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <Autocomplete
            id="administrative1"
            disabled={!country || admin1List.length===0}
            className={classes.field}
            options={admin1List}
            getOptionLabel={option => option.name}
            value={adminL1}
            defaultValue={adminL1}
            onChange={handleAdminL1Change}
            onSelect={fetchAdminL2}
            loading={true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("administrative1")}
                    variant="outlined"
                    margin="dense"
                    value={adminL1}
                    defaultValue={adminL1}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <Autocomplete
            id="administrative2"
            disabled={!adminL1 || admin2List.length===0}
            className={classes.field}
            options={admin2List}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={adminL2}
            onSelect={fetchAdminL3}
            onChange={handleAdminL2Change}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("administrative2")}
                    variant="outlined"
                    margin="dense"
                    defaultValue={adminL2}
                />
            )}
        />
        <Autocomplete
            id="administrative3"
            disabled={!adminL2 || admin3List.length===0}
            className={classes.field}
            options={admin3List}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={adminL3}
            onSelect={fetchCities}
            onChange={handleAdminL3Change}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("administrative3")}
                    variant="outlined"
                    margin="dense"
                    defaultValue={adminL3}
                />
            )}
        />
        <Autocomplete
            id="postalcode"
            disabled={!adminL3}
            className={classes.field}
            options={postalCodes}
            getOptionLabel={option => option.postalCode}
            getOptionSelected={(option, value) => option.postalCode === value.postalCode}
            value={postalCode}
            onOpen={fetchCAP}
            onChange={handlePostalCodeChange}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("postalCode")}
                    variant="outlined"
                    margin="dense"
                    defaultValue={postalCode}
                />
            )}
        />
        <Autocomplete
            id="city"
            disabled={!postalCode}
            className={classes.field}
            options={cities}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={city}
            onOpen={fetchCities}
            onChange={handleCityChange}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("city")}
                    variant="outlined"
                    margin="dense"
                    defaultValue={city}
                />
            )}
        />
        <Autocomplete
            id="hamlet"
            disabled={!city}
            className={classes.field}
            options={hamlets}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={hamlet}
            onOpen={fetchHamlets}
            onChange={handleHamletChange}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("hamlet")}
                    variant="outlined"
                    margin="dense"
                    defaultValue={hamlet}
                />
            )}
        />

        <CardActions className={classes.button_container}>
            <Button
                className={classes.cancel_button}
                endIcon={<CancelOutlined/>}
                onClick={cancelNewArea}
                variant={"contained"}>
                {t("cancel")}
            </Button>
            <Button
                className={classes.confirm_button}
                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                onClick={submitNewArea}
                color="primary"
                variant={"contained"}>
                {t("add_area")}
            </Button>
        </CardActions>

        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={closeNotification}>{notificationMessage}</Notification>



    </form>

} export default RoutingRuleForm;