import {DialogTitle, Fade, IconButton, Paper, TableRow, Typography} from "@mui/material";
import {TableCell} from "@material-ui/core";
import {ArrowCircleDown, ArrowCircleUp} from "@mui/icons-material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        // cursor: "pointer",
        // transition: "all 0.2s",
        //
        // "&:hover": {
        //
        //     background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'
        //
        //
        // },
        // '& > *': {
        //     borderBottom: 'unset',
        // },
    },
    iconCell: {
        justifyContent: "end",
        margin: "auto",
        textAlign: 'end',
        display: "block",
    },
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }


});

function ReportWarehouse(props) {

    let warehouse = props.warehouse;

    const {t} = useTranslation();

    const classes = useStyles();

    function openModalAndSetShipments(shipments) {
        props.openModal(shipments);
    }

    function getShipmentListAndTotalCodValue(list, keys) {

        let output = {};

        output.shipments = [];
        output.totalValue = 0;

        let total = 0;
        let shipments = [];


        for (let j = 0; j < keys.length; j++) {
            list[keys[j]].map((shipment) => {
                total += shipment.value;
                shipments.push(shipment.shipmentId);
            })
        }


        output.shipments = shipments;
        output.totalValue = total;

        return output;


    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }


    return <TableRow style={{display: !props.in ? "none" : null}}>
        <TableCell>

        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            {warehouse.warehouseId ? warehouse.warehouseCode + " - " + warehouse.warehouseName : t("undefined")}
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["toCollectWithoutCheckin"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["toCollectWithoutCheckin"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["toCollectWithCheckin"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["toCollectWithCheckin"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["collected"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["collected"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["verifiedInWarehouse"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["verifiedInWarehouse"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["paidOnBankAccount"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["paidOnBankAccount"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
        <TableCell style={{fontStyle: "oblique"}}>
            <Typography
                onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(warehouse, ["collected", "verifiedInWarehouse", "paidOnBankAccount"]).shipments)}
                className={classes.link}> {getShipmentListAndTotalCodValue(warehouse, ["collected", "verifiedInWarehouse", "paidOnBankAccount"]).totalValue.toFixed(2)} </Typography>
        </TableCell>
    </TableRow>


}

export default ReportWarehouse;