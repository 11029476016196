import {Autocomplete, Card, TableRow, TextField, Typography} from "@mui/material";
import {CircularProgress, MenuItem, TableCell} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import TripService from "../trips/TripService";
import {useTranslation} from "react-i18next";
import {Autorenew} from "@material-ui/icons";
import DepartureService from "./DepartureService";
import {Download} from "@mui/icons-material";
import Notification from "../../components/notifications/Notification";
import moment from "moment";


export default function CheckInDeparturesRouteComponent(props) {


    const [trips, setTrips] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState(null);

    const [loading, setLoading] = useState(false);

    const [pdfId, setPdfId] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const {t} = useTranslation();

    function handleTripChange(event, trip) {
        setSelectedTrip(trip);
    }

    async function fetchTrips() {

        setSelectedTrip(null);

        let filters = {};

        if (null !== props.route.routeID) {
            filters.routeId = props.route.routeID;
        }

        if(props.tripDate){
            filters.departureDateFrom = moment(props.tripDate).format("yyyy-MM-DD");
            filters.departureDateTo = moment(props.tripDate).format("yyyy-MM-DD");
        }

        await new TripService().getTrips(filters)
            .then(response => {
                setTrips(response.trips);
                if (response.trips.length === 1) {
                    setSelectedTrip(response.trips[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {

                })
            })

    }

    async function checkInDeparture() {

        if(!selectedTrip){
            setNotificationMessage(t("select_required_fields"));
            setNotifySeverity('error');
            setOpenNotify(true);
            return;
        }

        setLoading(true);

        let input = {};

        input.tripId = selectedTrip?.id;
        input.departureWarehouseCode = props.warehouseCode;

        input.destinationWarehouses = [];

        let warehouses = props.route.warehouses;

        for (let i = 0; i < warehouses.length; i++) {
            let item = {};

            item.destinationWarehouseCode = warehouses[i].destinationWarehouseCode;

            item.parcels = [];


            for (let j = 0; j < warehouses[i].parcels.length; j++) {
                item.parcels.push(warehouses[i].parcels[j].id);
            }

            input.destinationWarehouses.push(item);
        }

        await new DepartureService().checkInDeparture(input)
            .then(async result => {
                setPdfId(result.id);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message + ". " + t("check_in_departure_refresh_page_message"));
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })

            })


    }

    async function downloadSummary(id, reduced) {

        await new TripService().downloadSummary(id, reduced)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    function parcelsCount(route){

        let count = 0;

        route.warehouses.map(warehouse => count += warehouse.parcels.length);

        return count;

    }


    useEffect(() => {
        fetchTrips();
    }, [props.tripDate])


    useEffect(() => {

    }, [pdfId])


    return <TableRow>
        <TableCell>
            <Typography>{props.route.routeName}</Typography>
        </TableCell>
        <TableCell>
            {props.route.warehouses.map(warehouse => {
                return <Typography variant={"subtitle1"}>{warehouse.destinationWarehouseCode}</Typography>

            })}
        </TableCell>
        <TableCell>
            <Typography>{parcelsCount(props.route)}</Typography>
        </TableCell>
        <TableCell>
            <div>
                {pdfId ?
                    <MenuItem
                        style={{width: "fit-content"}}
                        onClick={() => downloadSummary(pdfId, false)}
                    ><Download/>&nbsp;{t("download_summary")}&nbsp;{"N."}&nbsp;{pdfId}</MenuItem>
                    :
                    <div style={{display: "flex"}}>
                        <Autocomplete
                            id="trip"
                            size="small"
                            style={{width: "50%"}}
                            options={trips}
                            getOptionLabel={option => t("trip") + " " + option.id + " " + t("of") + " " + new Date(option.departureDateTime).toLocaleDateString() + " - " + option.supplierAcc + " " + option.driverName + " " + option.plateNumber}
                            value={selectedTrip}
                            defaultValue={selectedTrip}
                            noOptionsText={t("no_options")}
                            onChange={handleTripChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("trip")}
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedTrip}
                                    defaultValue={selectedTrip}
                                    required
                                    error={!selectedTrip}
                                />
                            )}
                        />

                            <MenuItem
                                style={{width: "fit-content", margin: "auto"}}
                                disabled={loading}
                                onClick={checkInDeparture}>{loading ?
                                <CircularProgress style={{color: "black"}} size={20}/> :
                                <Autorenew/>}&nbsp;{t("generate_summary_out")}</MenuItem>
                        </div>

                }
            </div>
        </TableCell>
        <Notification open={openNotify} severity={notifySeverity}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </TableRow>

}










