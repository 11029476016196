import React, {useEffect, useState} from "react";
import {
    Button, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination, useTheme
} from "@material-ui/core";
import UserService from "./UserService";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import User from "./User";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import UserForm from "./UserForm";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))

function UsersTable(props) {

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign:'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: '100%',

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        iconCell: {
            justifyContent: "end",
            margin: "auto",
            textAlign:'end',
            display: "block",
        },

        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
        icon: {
            marginRight: "5%"
        },
        loading:{
            width: '100%',
            margin: "auto",
            marginBottom: "3%",
            display: "block",

        },
        hidden: {
            display: "none"
        }
    });

    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const history = useHistory();
    const location = useLocation();
    const [refresh, setRefresh] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [pages, setPages] = useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let counter = 0;


    const [users, setUsers] = useState(null);
    const [fetchUsersError, setFetchUsersError] = useState(null);

    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    function fetchUsers() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        const usersService = new UserService();
        usersService.getAllUsers(filters)
            .then((data) => {
                setUsers(data.users);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setTimeout(()=>{
                    setLoading(false);
                }, 1500)
            })

    }


    useEffect(() => {

     fetchUsers();

    }, [refresh, page, rowsPerPage, location]);


    function goToUserForm(){
        history.push("/users/new");
    }


    return <Paper className={classes.root} elevation={3}>

        {(loading) ?

            <LoadingComponent/>

            :

            null

        }

        {(users) ?

            <Switch>
                <div className={loading ? classes.hidden : null}>
                    <PrivateRoute exact path={"/users"}>

                        <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToUserForm}
                                className={classes.button}>
                            {t("create_new_user")}
                        </Button>


                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="" className={classes.table}>
                                <TableHead>
                                    <TableCell>ID</TableCell>
                                    <TableCell>{t("username")}</TableCell>
                                    <TableCell>{t("roles")}</TableCell>
                                    <TableCell/>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => {

                                        return <User key={index} user={user}/>

                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={-1}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                // SelectProps={{
                                //     inputProps: {'aria-label': 'rows per page'},
                                //     variant: "outlined"
                                //
                                // }}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    variant: "outlined",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    classes: {paper: classes.menuPaper}
                                }}
                                labelRowsPerPage={t("rows_per_page")}
                                labelDisplayedRows={() => {
                                    return t("page")
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableContainer>


                    </PrivateRoute>
                    <PrivateRoute strict path={`/users/:id/edit/`} children={<UserForm/>}>
                    </PrivateRoute>
                    <PrivateRoute exact path={`/users/new/`}>
                        <UserForm user={null}/>
                    </PrivateRoute>
                </div>
            </Switch>

            :

            null
        }


        </Paper>

}


export default UsersTable;