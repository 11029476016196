import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Paper, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";


function CountryForm(props){

    const {t} = useTranslation();
    const history = useHistory();
    const [name, setName] = useState('');
    const [isoAlpha3, setIsoAlpha3] = useState('');
    const [isoAlpha2, setIsoAlpha2] = useState('');
    const [isoAlphaNum, setIsoAlphaNum] = useState('');

    let useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',


        },
        field: {
            display: "flex",
            margin: "auto",
            marginBottom: "1%",
            marginTop: "1%",
            width: '25%',


        },

        label: {
            width: "15%",
            margin: "auto",
            display: "flex"
        },
        formControl: {
            margin: theme.spacing(1),
            width: "30%",
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        button: {
            display: "block",
            margin: "auto"
        }

    }));

    const classes = useStyles();

    function handleClose() {
        history.push('/countries');
    }



    useEffect(()=>{

    },[])


    return <Paper>
        <form>
            <DialogTitle>{t("country_data")}</DialogTitle>

            <DialogContent>
                <TextField
                    label={t("country_name")}
                    variant="outlined"
                    type="text"
                    name="name"
                    margin="dense"
                    onChange={event => {setName(event.target.value)}}
                    value={name}
                    className={classes.field}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button type="submit" color="primary">
                    {t("confirm")}
                </Button>
            </DialogActions>
        </form>
    </Paper>


}


export default CountryForm;