import React, {useEffect, useState} from "react";
import {
    Button, FormControlLabel,
    MenuItem,
    Paper, Radio, RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme
} from "@material-ui/core";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ContractService from "./ContractService";
import Contract from "./Contract";
import ContractForm from "./ContractForm";
import Notification from "../../components/notifications/Notification";
import {Card, CircularProgress, DialogTitle, Typography} from "@mui/material";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import IconButton from "@material-ui/core/IconButton";
import {Autocomplete} from "@material-ui/lab";
import AccountService from "../AccountService";
import theme from "../../theme/theme";
import DateField from "../../components/fields/DateField";
import moment from "moment/moment";


function ContractsTable(props) {

    const usePaginationStyles = makeStyles((theme) => ({
        root: {
            margin: "auto",
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
        menuPaper: {
            maxHeight: 250
        }
    }));


    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center'
        },
        container: {
            maxHeight: 840,
        },

        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [refresh, setRefresh] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [validFrom, setValidFrom] = useState(null);
    const [validUntil, setValidUntil] = useState(null);
    const [hasWhatsappStream, setWhatsappStream] = useState('');
    const [rateCode, setRateCode] = useState('');

    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pages, setPages] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {

            })
    }

    function handleAccountChange(event, account) {
        setAccount(account);
    }

    function handleValidFromChange(event){
        setValidFrom(event)
    }

    function handleValidUntilChange(event){
        setValidUntil(event);
    }

    function handleWhatsappStreamChange(event){
        setWhatsappStream(event.target.value)
    }

    function handleRateCodeChange(event){
        setRateCode(event.target.value)
    }


    async function fetchContracts() {
        setLoading(true);

        let filters = {};

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        if (account) {
            filters.accountId = account.id;
        }

        if(validFrom){
            filters.validFrom = moment(validFrom).format('yyyy-MM-DD');
        }

        if(validUntil) {
            filters.validUntil = moment(validUntil).format('yyyy-MM-DD');
        }

        if('' !== hasWhatsappStream){
            filters.hasWhatsappStream = hasWhatsappStream === 'true';
        }

        if('' !== rateCode){
            filters.rateCode = rateCode;
        }

        await new ContractService().getPagedContractsByFilters(filters)
            .then(response => {
                setContracts(response.contracts);
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setLoading(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })
    }

    function search() {
        setPage(0);
        setRefresh(!refresh);
    }

    function goToContractForm() {
        history.push(`/contracts/new`)
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function closeNotification() {
        setOpenNotify(false);
    }


    useEffect(() => {
        fetchContracts();
    }, [location, refresh, page, rowsPerPage])


    return <Paper className={classes.root}>
        <Switch>
            <PrivateRoute exact path={"/contracts"}>
                {loading ?

                    <LoadingComponent/>

                    :

                    <div style={{display: "block"}}>

                        <DialogTitle style={{margin: "auto"}}>{t("contracts")}</DialogTitle>

                        <Card style={{width: "50%", margin: "auto", marginTop: "3%"}}>
                            <DialogTitle style={{margin: "auto"}}>{t("filters")}</DialogTitle>
                            <Autocomplete
                                id="account"
                                options={accounts}
                                // className={classes.filter}
                                style={{width: "35%", margin: "auto", marginBlock: "3%"}}
                                getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                value={account}
                                defaultValue={account}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchAccounts}
                                onChange={handleAccountChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("account")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={account}
                                        defaultValue={account}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <div style={{display: "flex", marginBlock: "5%"}}>
                                <DateField
                                    style={{width: "20%", margin: "auto"}}
                                    label={t("valid_from")}
                                    value={validFrom}
                                    onChange={handleValidFromChange}
                                    format={"dd/MM/yyyy"}
                                    clearable={true}
                                />
                                <DateField
                                    style={{width: "20%", margin: "auto"}}
                                    label={t("valid_until")}
                                    value={validUntil}
                                    onChange={handleValidUntilChange}
                                    format={"dd/MM/yyyy"}
                                    clearable={true}
                                />
                            </div>
                            <div style={{display: "flex", width: "70%", margin: "auto"}}>
                                <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                                    <Typography disabled={true}
                                                style={{color: "black"}}>{t("has_whatsapp_stream")}</Typography>
                                </TableCell>

                                <TableCell style={{borderBottom: "none"}}>
                                    <RadioGroup
                                        row
                                        value={hasWhatsappStream}
                                        onChange={handleWhatsappStreamChange}
                                        style={{margin: "auto"}}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                          label={t("yes")}/>
                                        <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                          label={t("no")}/>
                                        <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                                          label={t("any")}/>
                                    </RadioGroup>
                                </TableCell>
                            </div>
                            <div style={{display: "flex", width: "100%", margin: "auto", marginBlock: "5%"}}>
                                <TextField
                                    style={{margin: "auto", width: "30%"}}
                                    label={t("rate_code")}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleRateCodeChange}
                                    value={rateCode}
                                    defaultValue={rateCode}
                                />
                            </div>
                            <IconButton onClick={search}
                                        disabled={loading}
                                        style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{loading ?
                                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                                <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                        </Card>

                        <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                                onClick={goToContractForm}
                                className={classes.button}>
                            {t("create_new_contract")}
                        </Button>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("contract_code")}</TableCell>
                                        <TableCell>{t("contract_name")}</TableCell>
                                        <TableCell>{t("account_corporate_code")}</TableCell>
                                        <TableCell>{t("valid_from")}</TableCell>
                                        <TableCell>{t("valid_until")}</TableCell>
                                        {/*<TableCell>{t("company")}</TableCell>*/}
                                        <TableCell>{t("Logo")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contracts.map((contract, index) => {
                                        return <Contract key={contract.id} contract={contract}/>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: false,
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            onPageChange={handleChangePage}/>
                    </div>
                }
            </PrivateRoute>
            <PrivateRoute strict path={`/contracts/:id/edit`} children={<ContractForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/contracts/new`}>
                <ContractForm/>
            </PrivateRoute>
        </Switch>

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>

}

export default ContractsTable;