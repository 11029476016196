import React, {useEffect, useState} from "react";
import WarehouseStorageService from "./WarehouseStorageService";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import {
    Button,
    Card,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme
} from "@material-ui/core";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {CircularProgress, DialogTitle, Tooltip, Typography} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {ArrowUpwardOutlined, CloudDownload} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import WarehouseStorage from "./WarehouseStorage";
import WarehouseStorageDetails from "./WarehouseStorageDetails";
import WarehouseStorageProvisionPage from "./WarehouseStorageProvisionPage";
import WarehouseStorageReleasePage from "./WarehouseStorageReleasePage";
import {Autocomplete} from "@material-ui/lab";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import WarehouseStorageForm from "./WarehouseStorageForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StyledButton from "../components/buttons/StyledButton";
import WarehouseService from "../warehouses/WarehouseService";
import ShipmentLabelPage from "../shipments/ShipmentLabelPage";
import WarehouseStorageLabelPage from "./WarehouseStorageLabelPage";
import InfoChip from "../components/chips/InfoChip";
import Notification from "../components/notifications/Notification";
import DateField from "../components/fields/DateField";
import moment from "moment";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))


const useStyles = makeStyles((theme) => ({
    root: {
        width: '101%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));


function WarehouseStoragesTable(props) {

    const [warehouseStorages, setWarehouseStorages] = useState([]);

    const location = useLocation();

    const [page, setPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [loadingCsv, setLoadingCsv] = useState(false);

    const [fetching, setFetching] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [count, setCount] = useState(0);

    const [fetched, setFetched] = useState(false);
    const [landing, setLanding] = useState(true);

    const [id, setId] = useState('');
    const [barcode, setBarcode] = useState('');

    const [shipmentId, setShipmentId] = useState('');
    const [reference, setReference] = useState('');
    const [customerReference, setCustomerReference] = useState('');

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [warehouseStorageDateFrom, setWarehouseStorageDateFrom] = useState(null);
    const [warehouseStorageDateTo, setWarehouseStorageDateTo] = useState(null);
    const [provisionDateFrom, setProvisionDateFrom] = useState(null);
    const [provisionDateTo, setProvisionDateTo] = useState(null);
    const [releaseDateFrom, setReleaseDateFrom] = useState(null);
    const [releaseDateTo, setReleaseDateTo] = useState(null);

    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [hasProvision, setHasProvision] = useState("null");
    const [hasRelease, setHasRelease] = useState("false");
    const [canceled, setCanceled] = useState("false");
    const [deliveryName, setDeliveryName] = useState('');
    const [reason, setReason] = useState(null);
    const [reasons, setReasons] = useState([]);

    const [accountList, setAccountList] = useState([]);
    const [contractList, setContractList] = useState([]);

    const {t} = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    function refreshCallback() {
        setOpenNotify(true);
        setNotificationMessage(t("successful"))
        setNotifySeverity('success')
        setRefresh(!refresh);
    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(response => {
                setWarehouses(response.warehouses)
            })
            .catch(error => {

            })
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts()
            .then(data => {
                setAccountList(data)
            })
            .catch((err) => {

            })
    }

    async function fetchReasons() {

        await new WarehouseStorageService().getWarehouseStorageReasons()
            .then(results => {
                setReasons(results);
            })
            .catch(error => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContractList(response);
            })

    }

    function handleDeliveryNameChange(event) {
        setDeliveryName(event.target.value);
    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        // setSubContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
        // setSubContract(null);

    }

    function handleWarehouseStorageDateFromChange(event) {
        setWarehouseStorageDateFrom(event);
    }

    function handleWarehouseStorageDateToChange(event) {
        setWarehouseStorageDateTo(event);
    }

    function handleReleaseDateFromChange(event) {
        setReleaseDateFrom(event);
    }

    function handleReleaseDateToChange(event) {
        setReleaseDateTo(event);
    }

    function handleProvisionDateFromChange(event) {
        setProvisionDateFrom(event);
    }

    function handleProvisionDateToChange(event) {
        setProvisionDateTo(event);
    }

    function handleReasonChange(event, reason) {
        setReason(reason);
    }


    function handleShipmentIdChange(event) {

        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleIdChange(event) {

        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setId(event.target.value);
        }
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value);
    }

    function handleReferenceChange(event) {
        setReference(event.target.value);
    }

    function handleCustomerReferenceChange(event) {
        setCustomerReference(event.target.value);
    }

    function handleChangePage(event, newPage) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function handleHasProvisionChange(event) {
        setHasProvision(event.target.value);
        setProvisionDateFrom(null);
        setProvisionDateTo(null);
    }

    function handleHasReleaseChange(event) {
        setHasRelease(event.target.value);
        setReleaseDateFrom(null);
        setReleaseDateTo(null);
    }

    function handleCanceledChange(event) {
        setCanceled(event.target.value);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }

    function goToWSDetails(id) {
        history.push(`/warehouse-storages/${id}/details`)
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function fetchCsv() {

        setLoadingCsv(true);

        let filters = {};

        if (null !== account) {
            filters.accountCorporateCode = account.accountCorporateCode;
        }
        if (null !== contract) {
            filters.contractCode = contract.code;
        }
        if (shipmentId !== '') {
            filters.shipmentId = shipmentId
        }
        if (customerReference !== '') {
            filters.shipmentCustomerReference = customerReference;
        }
        if (reference !== '') {
            filters.shipmentReference = reference;
        }

        if (warehouseStorageDateFrom) {
            filters.from = moment(warehouseStorageDateFrom).format('yyyy-MM-DD');
        }

        if (warehouseStorageDateTo) {
            filters.to = moment(warehouseStorageDateTo).format('yyyy-MM-DD');
        }

        switch (hasProvision) {
            case "true" :
                filters.hasProvision = true
                if (provisionDateFrom) {
                    filters.provisionFrom = moment(provisionDateFrom).format('yyyy-MM-DD');
                }

                if (provisionDateTo) {
                    filters.provisionTo = moment(provisionDateTo).format('yyyy-MM-DD');
                }
                break;
            case "false":
                filters.hasProvision = false
                break;
            default:
        }
        switch (hasRelease) {
            case "true" :
                filters.hasRelease = true
                if (releaseDateFrom) {
                    filters.releaseFrom = moment(releaseDateFrom).format('yyyy-MM-DD');
                }

                if (releaseDateTo) {
                    filters.releaseTo = moment(releaseDateTo).format('yyyy-MM-DD');
                }
                break;
            case "false":
                filters.hasRelease = false
                break;
            default:
        }
        switch (canceled) {
            case "true" :
                filters.isCanceled = true
                break;
            case "false":
                filters.isCanceled = false
                break;
            default:
        }

        if (deliveryName !== '') {
            filters.deliveryName = deliveryName;
        }

        if (warehouse) {
            filters.warehouseCode = warehouse.code;
        }

        if(reason){
            filters.reasonId = reason.id
        }

        if (id !== '') {

            let list = [];

            list.push(id)

            filters.id = list;
        }

        if (barcode !== '') {

            filters.barcode = barcode;
        }


        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getCsv(filters)
            .then(data => {

                let tempLink = document.createElement('a');
                tempLink.href = `data:${data.contentType};base64,` + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();

                setTimeout(() => {
                    setLoadingCsv(false)
                }, 1000)

            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCsv(false);
                })

            })
    }


    function fetchWarehouseStorages() {

        setFetching(true);

        let filters = {};
        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;
        if (null !== account) {
            filters.accountCorporateCode = account.accountCorporateCode;
        }
        if (null !== contract) {
            filters.contractCode = contract.code;
        }

        if (shipmentId !== '') {
            filters.shipmentId = shipmentId
        }
        if (customerReference !== '') {
            filters.shipmentCustomerReference = customerReference;
        }
        if (reference !== '') {
            filters.shipmentReference = reference;
        }

        if (warehouseStorageDateFrom) {
            filters.from = moment(warehouseStorageDateFrom).format('yyyy-MM-DD');
        }

        if (warehouseStorageDateTo) {
            filters.to = moment(warehouseStorageDateTo).format('yyyy-MM-DD');
        }

        switch (hasProvision) {
            case "true" :
                filters.hasProvision = true
                if (provisionDateFrom) {
                    filters.provisionFrom = moment(provisionDateFrom).format('yyyy-MM-DD');
                }

                if (provisionDateTo) {
                    filters.provisionTo = moment(provisionDateTo).format('yyyy-MM-DD');
                }
                break;
            case "false":
                filters.hasProvision = false
                break;
            default:
        }
        switch (hasRelease) {
            case "true" :
                filters.hasRelease = true
                if (releaseDateFrom) {
                    filters.releaseFrom = moment(releaseDateFrom).format('yyyy-MM-DD');
                }

                if (releaseDateTo) {
                    filters.releaseTo = moment(releaseDateTo).format('yyyy-MM-DD');
                }
                break;
            case "false":
                filters.hasRelease = false
                break;
            default:
        }
        switch (canceled) {
            case "true" :
                filters.isCanceled = true
                break;
            case "false":
                filters.isCanceled = false
                break;
            default:
        }

        if (deliveryName !== '') {
            filters.deliveryName = deliveryName;
        }

        if (warehouse) {
            filters.warehouseCode = warehouse.code;
        }

        if (reason) {
            filters.reasonId = reason.id;
        }

        if (id !== '') {

            let list = [];

            list.push(id)

            filters.id = list;
        }

        if (barcode !== '') {

            filters.barcode = barcode;
        }


        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getFilteredWarehouseStorages(filters)
            .then(data => {
                setWarehouseStorages(data.warehouseStorages);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setCount(data.count);
                setTimeout(() => {
                    setFetching(false)
                    setFetched(true);
                }, 1000)

            })
            .catch(error => {
                setFetching(false);
            })
    }

    function goToProvisionPage(id) {
        window.open(`/warehouse-storages/${id}/provision`, `${id}`, null, false);
    }

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);
        setLanding(false);

    }

    function clearFilters() {

        setAccount(null);
        setContract(null);
        setCanceled("false");
        setHasRelease("false");
        setHasProvision("null");
        setWarehouseStorageDateFrom(null)
        setWarehouseStorageDateTo(null)
        setProvisionDateFrom(null);
        setProvisionDateTo(null);
        setReleaseDateFrom(null);
        setReleaseDateTo(null);
        setDeliveryName('');
        setShipmentId('')
        setCustomerReference('')
        setReference('')
        setId('')
        setBarcode('')
        setReason(null);
        setWarehouse(null);
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }


    useEffect(() => {
        if (!landing) {
            fetchWarehouseStorages();
        }


        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, [rowsPerPage, page, refresh, location])

    return <div className={classes.root}>


        <Switch>
            <PrivateRoute exact path={"/warehouse-storages"}>
                <div className={classes.root}>
                                <Card elevation={1} defaultExpanded={true}
                                      style={{width: "90%", margin: "auto", marginBlock: "2%"}}>
                                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                                        <Typography variant={"button"}>{t("filters")}</Typography>
                                    </DialogTitle>
                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <TextField
                                            className={classes.filter}
                                            label={t("warehouse_storage_id")}
                                            value={id}
                                            onChange={handleIdChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                    </div>
                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <TextField
                                            className={classes.filter}
                                            label={t("barcode")}
                                            value={barcode}
                                            onChange={handleBarcodeChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                    </div>
                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <TextField
                                            className={classes.filter}
                                            label={t("shipmentId")}
                                            value={shipmentId}
                                            onChange={handleShipmentIdChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                        <TextField
                                            className={classes.filter}
                                            label={t("reference")}
                                            value={reference}
                                            onChange={handleReferenceChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                        <TextField
                                            className={classes.filter}
                                            label={t("customerReference")}
                                            value={customerReference}
                                            onChange={handleCustomerReferenceChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                    </div>

                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <Autocomplete
                                            id="account"
                                            options={accountList}
                                            className={classes.filter}
                                            getOptionLabel={option => option.companyName}
                                            value={account}
                                            defaultValue={account}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchAccounts}
                                            onChange={handleAccountChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("account")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={account}
                                                    defaultValue={account}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            id="contract"
                                            disabled={!account}
                                            options={contractList}
                                            className={classes.filter}
                                            getOptionLabel={option => option.code}
                                            value={contract}
                                            defaultValue={contract}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchContracts}
                                            onChange={handleContractChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("contract")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={contract}
                                                    defaultValue={contract}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <TextField
                                            className={classes.filter}
                                            label={t("delivery_name")}
                                            value={deliveryName}
                                            onChange={handleDeliveryNameChange}
                                            margin={"dense"}
                                            variant={"outlined"}
                                        />
                                    </div>
                                    <div style={{alignItems: 'center', display: "flex"}}>
                                        <Autocomplete
                                            id="warehouse"
                                            options={warehouses}
                                            size={"small"}
                                            className={classes.filter}
                                            getOptionLabel={option => option.code + " - " + option.name}
                                            value={warehouse}
                                            defaultValue={warehouse}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchWarehouses}
                                            onChange={handleWarehouseChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("warehouse")}
                                                    InputLabelProps={{shrink: true}}
                                                    variant="outlined"
                                                    placeholder={t("any")}
                                                    margin="dense"
                                                    size={"small"}
                                                    value={warehouse}
                                                    defaultValue={warehouse}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            id="reasons"
                                            options={reasons}
                                            size={"small"}
                                            className={classes.filter}
                                            getOptionLabel={option => option.id + " - " + option.description}
                                            value={reason}
                                            defaultValue={reason}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchReasons}
                                            onChange={handleReasonChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("reason")}
                                                    InputLabelProps={{shrink: true}}
                                                    variant="outlined"
                                                    placeholder={t("any")}
                                                    margin="dense"
                                                    size={"small"}
                                                    value={reason}
                                                    defaultValue={reason}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />

                                    </div>
                                    <div style={{display: "flex"}}>
                                        <DateField
                                            style={{margin: "auto", width: "30%"}}
                                            label={t("warehouse_storage_date_from")}
                                            value={warehouseStorageDateFrom}
                                            onChange={handleWarehouseStorageDateFromChange}
                                            format={"dd/MM/yyyy"}
                                            disableFuture={true}
                                        />
                                        <DateField
                                            style={{margin: "auto", width: "30%"}}
                                            label={t("warehouse_storage_date_to")}
                                            value={warehouseStorageDateTo}
                                            onChange={handleWarehouseStorageDateToChange}
                                            format={"dd/MM/yyyy"}
                                            disableFuture={true}
                                        />
                                    </div>
                                    <TableRow style={{display: "flex"}}>
                                        {/*<FormControl style={{*/}
                                        {/*    margin: "auto",*/}
                                        {/*    marginBottom: "2%",*/}
                                        {/*    width: "100%",*/}
                                        {/*}}>*/}
                                        <TableCell style={{
                                            borderBottom: "none",
                                            margin: "auto",
                                            width: "50%",
                                            textAlign: "right"
                                        }}>
                                            <Typography disabled={true}
                                                        style={{color: "black"}}>{t("has_provision")}</Typography>
                                        </TableCell>

                                        <TableCell style={{borderBottom: "none", width: "50%", margin: "auto"}}>
                                            <RadioGroup
                                                row
                                                value={hasProvision}
                                                onChange={handleHasProvisionChange}
                                                style={{margin: "auto"}}
                                            >
                                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                                  label={t("yes")}/>
                                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                                  label={t("no")}/>
                                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                                  label={t("any")}/>
                                            </RadioGroup>
                                        </TableCell>


                                        {/*</FormControl>*/}
                                    </TableRow>
                                    {hasProvision === "true" ?
                                        <div style={{display: "flex"}}>
                                            <DateField
                                                style={{margin: "auto", width: "20%"}}
                                                label={t("from_date")}
                                                value={provisionDateFrom}
                                                onChange={handleProvisionDateFromChange}
                                                format={"dd/MM/yyyy"}
                                                disableFuture={true}
                                            />
                                            <DateField
                                                style={{margin: "auto", width: "20%"}}
                                                label={t("to_date")}
                                                value={provisionDateTo}
                                                onChange={handleProvisionDateToChange}
                                                format={"dd/MM/yyyy"}
                                                disableFuture={true}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <TableRow style={{display: "flex"}}>
                                        {/*<FormControl style={{*/}
                                        {/*    margin: "auto",*/}
                                        {/*    marginBottom: "2%",*/}
                                        {/*    width: "100%",*/}
                                        {/*}}>*/}
                                        <TableCell style={{
                                            borderBottom: "none",
                                            margin: "auto",
                                            width: "50%",
                                            textAlign: "right"
                                        }}>
                                            <Typography disabled={true}
                                                        style={{color: "black"}}>{t("has_release")}</Typography>
                                        </TableCell>

                                        <TableCell style={{borderBottom: "none", width: "50%", margin: "auto"}}>
                                            <RadioGroup
                                                row
                                                value={hasRelease}
                                                onChange={handleHasReleaseChange}
                                                style={{margin: "auto"}}
                                            >
                                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                                  label={t("yes")}/>
                                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                                  label={t("no")}/>
                                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                                  label={t("any")}/>
                                            </RadioGroup>
                                        </TableCell>


                                        {/*</FormControl>*/}
                                    </TableRow>
                                    {hasRelease === "true" ?
                                        <div style={{display: "flex"}}>
                                            <DateField
                                                style={{margin: "auto", width: "20%"}}
                                                label={t("from_date")}
                                                value={releaseDateFrom}
                                                onChange={handleReleaseDateFromChange}
                                                format={"dd/MM/yyyy"}
                                                disableFuture={true}
                                            />
                                            <DateField
                                                style={{margin: "auto", width: "20%"}}
                                                label={t("to_date")}
                                                value={releaseDateTo}
                                                onChange={handleReleaseDateToChange}
                                                format={"dd/MM/yyyy"}
                                                disableFuture={true}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <TableRow style={{display: "flex"}}>
                                        {/*<FormControl style={{*/}
                                        {/*    margin: "auto",*/}
                                        {/*    marginBottom: "2%",*/}
                                        {/*    width: "100%",*/}
                                        {/*}}>*/}
                                        <TableCell style={{
                                            borderBottom: "none",
                                            margin: "auto",
                                            width: "50%",
                                            textAlign: "right"
                                        }}>
                                            <FormLabel disabled={true}
                                                       style={{color: "black"}}>{t("is_canceled")}</FormLabel>
                                        </TableCell>

                                        <TableCell style={{borderBottom: "none", width: "50%", margin: "auto"}}>
                                            <RadioGroup
                                                row
                                                value={canceled}
                                                onChange={handleCanceledChange}
                                                style={{margin: "auto"}}
                                            >
                                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                                  label={t("yes")}/>
                                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                                  label={t("no")}/>
                                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                                  label={t("any")}/>
                                            </RadioGroup>
                                        </TableCell>


                                        {/*</FormControl>*/}
                                    </TableRow>
                                    <div style={{textAlign: 'center'}}>
                                        <Button style={{margin: "5%"}}
                                                onClick={clearFilters}>{t("cancel_filter")}</Button>
                                        <Button style={{margin: "5%"}}
                                                onClick={applyFilters}>{t("apply_filter")}</Button>
                                    </div>
                                </Card>
                                <StyledButton onClick={() => history.push("/warehouse-storages/new")}
                                              icon={<AddCircleIcon/>}>{t("new_warehouse_storage")}</StyledButton>
                                <StyledButton disabled={loadingCsv || warehouseStorages.length < 1} onClick={fetchCsv}
                                              icon={loadingCsv ?
                                                  <CircularProgress size={24} style={{color: "white"}}/> :
                                                  <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_csv")}</StyledButton>

                                {fetched ?
                                    <div style={{width: "100%", marginBottom: "-5%"}}>
                                        <InfoChip><Typography
                                            variant={"subtitle2"}>{t("shipment_count")} {count}</Typography></InfoChip>
                                    </div>
                                    :
                                    null
                                }
                    {fetching ?
                        <Table>
                            <LoadingComponent/>
                        </Table>

                        :
                        <>
                            {fetched && warehouseStorages.length > 0 ?
                                <Paper>

                                    <Table stickyHeader aria-label="sticky table">

                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("warehouse_storage_id")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("stored_shipment_id")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("warehouse_code")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("company_name")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("account_corporate_code")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("contract_code")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("reason")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("delivery_address")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("datetime_open_dossier")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("user")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("notes")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("provisions")} </Typography>
                                                    <Typography variant={"subtitle2"}>{t("provision_datetime")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("canceled")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={"subtitle2"}>{t("released_shipment_id")} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {warehouseStorages.length > 0 ?
                                            <TableBody>
                                                {warehouseStorages.map((warehouseStorage, index) => {
                                                    return <WarehouseStorage key={index}
                                                                             warehouseStorage={warehouseStorage}
                                                                             goToWsDetails={goToWSDetails}
                                                                             goToProvisionPage={goToProvisionPage}
                                                                             refreshCallback={refreshCallback}
                                                    />
                                                })}
                                            </TableBody>

                                            :

                                            // <div style={{height: 100}}>
                                            //     <Typography style={{marginTop: "5%"}}>{t("no_warehouse_storages_found")}</Typography>
                                            // </div>
                                            null
                                        }
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={-1}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        // SelectProps={{
                                        //     inputProps: {'aria-label': 'rows per page'},
                                        //     variant: "outlined"
                                        //
                                        // }}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            variant: "outlined",
                                            anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null,
                                            classes: {paper: classes.menuPaper}
                                        }}
                                        labelRowsPerPage={t("rows_per_page")}
                                        labelDisplayedRows={() => {
                                            return t("page")
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Paper>

                                :
                                null
                            }
                        </>
                    }
                    <Notification open={openNotify} severity={notifySeverity} duration={3000}
                                  onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                </div>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-storages/:id/details"}>
                <WarehouseStorageDetails/>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-storages/new"}>
                <WarehouseStorageForm/>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-storages/:id/provision"}>
                <WarehouseStorageProvisionPage/>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-storages/:id/release"}>
                <WarehouseStorageReleasePage/>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-storages/:id/label"}>
                <WarehouseStorageLabelPage/>
            </PrivateRoute>
        </Switch>
        {showButton && (
            <Tooltip title={t("back_to_top")}>
                <IconButton onClick={scrollToTop} className={classes.backToTop}>
                    <ArrowUpwardOutlined/>
                </IconButton>
            </Tooltip>
        )}
    </div>

}

export default WarehouseStoragesTable;