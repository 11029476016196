import React, {useEffect, useState} from "react";
import {
    FormControlLabel,
    MenuItem,
    Paper, Radio,
    RadioGroup,
    Select,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Switch, useHistory} from "react-router-dom";
import ChequeForwardingService from "./ChequeForwardingService";
import {PrivateRoute} from "../../routes/PrivateRoute";
import NewChequeForwarding from "./NewChequeForwarding";
import StyledButton from "../../components/buttons/StyledButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import {Autocomplete, Card, CircularProgress, IconButton, Table, TableRow, TextField, Typography} from "@mui/material";
import PaymentToCustomer from "../payments_to_customers/PaymentToCustomer";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import WarehouseService from "../../warehouses/WarehouseService";
import DateField from "../../components/fields/DateField";
import FormControl from "@mui/material/FormControl";
import theme from "../../theme/theme";
import InfoChip from "../../components/chips/InfoChip";
import ChequeForwarding from "./ChequeForwarding";
import Notification from "../../components/notifications/Notification";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function ChequeForwardingTable(props) {


    const {t} = useTranslation();

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [cheques, setCheques] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [pages, setPages] = useState([]);


    function calculateTotal(cods) {

        let total = 0;

        for (let i = 0; i < cods.length; i++) {
            total += cods[i].value;
        }

        return total;

    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses);
    }

    async function fetchCheques() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (dateFrom) {
            filters.dateFrom = moment(dateFrom).format("yyyy-MM-DD")
        }
        if (dateTo) {
            filters.dateTo = moment(dateTo).format("yyyy-MM-DD")
        }

        await new ChequeForwardingService().getChequeForwardingByFilters(filters)
            .then(result => {
                setCheques(result.chequesForwarding);
                setPages(Array.from(Array(Math.ceil(result.count / rowsPerPage)).keys()));
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    function failureCallback(message){

        setNotificationMessage(message.status + " - " + message.message);
        setNotifySeverity('error');
        setOpenNotify(true);

    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleDateFromChange(event) {
        setDateFrom(event)
    }

    function handleDateToChange(event) {
        setDateTo(event)
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    useEffect(() => {
        fetchCheques();
    }, [refresh, page, rowsPerPage])


    return <div style={{
        width: '101%',
        textAlign: 'center',
        marginBottom: "15%"
    }}>

        <Switch>
            <PrivateRoute exact path={"/cheque-forwarding"}>
                <div>
                    <Card style={{width: "80%", margin: "auto", marginBottom: "3%"}}>
                        <Autocomplete
                            id="warehouse-select"
                            size="small"
                            style={{marginBlock: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                            options={warehouses}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={warehouse}
                            defaultValue={warehouse}
                            noOptionsText={t("no_options")}
                            onOpen={fetchWarehouses}
                            onChange={handleWarehouseChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                />
                            )}
                        />

                        <div style={{display: "flex", margin: "auto", width: "70%"}}>
                            <DateField
                                style={{width: "35%", margin: "auto"}}
                                label={t("date_from")}
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />
                            <DateField
                                style={{width: "35%", margin: "auto"}}
                                label={t("date_to")}
                                value={dateTo}
                                onChange={handleDateToChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />

                        </div>


                        <div style={{margin: "auto"}}>
                            <IconButton onClick={() => setRefresh(!refresh)}
                                        disabled={loading}
                                        style={{
                                            backgroundColor: 'transparent',
                                            margin: "auto",
                                            marginBottom: "2%"
                                        }}>{loading ?
                                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                                <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                        </div>

                    </Card>
                    <div style={{width: "100%"}}>
                        <StyledButton onClick={() => history.push("/cheque-forwarding/new")}
                                      icon={<AddCircleIcon/>}>{t("new_cheque_forwarding")}</StyledButton>
                    </div>
                    <Paper>

                        {loading ?
                            <LoadingComponent/>

                            :


                            <div>
                                {cheques?.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {t("number")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("warehouse")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("shipment")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("datetime")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("amount")}
                                                </TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cheques?.map(cheque => {
                                                return <ChequeForwarding cheque={cheque} failureCallback={failureCallback}/>
                                            })}
                                        </TableBody>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={-1}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                native: false,
                                            }}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return t("page")
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            onPageChange={handleChangePage}/>
                                    </Table>

                                    :

                                    <div style={{paddingBlock: "1%"}}>
                                        <InfoChip>{t("no_data")}</InfoChip>
                                    </div>

                                }
                            </div>

                        }

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                    </Paper>
                </div>
            </PrivateRoute>
            <PrivateRoute exact path={"/cheque-forwarding/new"}>
                <NewChequeForwarding/>
            </PrivateRoute>
        </Switch>
    </div>


}

export default ChequeForwardingTable;