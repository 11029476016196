import React, {useEffect, useState} from "react";
import {
    Button, Card, FormControlLabel,
    MenuItem,
    Paper, Radio, RadioGroup,
    Select,
    Table,
    TableCell,
    TablePagination,
    TableRow,
    useTheme
} from "@material-ui/core";
import PaymentCardService from "./PaymentCardService";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Search} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useStyles} from "../theme/useStyles";
import InfoChip from "../components/chips/InfoChip";
import {AddCardOutlined} from "@mui/icons-material";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import CostCenterForm from "../accounting/cost_center/CostCenterForm";
import PaymentCardForm from "./PaymentCardForm";
import {Autocomplete, CircularProgress, DialogTitle, TableBody, TableHead, TextField, Typography} from "@mui/material";
import PaymentCard from "./PaymentCard";
import Notification from "../components/notifications/Notification";
import DriverForm from "../drivers/DriverForm";
import AccountService from "../accounts/AccountService";
import WarehouseService from "../warehouses/WarehouseService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))

export default function PaymentCardTable(props) {

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    const location = useLocation();

    const [cards, setCards] = useState([]);

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pages, setPages] = useState([]);

    const [cardNumber, setCardNumber] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [deleted, setDeleted] = useState("no");

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const history = useHistory();

    const {t} = useTranslation();

    function handleDeletedChange(event){
        setDeleted(event.target.value);
    }

    function handlePageNumberChange(event) {
        setPageNumber(event.target.value);
    }

    function handlePageSizeChange(event) {
        setPageSize(+event.target.value);
        setPageNumber(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPageNumber(0)
        };

        const handleBackButtonClick = (event) => {
            setPageNumber(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPageNumber(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPageNumber(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handlePageNumberChange}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    async function fetchCards() {

        setLoading(true);

        let filters = {};

        if(cardNumber){
            filters.cardNumber = cardNumber;
        }

        if(supplier){
            filters.supplierId = supplier.id;
        }

        if(warehouse){
            filters.warehouseId = warehouse.id;
        }

        switch (deleted) {
            case "yes" :
                filters.deleted = true
                break;
            case "no":
                filters.deleted = false
                break;
            default:
        }

        filters.pageSize = pageSize;
        filters.pageNumber = pageNumber;

        await new PaymentCardService().getCardsByFilters(filters)
            .then(result => {
                setCards(result.paymentCards);
                setPages(Array.from(Array(Math.ceil(result.count / pageSize)).keys()));
                setLoading(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })


    }

    function deleteCallback() {
        setRefresh(!refresh)
    }

    function goToNewPaymentCard() {
        history.push("/payment-cards/new")
    }

    function search(){
        setRefresh(!refresh);
    }

    function handleSupplierChange(event, supplier){
        setSupplier(supplier);
        setWarehouse(null);
    }

    function handleWarehouseChange(event, warehouse){
        setWarehouse(warehouse);
    }

    function handleCardNumberChange(event){
        setCardNumber(event.target.value)
    }

    async function fetchSuppliers() {

        let filters = {};
        // I want to see both customers and accounts
        filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(result => {
                setSuppliers(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchWarehouses() {

        // setLoading(true);

        setWarehouses([]);

        let filters = {};


        filters.showAll = false;


        if (supplier) {
            filters.accountId = supplier?.id;
        }


        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setWarehouses(result.warehouses)
                if (result.warehouses?.length === 1) {
                    setWarehouse(result.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

        // setLoading(false);
    }


    useEffect(() => {
        fetchCards();
    }, [pageNumber, pageSize, refresh, location])


    return <div>
        <Switch>
            <PrivateRoute exact path={"/payment-cards"}>

                <Card>
                    <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("filters")}</DialogTitle>
                    <div style={{display: "flex"}}>
                        <TextField
                            label={t("card_number")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            onChange={handleCardNumberChange}
                            value={cardNumber}
                            size={"small"}
                            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                        />
                    </div>
                    <Autocomplete
                        id="supplier-select"
                        size="small"
                        style={{marginBlock: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                        options={suppliers}
                        getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                        value={supplier}
                        defaultValue={supplier}
                        noOptionsText={t("no_options")}
                        onChange={handleSupplierChange}
                        onOpen={fetchSuppliers}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("supplier")}
                                variant="outlined"
                                margin="dense"
                                value={supplier}
                                defaultValue={supplier}
                            />
                        )}
                    />
                    <Autocomplete
                        id="warehouse-select"
                        size="small"
                        style={{marginBlock: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                        options={warehouses}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        noOptionsText={t("no_options")}
                        onChange={handleWarehouseChange}
                        onOpen={fetchWarehouses}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("warehouse")}
                                variant="outlined"
                                margin="dense"
                                value={warehouse}
                                defaultValue={warehouse}
                            />
                        )}
                    />
                    <div style={{display: "flex", width: "40%", margin: "auto"}}>
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "fit-content"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("deleted")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={deleted}
                                onChange={handleDeletedChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>
                    </div>

                    <div style={{display: "flex"}}>
                        <Button endIcon={loading ? <CircularProgress size={22}/> : <Search/>}
                                style={{margin: "auto", marginBlock: "2%"}}
                                onClick={search}>{t("search")}</Button>
                    </div>
                </Card>

                <div style={{width: "100%", display: "flex", marginTop: "5%"}}>
                    <Button startIcon={<AddCardOutlined/>} variant="contained" color="primary"
                            onClick={goToNewPaymentCard}
                            style={{margin: "auto"}}>
                        {t("add_card")}
                    </Button>
                </div>


                <Paper style={{marginTop: "5%"}}>
                    {cards.length > 0 ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("card_number")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("name")}</Typography>
                                        <Typography variant={"subtitle2"}>{t("surname")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("release_date")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("expiration_date")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("supplier")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("warehouse")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {cards.map(item => {
                                return <PaymentCard deleteCallback={deleteCallback} item={item}/>
                                })}
                            </TableBody>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={-1}
                                rowsPerPage={pageSize}
                                page={pageNumber}
                                // SelectProps={{
                                //     inputProps: {'aria-label': 'rows per page'},
                                //     variant: "outlined"
                                //
                                // }}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    variant: "outlined",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    classes: {paper: classes.menuPaper}
                                }}
                                labelRowsPerPage={t("rows_per_page")}
                                labelDisplayedRows={() => {
                                    return t("page")
                                }}
                                onChangePage={handlePageNumberChange}
                                onChangeRowsPerPage={handlePageSizeChange}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Table>
                        :
                        <InfoChip>{t("no_data")}</InfoChip>
                    }
                </Paper>

            </PrivateRoute>
            <PrivateRoute exact path={`/payment-cards/:id/edit/`} children={<PaymentCardForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={"/payment-cards/new"}>
                <PaymentCardForm/>
            </PrivateRoute>
        </Switch>
    </div>


}