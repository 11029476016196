import React, {useEffect, useState} from "react";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import {
    Card,
    CardActions,
    Container,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {ClearAll, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import RoutingRuleService from "./RoutingRuleService";
import RoutingRule from "./RoutingRule";
import {Autocomplete, DialogTitle, Paper} from "@mui/material";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import ServiceService from "../shipments/services/ServiceService";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import WarehouseService from "../warehouses/WarehouseService";
import ZoneService from "../zones/ZoneService";
import TimingService from "../timings/TimingService";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "5%",
        marginBottom: "5%",
        margin: "auto",
    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "70%",
        margin: "auto",
        marginBottom: "5%"
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "35%",
        margin: "auto",
        paddingInline: "2%"
    }
}));

export default function TimingsRoutingRuleTable(props) {
    const [fetching, setFetching] = useState(true);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [routingRules, setRoutingRules] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);

    const [timing, setTiming] = useState(null);
    const [timings, setTimings] = useState([]);

    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);

    const [adminL1, setAdminL1] = useState(null);
    const [adminL1List, setAdminL1List] = useState([]);


    const [adminL2, setAdminL2] = useState(null);
    const [adminL2List, setAdminL2List] = useState([]);


    const [adminL3, setAdminL3] = useState(null);
    const [adminL3List, setAdminL3List] = useState([]);

    const [city, setCity] = useState(null);
    const [cities, setCities] = useState([]);

    const [postalCode, setPostalCode] = useState(null);
    const [postalCodes, setPostalCodes] = useState([]);


    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    function resetFilters() {
        setService(null);
        setCountry(null);
        setAdminL1(null);
        setAdminL2(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setWarehouse(null);
        setTiming(null);
        setPage(0);
        setRowsPerPage(10);
        setRefreshTable(!refreshTable);
    }

    function applyFilters() {
        setPage(0);
        setRowsPerPage(10);
        setRefreshTable(!refreshTable);
    }


    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };


    function closeNotification() {
        setOpenNotify(false);
    }

    function goToAreaForm() {
        history.push("/areas/new")
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

        setRefreshTable(!refreshTable);

    }

    async function fetchWarehouses() {
        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })
    }

    async function fetchRoutingRules() {

        setFetching(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (service) {
            filters.serviceId = service.id;
        }

        if (timing) {
            filters.timingId = timing.id;
        }

        if (country) {
            filters.countryId = country.id;
        }

        if (adminL1) {
            filters.adminLevel1Id = adminL1.id;
        }

        if (adminL2) {
            filters.adminLevel2Id = adminL2.id;
        }

        if (adminL3) {
            filters.adminLevel3Id = adminL3.id;
        }

        if (city) {
            filters.cityId = city.id;
        }

        if (postalCode) {
            filters.postalCodeId = postalCode.id;
        }

        filters.isInTiming = true;

        await new RoutingRuleService().getRoutingRulesByFilters(filters)
            .then(data => {
                console.log(data)
                setRoutingRules(data.routingRules);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false)
            })
            .catch(error => {
                setFetching(false)
            })
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    async function fetchTimings() {

        await new TimingService().getByFilters()
            .then(result => {
                setTimings(result.timings);
            })
            .catch(error => {

            })
    }

    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }


        adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL1List(data.admins1);
            })
    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        if (adminL1) {
            let filters = {}
            filters.adminL1 = adminL1.id;
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(data => {
                    setAdminL2List(data.admins2);
                })
        } else {
            adminL2Service.getAllAdminLevel2WithFilters(null, null, null, null)
                .then(data => {
                    setAdminL2List(data.admins2);
                })
        }
    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();
        if (adminL2) {
            let filters = {};
            filters.adminL2 = adminL2.id;
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(data => {
                    setAdminL3List(data.admins3);
                })
        } else {
            adminL3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(data => {
                    setAdminL3List(data.admins3);
                })
        }
    }

    function fetchCities() {
        let cityService = new CityService();

        if (adminL3) {
            let filters = {}
            filters.level3Id = adminL3.id;
            cityService.getCities(null, null, filters, null)
                .then(data => {
                    setCities(data.cities)
                })
        } else {
            cityService.getCities(null, null, null, null)
                .then(data => {
                    setCities(data.cities)
                })
        }
    }

    function fetchPostalCodes() {
        let postalCodeService = new PostalCodeService();

        if (city) {
            let filters = {}
            filters.cityId = city.id;
            postalCodeService.getAllPostalCodes(null, null, filters, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        } else {
            postalCodeService.getAllPostalCodes(null, null, null, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        }
    }

    function handleServiceChange(event, service) {
        setService(service);
    }


    function handleTimingChange(event, timing) {
        setTiming(timing);
    }


    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setAdminL2(null);
        setAdminL1(null);
        setCity(null);
        setPostalCode(null);

    }

    function handleAdminL1Change(event, adminL1) {

        setAdminL1(adminL1);
        setAdminL2(null)
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2(adminL2);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);


    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);


    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
    }

    function handlePostalCodeChange(event, postalCode) {

        setPostalCode(postalCode);
    }


    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    useEffect(() => {
        fetchRoutingRules();
    }, [location, refreshTable, page, rowsPerPage])

    return <Container className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/routing-rules/timings`}>
                {/*<Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToAreaForm}*/}
                {/*        className={classes.button}>*/}
                {/*    {t("create_new_area")}*/}
                {/*</Button>*/}
                <TableContainer className={classes.container}>
                    {fetching ?
                        <Table>
                            <LoadingComponent/>
                        </Table>

                        :
                        <div>
                            <Card className={classes.filterContainer}>
                                <DialogTitle>{t("filters")}</DialogTitle>
                                <div style={{display: "flex"}}>
                                    <Autocomplete
                                        id="wares"
                                        className={classes.field}
                                        options={warehouses}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        value={warehouse}
                                        defaultValue={warehouse}
                                        onOpen={fetchWarehouses}
                                        onChange={handleWarehouseChange}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("warehouse")}
                                                variant="outlined"
                                                margin="dense"
                                                value={warehouse}
                                                defaultValue={warehouse}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="service"

                                        className={classes.field}
                                        options={services}
                                        getOptionLabel={option => option.name}
                                        value={service}
                                        defaultValue={service}
                                        onOpen={fetchServices}
                                        onChange={handleServiceChange}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("service")}
                                                variant="outlined"
                                                margin="dense"
                                                value={service}
                                                defaultValue={service}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="timings"
                                        className={classes.field}
                                        options={timings}
                                        getOptionLabel={option => option.contractId + " " + option.type}
                                        value={timing}
                                        defaultValue={timing}
                                        onOpen={fetchTimings}
                                        onChange={handleTimingChange}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("timing")}
                                                variant="outlined"
                                                margin="dense"
                                                value={timing}
                                                defaultValue={timing}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{display: "flex"}}>
                                    <Autocomplete
                                        id="country"
                                        className={classes.field}
                                        options={countries}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        getOptionLabel={option => option.name}
                                        value={country}
                                        defaultValue={country}
                                        onOpen={fetchCountries}
                                        onChange={handleCountryChange}
                                        onBlur={fetchAdminL1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("country")}
                                                variant="outlined"
                                                margin="dense"
                                                value={option => option.iso3166Alpha3}
                                                defaultValue={country}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="administrative1"
                                        disabled={!country}
                                        className={classes.field}
                                        options={adminL1List}
                                        getOptionLabel={option => option.name}
                                        value={adminL1}
                                        defaultValue={adminL1}
                                        onOpen={fetchAdminL1}
                                        onChange={handleAdminL1Change}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("administrative1")}
                                                variant="outlined"
                                                margin="dense"
                                                value={adminL1}
                                                defaultValue={adminL1}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="administrative2"
                                        disabled={!adminL1}
                                        className={classes.field}
                                        options={adminL2List}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={adminL2}
                                        onOpen={fetchAdminL2}
                                        onChange={handleAdminL2Change}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("administrative2")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={adminL2}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{display: "flex"}}>

                                    <Autocomplete
                                        id="administrative3"
                                        disabled={!adminL2}
                                        className={classes.field}
                                        options={adminL3List}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={adminL3}
                                        onOpen={fetchAdminL3}
                                        onChange={handleAdminL3Change}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("administrative3")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={adminL3}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="city"
                                        disabled={!adminL3}
                                        className={classes.field}
                                        options={cities}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={city}
                                        onOpen={fetchCities}
                                        onChange={handleCityChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("city")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={city}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="tags-standard"
                                        className={classes.field}
                                        options={postalCodes}
                                        disabled={!city}
                                        getOptionLabel={(option) => option.postalCode}
                                        value={postalCode}
                                        onChange={handlePostalCodeChange}
                                        onOpen={fetchPostalCodes}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin="dense"
                                                label={t("postalCode")}
                                            />
                                        )}
                                    />
                                </div>


                                <CardActions className={classes.filterContainer}>
                                    <Button
                                        className={classes.button}
                                        startIcon={<ClearAll/>}
                                        onClick={resetFilters}
                                        variant={"contained"}>
                                        {t("clear")}
                                    </Button>
                                    <Button
                                        className={classes.button}
                                        endIcon={<SearchOutlined/>}
                                        onClick={applyFilters}
                                        color="primary"
                                        variant={"contained"}>
                                        {t("search")}
                                    </Button>
                                </CardActions>
                            </Card>
                            <Paper>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableCell>{t("ID")}</TableCell>
                                        <TableCell>{t("timing")}</TableCell>
                                        <TableCell>{t("warehouse")}</TableCell>
                                        <TableCell>{t("service")}</TableCell>
                                        <TableCell>{t("country")}</TableCell>
                                        <TableCell>{t("administrative1")}</TableCell>
                                        <TableCell>{t("administrative2")}</TableCell>
                                        <TableCell>{t("administrative3")}</TableCell>
                                        <TableCell>{t("city")}</TableCell>
                                        <TableCell>{t("postalCode")}</TableCell>
                                        <TableCell/>
                                    </TableHead>

                                    <TableBody>
                                        {routingRules.map((area, index) => {
                                            return <RoutingRule area={area} deleteCallback={deleteCallback}/>
                                        })}
                                    </TableBody>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={-1}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: true,
                                        }}
                                        labelRowsPerPage={t("rows_per_page")}
                                        labelDisplayedRows={() => {
                                            return t("page")
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                                  onClose={closeNotification}>{notificationMessage}</Notification>
                                </Table>
                            </Paper>

                        </div>

                    }

                </TableContainer>


            </PrivateRoute>

        </Switch>
    </Container>

}