import React, {useEffect, useState} from "react";
import PaymentToCustomerService from "./PaymentToCustomerService";
import {Card, CircularProgress, DialogTitle, IconButton, Table, TableRow, Typography} from "@mui/material";
import PaymentToCustomer from "./PaymentToCustomer";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import {Autocomplete} from "@material-ui/lab";
import {
    Button, Checkbox, FormControlLabel, MenuItem,
    Paper,
    Select,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import {compareAsc} from "date-fns";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import theme from "../../theme/theme";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import InfoChip from "../../components/chips/InfoChip";
import Notification from "../../components/notifications/Notification";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import WarehouseStorageDetails from "../../warehouse_storages/WarehouseStorageDetails";
import NewPaymentToCustomerPage from "./NewPaymentToCustomerPage";
import {FiberNew} from "@mui/icons-material";
import StyledButton from "../../components/buttons/StyledButton";
import ConfirmPaymentToCustomerPage from "./ConfirmPaymentToCustomerPage";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));


function PaymentsToCustomerTable(props) {

    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [payments, setPayments] = useState([]);
    const [count, setCount] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [pages, setPages] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [toBePaidOnly, setToBePaidOnly] = useState(true);

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleFirstDate(event) {
        if (compareAsc(event, toDate) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setFromDate(event)
        }
    }

    function handleSecondDate(event) {
        if (compareAsc(fromDate, event) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setToDate(event);
        }
    }

    async function fetchAccounts() {

        let filters = {};

        filters.isCustomer = true;

        await new AccountService().getAccounts(filters)
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setRefresh(!refresh);
                })
            })
    }

    async function fetchContracts() {

        let filters = {};

        filters.accountId = account.id;

        await new ContractService().getContractsByFilters(filters)
            .then(result => {
                setContracts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setRefresh(!refresh);
                })
            })

    }

    async function fetchPayments() {

        setLoading(true);

        let filters = {};

        filters.isPaidToCustomer = !toBePaidOnly;

        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (null != fromDate) {
            filters.fromDate = moment(fromDate).format('yyyy-MM-DD');
        }
        if (null != toDate) {
            filters.toDate = moment(toDate).format('yyyy-MM-DD');
        }

        await new PaymentToCustomerService().getPaymentsByFilters(filters)
            .then(results => {
                setPayments(results.payments)
                setCount(results.count);
                setPages(Array.from(Array(Math.ceil(results.count / rowsPerPage)).keys()));
                setLoading(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setRefresh(!refresh);
                    setLoading(false);
                })
            })

    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function goToNewPaymentToCustomer(){
        history.push("/payments/customer/new");
    }


    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function operationSuccessfulCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

    }
    function failureCallback(message) {

        setNotificationMessage(message.status + " - " + message.message);
        setNotifySeverity('error');
        setOpenNotify(true);

    }

    function handleToBePaidOnlyChange(event) {
        setToBePaidOnly(event.target.checked);
    }

    function search() {
        setRefresh(!refresh);
    }

    useEffect(() => {
        fetchPayments();
    }, [page, rowsPerPage, refresh, location])

    return <div style={{width: '101%',
        textAlign: 'center',
        marginBottom: "15%"}}>
        <Switch>
            <PrivateRoute exact path={"/payments/customer"}>
        <div>
            <Card elevation={1} defaultExpanded={true}
                  style={{width: "60%", margin: "auto", marginBlock: "2%"}}>
                <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                    <Typography variant={"button"}>{t("filters")}</Typography>
                </DialogTitle>
                <div>
                    <Autocomplete
                        id="account"
                        options={accounts}
                        style={{
                            margin: "auto",
                            marginBottom: "2%",
                            width: "30%"
                        }}
                        getOptionLabel={option => option.companyName}
                        value={account}
                        defaultValue={account}
                        noOptionsText={t("no_options_available")}
                        onOpen={fetchAccounts}
                        onChange={handleAccountChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("account")}
                                InputLabelProps={{shrink: true}}
                                placeholder={t("any")}
                                variant="outlined"
                                margin="dense"
                                value={account}
                                defaultValue={account}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="contract"
                        disabled={!account}
                        style={{
                            margin: "auto",
                            marginBottom: "2%",
                            width: "30%"
                        }}
                        options={contracts}
                        getOptionLabel={option => option.code}
                        value={contract}
                        defaultValue={contract}
                        noOptionsText={t("no_options_available")}
                        onOpen={fetchContracts}
                        onChange={handleContractChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("contract")}
                                InputLabelProps={{shrink: true}}
                                placeholder={t("any")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>
                <div style={{display: "flex"}}>
                    <DateField
                        style={{margin: "auto", width: "20%"}}
                        label={t("from_date")}
                        value={fromDate}
                        onChange={handleFirstDate}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={true}
                    />
                    <DateField
                        style={{margin: "auto", width: "20%"}}
                        label={t("to_date")}
                        value={toDate}
                        onChange={handleSecondDate}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={true}
                    />
                </div>
                <FormControlLabel
                    style={{marginTop: "2%"}}
                    label={<Typography fontSize={12}
                                       variant={"button"}>{t("show_to_be_paid_only")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={toBePaidOnly}
                            onChange={handleToBePaidOnlyChange}
                        />
                    }
                    labelPlacement={"right"}
                />
                <div style={{margin: "auto", width: "fit-content", marginBlock: "1%"}}>
                    <IconButton onClick={search}
                                disabled={loading}
                                style={{backgroundColor: 'transparent'}}>{loading ?
                        <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                        <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                </div>

            </Card>
            <div style={{width: "100%"}}>
                <StyledButton onClick={goToNewPaymentToCustomer}
                              icon={<FiberNew/>}>{t("new_payment_to_customer")}</StyledButton>
            </div>

            <InfoChip>{t("shipment_count") + count}</InfoChip>
            <Paper>
                {loading ?
                    <LoadingComponent/>

                    :


                    <div>
                        {payments?.length > 0 ?
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t("ID")}
                                        </TableCell>
                                        <TableCell>
                                            {t("contract")}
                                        </TableCell>
                                        <TableCell>
                                            {t("datetime")}
                                        </TableCell>
                                        <TableCell>
                                            {t("amount")}
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payments?.map(payment => {
                                        return <PaymentToCustomer payment={payment} successCallback={operationSuccessfulCallback} failureCallback={failureCallback}/>
                                    })}
                                </TableBody>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={-1}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: false,
                                    }}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={() => {
                                        return t("page")
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    onPageChange={handleChangePage}/>
                            </Table>

                            :

                            null
                        }
                    </div>

                }
            </Paper>
            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                          onClose={closeNotification}>{notificationMessage}</Notification>
        </div>
            </PrivateRoute>
            <PrivateRoute exact path={"/payments/customer/new"}>
                <NewPaymentToCustomerPage/>
            </PrivateRoute>
            <PrivateRoute exact path={"/payments/customer/:id/confirm"}>
                <ConfirmPaymentToCustomerPage/>
            </PrivateRoute>
        </Switch>
    </div>


}

export default PaymentsToCustomerTable;