import React, {useEffect, useState} from "react";
import {
    Card,
    CircularProgress,
    DialogTitle,
    Paper,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import ShipmentService from "../shipments/ShipmentService";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import theme from "../theme/theme";
import {Backdrop, Button, CardActions, Fade, Modal, Table, TableCell} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import WarehouseStorageService from "./WarehouseStorageService";
import WarehouseService from "../warehouses/WarehouseService";
import {CancelOutlined, CheckCircleOutlined, FindInPage, Lens, Search} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {ArrowBackIosRounded} from "@mui/icons-material";
import WarningChip from "../components/chips/WarningChip";


const useStyles = makeStyles((theme) => ({
    searchField: {
        width: "40%",
        margin: "2%"
    },
    searchBox: {
        width: "90%",
        margin: "auto",
        marginTop: "2%",
        // display: "flex",
        border: "solid 2px",
        borderRadius: 20,
        borderColor: theme.palette.primary.main
    },
    boxTitle: {
        width: "100%",
        margin: "auto"
    },
    box: {
        marginBlock: "2%"
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "4%",
        paddingBottom: "2%"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    },
    shipmentsTable: {
        marginBlock: "2%",
        width: "80%",
        margin: "auto"
    },
    tableRow: {
        cursor: "pointer",
        transition: "all 0.2s",

        "&:hover": {

            background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'


        },
        '& > *': {
            borderBottom: 'unset',
        },
    },
    backButton: {
        marginBlock: "2%"
    }

}));

function WarehouseStorageForm(props) {

    const {t} = useTranslation();

    const history = useHistory();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [shipmentId, setShipmentId] = useState('');
    const [reference, setReference] = useState('');
    const [barcode, setBarcode] = useState('');
    const [customerReference, setCustomerReference] = useState('');
    const [customerCode, setCustomerCode] = useState('');
    const [contractCode, setContractCode] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [shipments, setShipments] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState(null);
    const [wsReason, setWsReason] = useState(null);
    const [notes, setNotes] = useState('');
    const [warehouse, setWarehouse] = useState(null);

    const [wsReasons, setWsReasons] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const [openFindShipment, setOpenFindShipment] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleBarcodeChange(event){
        setBarcode(event.target.value);
    }

    function handleEnterKey(event) {

        if (event.code === "Enter" && (shipmentId !== '' || reference !== '' || customerReference !== '' || orderNumber !== '')) {

            let filters = {};
            if ('' !== shipmentId) {
                filters.shipmentId = shipmentId;
            }
            if ('' !== reference) {
                filters.reference = reference;
            }
            if ('' !== customerReference) {
                filters.customerReference = customerReference;
            }
            if ('' !== customerCode) {
                filters.customerCode = customerCode;
            }
            if ('' !== contractCode) {
                filters.contractCode = contractCode;
            }
            if ('' !== orderNumber) {
                filters.orderNumber = orderNumber;
            }

            findShipments(filters);
        } else {
            setOpenNotify(true);
            setNotifySeverity('warning')
            setNotificationMessage(t("insert_one_of_fields") + t("shipment_id") + ", " + t("reference") + ", " + t("customer_reference"))
        }
    }

    function findShipments(filters) {

        if (shipmentId !== '' || reference !== '' || customerReference !== '' || orderNumber !== '' || barcode !== '') {

            let filters = {};
            if ('' !== shipmentId) {
                filters.shipmentId = shipmentId;
            }
            if ('' !== reference) {
                filters.reference = reference;
            }

            if ('' !== barcode) {
                filters.barcodes = [];
                filters.barcodes.push(barcode);
            }
            if ('' !== customerReference) {
                filters.customerReference = customerReference;
            }
            if ('' !== customerCode) {
                filters.customerCode = customerCode;
            }
            if ('' !== contractCode) {
                filters.contractCode = contractCode;
            }
            if ('' !== orderNumber) {
                filters.orderNumber = orderNumber;
            }

            filters.includeRelease = true;

            setLoading(true);

            let shipmentService = new ShipmentService();
            shipmentService.getAllShipment(filters)
                .then(result => {
                    setShipments(result.shipments);
                })
                .catch(error => {

                })


            setLoading(false);


            console.log(shipments)
        } else {
            setOpenNotify(true);
            setNotifySeverity('warning')
            setNotificationMessage(t("insert_one_of_fields") + t("shipment_id") + ", " + t("reference") + ", " + t("customer_reference"))
        }


    }

    function handleSubmit() {
        if (null === wsReason) {
            setOpenNotify(true);
            setNotifySeverity('warning')
            setNotificationMessage(t("no_reason_selected"))
        } else if (null === warehouse) {
            setOpenNotify(true);
            setNotifySeverity('warning')
            setNotificationMessage(t("no_warehouse_selected"))
        } else if (null === selectedShipment) {
            setOpenNotify(true);
            setNotifySeverity('warning')
            setNotificationMessage(t("no_shipment_selected"))
        } else {
            let request = {};

            request.shipment = {};

            request.shipment.id = selectedShipment.id;
            request.reasonId = wsReason.id;
            request.warehouseCode = warehouse.code;
            request.notes = notes;

            let warehouseStorageService = new WarehouseStorageService();
            warehouseStorageService.createWarehouseStorage(request)
                .then(result => {
                    setNotificationMessage(t("created_warehouse_storage") + result.id);
                    setNotifySeverity('success');
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/warehouse-storages/${result.id}/label`)
                    }, 2500)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }


    }

    async function fetchReasons() {
        let warehouseStorageService = new WarehouseStorageService();
        let reasons = await warehouseStorageService.getWarehouseStorageReasons()

        setWsReasons(reasons);
    }

    async function fetchWarehouses() {
        let warehouseService = new WarehouseService();
        let wares = await warehouseService.getWarehousesByFilters(0, 1000, null);
        setWarehouses(wares.warehouses);
    }

    useEffect(() => {
        fetchReasons();
        fetchWarehouses();
    }, [])

    return <div>
        <Button className={classes.backButton} startIcon={<ArrowBackIosRounded/>}
                onClick={() => history.goBack()}>{t("go_back")}</Button>
        <Paper style={{display: "block"}}>
            <DialogTitle>{t("new_warehouse_storage")}</DialogTitle>
            <div className={classes.boxTitle}>
                <DialogTitle>
                    <Button variant={"outlined"} onClick={() => setOpenFindShipment(true)}>{t("find_shipment")}</Button>
                </DialogTitle>
            </div>
            <Modal open={openFindShipment}
                // className={classes.modal}
                   onClose={() => setOpenFindShipment(false)}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{
                       timeout: 500,
                   }}
            >
                <Fade in={openFindShipment}>
                    <Paper variant={"outlined"} elevation={2} style={{
                        width: "80%",
                        marginInline: "auto",
                        marginTop: "20%"
                        // overflow: "hidden",
                        // border: `1px solid black`
                    }}>
                        <div className={classes.searchBox}>
                            <div style={{display: "flex"}}>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("shipment_id")}
                                        variant={"outlined"}
                                        value={shipmentId}
                                        defaultValue={shipmentId}
                                        onChange={event => setShipmentId(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("barcode")}
                                        variant={"outlined"}
                                        value={barcode}
                                        defaultValue={barcode}
                                        onChange={handleBarcodeChange}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("reference")}
                                        variant={"outlined"}
                                        value={reference}
                                        defaultValue={reference}
                                        onChange={event => setReference(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("customer_reference")}
                                        variant={"outlined"}
                                        value={customerReference}
                                        defaultValue={customerReference}
                                        onChange={event => setCustomerReference(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("customer_code")}
                                        variant={"outlined"}
                                        value={customerCode}
                                        defaultValue={customerCode}
                                        onChange={event => setCustomerCode(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("contract_code")}
                                        variant={"outlined"}
                                        value={contractCode}
                                        defaultValue={contractCode}
                                        onChange={event => setContractCode(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                                <div className={classes.searchField}>
                                    <TextField
                                        label={t("orderNumber")}
                                        variant={"outlined"}
                                        value={orderNumber}
                                        defaultValue={orderNumber}
                                        onChange={event => setOrderNumber(event.target.value.trim())}
                                        // onKeyDown={handleEnterKey}
                                    />
                                </div>
                            </div>
                            <div style={{width: "100%", display: "flex"}}>
                                <Button style={{margin: "auto", backgroundColor: "transparent"}}
                                        onClick={findShipments}>{loading ? <CircularProgress/> : <Search/>}</Button>
                            </div>
                        </div>

                        {loading ?
                            <LoadingComponent/>
                            :
                            <>
                                {shipments.length > 0 ?

                                    <Table stickyHeader className={classes.shipmentsTable}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {t("shipment_id")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("customer_code")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("delivery")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("delivery_address")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("contacts")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {shipments?.map(shipment => {
                                            return <TableRow
                                                className={classes.tableRow}
                                                onClick={() => {
                                                    setSelectedShipment(shipment);
                                                    setOpenFindShipment(false)
                                                }}
                                            >
                                                <TableCell>
                                                    {shipment.id}
                                                </TableCell>
                                                <TableCell>
                                                    {shipment.customerCode}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{shipment.deliveryAddress.referencePerson}</Typography>
                                                    <Typography>{shipment.deliveryAddress.companyName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{shipment.deliveryAddress.street + (shipment.deliveryAddress.buildingNr ? (", " + shipment.deliveryAddress.buildingNr) : '')}</Typography>
                                                    <Typography>{shipment.deliveryAddress.postalCode + " " + shipment.deliveryAddress.city + " " + shipment.deliveryAddress.administrative_level_3}</Typography>
                                                    <Typography>{shipment.deliveryAddress.country}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{shipment.deliveryAddress.email}</Typography>
                                                    <Typography>{shipment.deliveryAddress.mobile}</Typography>
                                                    <Typography>{shipment.deliveryAddress.phone}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </Table>

                                    :
                                    <WarningChip><Typography
                                        variant={"subtitle2"}>{t("no_shipments_found")}</Typography></WarningChip>
                                }
                            </>

                        }
                    </Paper>
                </Fade>

            </Modal>
            {selectedShipment ?

                <div>
                    <Table className={classes.shipmentsTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t("shipment_id")}
                                </TableCell>
                                <TableCell>
                                    {t("customer_code")}
                                </TableCell>
                                <TableCell>
                                    {t("delivery")}
                                </TableCell>
                                <TableCell>
                                    {t("delivery_address")}
                                </TableCell>
                                <TableCell>
                                    {t("contacts")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {selectedShipment.id}
                                </TableCell>
                                <TableCell>
                                    {selectedShipment.customerCode}
                                </TableCell>
                                <TableCell>
                                    <Typography>{selectedShipment.deliveryAddress.referencePerson}</Typography>
                                    <Typography>{selectedShipment.deliveryAddress.companyName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{selectedShipment.deliveryAddress.street + (selectedShipment.deliveryAddress.buildingNr ? (", " + selectedShipment.deliveryAddress.buildingNr) : '')}</Typography>
                                    <Typography>{selectedShipment.deliveryAddress.postalCode + " " + selectedShipment.deliveryAddress.city + " " + selectedShipment.deliveryAddress.administrative_level_3}</Typography>
                                    <Typography>{selectedShipment.deliveryAddress.country}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{selectedShipment.deliveryAddress.email}</Typography>
                                    <Typography>{selectedShipment.deliveryAddress.mobile}</Typography>
                                    <Typography>{selectedShipment.deliveryAddress.phone}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </div>
                :

                null

            }
            <div className={classes.box}>
                <Autocomplete
                    id="reason"
                    size="medium"
                    style={{minWidth: "30%", width: "fit-content", marginBlock: "2%", margin: "auto"}}
                    options={wsReasons}
                    getOptionLabel={reason => reason.description}
                    value={wsReason}
                    defaultValue={wsReason}
                    required
                    error={!wsReason}
                    // helperText={!wsReason ? t("required") : null}
                    onChange={(event, wsReason) => setWsReason(wsReason)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        // style={{width: "fit-content"}}
                        label={t("reason")}
                        variant="outlined"
                        margin="dense"
                        value={wsReason}
                        defaultValue={wsReason}
                        required
                        error={!wsReason}
                        // helperText={!wsReason ? t("required") : null}
                    />}
                />
                <Autocomplete
                    id="warehouse"
                    size="medium"
                    style={{minWidth: "30%", width: "fit-content", marginBlock: "2%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    required
                    // onOpen={fetchWarehouses}
                    error={!warehouse}
                    // helperText={!warehouse ? t("required") : null}
                    onChange={(event, warehouse) => setWarehouse(warehouse)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                        required
                        error={!warehouse}
                        // helperText={!warehouse ? t("required") : null}
                    />}
                />
                <TextField
                    style={{width: "60%", margin: "auto", marginBlock: "2%"}}
                    label={t("notes")}
                    variant="outlined"
                    margin="dense"
                    onChange={event => setNotes(event.target.value)}
                    value={notes}
                    multiline
                    minRows={4}
                    maxRows={4}
                    // className={classes.field}
                />
            </div>
            <div className={classes.box}>
                <CardActions className={classes.button_container}>
                    <Button
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            </div>

        </Paper>
    </div>

}

export default WarehouseStorageForm;