import {IconButton, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {formatDate} from "../utils/utils";
import PaymentCardService from "./PaymentCardService";
import Notification from "../components/notifications/Notification";
import React, {useState} from "react";
import {Backdrop, Button, Fade, Modal} from "@material-ui/core";
import {CancelOutlined, CheckCircleOutlined, DeleteForever, Edit, Restore, RestoreFromTrash} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


export default function PaymentCard(props) {

    let item = props.item;

    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notifySeverity, setNotifySeverity] = useState(null);
    const [openNotify, setOpenNotify] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const history = useHistory();

    const {t} = useTranslation();

    async function restoreCard() {

        await new PaymentCardService().restoreCard(item.id)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setOpenConfirmDialog(false);
                props.deleteCallback()
            })
            .catch(error => {
                error.response.json().then(response => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                })
            })

    }

    async function deleteCard() {

        await new PaymentCardService().deleteCard(item.id)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setOpenConfirmDialog(false);
                props.deleteCallback()
            })
            .catch(error => {
                error.response.json().then(response => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                })
            })


    }

    function goToEdit() {
        history.push(`payment-cards/${item.id}/edit`)
    }


    return <TableRow>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{item.cardNumber}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{item.name}</Typography>
            <Typography variant={"subtitle2"}>{item.surname}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{formatDate(item.releaseDate)}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{formatDate(item.expirationDate)}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{item.accountCorporateCode}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            <Typography variant={"subtitle2"}>{item.warehouseCode}</Typography>
        </TableCell>
        <TableCell style={{color: item.deleted ? "grey" : null, fontStyle: item.deleted ? "italic" : null}}>
            {item.deleted ?
                <IconButton onClick={restoreCard}><Restore/></IconButton>
                :
                <>
                    <IconButton onClick={goToEdit}><Edit/></IconButton>
                    <IconButton onClick={() => setOpenConfirmDialog(true)}><DeleteForever/></IconButton>
                </>
            }
        </TableCell>
        <Modal open={openConfirmDialog}
            // className={classes.modal}
               onClose={() => setOpenConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openConfirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        {/*<WarningOutlined color={"warning"}/>*/}
                        <Typography
                            style={{margin: "auto"}}>{t("confirm_delete")}</Typography>
                        {/*<WarningOutlined color={"warning"}/>*/}
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteCard}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>
        </Modal>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </TableRow>
}