import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import WarehouseService from "../../warehouses/WarehouseService";
import CodService from "../../shipments/services/CodService";
import {Button, Checkbox, Paper, Table, TableCell, TableHead, TablePagination} from "@material-ui/core";
import {ArrowBackRounded, CheckCircleOutline, SaveOutlined, SearchOutlined} from "@material-ui/icons";
import {
    Autocomplete,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import InfoChip from "../../components/chips/InfoChip";
import Notification from "../../components/notifications/Notification";
import CurrencyService from "../../currencies/CurrencyService";
import moment from "moment";
import AmountChip from "../../components/chips/AmountChip";
import ChequeForwardingService from "./ChequeForwardingService";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import ShipmentService from "../../shipments/ShipmentService";


function NewChequeForwarding(props) {

    const {t} = useTranslation()
    const history = useHistory();

    const [cods, setCods] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);

    const [shipmentID, setShipmentID] = useState('');
    const [generateShipment, setGenerateShipment] = useState(false);

    const [labelContent, setLabelContent] = useState(null);
    const [pdfContent, setPdfContent] = useState(null);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [labelError, setLabelError] = useState(false);


    const [loading, setLoading] = useState(false);
    const [loadingCods, setLoadingCods] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [allChecked, setAllChecked] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));
    const [dateTo, setDateTo] = useState(moment());

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [codsTotal, setCodsTotal] = useState(0.00);

    function handleShipmentIDChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentID(event.target.value);
        }
    }

    function handleGenerateShipmentChange(event) {
        setGenerateShipment(event.target.checked);
        setShipmentID('');
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        setCods([]);
    }

    function handleCurrencyChange(event, currency) {
        setCurrency(currency);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleChecked(event, key) {

        cods
            // .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].checked = event.target.checked;

        if (cods.some(accountingRow => accountingRow.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }

    function bankNameChange(event, key) {

        console.log(cods);

        cods
            // .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].cod.cheque.bankName = event.target.value;

        setRefresh(!refresh);

    }

    function chequeNumberChange(event, key) {

        cods
            // .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].cod.cheque.chequeNumber = event.target.value;

        setRefresh(!refresh);

    }

    function chequeDateChange(event, key) {

        cods
            // .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].cod.cheque.chequeDate = event;

        setRefresh(!refresh);

    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);

        // let rows = cods
        //     .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
        //     .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
        //     .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row);


        for (let i = 0; i < cods.length; i++) {
            cods[i].checked = !!event.target.checked;
        }

        setRefresh(!refresh);

    }

    function calculateCodsTotal(cods) {
        let total = 0.00;

        for (let i = 0; i < cods.length; i++) {

            if (cods[i].checked === true) {
                total += cods[i].cod.value;
            }
        }

        setCodsTotal(total);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })

    }

    async function fetchCurrencies() {


        if (currencies.length < 1) {
            await new CurrencyService().getAll()
                .then(result => {
                    setCurrencies(result);
                    if (!fetched) {
                        setCurrency(result[0]);
                    }
                })
                .catch(error => {
                    if (error.response.status !== 500) {
                        error.response.json()
                            .then(response => {
                                setNotificationMessage(response.status + " - " + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                    } else {
                        setNotificationMessage(error.response.status + " - " + error.response.statusText);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }

                })
        }


    }

    async function fetchCods() {

        setLoadingCods(true);

        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (currency) {
            filters.currency = currency.code;
        }

        if (dateFrom) {
            filters.collectedDateFrom = moment(dateFrom).format('yyyy-MM-DD');
        }
        if (dateTo) {
            filters.collectedDateTo = moment(dateTo).format('yyyy-MM-DD');
        }

        filters.hasEventWithCode = [5, 6];
        filters.isInChequeForwarding = false;

        await new CodService().getCods(filters)
            .then(result => {

                let data = [];

                for (let i = 0; i < result.length; i++) {
                    let codData = {};
                    codData.checked = true;
                    codData.cod = result[i];

                    if (codData.cod.cheque == null) {
                        codData.cod.cheque = {}
                        codData.cod.cheque.chequeDate = new Date();
                    }

                    data.push(codData);
                }

                setAllChecked(true);

                setCods(data);
                setLoadingCods(false);
                setFetched(true);
                setRefresh(!refresh);

            })
            .catch(error => {


                setLoadingCods(false);

            })


    }


    async function saveChequeForwarding() {

        let input = {};

        for (let i = 0; i < cods.length; i++) {
            let cod = cods[i];

            if(cod.checked === true && (!cod.cod.cheque?.bankName || !cod.cod.cheque?.chequeNumber || !cod.cod.cheque?.chequeDate)){
                setOpenNotify(true);
                setNotificationMessage('Tutti i campi degli assegni devono essere compilati');
                setNotifySeverity('error');
                return;
            }
        }

        if(!generateShipment && shipmentID === ""){
            setOpenNotify(true);
            setNotificationMessage('Inserire il numero di spedizione o selezionare `Genera Spedizione`');
            setNotifySeverity('error');
            return;
        }

        if (warehouse) {
            input.warehouseId = warehouse.id;
        }

        let codIds = [];

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked === true) {

                await new CodService().updateChequeData(cods[i].cod.id,
                    {
                        bankName: cods[i].cod.cheque.bankName,
                        chequeNumber: cods[i].cod.cheque.chequeNumber,
                        chequeDate: cods[i].cod.cheque.chequeDate ? moment(cods[i].cod.cheque.chequeDate).format("yyyy-MM-DD") : null
                    })

                codIds.push(cods[i].cod.id)
            }
        }

        input.codIds = codIds;

        if (generateShipment === true) {
            input.generateShipment = true;
        } else if (shipmentID !== '') {
            input.shipmentId = shipmentID;
        }

        await new ChequeForwardingService().newChequeForwarding(input)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                fetchCods();
                fetchLabel(response.shipmentId);
                fetchPdf(response.id);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchPdf(id){

        await new ChequeForwardingService().getPdf(id)
            .then(result => {
                setPdfContent(result.content);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchLabel(shipmentId) {

        setLoadingLabel(true)

        let shipmentService = new ShipmentService();
        await shipmentService.getLabel(shipmentId, "PDF")
            .then(response => {
                setLabelContent(response[0].content)
                setLoadingLabel(false)
                setLabelError(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    if (response.status === 425) {
                        setTimeout(() => {
                            fetchLabel(shipmentId);
                        }, 5000)
                    } else {
                        setLoadingLabel(false);
                        setLabelError(true);
                    }
                })
            })

    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }

    useEffect(() => {

        if (!fetched) {
            fetchCurrencies();
        }

        calculateCodsTotal(cods);

    }, [page, rowsPerPage, refresh]);


    return <Paper style={{
        width: '100%',
        textAlign: 'left',
        display: "block",
    }}>
        {loading ?

            <LoadingComponent/>

            :
            <div>
                {loadingLabel || pdfContent || labelContent?
                    <div style={{width: "90%", margin: "auto"}}>
                        {loadingLabel ?
                            <div style={{width: "100%", marginBlock: "5%", margin: "auto"}}>
                                <Typography variant={"subtitle2"} fontSize={20}
                                            style={{marginBlock: "5%"}}>{t("shipment_created_wait_label_message")}</Typography>
                                <LoadingComponent/>
                            </div>

                            :

                            (labelError ?

                                    <div>
                                        <Typography
                                            variant={"button"}>{"Si è verificato un errore nel recupero dell'etichetta"}</Typography>
                                    </div>
                                    :
                                    <div style={{width: "fit-content", margin: "auto"}}>
                                        <object style={{marginBlock: "5%"}} width={400} height={400}
                                                type={'application/pdf'}
                                                data={"data:application/pdf;base64," + labelContent}>";
                                            html += "
                                        </object>
                                        {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                                    </div>
                            )
                        }
                        <div style={{margin: "auto", width: "90%"}}>
                            <object style={{margin: "auto", marginBlock: "5%"}} width={"100%"} height={600} type={'application/pdf'} data={"data:application/pdf;base64," + pdfContent}>";
                                html += "</object>
                            {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                        </div>
                    </div>
                    :

                    <div>
                        <div style={{display: "flex"}}>
                            <Button
                                style={{margin: "2%", background: "transparent"}}
                                onClick={() => history.goBack()}
                                startIcon={<ArrowBackRounded/>}
                                variant={"filled"}
                            >
                                {t("back")}
                            </Button>
                        </div>
                        <div style={{width: "fit-content", minWidth: "30%", margin: "auto"}}>
                            <div style={{display: "flex", margin: "auto", marginBlock: "5%", width: "100%"}}>
                                <DialogTitle style={{margin: "auto"}}>{t("new_cheque_forwarding")}</DialogTitle>
                            </div>
                            <Autocomplete
                                id="warehouse-select"
                                size="small"
                                options={warehouses}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={warehouse}
                                defaultValue={warehouse}
                                noOptionsText={t("no_options")}
                                onOpen={fetchWarehouses}
                                onChange={handleWarehouseChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("warehouse")}
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        error={!warehouse}
                                        value={warehouse}
                                        defaultValue={warehouse}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="currency"
                                size="small"
                                options={currencies}
                                getOptionLabel={option => option.symbol + "  " + option.name}
                                value={currency}
                                defaultValue={currency}
                                noOptionsText={t("no_options")}
                                onOpen={fetchCurrencies}
                                onChange={handleCurrencyChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("currency")}
                                        variant="outlined"
                                        margin="dense"
                                        value={currency}
                                        required
                                        error={!currency}
                                        defaultValue={currency}
                                    />
                                )}
                            />
                        </div>
                        <div style={{width: "90%", margin: "auto", border: "1px solid", marginBlock: "2%"}}>
                            <div style={{display: "flex", margin: "auto", marginBlock: "2%", width: "70%"}}>
                                <DialogTitle style={{margin: "auto"}}>{t("cods")}</DialogTitle>
                            </div>

                            <div style={{display: "flex", margin: "auto", width: "70%"}}>
                                <DateField
                                    style={{width: "35%", margin: "auto"}}
                                    label={t("collected_date_from")}
                                    value={dateFrom}
                                    onChange={handleDateFromChange}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                    clearable={false}
                                />
                                <DateField
                                    style={{width: "35%", margin: "auto"}}
                                    label={t("collected_date_to")}
                                    value={dateTo}
                                    onChange={handleDateToChange}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                    clearable={false}
                                />

                            </div>
                            <div style={{display: "flex"}}>
                                <IconButton onClick={fetchCods}
                                            disabled={loading || !warehouse}
                                            style={{
                                                backgroundColor: 'transparent',
                                                margin: "auto",
                                                marginBlock: "3%"
                                            }}>{loading ?
                                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                                    <SearchOutlined
                                        style={{color: loading || !warehouse ? "grey" : theme.palette.primary.main}}/>}</IconButton>
                            </div>
                            <div>
                                {fetched ?
                                    <div>
                                        {loadingCods ?
                                            <LoadingComponent/>
                                            :
                                            <div>
                                                {cods.length > 0 ?
                                                    <div>
                                                        <AmountChip><Typography variant={"subtitle2"}
                                                                                fontSize={18}>{t("cods_total")}{": "}{currency ? currency.symbol + " " : null}{(codsTotal).toFixed(2)}</Typography></AmountChip>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Checkbox color="primary"
                                                                                  onChange={handleAllChecked}
                                                                                  checked={allChecked}/>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {t("ID")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {t("shipment")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {t("sender")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {t("value")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            {cods
                                                                .slice(rowsPerPage > 0 ? page * rowsPerPage : 0, rowsPerPage > 0 ? page * rowsPerPage + rowsPerPage : cods.length)
                                                                .map((cod, key) => {

                                                                    return <TableRow>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                color="primary"
                                                                                onChange={event => handleChecked(event, key)}
                                                                                checked={cod.checked}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {cod.cod.id}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography style={{
                                                                                cursor: "pointer",
                                                                                transition: "all 0.2s",
                                                                                color: "#144dff",
                                                                                '& > *': {
                                                                                    borderBottom: 'unset',
                                                                                }
                                                                            }} onClick={() => goToAboutMe(cod.cod.shipmentId)}>
                                                                                {cod.cod.shipmentId}
                                                                            </Typography>

                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {cod.cod.senderReference}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {cod.cod.currency + " " + cod.cod.value}
                                                                        </TableCell>
                                                                        <TableCell style={{display: "flex"}}>
                                                                            <TextField
                                                                                style={{
                                                                                    width: "30%",
                                                                                    margin: "auto"
                                                                                }}
                                                                                label={t("bank_name")}
                                                                                variant="outlined"
                                                                                type="text"
                                                                                margin="dense"
                                                                                size={"small"}
                                                                                required={true}
                                                                                error={!cod?.cod.cheque?.bankName}
                                                                                onChange={event => bankNameChange(event, key)}
                                                                                value={cod?.cod.cheque?.bankName}
                                                                            />
                                                                            <TextField
                                                                                style={{
                                                                                    width: "30%",
                                                                                    margin: "auto"
                                                                                }}
                                                                                label={t("cheque_number")}
                                                                                variant="outlined"
                                                                                type="text"
                                                                                margin="dense"
                                                                                size={"small"}
                                                                                required={true}
                                                                                error={!cod?.cod.cheque?.chequeNumber}
                                                                                onChange={event => chequeNumberChange(event, key)}
                                                                                value={cod?.cod.cheque?.chequeNumber}
                                                                            />
                                                                            <DateField
                                                                                style={{
                                                                                    width: "30%",
                                                                                    margin: "auto",
                                                                                    marginBottom: 3
                                                                                }}
                                                                                label={t("date")}
                                                                                variant="outlined"
                                                                                type="text"
                                                                                margin="dense"
                                                                                format={"dd/MM/yyyy"}
                                                                                clearable={true}
                                                                                disableFuture={true}
                                                                                required={true}
                                                                                error={!cod?.cod.cheque?.chequeDate}
                                                                                onChange={event => chequeDateChange(event, key)}
                                                                                value={cod?.cod.cheque?.chequeDate}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>

                                                                })}
                                                        </Table>
                                                        <TablePagination
                                                            style={{width: "80%", margin: "auto"}}
                                                            rowsPerPageOptions={[5, 10, 25, {
                                                                label: t("all"),
                                                                value: -1
                                                            }]}
                                                            labelRowsPerPage={t("rows_per_page")}
                                                            labelDisplayedRows={() => {
                                                                return (t("total_selected") + ": " + cods.filter(acc => !!acc.checked)?.length)
                                                            }}
                                                            component="div"
                                                            count={cods.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </div>
                                                    :
                                                    <InfoChip>{t("no_data")}</InfoChip>
                                                }
                                            </div>
                                        }

                                    </div>

                                    :
                                    null
                                }
                            </div>

                        </div>
                        {cods.length > 0 ?
                            <div style={{alignItems: 'center', display: "flex", width: "fit-content", margin: "auto"}}>
                                <TextField
                                    disabled={generateShipment}
                                    size={"small"}
                                    style={{width: "50%"}}
                                    label={t("shipmentId")}
                                    value={shipmentID}
                                    onChange={handleShipmentIDChange}
                                    margin={"dense"}
                                    variant={"outlined"}
                                />
                                <Typography style={{marginInline: "4%"}}>{t("or")}</Typography>
                                <FormControlLabel
                                    style={{width: "50%"}}
                                    label={<Typography fontSize={12}
                                                       variant={"button"}>{t("generate_shipment")}</Typography>}
                                    control={
                                        <Checkbox
                                            style={{color: theme.palette.primary.main}}
                                            checked={generateShipment}
                                            onChange={handleGenerateShipmentChange}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                            </div>
                            :
                            null
                        }
                        <div style={{display: "flex"}}>
                            <Button
                                disabled={!warehouse || cods.length < 1 || cods.filter(acc => !!acc.checked)?.length < 1}
                                style={{margin: "auto", marginTop: "3%", marginBottom: "10%"}}
                                endIcon={<SaveOutlined/>}
                                onClick={saveChequeForwarding}
                                color="primary"
                                variant={"contained"}>
                                {t("save")}
                            </Button>
                        </div>

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                    </div>
                }
            </div>

        }

    </Paper>


}

export default NewChequeForwarding;