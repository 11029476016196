import {TableCell, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Delete} from "@material-ui/icons";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Chip, Typography} from "@mui/material";
import theme from "../../theme/theme";


function Route(props){

    let route = props.route;

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const {t} = useTranslation();

    return <TableRow>
        <TableCell>{route.id}</TableCell>
        <TableCell>{route.name}</TableCell>
        <TableCell>{route.warehouses.map(warehouse => {
            return <Typography style={{marginBlock: "2%"}}><Chip icon={<Avatar sx={{ width: 24, height: 24, bgcolor: "white"}}>{warehouse.indexOrder}</Avatar>} label={" " + warehouse.warehouseCode + " - " + warehouse.warehouseName}/></Typography>
        })}</TableCell>
        <TableCell>
            <IconButton onClick={() => setOpenConfirmDelete(true)}><Delete/></IconButton>
        </TableCell>
        <ConfirmDialog open={openConfirmDelete}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={() => props.delete(route.id)}
                       onCancel={() => setOpenConfirmDelete(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
    </TableRow>



} export default Route;