import React, {useEffect, useState} from "react";
import {Autocomplete, Checkbox, CircularProgress, Container, DialogTitle, Paper} from "@mui/material";
import {
    Backdrop,
    Button,
    Card, CardActionArea,
    Chip, Fade,
    Grid, MenuItem, Modal, Select,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow, TextField,
    Typography, useTheme
} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import PickupService from "./PickupService";
import ShipmentService from "../shipments/ShipmentService";
import {useTranslation} from "react-i18next";
import {ArrowBackIosRounded, Create, Save, WarningOutlined} from "@mui/icons-material";
import {makeStyles} from "@material-ui/core/styles";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    ArrowBackIos,
    ArrowForwardIos,
    Cancel, ClearAll,
    FirstPage, KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    Search
} from "@material-ui/icons";
import {DataGrid} from "@material-ui/data-grid";
import theme from "../theme/theme";
import IconButton from "@material-ui/core/IconButton";
import WarningChip from "../components/chips/WarningChip";
import InfoChip from "../components/chips/InfoChip";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {add} from "lodash";
import {MinimalLoadingComponent} from "../components/loading/MinimalLoadingComponent";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    paper: {
        width: 600,
        marginBottom: "2%"
    },
    pod: {
        width: 900,
        marginBottom: "2%"
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        width: '100%',
        maxHeight: 840,
    },
    table: {
        display: "inline-block",
        width: '40%',
        margin: "auto"
    },
    button: {
        marginTop: "3%",
        marginBottom: "3%"
    },
    field: {
        margin: "auto",
        marginBottom: "2%",
        width: "60%"
    },
    comCard: {
        width: "60%",
        margin: "auto",
        marginBlock: "3%"

    },
    noteCard: {
        width: "60%",
        margin: "auto",
        marginBlock: "3%"
    },
    commColumn: {
        width: 600,
        marginBottom: "2%",
        justifyContent: "center"
    },
    noteColumn: {
        marginBottom: "2%",
        justifyContent: "center",
        paddingBottom: "4%"
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
}));

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))

function PickupDetails(props) {

    let {id} = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingShipmentsToAdd, setLoadingShipmentsToAdd] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [confirmPickupAddressDialog, setConfirmPickupAddressDialog] = useState(false);

    const [isUpdatingAddress, setUpdatingAddress] = useState(false);

    const [loadingAddress, setLoadingAddress] = useState(false);

    const [address, setAddress] = useState({});

    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    const [openShipmentModal, setOpenShipmentModal] = useState(false);
    const [fetchedShipments, setFetchedShipments] = useState([]);

    const [shipmentId, setShipmentId] = useState('');
    const [reference, setReference] = useState('');
    const [customerReference, setCustomerReference] = useState('');
    const [orderNumber, setOrderNumber] = useState('');

    const [pickupData, setPickupData] = useState({});
    const [shipments, setShipments] = useState([]);
    const [events, setEvents] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState([]);

    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [countries, setCountries] = useState([]);
    const [administrativeLevel3List, setAdministrativeLevel3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [freeCity, setFreeCity] = useState('');
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');

    const [country, setCountry] = useState({});
    const [companyName, setCompanyName] = useState('');
    const [referencePerson, setReferencePerson] = useState('');
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');
    const [postalCode, setPostalCode] = useState({});
    const [city, setCity] = useState({});
    const [administrativeLevel3, setAdministrativeLevel3] = useState({});
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    let shipmentsToAdd = [];

    function handlePageNumberChange(event) {
        setPageNumber(event.target.value);
    }

    function handlePageSizeChange(event) {
        setPageSize(+event.target.value);
        setPageNumber(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPageNumber(0)
        };

        const handleBackButtonClick = (event) => {
            setPageNumber(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPageNumber(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPageNumber(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handlePageNumberChange}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    async function fetchPickup() {

        let pickupService = new PickupService();
        let shipmentService = new ShipmentService();

        await pickupService.getPickupById(id)
            .then(data => {
                setPickupData(data);
                setEvents(data.events)
                setAddress(data.address);
                setAddressInputs(data.address);
                let filters = {};

                if (null != data.shipments) {
                    filters.idList = data.shipments;
                    shipmentService.getAllShipment(filters)
                        .then(data => {
                            setShipments(data.shipments);
                            setLoading(false);
                        })
                        .catch(error => {
                            setLoading(false)
                        })
                } else {
                    // setShipments(null)
                }

            })
            .catch(error => {
                setLoading(false);
            })
        setLoading(false);
    }

    function fetchShipments() {

        setLoadingShipmentsToAdd(true);

        let addShipmentFilters = {};

        addShipmentFilters.pageSize = pageSize;
        addShipmentFilters.pageNumber = pageNumber;

        if(shipmentId !== ''){
            addShipmentFilters.shipmentId = shipmentId;
        }
        if(reference !== ''){
            addShipmentFilters.reference = reference;
        }
        if(customerReference !== ''){
            addShipmentFilters.customerReference = customerReference;
        }
        if(orderNumber !== ''){
            addShipmentFilters.orderNumber = orderNumber;
        }

        let shipmentService = new ShipmentService();
        shipmentService.getAllShipment(addShipmentFilters)
            .then(shipments => {
                setFetchedShipments(shipments.shipments)
                setPages(Array.from(Array(Math.ceil(shipments.count / pageSize)).keys()));
                // setTimeout(() => {
                //     setLoadingShipmentsToAdd(false);
                // }, 1000)
                setLoadingShipmentsToAdd(false);
            })
            .catch(error => {
                setLoadingShipmentsToAdd(false);
            })


    }

    function clearFilters(){
        setShipmentId('')
        setReference('')
        setCustomerReference('')
        setOrderNumber('')
        setPageSize(10);
        setPageNumber(0);

        setRefresh(!refresh);
    }

    function addSelected() {

        if(shipmentsToAdd.length < 1){
            setOpenNotify(true);
            setNotificationMessage(t('no_shipment_selected'))
            setNotifySeverity('warning')
        } else {
            let pickupService = new PickupService();
            pickupService.addShipmentsToPickup(id, shipmentsToAdd)
                .then(result => {
                    setOpenNotify(true);
                    setNotificationMessage(t('successful'))
                    setNotifySeverity('success')
                    setOpenShipmentModal(false);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }


    }

    function cancelAddShipments() {
        shipmentsToAdd = [];
        setOpenShipmentModal(false);
    }

    function handleCheckboxValue(id, event) {
        if (event.target.checked) {
            shipmentsToAdd.push(id)
        } else {
            shipmentsToAdd.splice(shipmentsToAdd.indexOf(id), 1);
        }

        console.log(shipmentsToAdd.length);
    }


    function calculateTotalParcels(shipments) {
        let totalParcels = 0;
        let _shipments = shipments;
        for (let i = 0; i < _shipments.length; i++) {
            totalParcels += _shipments[i].totalParcelNumber;
        }
        return totalParcels;
    }

    function calculateTotalWeight(shipments) {
        let totalWeight = 0;
        shipments.map(shipment => {
            if (shipment.totalWeight !== null) {
                return totalWeight += shipment.totalWeight;
            } else return totalWeight;
        })

        return totalWeight;
    }

    function calculateTotalVolume(shipments) {
        let totalVolume = 0;
        shipments.map(shipment => {
            if (shipment.totalVolume !== null) {
                return totalVolume += shipment.totalVolume;
            } else return totalVolume;
        })

        return totalVolume;
    }

    function handleShipmentIdChange(event) {

        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleReferenceChange(event) {
        setReference(event.target.value);
    }

    function handleCustomerReferenceChange(event) {
        setCustomerReference(event.target.value);
    }

    function handleOrderNumberChange(event) {
        setOrderNumber(event.target.value);
    }
    async function setAddressInputs(address) {
        let country = {};
        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null)
            .then(data => {
                if (address.country.length === 2) {
                    country = data.countries.filter(country => country.iso3166Alpha2 === address.country)[0];
                } else {
                    country = data.countries.filter(country => country.iso3166Alpha3 === address.country)[0]
                }

            })

        setCountry(country);

        let city = {}
        let administrative3 = {}
        let postalCode = {}


        if (address.country === "IT" || address.country === "ITA" || address.country === "SP" || address.country === "SPA" || address.country === "DE" || address.country === "DEU") {

            if (null !== address.administrative_level_3) {
                let admin3Service = new AdministrativeLevel3Service();
                await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                    .then(data => {
                        if (address.administrative_level_3 !== null) {
                            administrative3 = data.admins3.filter(admin3 => admin3.code === address.administrative_level_3)[0]
                        } else {
                            administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                        }
                    })
            }


            if (null !== address.city) {
                let cityService = new CityService();
                await cityService.getCities(null, null, null, null)
                    .then(data => {
                        city = data.cities.filter(city => city.name.toLowerCase() === address.city.toLowerCase())[0]
                    })
            }

            if (null !== address.postalCode) {
                let postalCodeService = new PostalCodeService();
                await postalCodeService.getAllPostalCodes(null, null, null, null)
                    .then(data => {
                        postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === address.postalCode)[0];
                    })
            }


            setCity(city);
            setAdministrativeLevel3(administrative3);
            setPostalCode(postalCode);
        } else {
            setFreeAdministrativeLevel1(address.administrative_level_1);
            setFreeAdministrativeLevel2(address.administrative_level_2);
            setFreeAdministrativeLevel3(address.administrative_level_3);
            setFreeCity(address.city);
            setFreePostalCode(address.postalCode);
        }

        setCompanyName(address.companyName);
        setStreet(address.street);
        setBuildingNr(address.buildingNr);
        setPhone(address.phone);
        setMobile(address.mobile);
        setEmail(address.email);
        setReferencePerson(address.referencePerson);
        setCompanyName(address.companyName);

    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    async function fetchAdministrativeLevel3(country) {

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        await administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdministrativeLevel3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function handleCountryChange(event, country) {
        setLoadingAddress(true)
        setCountry(country);
        setAdministrativeLevel3(null);
        setCity(null);
        setPostalCode(null);
        setStreet('');
        setBuildingNr('');
        setTimeout(() => {
            setLoadingAddress(false);
        }, 1000)

    }

    function handleAdministrativeLevel3Change(event, adminl3) {
        setAdministrativeLevel3(adminl3);
        setCity(null);
        setPostalCode(null);
    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
    }

    function handlePostalCodeChange(event, postalCode) {
        setPostalCode(postalCode);
    }

    function handleStreetChange(event, street) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event, buildingNr) {
        setBuildingNr(event.target.value);
    }

    function handleCompanyNameChange(event, companyName) {
        setCompanyName(event.target.value);
    }

    function handleReferencePerson(event, referencePerson) {
        setReferencePerson(event.target.value);
    }

    function handleEmail(event) {
        setEmail(event.target.value);
    }

    function handlePhone(event) {
        setPhone(event.target.value);
    }

    function handleMobile(event) {
        setMobile(event.target.value);
    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function cancelUpdate() {
        setUpdatingAddress(false);
        setConfirmPickupAddressDialog(false);
        setAddressInputs(address).then(r => {

        });
    }

    function setUpdateAddress() {
        setLoadingUpdate(true)

        setTimeout(() => {
            setUpdatingAddress(true)
            setLoadingUpdate(false);
        }, 1500)
    }

    async function updateAddress() {

        let input = {};
        if (country != null) {
            input.country = country.iso3166Alpha2;
        }
        if (input.country === "IT") {
            if (administrativeLevel3 !== null) {
                input.administrativeLevel3 = administrativeLevel3.code;
            }
            if (city !== null) {
                input.city = city.name;
            }
            if (postalCode !== null) {
                input.postalCode = postalCode.postalCode
            }
        } else {
            input.administrativeLevel3 = freeAdministrativeLevel3;
            input.administrativeLevel2 = freeAdministrativeLevel2;
            input.administrativeLevel1 = freeAdministrativeLevel1;
            input.city = freeCity;
            input.postalCode = freePostalCode;
        }

        input.street = street;
        input.buildingNr = buildingNr;
        input.companyName = companyName;
        input.referencePerson = referencePerson;
        input.phone = phone;
        input.mobile = mobile;
        input.email = email;


        await new PickupService().updateAddress(id, input)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setRefresh(!refresh);
                setUpdatingAddress(false);
                setConfirmPickupAddressDialog(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setConfirmPickupAddressDialog(false);
                });
            })

    }

    function generateAddress() {

        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <>
                {loadingAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    /> :
                    <>
                        <Autocomplete
                            id="administrativeLevel3"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={administrativeLevel3List}
                            disabled={!country}
                            getOptionLabel={option => option.name}
                            value={administrativeLevel3}
                            defaultValue={administrativeLevel3}
                            noOptionsText={t("no_options")}
                            onChange={handleAdministrativeLevel3Change}
                            onOpen={() => fetchAdministrativeLevel3(country)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrative3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={administrativeLevel3}
                                    defaultValue={administrativeLevel3}
                                />
                            )}
                        />
                        <Autocomplete
                            id="city"
                            size="small"
                            options={cities}
                            style={{marginBottom: "2%", width: "100%"}}
                            disabled={!administrativeLevel3}
                            getOptionLabel={option => option.name}
                            value={city}
                            defaultValue={city}
                            noOptionsText={t("no_options")}
                            onChange={handleCityChange}
                            onOpen={() => fetchCities(administrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={city}
                                    defaultValue={city}
                                    helperText={!administrativeLevel3 ? t("select_admin3_first") : null}
                                />
                            )}
                        />
                        <Autocomplete
                            id="postalCode"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            value={postalCode}
                            defaultValue={postalCode}
                            noOptionsText={t("no_options")}
                            onChange={handlePostalCodeChange}
                            onOpen={() => fetchPostalCodes(city, administrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={postalCode}
                                    defaultValue={postalCode}
                                    required
                                    error={!postalCode}
                                    helperText={!postalCode ? t("required") : ""}
                                />
                            )}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "70%"}}
                            label={t("street")}
                            variant={"outlined"}
                            size={"small"}
                            value={street}
                            onChange={handleStreetChange}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "22%"}}
                            label={t("buildingNr")}
                            variant={"outlined"}
                            size={"small"}
                            value={buildingNr}
                            onChange={handleBuildingNrChange}
                        />

                    </>
                }
            </>
        } else {
            return <>
                {loadingAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    />
                    :
                    <>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative1")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeAdministrativeLevel1}
                                onChange={handleFreeAdministrativeLevel1Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative2")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeAdministrativeLevel2}
                                onChange={handleFreeAdministrativeLevel2Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative3")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeAdministrativeLevel3}
                                onChange={handleFreeAdministrativeLevel3Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("city")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeCity}
                                onChange={handleFreeCityChange}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("postalCode")}
                                variant={"outlined"}
                                size={"small"}
                                value={freePostalCode}
                                onChange={handleFreePostalCodeChange}
                                required
                                error={!freePostalCode}
                                helperText={!freePostalCode ? t("required") : null}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginRight: "2%", marginBottom: "2%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={street}
                                onChange={handleStreetChange}
                            />
                            <TextField
                                style={{marginLeft: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={buildingNr}
                                onChange={handleBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        }

    }


    function generateDeliveryAddressInputField() {

        return <div>
            <>
                <Autocomplete
                    id="country"
                    size="small"
                    // className={classes.field}
                    // style={{marginBottom: "2%", width: "100%"}}
                    options={countries}
                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                    value={country}
                    defaultValue={country}
                    onChange={handleCountryChange}
                    onSelect={fetchCountries}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        style={{marginBottom: "2%", width: "100%"}}
                        label={t("country")}
                        variant="outlined"
                        margin="dense"
                        value={country}
                        defaultValue={country}
                        required
                        error={!country}
                        helperText={!country ? t("required") : ""}
                    />}
                />
            </>
            {generateAddress()}
        </div>
    }


    useEffect(() => {
        fetchPickup();
    }, [refresh])

    useEffect(() => {
        fetchShipments();
    }, [pageSize, pageNumber, refresh])

    return <div className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>


        {loading ?
            <LoadingComponent/>

            :
            <div>
                <Button className={classes.button} startIcon={<ArrowBackIosRounded/>}
                        onClick={() => history.goBack()}>{t("go_back")}</Button>


                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item>
                            <Card style={{width: 500, minHeight: 600, marginBottom: "2%"}}>
                                <DialogTitle>{t("pickup_details")}</DialogTitle>
                                <Table>

                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("contract")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{pickupData.contractCode || "---"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("warehouse")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{pickupData.warehouseCode || null}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("created_at")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{new Date(pickupData.createdAt).toLocaleDateString() + " - " + new Date(pickupData.createdAt).toLocaleTimeString() || null}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    {!isUpdatingAddress ?
                                        <TableRow>
                                            <TableCell>
                                                <div>
                                                    <Typography>{t("pickup_address")}<IconButton
                                                        disabled={loadingUpdate}
                                                        style={{marginLeft: "2%"}}
                                                        onClick={setUpdateAddress}>
                                                        {loadingUpdate ?
                                                            <CircularProgress size={25}/> : <Create/>}
                                                    </IconButton></Typography>


                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{pickupData.address ? pickupData.address.companyName : ""}</Typography>
                                                <Typography>{pickupData.address ? pickupData.address.referencePerson : ""}</Typography>
                                                <Typography
                                                    display={"inline"}>{(pickupData.address ? pickupData.address.street : "")}</Typography>
                                                <Typography
                                                    display={"inline"}>{(pickupData.address ? ", " + pickupData.address.buildingNr : "")}</Typography>
                                                <Typography/>
                                                <Typography
                                                    display={"inline"}>{(pickupData.address ? pickupData.address.postalCode : "") + " "}</Typography>
                                                <Typography
                                                    display={"inline"}>{(pickupData.address ? (pickupData.address.city + " " + (pickupData.address.administrative_level_3 ? "(" + pickupData.address.administrative_level_3 + ")" : "")) : "")}</Typography>
                                                <div>
                                                    <Typography
                                                        display={"inline"}>{(pickupData.address && pickupData.address.administrative_level_2 ? (pickupData.address.administrative_level_2 + " ") : null)}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(pickupData.address && pickupData.address.administrative_level_1 ? pickupData.address.administrative_level_1 : null)}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(pickupData.address ? pickupData.address.country : "")}</Typography>
                                                </div>

                                                <Typography>{pickupData.address ? pickupData.address.phone : ""}</Typography>
                                                <Typography>{pickupData.address ? pickupData.address.mobile : ""}</Typography>
                                                <Typography>{pickupData.address ? pickupData.address.email : ""}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell width={"50%"}>
                                                {generateDeliveryAddressInputField()}
                                            </TableCell>
                                            <TableCell>

                                                <TextField
                                                    style={{
                                                        marginBottom: "2%",
                                                        marginTop: "2%",
                                                        width: "100%"
                                                    }}
                                                    label={t("shipment_company_name")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    value={companyName}
                                                    onChange={handleCompanyNameChange}
                                                />

                                                <TextField
                                                    style={{
                                                        marginBottom: "2%",
                                                        marginTop: "2%",
                                                        width: "100%"
                                                    }}
                                                    label={t("shipment_reference_person")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    value={referencePerson}
                                                    onChange={handleReferencePerson}
                                                />

                                                <TextField
                                                    style={{
                                                        marginBottom: "2%",
                                                        marginTop: "2%",
                                                        width: "100%"
                                                    }}
                                                    label={t("email")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    value={email}
                                                    onChange={handleEmail}
                                                />

                                                <TextField
                                                    style={{
                                                        marginBottom: "2%",
                                                        marginTop: "2%",
                                                        width: "100%"
                                                    }}
                                                    label={t("phone")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    type={"tel"}
                                                    value={phone}
                                                    onChange={handlePhone}
                                                />

                                                <TextField
                                                    style={{
                                                        marginBottom: "2%",
                                                        marginTop: "2%",
                                                        width: "100%"
                                                    }}
                                                    label={t("mobile")}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    value={mobile}
                                                    onChange={handleMobile}

                                                />
                                                <div style={{display: "flex"}}>
                                                    <Button onClick={cancelUpdate}
                                                            style={{margin: "auto"}}>{t("cancel")}</Button>
                                                    <Button onClick={()=>setConfirmPickupAddressDialog(true)}
                                                            style={{margin: "auto"}}>{t("confirm")}</Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("pickup_date")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{new Date(pickupData.date).toLocaleDateString() || ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("pickup_time_slot")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{pickupData.fromTime ? pickupData.fromTime : ""} {pickupData.toTime ? " - " + pickupData.toTime : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("notes")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{pickupData.notes ? pickupData.notes : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>

                            </Card>
                        </Grid>
                        <Grid item>
                            <Card style={{width: 500, minHeight: 600, marginBottom: "2%"}}>
                                <DialogTitle>{t("shipments")}</DialogTitle>
                                {shipments ?
                                    <Table stickyHeader>

                                        <TableHead>
                                            <TableCell>{t("ID")}</TableCell>
                                            <TableCell>{t("destination")}</TableCell>
                                            <TableCell>{t("parcel_number")}</TableCell>
                                            <TableCell>{t("total_weight")}</TableCell>
                                            <TableCell>{t("total_volume")}</TableCell>
                                        </TableHead>


                                        <TableBody>
                                            {shipments.map((shipment, index) => {
                                                return <TableRow>
                                                    <TableCell>
                                                        {shipment.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipment.deliveryAddress.companyName || null}</Typography>
                                                        <Typography>{shipment.deliveryAddress.referencePerson || null}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(shipment.deliveryAddress.street || null)}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(shipment.deliveryAddress.buildingNr ? ", " + shipment.deliveryAddress.buildingNr : null)}</Typography>
                                                        <Typography/>
                                                        <Typography
                                                            display={"inline"}>{(shipment.deliveryAddress.postalCode || null) + " "}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(shipment.deliveryAddress.city ? shipment.deliveryAddress.city : null) + " " + (shipment.deliveryAddress.administrative_level_3 ? "(" + shipment.deliveryAddress.administrative_level_3 + ")" : "")}</Typography>
                                                        <div>
                                                            <Typography
                                                                display={"inline"}>{(shipment.deliveryAddress.administrative_level_2 ? shipment.deliveryAddress.administrative_level_2 + " " : null)}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(shipment.deliveryAddress.administrative_level_1 ? shipment.deliveryAddress.administrative_level_1 + " " : null)}</Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {shipment.totalParcelNumber ? shipment.totalParcelNumber : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        {shipment.totalWeight ? shipment.totalWeight + " kg" : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        {shipment.totalVolume ? shipment.totalVolume + " \u33A5" : ""}
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                            <TableRow>
                                                <TableCell>
                                                    {t("total")}
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                                <TableCell>
                                                    {calculateTotalParcels(shipments)}
                                                </TableCell>
                                                <TableCell>
                                                    {calculateTotalWeight(shipments) + " kg"}
                                                </TableCell>
                                                <TableCell>
                                                    {calculateTotalVolume(shipments) + " \u33A5"}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>


                                    </Table>
                                    :

                                    <InfoChip>{t("no_shipments")}</InfoChip>
                                    // <div style={{width: "50%", display: "flex", margin: "auto", paddingBottom: "10%"}}>
                                    //     {/*<Typography style={{margin: "auto"}}><WarningOutlined color={"warning"}/>{t("no_events")}</Typography>*/}
                                    //     <Chip style={{margin: "auto", paddingInline: "3%"}}
                                    //           icon={<WarningOutlined color={"warning"}/>} label=/>
                                    // </div>
                                }
                                <div style={{width: "100%", display: "flex", margin: "auto", paddingBottom: "5%"}}>
                                    <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                                            style={{margin: "auto", marginTop: "5%"}}
                                            onClick={() => setOpenShipmentModal(true)}>
                                        {t("add_shipment")}
                                    </Button>
                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={openShipmentModal}
                                        onClose={() => setOpenShipmentModal(false)}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}
                                    >
                                        {/*aaa*/}
                                        <Fade in={openShipmentModal}>
                                            <Paper variant={"outlined"} elevation={2} style={{
                                                width: "50%",
                                                marginInline: "auto",
                                                marginTop: "5%",
                                                height: "80%",
                                                overflow: "scroll",
                                                // border: `1px solid black`
                                            }}>
                                                <IconButton onClick={cancelAddShipments}><Cancel/></IconButton>
                                                <Card elevation={0}
                                                      style={{width: "70%", margin: "auto", marginBottom: "2%"}}>
                                                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                                                        <Typography variant={"button"}>{t("filters")}</Typography>
                                                    </DialogTitle>
                                                    <div style={{alignItems: 'center', display: "flex"}}>
                                                        <TextField
                                                            className={classes.filter}
                                                            label={t("shipmentId")}
                                                            value={shipmentId}
                                                            onChange={handleShipmentIdChange}
                                                            margin={"dense"}
                                                            variant={"outlined"}
                                                        />
                                                    </div>
                                                    <div style={{alignItems: 'center', display: "flex"}}>
                                                        <TextField
                                                            className={classes.filter}
                                                            label={t("reference")}
                                                            value={reference}
                                                            onChange={handleReferenceChange}
                                                            margin={"dense"}
                                                            variant={"outlined"}
                                                        />
                                                        <TextField
                                                            className={classes.filter}
                                                            label={t("customerReference")}
                                                            value={customerReference}
                                                            onChange={handleCustomerReferenceChange}
                                                            margin={"dense"}
                                                            variant={"outlined"}
                                                        />
                                                        <TextField
                                                            className={classes.filter}
                                                            label={t("orderNumber")}
                                                            value={orderNumber}
                                                            onChange={handleOrderNumberChange}
                                                            margin={"dense"}
                                                            variant={"outlined"}
                                                        />
                                                    </div>
                                                </Card>


                                                <div style={{width: "100%", display: "flex", margin: "auto"}}>
                                                    <Button startIcon={<ClearAll/>} variant="contained" color="default"
                                                            style={{margin: "auto", marginTop: "5%"}}
                                                            onClick={clearFilters}>
                                                        {t("clear")}
                                                    </Button>
                                                    <Button startIcon={<Search/>} variant="contained" color="primary"
                                                            style={{margin: "auto", marginTop: "5%"}}
                                                            onClick={fetchShipments}>
                                                        {t("find")}
                                                    </Button>
                                                </div>
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    margin: "auto",
                                                    paddingBottom: "5%"
                                                }}>
                                                    <Button disabled={fetchedShipments.length < 1} startIcon={<Save/>}
                                                            variant="contained" color={"primary"}
                                                            style={{margin: "auto", marginTop: "5%"}}
                                                            onClick={addSelected}>
                                                        {t("add_selected")}
                                                    </Button>
                                                    {/*<Button startIcon={<Cancel/>} variant="contained"*/}
                                                    {/*        style={{margin: "auto", marginTop: "5%"}}*/}
                                                    {/*        onClick={cancelAddShipments}>*/}
                                                    {/*    {t("cancel")}*/}
                                                    {/*</Button>*/}
                                                </div>
                                                {loadingShipmentsToAdd ?


                                                    <LoadingComponent/>

                                                    :
                                                    <>

                                                        {fetchedShipments.length > 0 ?
                                                            <Table>
                                                                <TableHead>
                                                                    <TableCell/>
                                                                    <TableCell>{t("ID")}</TableCell>
                                                                    <TableCell>{t("reference")}</TableCell>
                                                                    <TableCell>{t("customer_reference")}</TableCell>
                                                                    <TableCell>{t("destination")}</TableCell>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {fetchedShipments.map((shipment, index) => {
                                                                        return <TableRow>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    color={"primary"}
                                                                                    onChange={(event) => handleCheckboxValue(shipment.id, event)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {shipment.id}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {shipment.reference}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {shipment.customerReference}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography>{shipment.deliveryAddress.companyName || null}</Typography>
                                                                                <Typography>{shipment.deliveryAddress.referencePerson || null}</Typography>
                                                                                <Typography
                                                                                    display={"inline"}>{(shipment.deliveryAddress.street || null)}</Typography>
                                                                                <Typography
                                                                                    display={"inline"}>{(shipment.deliveryAddress.buildingNr ? ", " + shipment.deliveryAddress.buildingNr : null)}</Typography>
                                                                                <Typography/>
                                                                                <Typography
                                                                                    display={"inline"}>{(shipment.deliveryAddress.postalCode || null) + " "}</Typography>
                                                                                <Typography
                                                                                    display={"inline"}>{(shipment.deliveryAddress.city ? shipment.deliveryAddress.city : null) + " " + (shipment.deliveryAddress.administrative_level_3 ? "(" + shipment.deliveryAddress.administrative_level_3 + ")" : "")}</Typography>
                                                                                <div>
                                                                                    <Typography
                                                                                        display={"inline"}>{(shipment.deliveryAddress.administrative_level_2 ? shipment.deliveryAddress.administrative_level_2 + " " : null)}</Typography>
                                                                                    <Typography
                                                                                        display={"inline"}>{(shipment.deliveryAddress.administrative_level_1 ? shipment.deliveryAddress.administrative_level_1 + " " : null)}</Typography>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    })}
                                                                </TableBody>
                                                                <TablePagination
                                                                    rowsPerPageOptions={[10, 25, 100]}
                                                                    count={-1}
                                                                    rowsPerPage={pageSize}
                                                                    page={pageNumber}
                                                                    // SelectProps={{
                                                                    //     inputProps: {'aria-label': 'rows per page'},
                                                                    //     variant: "outlined"
                                                                    //
                                                                    // }}
                                                                    SelectProps={{
                                                                        inputProps: {'aria-label': 'rows per page'},
                                                                        variant: "outlined",
                                                                        anchorOrigin: {
                                                                            vertical: "top",
                                                                            horizontal: "left"
                                                                        },
                                                                        transformOrigin: {
                                                                            vertical: "top",
                                                                            horizontal: "left"
                                                                        },
                                                                        getContentAnchorEl: null,
                                                                        classes: {paper: classes.menuPaper}
                                                                    }}
                                                                    labelRowsPerPage={t("rows_per_page")}
                                                                    labelDisplayedRows={() => {
                                                                        return t("page")
                                                                    }}
                                                                    onChangePage={handlePageNumberChange}
                                                                    onChangeRowsPerPage={handlePageSizeChange}
                                                                    ActionsComponent={TablePaginationActions}
                                                                />

                                                            </Table>
                                                            :
                                                            <div/>
                                                        }
                                                    </>
                                                }
                                            </Paper>
                                        </Fade>
                                    </Modal>
                                </div>

                            </Card>
                        </Grid>
                        <Grid item>
                            <Card style={{width: 500, marginBottom: "2%"}}>
                                <DialogTitle>{t("pickup_events")}</DialogTitle>

                                {events ?
                                    <Table stickyHeader>

                                        <TableHead>
                                            <TableCell>{t("date")}</TableCell>
                                            <TableCell>{t("time")}</TableCell>
                                            <TableCell>{t("event")}</TableCell>
                                            <TableCell>{t("warehouse")}</TableCell>
                                            <TableCell>{t("notes")}</TableCell>
                                        </TableHead>


                                        <TableBody>
                                            {events.map((event, index) => {
                                                return <TableRow>
                                                    <TableCell>
                                                        {new Date(event.dateTime).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(event.dateTime).toLocaleTimeString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {event.pickupEventCode ? event.pickupEventCode.id + " - " + event.pickupEventCode.name : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        {event.warehouseCode ? event.warehouseCode : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        {event.notes}
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>


                                    </Table>
                                    :

                                    <InfoChip>{t("no_events")}</InfoChip>
                                    // <div style={{paddingBottom: "10%"}}>
                                    // <div style={{width: "50%", display: "flex", margin: "auto", paddingBottom: "10%"}}>
                                    //     {/*<Typography style={{margin: "auto"}}><WarningOutlined color={"warning"}/>{t("no_events")}</Typography>*/}
                                    //     <Chip style={{margin: "auto", paddingInline: "3%"}}
                                    //           icon={<WarningOutlined color={"warning"}/>} label={t("no_events")}/>
                                    // </div>
                                    // </div>

                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmDialog open={confirmPickupAddressDialog}
                               confirmText={t("confirm")}
                               cancelText={t("cancel")}
                               title={<WarningOutlined color={"warning"}/>}
                               onConfirm={updateAddress}
                               onCancel={cancelUpdate}>
                   {t("update_pickup_address_warning_message")}
                </ConfirmDialog>
            </div>
        }
    </div>


}

export default PickupDetails;
