import React, {useEffect, useState} from "react";
import moment from "moment";
import WarehouseService from "../../warehouses/WarehouseService";
import DepartureService from "./DepartureService";
import {
    Checkbox,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Paper,
    TableRow,
    TextField
} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import {Table, TableCell, TableHead, Typography} from "@material-ui/core";
import CheckInDeparturesRouteComponent from "./CheckInDeparturesRouteComponent";
import Notification from "../../components/notifications/Notification";
import RouteService from "../routes/RouteService";


export default function CheckInDeparturesPage() {


    const [warehouses, setWarehouses] = useState([]);
    const [departureWarehouse, setDepartureWarehouse] = useState(null);

    const [checkInDateFrom, setCheckInDateFrom] = useState(moment());
    const [checkInDateTo, setCheckInDateTo] = useState(moment());

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [excludeLoadingLists, setExcludeLoadingLists] = useState(true);

    const [routes, setRoutes] = useState([]);

    const [filterRoutes, setFilterRoutes] = useState([]);
    const [route, setRoute] = useState(null);

    const [loading, setLoading] = useState(false);

    const {t} = useTranslation();

    const [tripDate, setTripDate] = useState(moment());


    function handleRouteChange(event, route) {
        setRoute(route);
    }

    function handleWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
    }

    function handleCheckInDateFromChange(event) {
        setCheckInDateFrom(event)
    }

    function handleCheckInDateToChange(event) {
        setCheckInDateTo(event)
    }

    function handleTripDateChange(event) {
        setTripDate(event);
    }

    async function fetchRoutes() {

        let filters = {};

        if (departureWarehouse) {
            filters.departureWarehouseCode = departureWarehouse.code;
        }

        await new RouteService().getRoutes(filters)
            .then(result => {
                setFilterRoutes(result.routes)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
                if (result.warehouses?.length === 1) {
                    setDepartureWarehouse(result.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchData() {

        if (!departureWarehouse || !checkInDateFrom || !checkInDateTo) {
            setNotificationMessage(t("select_required_fields"));
            setNotifySeverity('error');
            setOpenNotify(true);
            return;
        }

        setLoading(true);

        let filters = {
            departureWarehouseCode: departureWarehouse.code,
            checkInDateFrom: moment(checkInDateFrom).format("yyyy-MM-DD"),
            checkInDateTo: moment(checkInDateTo).format("yyyy-MM-DD"),
            excludeLoadingLists: excludeLoadingLists
        }

        if (route) {
            filters.routeId = route.id
        }

        await new DepartureService().getParcelsForDeparture(filters)
            .then(result => {
                setRoutes(result.routes);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })

    }

    useEffect(() => {
        fetchWarehouses();
    }, [])


    return <div>
        <Paper>
            <div style={{display: "flex"}}>
                <DialogTitle style={{margin: "auto"}}>{t("check_in_departures")}</DialogTitle>
            </div>
            <div style={{display: "flex"}}>
                <Autocomplete
                    id="warehouse"
                    options={warehouses}
                    size={"small"}
                    style={{width: "20%", margin: "auto"}}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={departureWarehouse}
                    defaultValue={departureWarehouse}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchWarehouses}
                    onChange={handleWarehouseChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("departure_warehouse")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            required
                            error={!departureWarehouse}
                            value={departureWarehouse}
                            defaultValue={departureWarehouse}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Autocomplete
                    id="routes"
                    disabled={!departureWarehouse}
                    options={filterRoutes}
                    size={"small"}
                    style={{width: "20%", margin: "auto"}}
                    getOptionLabel={option => option.name}
                    value={route}
                    defaultValue={route}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchRoutes}
                    onChange={handleRouteChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("route")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={route}
                            defaultValue={route}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex"}}>
                <DateField
                    style={{margin: "auto", width: "15%", marginBlock: "2%"}}
                    label={t("check_in_from_date")}
                    value={checkInDateFrom}
                    onChange={handleCheckInDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
                <DateField
                    style={{margin: "auto", width: "15%", marginBlock: "2%"}}
                    label={t("check_in_to_date")}
                    value={checkInDateTo}
                    onChange={handleCheckInDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
            </div>
            <div style={{display: "flex"}}>
                <FormControlLabel
                    style={{margin: "auto", marginBottom: "3%"}}
                    label={<Typography fontSize={12} variant={"button"}>{t("exclude_loading_lists")}</Typography>}
                    control={<Checkbox style={{color: theme.palette.primary.main}} checked={excludeLoadingLists}
                                       onChange={(event) => setExcludeLoadingLists(event.target.checked)}/>}
                    labelPlacement={"start"}
                />
            </div>
            <div style={{display: "flex"}}>
                <IconButton onClick={fetchData}
                            disabled={loading}
                            style={{backgroundColor: 'transparent', margin: "auto", marginBottom: "2%"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Paper>

        <Paper style={{marginBlock: "2%"}}>
            <Table>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("route")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("destination_warehouse")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("parcels")}</Typography>
                    </TableCell>
                    <TableCell style={{display: "flex"}}>
                        <DateField
                            style={{margin: "auto", width: "15%"}}
                            label={t("trip_date")}
                            value={tripDate}
                            onChange={handleTripDateChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                            clearable={false}
                        />
                    </TableCell>
                </TableRow>

                {routes.map(route => {
                    return <CheckInDeparturesRouteComponent warehouseCode={departureWarehouse.code} route={route} tripDate={tripDate}/>
                })}
            </Table>
        </Paper>
        <Notification open={openNotify} severity={notifySeverity} duration={15000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>


}