import React, {useEffect, useState} from "react";
import {Backdrop, Button, Dialog, Fade, Modal, TableRow} from "@material-ui/core";
import {IconButton, Paper, TableCell, Typography} from "@mui/material";
import {CancelOutlined, CheckCircleOutlined, Delete, DeleteForever, Edit} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {WarningOutlined} from "@mui/icons-material";
import DriverService from "./DriverService";
import Notification from "../components/notifications/Notification";


function Driver(props) {

    const history = useHistory();

    let driver = props.driver;

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const {t} = useTranslation();

    function goToEditDriver() {
        history.push(`drivers/${driver.id}/edit`)
    }

    function openDialog() {
        setOpenConfirmDialog(true);
    }

    function deleteDriver() {
        setOpenConfirmDialog(false);

        const driverService = new DriverService();
        driverService.deleteDriver(driver.id)
            .then(result => {
                let response = {};
                response.message = t("successful");
                props.deleteCallback('success', response)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.deleteCallback('error', response)
                });
            })
    }

    useEffect(() => {

    })

    return <TableRow>
        <TableCell>
            {driver.id}
        </TableCell>
        <TableCell>
            {driver.warehouseCode}
        </TableCell>
        <TableCell>
            {driver.accountCorporateCode}
        </TableCell>
        <TableCell>
            {driver.contractCode}
        </TableCell>
        <TableCell>
            {driver.name + " " + driver.surname}
        </TableCell>
        <TableCell>
            {driver.mobileNumber}
        </TableCell>
        <TableCell>
            <IconButton onClick={goToEditDriver}><Edit/></IconButton>
            <IconButton onClick={openDialog}><DeleteForever/></IconButton>
        </TableCell>
        <Modal open={openConfirmDialog}
            // className={classes.modal}
               onClose={() => setOpenConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openConfirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        {/*<WarningOutlined color={"warning"}/>*/}
                        <Typography
                            style={{margin: "auto"}}>{t("confirm_driver_delete") + " " + driver.name + " " + driver.surname + "?"}</Typography>
                        {/*<WarningOutlined color={"warning"}/>*/}
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteDriver}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
    </TableRow>


}

export default Driver;