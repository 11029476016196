import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BankTransactionService from "./BankTransactionService";
import {
    Autocomplete,
    Checkbox, DialogTitle,
    FormControlLabel,
    FormGroup,
    Paper,
    TableBody,
    TextField,
    Typography
} from "@mui/material";
import {Button, Card, Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import BankTransaction from "./BankTransaction";
import WarehouseService from "../warehouses/WarehouseService";
import DateField from "../components/fields/DateField";
import {compareAsc} from "date-fns";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import StyledButton from "../components/buttons/StyledButton";
import {FiberNew} from "@mui/icons-material";
import {AddCircleOutlined} from "@material-ui/icons";
import {Switch, useHistory, useLocation} from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {PrivateRoute} from "../routes/PrivateRoute";
import BankTransactionForm from "./BankTransactionForm";
import Notification from "../components/notifications/Notification";
import theme from "../theme/theme";
import moment from "moment";


function BankTransactionTable(props) {


    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [refresh, setRefresh] = useState(false);

    const [transactions, setTransactions] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [paymentCardNumber, setPaymentCardNumber] = useState('');

    const [needBankReconciliation, setNeedBankReconciliation] = useState(true);

    const [loading, setLoading] = useState(false);

    const [paymentSlipId, setPaymentSlipId] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handlePaymentSlipIdChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setPaymentSlipId(event.target.value);
        }
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handlePaymentCardNumberChange(event){
        setPaymentCardNumber(event.target.value);
    }

    function handleDateFrom(event) {
        if (dateTo !== null && compareAsc(event, dateTo) === 1) {
            setDateFrom(event);
            setDateTo(event);
        } else {
            setDateFrom(event)
        }
    }

    function handleDateTo(event) {
        if (dateFrom !== null && compareAsc(dateFrom, event) === 1) {
            setDateFrom(event);
            setDateTo(event);
        } else {
            setDateTo(event);
        }
    }

    async function fetchWarehouses(showAll) {

        let filters = {};

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(response => {
                setWarehouses(response.warehouses)
            })
            .catch(error => {

            })
    }


    async function fetchTransactions() {

        setLoading(true);

        let filters = {};

        if (warehouse) {
            filters.warehouseCode = warehouse.code;
        }

        if (paymentSlipId && paymentSlipId !== '') {
            filters.paymentSlipId = paymentSlipId;
        }

        if (paymentCardNumber && paymentCardNumber !== '') {
            filters.paymentCardNumber = paymentCardNumber;
        }

        if (dateFrom) {
            filters.dateFrom = moment(dateFrom).format("yyyy-MM-DD");
        }

        if (dateTo) {
            filters.dateTo = moment(dateTo).format("yyyy-MM-DD");
        }

        if (needBankReconciliation) {
            filters.needBankReconciliation = true;
        }

        await new BankTransactionService().getBankTransactionsByFilters(filters)
            .then(result => {
                setTransactions(result.transactions);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })


    }

    function deleteCallback() {
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));
        setOpenNotify(true);
        setRefresh(!refresh);
    }


    function applyFilters() {
        setRefresh(!refresh)
    }

    function clearFilters() {

        setWarehouse(null);
        setDateFrom(null);
        setDateTo(null);
        setPaymentSlipId('');
        setPaymentCardNumber('');

        setRefresh(!refresh);

    }

    useEffect(() => {
        fetchTransactions()
    }, [refresh, location])


    return <div style={{
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
    }}>

        <Switch>
            <PrivateRoute exact path={"/bank-transactions"}>
                <DialogTitle style={{margin: "auto"}}>{t("bank_transactions")}</DialogTitle>
                <Card style={{
                    width: "90%",
                    margin: "auto",
                    marginTop: "3%"
                }}>
                    <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("filters")}</DialogTitle>
                    <Autocomplete
                        id="warehouse-select"
                        size="small"
                        options={warehouses}
                        style={{margin: "auto", width: "25%"}}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        noOptionsText={t("no_options")}
                        onOpen={fetchWarehouses}
                        onChange={handleWarehouseChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("warehouse")}
                                variant="outlined"
                                margin="dense"
                                value={warehouse}
                                defaultValue={warehouse}
                            />
                        )}
                    />
                    <div style={{display: "flex"}}>
                        <DateField
                            style={{margin: "auto", width: "20%"}}
                            label={t("date_from")}
                            value={dateFrom}
                            onChange={handleDateFrom}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                            clearable={true}
                        />
                        <DateField
                            style={{margin: "auto", width: "20%"}}
                            label={t("date_to")}
                            value={dateTo}
                            onChange={handleDateTo}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                            clearable={true}
                        />
                    </div>

                    <TextField
                        style={{display: "flex", width: "25%", margin: "auto", marginBlock: "1%"}}
                        label={t("payment_slip_id")}
                        size={"small"}
                        variant="outlined"
                        margin="dense"
                        value={paymentSlipId}
                        defaultValue={paymentSlipId}
                        onChange={handlePaymentSlipIdChange}
                    />
                    <TextField
                        style={{display: "flex", width: "25%", margin: "auto", marginBlock: "2%"}}
                        label={t("card_number")}
                        size={"small"}
                        variant="outlined"
                        margin="dense"
                        value={paymentCardNumber}
                        defaultValue={paymentCardNumber}
                        onChange={handlePaymentCardNumberChange}
                    />
                    <FormControlLabel
                        style={{marginTop: "5%"}}
                        label={<Typography fontSize={12}
                                           variant={"button"}>{t("show_only_transactions_that_need_reconciliation")}</Typography>}
                        control={
                            <Checkbox
                                style={{color: theme.palette.primary.main}}
                                checked={needBankReconciliation}
                                onChange={event => setNeedBankReconciliation(event.target.checked)}
                            />
                        }
                        labelPlacement={"right"}
                    />

                    <div style={{textAlign: 'center'}}>
                        <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
                    </div>
                </Card>
                <div style={{display: "flex"}}>
                    <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                            onClick={() => history.push("/bank-transactions/new")}
                            style={{
                                color: "primary",
                                margin: "auto",
                                marginTop: "3%",
                                marginBottom: "3%"
                            }}
                    >
                        {t("new_transaction")}
                    </Button>
                </div>

                <Paper>

                    {loading ?
                        <LoadingComponent/>
                        :
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"subtitle2"}>{t("transaction_number")}</Typography>
                                            <Typography variant={"subtitle2"}>{t("card_number")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {t("date")}
                                        </TableCell>
                                        <TableCell>
                                            {t("amount")}
                                        </TableCell>
                                        <TableCell>
                                            {t("document")}
                                        </TableCell>
                                        <TableCell>
                                            {t("document_amount")}
                                        </TableCell>
                                        <TableCell>
                                            {t("document_warehouse")}
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions?.map(transaction => {
                                        return <BankTransaction bankTransaction={transaction}
                                                                deleteCallback={deleteCallback}/>
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    }

                </Paper>
                <Notification open={openNotify} severity={notifySeverity} duration={3000}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            </PrivateRoute>
            <PrivateRoute exact path={"/bank-transactions/new"}>
                <BankTransactionForm/>
            </PrivateRoute>
            <PrivateRoute exact path={"/bank-transactions/:id/edit"}>
                <BankTransactionForm/>
            </PrivateRoute>
        </Switch>


    </div>


}

export default BankTransactionTable;