import {
    Autocomplete,
    CircularProgress,
    DialogTitle,
    Divider,
    IconButton,
    Paper, TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import moment from "moment";
import WarehouseService from "../../warehouses/WarehouseService";
import BankAccountService from "../../bank_accounts/BankAccountService";
import {useTranslation} from "react-i18next";
import CurrencyService from "../../currencies/CurrencyService";
import DateField from "../../components/fields/DateField";
import Notification from "../../components/notifications/Notification";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import theme from "../../theme/theme";
import {ArrowBackRounded, CheckCircleOutlined, SaveOutlined, SearchOutlined} from "@material-ui/icons";
import CodService from "../../shipments/services/CodService";
import {Button, Checkbox, Table, TableCell, TableHead, TablePagination} from "@material-ui/core";
import InfoChip from "../../components/chips/InfoChip";
import PaymentSlipsService from "./PaymentSlipsService";
import CompanyService from "../../companies/CompanyService";
import AccountService from "../../accounts/AccountService";
import {useHistory} from "react-router-dom";
import {LoadingComponent} from "../../components/loading/LoadingComponent";


function PaymentSlipForm(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [paymentDate, setPaymentDate] = useState(moment());
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [supplier, setSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [refresh, setRefresh] = useState(false);

    const [notes, setNotes] = useState('');

    const [cods, setCods] = useState([]);

    const [codsTotal, setCodsTotal] = useState(0.00);
    const [previousRounding, setPreviousRounding] = useState(0.00);
    const [expectedPayment, setExpectedPayment] = useState(0.00);
    const [totalPaid, setTotalPaid] = useState(null);
    const [rounding, setRounding] = useState(0.00);
    const [totalRounding, setTotalRounding] = useState(0.00);

    const [pdfContent, setPdfContent] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [allChecked, setAllChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);

    async function getCods() {

        setLoading(true);

        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (supplier) {
            filters.supplierId = supplier.id;
        }

        filters.isPaidToCompany = false;

        filters.hasEventWithCode = [2, 3, 4];

        if (dateFrom) {
            filters.verifiedDateFrom = moment(dateFrom).format('yyyy-MM-DD');
        }
        if (dateTo) {
            filters.verifiedDateTo = moment(dateTo).format('yyyy-MM-DD');
        }

        let paymentFilters = {};

        if (warehouse) {
            paymentFilters.warehouseId = warehouse.id;
        }
        if (company) {
            paymentFilters.companyId = company.id;
        }

        await new PaymentSlipsService().getPaymentSlipHeads(paymentFilters)
            .then(result => {
                let prevRound = result.paymentSlips[result.paymentSlips.length - 1].rounding;

                setPreviousRounding(prevRound);
            })
            .catch(error => {

            })

        await new CodService().getCods(filters)
            .then(result => {

                let data = [];

                for (let i = 0; i < result.length; i++) {
                    let codData = {};
                    codData.checked = true;
                    codData.cod = result[i];

                    data.push(codData);
                }

                setAllChecked(true);

                setCods(data);
                setLoading(false);
                setFetched(true);
                setRefresh(!refresh);
            })
            .catch(error => {
                setLoading(false);
                setFetched(true);
                setRefresh(!refresh);
            })
    }

    async function createPaymentSlip() {

        let input = {};

        input.warehouseId = warehouse.id;
        input.currencyId = currency.id;
        input.accountId = supplier.id;
        input.bankAccountId = bankAccount.id;
        input.companyId = company.id;
        input.dateTime = moment(paymentDate).format('YYYY-MM-DDTHH:mm:ssZ');
        input.totalPaid = totalPaid;
        input.rounding = previousRounding + (codsTotal - totalPaid);

        input.cods = [];

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked) {
                input.cods.push(cods[i].cod.id);
            }
        }

        await new PaymentSlipsService().newPayment(input)
            .then(async result => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);

                // setTimeout(() => {
                //     history.push("/payments");
                // }, 1500)

                await new PaymentSlipsService().getPdf(result.id)
                    .then(result1 => {
                        setPdfContent(result1.content);
                    })

            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }
                )
            })


    }


    function handleNotesChange(event) {
        setNotes(event.target.value);
    }

    function handlePaymentDateChange(event) {
        setPaymentDate(event);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        setSuppliers([]);
        setSupplier(null);
        setCods([])
        setFetched(false);
    }

    function handleSupplierChange(event, supplier) {
        setSupplier(supplier);
        setCods([])
        setFetched(false);
    }

    function handleCompanyChange(event, company) {
        setCompany(company);
    }

    function handleBankAccountChange(event, account) {
        setBankAccount(account);
    }

    function handleCurrencyChange(event, currency) {
        setCurrency(currency);
    }


    function handleTotalPaidChange(event, value) {
        setTotalPaid(value);
    }

    async function init() {

        setLoadingPage(true);

        await new WarehouseService().getWarehousesByFilters()
            .then(async result => {
                setWarehouses(result.warehouses);
                if (result.warehouses.length === 1) {
                    setWarehouse(result.warehouses[result.warehouses.length - 1])

                    let filters = {};
                    filters.isSupplier = true;

                    filters.warehouseId = result.warehouses[result.warehouses.length - 1].id

                    await new AccountService().getAccounts(filters)
                        .then(response => {
                            setSuppliers(response);
                            if (response.length === 1) {
                                setSupplier(response[response.length - 1])
                            }
                        })

                }
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })

        setLoadingPage(false);

    }

    async function fetchSuppliers() {

        let filters = {};
        filters.isSupplier = true;

        filters.warehouseId = warehouse.id


        await new AccountService().getAccounts(filters)
            .then(response => {
                setSuppliers(response);
            })


    }


    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }


    async function fetchCompanies() {

        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchBankAccounts() {

        let filters = {};

        filters.companyId = company.id;

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchCurrencies() {

        await new CurrencyService().getAll()
            .then(result => {
                setCurrencies(result);
                setCurrency(result[0]);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }

            })

    }

    function handleChecked(event, key) {

        cods
            // .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].checked = event.target.checked;

        if (cods.some(accountingRow => accountingRow.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);

        // let rows = cods
        //     .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
        //     .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
        //     .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row);


        for (let i = 0; i < cods.length; i++) {
            cods[i].checked = !!event.target.checked;
        }

        setRefresh(!refresh);

    }

    function checkValidCodsSelected() {

        if (cods.length < 1) {
            return false;
        }

        return cods.filter(acc => !!acc.checked)?.length >= 1;


    }

    function calculateCodsTotal(cods) {
        let total = 0.00;

        for (let i = 0; i < cods.length; i++) {

            if (cods[i].checked === true) {
                total += cods[i].cod.value;
            }
        }

        setCodsTotal(total);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchCurrencies();
        calculateCodsTotal(cods);
    }, [refresh])

    useEffect(() => {
        init();
    }, [])

    return <Paper style={{
        width: '100%',
        textAlign: 'left',
        display: "block",
    }}>
        {loadingPage ?
            <LoadingComponent/>
            :
            <div>
                {pdfContent?
                    <div style={{margin: "auto", width: "90%"}}>
                        <object style={{margin: "auto", marginBlock: "5%"}} width={"100%"} height={600} type={'application/pdf'} data={"data:application/pdf;base64," + pdfContent}>";
                            html += "</object>
                        {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                    </div>
                    :
                <div>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{margin: "2%", background: "transparent"}}
                            onClick={() => history.goBack()}
                            startIcon={<ArrowBackRounded/>}
                            variant={"filled"}
                        >
                            {t("back")}
                        </Button>
                    </div>
                    <div style={{width: "fit-content", minWidth: "30%", margin: "auto"}}>
                        <DialogTitle style={{margin: "auto", width: "fit-content"}}>{t("new_payment_slip")}</DialogTitle>

                        <div style={{marginBlock: "10%"}}>
                            <div style={{display: "flex"}}>
                                <Typography variant={"button"} style={{margin: "auto"}}>{t("payee")}</Typography>

                            </div>
                            <Autocomplete
                                id="companies"
                                size="small"
                                style={{marginTop: "4%"}}
                                options={companies}
                                getOptionLabel={option => option.companyName}
                                value={company}
                                defaultValue={company}
                                noOptionsText={t("no_options")}
                                onOpen={fetchCompanies}
                                onChange={handleCompanyChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("company")}
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        error={!company}
                                        value={company}
                                        defaultValue={company}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="bank-account"
                                size="small"
                                disabled={!company}
                                options={bankAccounts}
                                getOptionLabel={option => option.bankName + " - " + option.iban}
                                value={bankAccount}
                                defaultValue={bankAccount}
                                noOptionsText={t("no_options")}
                                onOpen={fetchBankAccounts}
                                onChange={handleBankAccountChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("bank_account")}
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        error={!bankAccount}
                                        value={bankAccount}
                                        defaultValue={bankAccount}
                                    />
                                )}
                            />
                        </div>

                        <div style={{marginBlock: "10%"}}>
                            <div style={{display: "flex"}}>
                                <Typography variant={"button"} style={{margin: "auto"}}>{t("issuer")}</Typography>
                            </div>
                            <Autocomplete
                                id="warehouse-select"
                                size="small"
                                options={warehouses}
                                style={{marginTop: "4%"}}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={warehouse}
                                defaultValue={warehouse}
                                noOptionsText={t("no_options")}
                                onOpen={fetchWarehouses}
                                onChange={handleWarehouseChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("warehouse")}
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        error={!warehouse}
                                        value={warehouse}
                                        defaultValue={warehouse}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="supplier"
                                size="small"
                                disabled={!warehouse}
                                options={suppliers}
                                getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                value={supplier}
                                defaultValue={supplier}
                                noOptionsText={t("no_options")}
                                onOpen={fetchSuppliers}
                                onChange={handleSupplierChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("supplier")}
                                        variant="outlined"
                                        margin="dense"
                                        value={supplier}
                                        required
                                        error={!supplier}
                                        defaultValue={supplier}
                                    />
                                )}
                            />
                        </div>

                        <Autocomplete
                            id="currency"
                            size="small"
                            options={currencies}
                            getOptionLabel={option => option.symbol + "  " + option.name}
                            value={currency}
                            defaultValue={currency}
                            noOptionsText={t("no_options")}
                            onOpen={fetchCurrencies}
                            onChange={handleCurrencyChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("currency")}
                                    variant="outlined"
                                    margin="dense"
                                    value={currency}
                                    required
                                    error={!currency}
                                    defaultValue={currency}
                                />
                            )}
                        />

                        <div style={{marginBlock: "2%"}}>
                            <Typography variant={"subtitle2"}
                                        fontSize={18}>{t("cods_total")}{": "}{currency ? currency.symbol + " " : null}{codsTotal.toFixed(2)}</Typography>
                        </div>
                        <div style={{marginBlock: "2%"}}>
                            <Typography variant={"subtitle2"}
                                        fontSize={18}>{t("previous_rounding")}{": "}{currency ? currency.symbol + " " : null}{previousRounding.toFixed(2)}</Typography>
                        </div>
                        <div style={{marginBlock: "2%"}}>
                            <Typography variant={"subtitle2"}
                                        fontSize={18}>{t("expected_payment")}{": "}{currency ? currency.symbol + " " : null}{(codsTotal + previousRounding).toFixed(2)}</Typography>
                        </div>
                        <CurrencyTextField
                            label={t("total_document")}
                            variant={"outlined"}
                            margin={"dense"}
                            decimalPlaces={2}
                            minimumValue={0}
                            digitGroupSeparator={"."}
                            decimalCharacter={","}
                            value={totalPaid}
                            currencySymbol={currency ? currency.symbol : null}
                            onChange={handleTotalPaidChange}
                            required
                            error={!totalPaid}
                        />
                        <div style={{marginBlock: "2%"}}>
                            <Typography variant={"subtitle2"}
                                        fontSize={18}>{t("rounding")}{": "}{currency ? currency.symbol + " " : null}{(codsTotal - totalPaid).toFixed(2)}</Typography>
                        </div>
                        <div style={{marginBlock: "2%"}}>
                            <Typography variant={"subtitle2"}
                                        fontSize={18}>{t("total_rounding")}{": "}{currency ? currency.symbol + " " : null}{(previousRounding + (codsTotal - totalPaid)).toFixed(2)}</Typography>
                        </div>
                        <DateField
                            style={{width: "50%"}}
                            label={t("payment_date")}
                            value={paymentDate}
                            onChange={handlePaymentDateChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                            clearable={false}
                        />
                        {/*<TextField*/}
                        {/*    style={{width: "100%"}}*/}
                        {/*    label={t("notes")}*/}
                        {/*    variant="outlined"*/}
                        {/*    margin="dense"*/}
                        {/*    onChange={handleNotesChange}*/}
                        {/*    value={notes}*/}
                        {/*    multiline*/}
                        {/*    maxRows={4}*/}
                        {/*    // className={classes.field}*/}
                        {/*/>*/}
                    </div>
                    <div style={{width: "60%", margin: "auto", border: "1px solid", marginBlock: "2%"}}>
                        <div style={{display: "flex", margin: "auto", marginBlock: "2%", width: "70%"}}>
                            <DialogTitle style={{margin: "auto"}}>{t("cods")}</DialogTitle>
                        </div>

                        <div style={{display: "flex", margin: "auto", width: "70%"}}>
                            <DateField
                                style={{minWidth: "45%", margin: "auto"}}
                                label={t("verified_date_from")}
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />
                            <DateField
                                style={{minWidth: "45%", margin: "auto"}}
                                label={t("verified_date_to")}
                                value={dateTo}
                                onChange={handleDateToChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />

                        </div>
                        <div style={{display: "flex"}}>
                            <IconButton onClick={getCods}
                                        disabled={loading || !warehouse || !supplier}
                                        style={{
                                            backgroundColor: 'transparent',
                                            margin: "auto",
                                            marginBlock: "3%"
                                        }}>{loading ?
                                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                                <SearchOutlined
                                    style={{color: loading || !warehouse || !supplier ? "grey" : theme.palette.primary.main}}/>}</IconButton>
                        </div>
                        <div style={{display: "flex"}}>
                            {!supplier ? <Typography variant={"caption"} style={{
                                margin: "auto",
                                color: "red"
                            }}>{t("select_supplier")}</Typography> : null}
                        </div>

                        {fetched ?
                            <div>
                                {cods.length > 0 ?
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Checkbox color="primary"
                                                                  onChange={handleAllChecked}
                                                                  checked={allChecked}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("shipment")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {t("value")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {cods
                                                .slice(rowsPerPage > 0 ? page * rowsPerPage : 0, rowsPerPage > 0 ? page * rowsPerPage + rowsPerPage : cods.length)
                                                .map((cod, key) => {

                                                    return <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                color="primary"
                                                                onChange={event => handleChecked(event, key)}
                                                                checked={cod.checked}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            {cod.cod.shipmentId}
                                                        </TableCell>
                                                        <TableCell>
                                                            {cod.cod.currency + " " + cod.cod.value.toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>

                                                })}
                                        </Table>
                                        <TablePagination
                                            style={{width: "80%", margin: "auto"}}
                                            rowsPerPageOptions={[5, 10, 25, {label: t("all"), value: -1}]}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return (t("total_selected") + ": " + cods.filter(acc => !!acc.checked)?.length)
                                            }}
                                            component="div"
                                            count={cods.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                    :
                                    <InfoChip>{t("no_data")}</InfoChip>
                                }

                            </div>

                            :
                            null
                        }
                    </div>
                    <div style={{display: "flex"}}>
                        <Button
                            disabled={!company || !bankAccount || !supplier || !warehouse || !totalPaid || !paymentDate || cods.length < 1 || cods.filter(acc => !!acc.checked)?.length < 1}
                            style={{margin: "auto", marginTop: "3%", marginBottom: "10%"}}
                            endIcon={<SaveOutlined/>}
                            onClick={createPaymentSlip}
                            color="primary"
                            variant={"contained"}>
                            {t("save")}
                        </Button>
                    </div>


                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                  onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                </div>
                    }
            </div>

        }
    </Paper>


}

export default PaymentSlipForm;