import {
    Box,
    Button, Checkbox, Collapse,
    FormControlLabel, FormGroup, FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup, Table,
    TableCell, TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import {Card, CircularProgress, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import ChipInput from "material-ui-chip-input";
import {Autocomplete} from "@material-ui/lab";
import DateField from "../components/fields/DateField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ShipmentService from "../shipments/ShipmentService";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import WarehouseService from "../warehouses/WarehouseService";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import {compareAsc} from "date-fns";
import ServiceLevelsService from "./ServiceLevelsService";
import InfoChip from "../components/chips/InfoChip";
import ParcelEvent from "../shipments/ParcelEvent";
import {Download} from "@mui/icons-material";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import ServiceService from "../shipments/services/ServiceService";
import {SearchOutlined} from "@material-ui/icons";
import FormControl from "@mui/material/FormControl";
import Notification from "../components/notifications/Notification";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    paper: {
        width: "100%",
        maxWidth: 1024,
        minWidth: 600,
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBlock: "2%",
        width: "30%"
    },
    barcodes: {
        margin: "auto",
        marginBottom: "2%",
        width: "96%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));


function ServiceLevels(props) {

    const classes = useStyles();

    const {t} = useTranslation();

    const [landing, setLanding] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [shipments, setShipments] = useState(null);
    const [count, setCount] = useState(0);

    const [openNotCompletedIn, setOpenNotCompletedIn] = useState(false);
    const [openNotCompletedOut, setOpenNotCompletedOut] = useState(false);
    const [openCompletedIn, setOpenCompletedIn] = useState(false);
    const [openCompletedOut, setOpenCompletedOut] = useState(false);

    const [suppliers, setSuppliers] = useState([]);
    const [suppliersContracts, setSuppliersContracts] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customersContracts, setCustomersContracts] = useState([]);
    const [services, setServices] = useState([]);

    const [service, setService] = useState(null);
    const [supplierAccount, setSupplierAccount] = useState(null);
    const [supplierContract, setSupplierContract] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [customerAccount, setCustomerAccount] = useState(null);
    const [customerContract, setCustomerContract] = useState(null);
    const [taskDateFrom, setTaskDateFrom] = useState(null);
    const [taskDateTo, setTaskDateTo] = useState(null);

    const [checkInDateFrom, setCheckInDateFrom] = useState(null);
    const [checkInDateTo, setCheckInDateTo] = useState(null);

    const [manifestDateFrom, setManifestDateFrom] = useState(null);
    const [manifestDateTo, setManifestDateTo] = useState(null);


    const [country, setCountry] = useState(null);
    const [administrativeLevel3, setAdministrativeLevel3] = useState(null);
    const [administrativeLevel2, setAdministrativeLevel2] = useState(null);
    const [administrativeLevel1, setAdministrativeLevel1] = useState(null);
    const [city, setCity] = useState(null);
    const [postalCode, setPostalCode] = useState(null);

    const [countries, setCountries] = useState([]);
    const [adminL1List, setAdminL1List] = useState([]);
    const [adminL2List, setAdminL2List] = useState([]);
    const [adminL3List, setAdminL3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [lastMile, setLastMile] = useState(true);
    const [firstMile, setFirstMile] = useState(true);
    const [hub, setHub] = useState(false);
    const [handling, setHandling] = useState(false);


    const [loadingCsv, setLoadingCsv] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleFirstMileChange(event) {
        setFirstMile(event.target.checked)
    }

    function handleLastMileChange(event) {
        setLastMile(event.target.checked)
    }

    function handleHubChange(event) {
        setHub(event.target.checked);
    }

    function handleHandlingChange(event) {
        setHandling(event.target.checked);
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();
        let filters = {}

        if (country) {
            filters.countryId = country.id;
        }

        adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL1List(data.admins1);
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        let filters = {}
        if (administrativeLevel1) {

            filters.adminL1 = administrativeLevel1.id;
        }

        adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL2List(data.admins2);
            })

    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }

        if (administrativeLevel2) {
            filters.adminL2 = administrativeLevel2.id;
        }

        adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL3List(data.admins3);
            })

    }

    function fetchCities() {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes() {

        let filters = {};
        if (!city && administrativeLevel3) {
            filters.administrativeLevel3Id = administrativeLevel3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }


    function calculateCompletedPercentage(shipments) {
        if (null !== shipments) {
            let completed = shipments?.completed.inServiceLevel.length + shipments?.completed.outServiceLevel.length;
            let notCompleted = shipments?.notCompleted.inServiceLevel.length + shipments?.notCompleted.outServiceLevel.length;

            if (completed + notCompleted === 0) {
                return 0;
            }

            return (completed / (completed + notCompleted)) * 100;
        } else {
            return 0;
        }
    }

    function calculateNotCompletedPercentage(shipments) {

        if (null !== shipments) {
            let completed = shipments?.completed.inServiceLevel.length + shipments?.completed.outServiceLevel.length;
            let notCompleted = shipments?.notCompleted.inServiceLevel.length + shipments?.notCompleted.outServiceLevel.length;

            if (completed + notCompleted === 0) {
                return 0;
            }

            return (notCompleted / (completed + notCompleted)) * 100;
        } else {
            return 0;
        }

    }

    function calculateInServicePercentage(shipments) {
        let total = shipments?.inServiceLevel.length + shipments?.outServiceLevel.length;
        let inService = shipments.inServiceLevel.length;

        if (total === 0) {
            return 0;
        }
        return inService / total * 100;
    }

    function calculateOutServicePercentage(shipments) {
        let total = shipments?.inServiceLevel.length + shipments?.outServiceLevel.length;
        let outService = shipments.outServiceLevel.length;

        if (total === 0) {
            return 0;
        }
        return outService / total * 100;
    }

    function handleEnter(event) {

        if (event.code === "Enter") {
            fetchTasks();
        }

    }

    function fetchWarehouses() {

        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters()
            .then(data => {
                setWarehouses(data.warehouses);
            })
            .catch(error => {

            })

    }

    function fetchSuppliers() {
        const accountService = new AccountService();

        let filters = {};

        filters.isSupplier = true;
        accountService.getAccounts(filters)
            .then(data => {
                setSuppliers(data)
            })
            .catch((err) => {

            })
    }


    function fetchSuppliersContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (supplierAccount != null) {
            filters.accountId = supplierAccount.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setSuppliersContracts(response);
            })

    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts()
            .then(data => {
                setCustomers(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (customerAccount != null) {
            filters.accountId = customerAccount.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setCustomersContracts(response);
            })

    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    function handleCountryChange(event, country) {
        setCountry(country);
        setAdministrativeLevel1(null);
        setAdministrativeLevel2(null);
        setAdministrativeLevel3(null);
        setCity(null)
        setPostalCode(null)
    }

    function handleAdminL1Change(event, adminL1) {
        setAdministrativeLevel1(adminL1);
        setAdministrativeLevel2(null);
        setAdministrativeLevel3(null);
        setCity(null)
        setPostalCode(null)
    }

    function handleAdminL2Change(event, adminL2) {
        setAdministrativeLevel2(adminL2);
        setAdministrativeLevel3(null);
        setCity(null)
        setPostalCode(null)
    }

    function handleAdminL3Change(event, adminL3) {
        setAdministrativeLevel3(adminL3);
        setCity(null)
        setPostalCode(null)
    }

    function handleCityChange(event, city) {
        setCity(city)
        setPostalCode(null)
    }

    function handlePostalCodeChange(event, postalCode) {
        setPostalCode(postalCode);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    function handleSupplierChange(event, supplier) {
        setSupplierAccount(supplier);
        setSupplierContract(null);
    }

    function handleSupplierContractChange(event, contract) {
        setSupplierContract(contract);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleAccountChange(event, account) {
        setCustomerAccount(account);
        setCustomerContract(null);
    }

    function handleContractChange(event, contract) {
        setCustomerContract(contract);
    }

    function handleTaskDateFromChange(event) {
        if (taskDateTo == null || compareAsc(event, taskDateTo) === 1) {
            setTaskDateFrom(event);
            setTaskDateTo(event);
        } else {
            setTaskDateFrom(event)
        }
    }

    function handleTaskDateToChange(event) {
        if (taskDateFrom == null || compareAsc(taskDateFrom, event) === 1) {
            setTaskDateFrom(event);
            setTaskDateTo(event);
        } else {
            setTaskDateTo(event);
        }
    }
 function handleManifestDateFromChange(event) {
        if (manifestDateTo == null || compareAsc(event, manifestDateTo) === 1) {
            setManifestDateFrom(event);
            setManifestDateTo(event);
        } else {
            setManifestDateFrom(event)
        }
    }

    function handleManifestDateToChange(event) {
        if (manifestDateFrom == null || compareAsc(manifestDateFrom, event) === 1) {
            setManifestDateFrom(event);
            setManifestDateTo(event);
        } else {
            setManifestDateTo(event);
        }
    }

    function handleCheckInDateFromChange(event) {
        if (checkInDateTo == null || compareAsc(event, checkInDateTo) === 1) {
            setCheckInDateFrom(event);
            setCheckInDateTo(event);
        } else {
            setCheckInDateFrom(event)
        }
    }

    function handleCheckInDateToChange(event) {
        if (checkInDateFrom == null || compareAsc(checkInDateFrom, event) === 1) {
            setCheckInDateFrom(event);
            setCheckInDateTo(event);
        } else {
            setCheckInDateTo(event);
        }
    }

    function getFilters() {
        let filters = {};

        if (null != supplierAccount) {
            filters.supplierAccountId = supplierAccount.id;
        }
        if (null != supplierContract) {
            filters.supplierContractId = supplierContract.id;
        }
        if (null != warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (null != customerAccount) {
            filters.customerAccountId = customerAccount.id;
        }
        if (null != customerContract) {
            filters.customerContractId = customerContract.id;
        }

        if (taskDateFrom) {
            filters.taskDateFrom = moment(taskDateFrom).format("yyyy-MM-DD");
        }

        if (taskDateTo) {
            filters.taskDateTo = moment(taskDateTo).format("yyyy-MM-DD");
        }

        if (checkInDateFrom) {
            filters.checkInDateFrom = moment(checkInDateFrom).format("yyyy-MM-DD");
        }

        if (checkInDateTo) {
            filters.checkInDateTo = moment(checkInDateTo).format("yyyy-MM-DD");
        }

        if (manifestDateFrom) {
            filters.manifestDateFrom = moment(manifestDateFrom).format("yyyy-MM-DD");
        }

        if (manifestDateTo) {
            filters.manifestDateTo = moment(manifestDateTo).format("yyyy-MM-DD");
        }

        filters.taskTypes = [];

        if(firstMile){
            filters.taskTypes.push("FIRST_MILE");
        }

        if(lastMile){
            filters.taskTypes.push("LAST_MILE");
        }

        if(hub){
            filters.taskTypes.push("HUB");
        }

        if(handling){
            filters.taskTypes.push("HANDLING");
        }

        if (country) {
            filters.country = country.iso3166Alpha3;
        }

        if (administrativeLevel1) {
            filters.administrativeLevel1 = administrativeLevel1.code;
        }
        if (administrativeLevel2) {
            filters.administrativeLevel2 = administrativeLevel2.code;
        }
        if (administrativeLevel3) {
            filters.administrativeLevel3 = administrativeLevel3.code;
        }

        if (city) {
            filters.city = city.name;
        }

        if (postalCode) {
            filters.postalCode = postalCode.postalCode;
        }

        if (service) {
            filters.serviceCode = service.code;
        }

        return filters;
    }

    function applyFilters() {
        setRefresh(!refresh);
        setLanding(false);

    }

    function clearFilters() {

        setCustomerAccount(null);
        setCustomerContract(null);
        setSupplierAccount(null);
        setWarehouse(null);
        setTaskDateFrom(new Date());
        setTaskDateTo(new Date());

        setRefresh(!refresh);
    }


    async function fetchTasks() {
        setFetching(true);

        let filters = getFilters();

        if(
        (!filters.taskDateFrom || !filters.taskDateTo) &&
            (!filters.checkInDateFrom || !filters.checkInDateTo ) &&
            (!filters.manifestDateFrom || !filters.manifestDateTo )
        ) {
            setOpenNotify(true);
            setNotifySeverity("warning");
            setNotificationMessage("Inserire una coppia di date valide");
            setFetching(false);
            return;
        }

        await new ServiceLevelsService().getByFilters(filters)
            .then(response => {
                setShipments(response);
                // setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setFetching(false)
                setFetched(true);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setShipments(null);
                        setOpenNotify(true);
                        setNotificationMessage(response.message)
                        setNotifySeverity('error')
                        setFetching(false);
                        setFetched(true);
                    })

            });
    }

    async function fetchCsv() {
        setLoadingCsv(true);

        let filters = getFilters();

        if(
            (!filters.taskDateFrom || !filters.taskDateTo) &&
            (!filters.checkInDateFrom || !filters.checkInDateTo ) &&
            (!filters.manifestDateFrom || !filters.manifestDateTo )
        ) {
            setOpenNotify(true);
            setNotifySeverity("warning");
            setNotificationMessage("Inserire una coppia di date valide");
            setFetching(false);
            return;
        }

        await new ServiceLevelsService().getCSVByFilters(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();
                // setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setLoadingCsv(false)
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setOpenNotify(true);
                        setNotificationMessage(response.message)
                        setNotifySeverity('error')
                        setLoadingCsv(false)
                    })

            });
    }

    useEffect(() => {
        if (!landing) {
            fetchTasks();
        }
    }, [refresh])


    return <div className={classes.root}>

        <Card elevation={1} defaultExpanded={true} onKeyPress={handleEnter}
              style={{width: "95%", margin: "auto", marginBottom: "2%"}}>
            <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                <Typography variant={"button"}>{t("service_levels_network")}</Typography>
            </DialogTitle>

            <Autocomplete
                id="warehouse"
                options={warehouses}
                className={classes.filter}
                getOptionLabel={option => option.code}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options_available")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        InputLabelProps={{shrink: true}}
                        placeholder={t("any")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <Autocomplete
                id="service"
                options={services}
                className={classes.filter}
                getOptionLabel={option => option.code + " - " + option.name}
                value={service}
                defaultValue={service}
                noOptionsText={t("no_options_available")}
                onOpen={fetchServices}
                onChange={handleServiceChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("service")}
                        InputLabelProps={{shrink: true}}
                        placeholder={t("any")}
                        variant="outlined"
                        margin="dense"
                        value={service}
                        defaultValue={service}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <Typography variant={"subtitle2"} fontSize={16}
                        style={{color: "black", margin: "auto"}}>{t("supplier")}</Typography>
            <div style={{display: "flex", border: "1px solid", width: "95%", margin: "auto", marginBlock: "2%"}}>
                <Autocomplete
                    id="supplier"
                    options={suppliers}
                    className={classes.filter}
                    getOptionLabel={option => option.companyName}
                    value={supplierAccount}
                    defaultValue={supplierAccount}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchSuppliers}
                    onChange={handleSupplierChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("supplier_account")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={supplierAccount}
                            defaultValue={supplierAccount}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="contract"
                    options={suppliersContracts}
                    disabled={!supplierAccount}
                    className={classes.filter}
                    getOptionLabel={option => option.code}
                    value={supplierContract}
                    defaultValue={supplierContract}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchSuppliersContracts}
                    onChange={handleSupplierContractChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("supplier_contract")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={supplierContract}
                            defaultValue={supplierContract}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <Typography variant={"subtitle2"} fontSize={16}
                        style={{color: "black", margin: "auto"}}>{t("customer")}</Typography>
            <div style={{display: "flex", border: "1px solid", width: "95%", margin: "auto", marginBlock: "2%"}}>
                <Autocomplete
                    id="account"
                    options={customers}
                    className={classes.filter}
                    getOptionLabel={option => option.companyName}
                    value={customerAccount}
                    defaultValue={customerAccount}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchAccounts}
                    onChange={handleAccountChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("customer_account")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={customerAccount}
                            defaultValue={customerAccount}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="contract"
                    disabled={!customerAccount}
                    options={customersContracts}
                    className={classes.filter}
                    getOptionLabel={option => option.code}
                    value={customerContract}
                    defaultValue={customerContract}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchContracts}
                    onChange={handleContractChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("customer_contract")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={customerContract}
                            defaultValue={customerContract}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", marginBottom: "3%"}}>
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("task_date_from")}
                    value={taskDateFrom}
                    onChange={handleTaskDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("task_date_to")}
                    value={taskDateTo}
                    onChange={handleTaskDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", marginBottom: "3%"}}>
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("check_in_from_date")}
                    value={checkInDateFrom}
                    onChange={handleCheckInDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("check_in_to_date")}
                    value={checkInDateTo}
                    onChange={handleCheckInDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", marginBottom: "3%"}}>
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("manifest_from_date")}
                    value={manifestDateFrom}
                    onChange={handleManifestDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{margin: "auto", width: "25%"}}
                    label={t("manifest_to_date")}
                    value={manifestDateTo}
                    onChange={handleManifestDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <Typography variant={"subtitle2"} fontSize={16}
                        style={{color: "black", margin: "auto"}}>{t("address")}</Typography>
            <div style={{display: "flex", width: "100%"}}>
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="country"
                    className={classes.field}
                    options={countries}
                    classes={{
                        option: classes.option,
                    }}
                    getOptionLabel={(option) => option.name}
                    value={country}
                    defaultValue={country}
                    onOpen={fetchCountries}
                    onChange={handleCountryChange}
                    onBlur={fetchAdminL1}
                    renderOption={(option) => (
                        <React.Fragment>
                            {option.name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("country")}
                            variant="outlined"
                            margin="dense"
                            value={option => option.iso3166Alpha3}
                            defaultValue={country}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="administrative1"
                    disabled={!country}
                    className={classes.field}
                    options={adminL1List}
                    getOptionLabel={option => option.name}
                    value={administrativeLevel1}
                    defaultValue={administrativeLevel1}
                    onOpen={fetchAdminL1}
                    onChange={handleAdminL1Change}
                    // loading={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative1")}
                            variant="outlined"
                            margin="dense"
                            value={administrativeLevel1}
                            defaultValue={administrativeLevel1}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="administrative2"
                    disabled={!administrativeLevel1}
                    className={classes.field}
                    options={adminL2List}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={administrativeLevel2}
                    onOpen={fetchAdminL2}
                    onChange={handleAdminL2Change}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("administrative2")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={administrativeLevel2}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", width: "100%"}}>
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="administrative3"
                    disabled={!country}
                    options={adminL3List}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={administrativeLevel3}
                    onOpen={fetchAdminL3}
                    onChange={handleAdminL3Change}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={administrativeLevel3}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="city"
                    disabled={!country}
                    options={cities}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={city}
                    onOpen={fetchCities}
                    onChange={handleCityChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={city}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "25%", margin: "auto"}}
                    id="postalCode"
                    disabled={!country}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    getOptionSelected={(option, value) => option.postalCode === value.postalCode}
                    value={postalCode}
                    onOpen={fetchPostalCodes}
                    onChange={handlePostalCodeChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={postalCode}
                        />
                    )}
                />
            </div>
            <div style={{marginBlock: "2%"}}>
                <Typography variant={"subtitle2"} fontSize={16}
                            style={{color: "black", margin: "auto"}}>{t("type")}</Typography>
                <div style={{margin: "auto", display: "flex"}}>
                    <FormGroup row style={{margin: "auto"}}>
                        <FormControlLabel
                            control={<Checkbox color={"primary"} checked={firstMile} onChange={handleFirstMileChange}/>}
                            label={t("first_mile")}
                        />
                        <FormControlLabel
                            control={<Checkbox color={"primary"} checked={lastMile} onChange={handleLastMileChange}/>}
                            label={t("last_mile")}
                        />
                        <FormControlLabel
                            control={<Checkbox color={"primary"} checked={hub} onChange={handleHubChange}/>}
                            label={t("hub")}
                        />
                        <FormControlLabel
                            control={<Checkbox color={"primary"}checked={handling} onChange={handleHandlingChange}/>}
                            label={t("handling")}
                        />
                    </FormGroup>
                </div>
            </div>

            <div style={{textAlign: 'center'}}>
                {/*<Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>*/}
                <Button startIcon={fetching ? <CircularProgress size={20}/> : <SearchOutlined/>} style={{margin: "5%"}}
                        onClick={applyFilters}>{t("search")}</Button>
            </div>
        </Card>

        <div style={{textAlign: 'center', marginBottom: "5%"}}>
            {/*<Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>*/}
            <Button startIcon={loadingCsv ?
                <CircularProgress size={24}/>
                :
                <Download/>
            } style={{marginInline: "2%"}} disabled={!shipments || (shipments.completed.inServiceLevel.length < 1 &&
                shipments.completed.outServiceLevel.length < 1 &&
                shipments.notCompleted.inServiceLevel.length < 1 &&
                shipments.notCompleted.outServiceLevel.length < 1
            )} onClick={fetchCsv}>
                {t("download_csv")}
            </Button>
        </div>

        {shipments ?
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item style={{display: "flex"}}>
                        <Card className={classes.paper}>
                            <Typography fontSize={34}
                                        variant={"button"}>{t("completed")} {calculateCompletedPercentage(shipments).toFixed(1) + "%"}</Typography>
                            <Typography variant={"subtitle2"} fontSize={22}
                                        style={{cursor: "pointer", marginBlock: "3%"}}
                                        onClick={() => setOpenCompletedIn(!openCompletedIn)}>{calculateInServicePercentage(shipments.completed).toFixed(1) + "% " + t("in_service_level") + ": " + shipments?.completed?.inServiceLevel?.length + " " + t("shipments")}</Typography>
                            <Collapse in={openCompletedIn} timeout="auto" unmountOnExit>
                                <Table>
                                    {shipments?.completed?.inServiceLevel?.length > 0 ?
                                        <TableRow>
                                            <TableCell>
                                                {t("delay_days")}
                                            </TableCell>
                                            <TableCell>
                                                {t("type")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_number")}
                                            </TableCell>
                                            <TableCell>
                                                {t("service")}
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("destination")}
                                            </TableCell>
                                            <TableCell>
                                                {t("administrative3")}
                                            </TableCell>

                                        </TableRow>
                                        : null}
                                    {shipments.completed.inServiceLevel.map(shipment => {
                                        return <TableRow>
                                            <TableCell>
                                                {shipment.daysOfDelay}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.type}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.id}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.service.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.contract.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.reference}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.companyName}
                                                {shipment.shipment.deliveryAddress.referencePerson}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.administrative_level_3}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Table>

                            </Collapse>
                            <Typography variant={"subtitle2"} fontSize={22}
                                        style={{cursor: "pointer", marginBlock: "3%"}}
                                        onClick={() => setOpenCompletedOut(!openCompletedOut)}>{calculateOutServicePercentage(shipments.completed).toFixed(1) + "% " + t("out_service_level") + ": " + shipments?.completed?.outServiceLevel?.length + " " + t("shipments")}</Typography>
                            <Collapse in={openCompletedOut} timeout="auto" unmountOnExit>
                                <Table>
                                    {shipments?.completed?.outServiceLevel?.length > 0 ?
                                        <TableRow>
                                            <TableCell>
                                                {t("delay_days")}
                                            </TableCell>
                                            <TableCell>
                                                {t("type")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_number")}
                                            </TableCell>
                                            <TableCell>
                                                {t("service")}
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("destination")}
                                            </TableCell>
                                            <TableCell>
                                                {t("administrative3")}
                                            </TableCell>

                                        </TableRow>
                                        : null}
                                    {shipments.completed.outServiceLevel.map(shipment => {
                                        return <TableRow>
                                            <TableCell>
                                                {shipment.daysOfDelay}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.type}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.id}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.service.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.contract.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.reference}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.companyName}
                                                {shipment.shipment.deliveryAddress.referencePerson}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.administrative_level_3}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Table>
                            </Collapse>
                        </Card>
                    </Grid>
                    <Grid item style={{display: "flex"}}>
                        <Card className={classes.paper}>
                            <Typography fontSize={34}
                                        variant={"button"}>{t("not_completed")} {calculateNotCompletedPercentage(shipments).toFixed(1) + "%"}</Typography>
                            <Typography variant={"subtitle2"} fontSize={22}
                                        style={{cursor: "pointer", marginBlock: "3%"}}
                                        onClick={() => setOpenNotCompletedIn(!openNotCompletedIn)}>{calculateInServicePercentage(shipments.notCompleted).toFixed(1) + "% " + t("in_service_level") + ": " + shipments?.notCompleted?.inServiceLevel?.length + " " + t("shipments")}</Typography>
                            <Collapse in={openNotCompletedIn} timeout="auto" unmountOnExit>
                                <Table>
                                    {shipments?.notCompleted?.inServiceLevel?.length > 0 ?
                                        <TableRow>
                                            <TableCell>
                                                {t("delay_days")}
                                            </TableCell>
                                            <TableCell>
                                                {t("type")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_number")}
                                            </TableCell>
                                            <TableCell>
                                                {t("service")}
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("destination")}
                                            </TableCell>
                                            <TableCell>
                                                {t("administrative3")}
                                            </TableCell>

                                        </TableRow>
                                        : null}
                                    {shipments.notCompleted.inServiceLevel.map(shipment => {
                                        return <TableRow>
                                            <TableCell>
                                                {shipment.daysOfDelay}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.type}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.id}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.service.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.contract.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.reference}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.companyName}
                                                {shipment.shipment.deliveryAddress.referencePerson}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.administrative_level_3}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Table>

                            </Collapse>
                            <Typography variant={"subtitle2"} fontSize={22}
                                        style={{cursor: "pointer", marginBlock: "3%"}}
                                        onClick={() => setOpenNotCompletedOut(!openNotCompletedOut)}>{calculateOutServicePercentage(shipments.notCompleted).toFixed(1) + "% " + t("out_service_level") + ": " + shipments?.notCompleted?.outServiceLevel?.length + " " + t("shipments")}</Typography>
                            <Collapse in={openNotCompletedOut} timeout="auto" unmountOnExit>
                                <Table>
                                    {shipments?.notCompleted?.outServiceLevel?.length > 0 ?
                                        <TableRow>
                                            <TableCell>
                                                {t("delay_days")}
                                            </TableCell>
                                            <TableCell>
                                                {t("type")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_number")}
                                            </TableCell>
                                            <TableCell>
                                                {t("service")}
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("destination")}
                                            </TableCell>
                                            <TableCell>
                                                {t("administrative3")}
                                            </TableCell>

                                        </TableRow>
                                        : null}
                                    {shipments.notCompleted.outServiceLevel.map(shipment => {
                                        return <TableRow>
                                            <TableCell>
                                                {shipment.daysOfDelay}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.type}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.id}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.service.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.contract.code}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.reference}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.companyName}
                                                {shipment.shipment.deliveryAddress.referencePerson}
                                            </TableCell>
                                            <TableCell>
                                                {shipment.shipment.deliveryAddress.administrative_level_3}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Table>
                            </Collapse>
                        </Card>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
            :
            null
        }
        <Notification open={openNotify} severity={notifySeverity} duration={4000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </div>


}

export default ServiceLevels;