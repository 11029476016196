import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import UserService from "./UserService";


function User(props){

    const user = props.user;
    const {t} = useTranslation();
    const history = useHistory();
    const [userRoles, setUserRoles] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    let useStyles = makeStyles((theme) => ({
        icon: {
            marginRight: "5%"
        },
        iconCell: {

            margin: "auto",
            textAlign:'end',

        },
        userDetailTypo: {
            ...theme.typography.button,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),

        }
    }));

    const classes = useStyles();

    useEffect(()=>{

    },[])


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const editUser = (userId) => {
        setAnchorEl(null);
        history.push(`/users/${userId}/edit/`);
    };

    // const deleteUser = (userId) => {
    //     let userService = new UserService();
    //     userService.deleteRole(userId)
    //         .then( () => {
    //             props.deleteCallback();
    //         })
    //         .catch((err) => {
    //             err.response.json().then((response) => {
    //             });
    //         })
    //         .finally( () => {
    //             setAnchorEl(null);
    //         })
    // };

    return <TableRow>

        <TableCell>{user.id}</TableCell>
        <TableCell>{user.username}</TableCell>
        <TableCell>
            {user.roles.map((role, index) => {
                return <Typography key={index}>{role}&nbsp;</Typography>
        })}
        </TableCell>
        <TableCell className={classes.iconCell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => editUser(user.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

                {/*<MenuItem onClick={() => deleteUser(user.id)}><DeleteIcon className={classes.icon}/>{t("delete")}</MenuItem>*/}
            </Menu>
        </TableCell>
    </TableRow>


}


export default User;