import React, {useEffect, useState} from "react";
import {Button, Paper, TableCell, TableRow} from "@material-ui/core";
import WarehouseService from "../warehouses/WarehouseService";
import {format} from "date-fns";
import DriverService from "../drivers/DriverService";
import DriverWorkListService from "./DriverWorkListService";
import PickupService from "../pickups/PickupService";
import ParcelService from "../shipments/services/ParcelService";
import ShipmentService from "../shipments/ShipmentService";
import CodService from "../shipments/services/CodService";
import {Autocomplete, Box, DialogTitle, Table, TableHead, TextField, Typography} from "@mui/material";
import DateField from "../components/fields/DateField";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import MyTimeField from "../components/fields/MyTimeField";
import theme from "../theme/theme";
import {CheckCircleOutlined} from "@material-ui/icons";


function ConfirmDelayedDriverWorkListPage(props) {

    const {t} = useTranslation();

    const [driverWorkLists, setDriverWorkLists] = useState([]);
    const [driverWorkList, setDriverWorkList] = useState(null);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState(null);

    const [date, setDate] = useState(new Date());

    const [workListData, setWorkListData] = useState([]);
    const [pickupsData, setPickupsData] = useState([]);


    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
        if(wares?.warehouses?.length === 1){
            setWarehouse(wares.warehouses[0]);
        }
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }

    async function fetchDriverWorkLists() {
        let filters = {};
        filters.delayed = true;
        filters.confirmed = false;
        filters.driverId = driver.id;
        filters.date = format(date, "yyyy-MM-dd");

        let worklists = await new DriverWorkListService().getDriverWorkLists(filters);
        setDriverWorkLists(worklists.driverWorkLists);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        if (null == warehouse) {
            setDriverWorkList(null);
            setDriver(null);
            setWorkListData([])
            setPickupsData([]);
            setDate(null)
        }
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
        if (null == driver) {
            setDriverWorkList(null);
            setWorkListData([])
            setPickupsData([]);
        }
    }

    function handleDateChange(event) {
        setDate(event);
        setDriver(null)
        setDriverWorkList(null)
        setWorkListData([])
        setPickupsData([]);

    }

    function handleWorkListChange(event, workList) {
        setDriverWorkList(workList);

        if (workList == null) {
            setWorkListData([]);
            setPickupsData([]);
        } else {
            fetchWorkListData(workList.parcels, workList.pickupIds);
        }
    }

    async function fetchWorkListData(driverWorkListParcels, pickupIds) {
        setLoading(true);

        let pickups = [];
        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del ritiro: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            let data = {};
            data.pickup = pickup;

            //data.time = new Date(now());
            //data.time.setHours(19, 0, 0, 0);

            pickups.push(data);
        }

        setPickupsData(pickups);

        let wrklst = [];
        for (let i = 0; i < driverWorkListParcels?.length; i++) {
            let parcelService = new ParcelService();
            let parcel = await parcelService.getParcelById(driverWorkListParcels[i].id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            const parcelIsInSomeShipment = wrklst.some(data => {
                return data.shipment.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode === parcel.barcode);
                })
            });
            if (parcelIsInSomeShipment) {
                wrklst.forEach(data => {
                    data.shipment.parcels.forEach(async _parcel => {
                        if (_parcel.parcel.barcode === parcel.barcode) {
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                });
            } else {
                if (parcel) {
                    let shipmentId = parcel.shipmentId;

                    let shipmentService = new ShipmentService();
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        });

                    let shipmentParcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        });

                    let cod = null;
                    if (shipment.codId) {
                        const codService = new CodService();
                        cod = await codService.getCodById(shipment.codId)
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage("Si è verificato un errore nel recupero del contrassegno: " + response.status + "-" + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })
                            });
                    }

                    // let warehouseStorage = null;
                    // const parcelThatCauseWarehouseStorage = shipmentParcels.find(tmpParcel => !!deliveryEventCodesThatOpensWarehouseStorages[tmpParcel.lastEvent.eventCodeId]);
                    // if (parcelThatCauseWarehouseStorage) {
                    //     let needToOpenWarehouseStorage = true;
                    //     let warehouseStorageReasonId = deliveryEventCodesThatOpensWarehouseStorages[parcelThatCauseWarehouseStorage.lastEvent.eventCodeId].openingReasonCodeId;
                    //     if (deliveryEventCodesWithMultipleAttempts.some(value => value === parcelThatCauseWarehouseStorage.lastEvent.eventCodeId)) {
                    //         // got an event code with multiple attempts
                    //         warehouseStorageReasonId = 2; // Done multiple attempts
                    //         needToOpenWarehouseStorage = driverWorkListParcels.some(workListParcel => {
                    //             return workListParcel.numOfDeliveryAttempts >= workListParcel.numOfAllowedDeliveryAttempts;
                    //         });
                    //     }
                    //
                    //     if (needToOpenWarehouseStorage) {
                    //         warehouseStorage = {
                    //             checked: true,
                    //             reasonId: warehouseStorageReasonId
                    //         };
                    //
                    //         const warehouseStorageService = new WarehouseStorageService();
                    //         const restWarehouseStorages = await warehouseStorageService.getFilteredWarehouseStorages({
                    //             shipmentId: shipment.id
                    //         })
                    //             .catch(error => {
                    //                 error.response.json().then(response => {
                    //                     setNotificationMessage("Si è verificato un errore nel recupero delle giacenze : " + response.status + "-" + response.message);
                    //                     setNotifySeverity('error');
                    //                     setOpenNotify(true);
                    //                 })
                    //             });
                    //
                    //         if (restWarehouseStorages && 0 < restWarehouseStorages.warehouseStorages.length) {
                    //             warehouseStorage = restWarehouseStorages.warehouseStorages[0];
                    //         }
                    //     }
                    // }

                    let data = {};
                    data.shipment = shipment;
                    data.shipment.cod = cod;
                    // data.warehouseStorage = warehouseStorage;

                    data.shipment.parcels = [];
                    data.driverWorkListParcels = driverWorkListParcels;
                    for (let i = 0; i < shipmentParcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = shipmentParcels[i];
                        if (shipmentParcels[i].barcode === parcel.barcode) {
                            parcelData.checked = true;
                            setRefresh(!refresh);
                        }

                        data.shipment.parcels.push(parcelData);
                    }

                    wrklst.push(data);
                }
            }

            setRefresh(!refresh);
        }

        setWorkListData(wrklst);

        setLoading(false);
    }

    async function confirmDriverWorkList() {

        await new DriverWorkListService().confirmDriverWorkList(driverWorkList.id)
            .then(result => {
                setNotificationMessage(t("successful"))
                setNotifySeverity("success")
                setOpenNotify(true);
                setDriverWorkList(null);
                setWarehouse(null);
                setDriver(null);
                setDate(null);
                setWorkListData([]);
                setPickupsData([]);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }


    useEffect(() => {
        fetchWarehouses()
    }, [])

    return <Paper>
        <div>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("driver_work_lists")}</DialogTitle>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onChange={handleWarehouseChange}
                onOpen={fetchWarehouses}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <DateField
                style={{width: "20%", margin: "auto"}}
                label={t("date")}
                value={date}
                onChange={handleDateChange}
                format={"dd/MM/yyyy"}
                disablePast={true}
            />
            <Autocomplete
                id="driver-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={drivers}
                disabled={!warehouse || !date} F
                getOptionLabel={option => option.name + " " + option.surname}
                value={driver}
                defaultValue={driver}
                noOptionsText={t("no_options")}
                onChange={handleDriverChange}
                onOpen={fetchDrivers}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("driver")}
                        variant="outlined"
                        margin="dense"
                        value={driver}
                        defaultValue={driver}
                    />
                )}
            />
            <Autocomplete
                id="wrkList"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={driverWorkLists}
                disabled={!driver}
                getOptionLabel={option => option.id + " del " + new Date(option.dateTime).toLocaleDateString() + " ore " + new Date(option.dateTime).toLocaleTimeString()}
                value={driverWorkList}
                defaultValue={driverWorkList}
                noOptionsText={t("no_options")}
                onChange={handleWorkListChange}
                onOpen={fetchDriverWorkLists}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("driver_work_list")}
                        variant="outlined"
                        margin="dense"
                        value={driverWorkList}
                        defaultValue={driverWorkList}
                    />
                )}
            />
        </div>
        <div>
            {loading ?

                <LoadingComponent/>

                :

                <>
                    {driverWorkList ?
                        <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                            <TableHead>
                                <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                    <TableCell style={{border: "2px solid", width: "25%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}>{t("shipment_details")}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid", width: "25%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}> {t("delivery_address")}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid", width: "5%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}> {t("parcels")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {workListData.map(data => {
                                return <TableRow>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{data.shipment.deliveryAddress.companyName} {data.shipment.deliveryAddress.referencePerson}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{data.shipment.deliveryAddress.street} {data.shipment.deliveryAddress.buildingNr}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"h5"}
                                            fontWeight={"bold"}>{data.shipment.parcels.filter(parcel => parcel.checked).length + "/" + data.shipment.parcels.length}</Typography>
                                    </TableCell>
                                </TableRow>
                            })}
                            <TableHead>
                                <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                    <TableCell style={{border: "2px solid", width: "25%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}>{t("pickup_details")}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid", width: "25%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}> {t("pickup_address")}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid", width: "5%"}}>
                                        <Typography variant={"button"} style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            color: "whitesmoke"
                                        }}> {t("shipments")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {pickupsData.map(data => {
                                return <TableRow>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{t("pickupId")} : {data.pickup.id}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("warehouseCode")} : {data.pickup.warehouseCode}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("contract_code")} : {data.pickup.contractCode}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("pickup_date")} : {new Date(data.pickup.date).toLocaleDateString()}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{data.pickup.address?.companyName} {data.pickup.address?.referencePerson}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{data.pickup.address?.street} {data.pickup.address?.buildingNr}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{data.pickup.address?.postalCode} {data.pickup.address?.city} {data.pickup.address?.administrative_level_3}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"h5"}
                                            fontWeight={"bold"}>{data.pickup.shipments.length}</Typography>
                                    </TableCell>
                                </TableRow>
                            })}
                        </Table>
                        :
                        null
                    }
                </>

            }
        </div>
        <div style={{display: "flex", paddingBottom: "5%"}}>
            {driverWorkList ?
                <Button
                    disabled={!driverWorkList}
                    style={{
                        margin: "auto",
                        marginTop: "3%",
                        width: "15%"
                    }}
                    endIcon={<CheckCircleOutlined/>}
                    onClick={confirmDriverWorkList}
                    color="primary"
                    variant={"contained"}>
                    {t("confirm")}
                </Button>
                :
                null
            }
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={4000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>


    </Paper>

}

export default ConfirmDelayedDriverWorkListPage;