import {DialogTitle, IconButton, Paper, Typography} from "@mui/material";
import {
    Button,
    Card,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {AddCircle, AddCircleOutlineOutlined, CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CountryService from "../../../geo/countries/CountryService";
import AdministrativeLevel3Service from "../../../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../../../geo/cities/CityService";
import PostalCodeService from "../../../geo/postal_codes/PostalCodeService";
import AccountAddressValues from "../../AccountAddressValues";
import ContractService from "../ContractService";
import {useHistory, useParams} from "react-router-dom";
import SubContractService from "./SubContractService";
import AccountService from "../../AccountService";
import {LoadingComponent} from "../../../components/loading/LoadingComponent";
import Notification from "../../../components/notifications/Notification";


export default function SubContractForm(props) {

    const {id} = useParams();

    const history = useHistory();
    const {t} = useTranslation();

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [subContractCode, setSubContractCode] = useState('');

    const [addingAddress, setAddingAddress] = useState(false);

    const [addresses, setAddresses] = useState([]);
    let types = ["REGISTERED_OFFICE", "OPERATING_CENTER"];

    const [countries, setCountries] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);

    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freeCity, setFreeCity] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');
    const [city, setCity] = useState(null);
    const [addressCompanyName, setAddressCompanyName] = useState('');
    const [postalCode, setPostalCode] = useState(null);
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');

    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function cancelAddAddress() {
        setAddingAddress(false);
        clearData();
    }

    function addAddress() {
        if (!country ||
            (!adminL3 && !freeAdministrativeLevel3) ||
            (!postalCode && !freePostalCode) ||
            (!city && !freeCity) ||
            !addressCompanyName ||
            !street
        ) {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage("Inserire tutti i campi obbligatori");
        } else {
            let address = new AccountAddressValues();

            let addressData = {}


            addressData.country = country.iso3166Alpha2;


            if (country.id === 106 || country.id === 67 || country.id === 56) {
                addressData.administrativeLevel3 = adminL3.code;
                addressData.city = city.name;
                addressData.postalCode = postalCode.postalCode;
            } else {
                addressData.administrativeLevel1 = freeAdministrativeLevel1;
                addressData.administrativeLevel2 = freeAdministrativeLevel2;
                addressData.administrativeLevel3 = freeAdministrativeLevel3;
                addressData.city = freeCity;
                addressData.postalCode = freePostalCode;
            }

            addressData.street = street;
            addressData.buildingNr = buildingNr;
            addressData.companyName = addressCompanyName;
            addressData.email = email;
            addressData.phone = phone;
            addressData.mobile = mobile;

            address.address = addressData;

            // addresses.push(address);
            const newAddresses = [...addresses, address];
            setAddresses(newAddresses);

            clearData();
            setAddingAddress(false);
            // setRefresh(!refresh);
        }
    }

    function handleAddressValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.value;
        newAddresses[index].default = false;
        setAddresses(newAddresses);
    }

    function removeAddress(index) {
        let newAddresses = addresses;
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
        // setRefresh(!refresh);
    }

    function handleCheckboxValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.checked;
        for (let i = 0; i < newAddresses.length; i++) {
            if (i !== index && newAddresses[i].type === newAddresses[index].type) {
                newAddresses[i][key] = false;
            }
        }
        setAddresses(newAddresses);
    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function handleStreetChange(event) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event) {
        setBuildingNr(event.target.value)
    }

    function handleAddressCompanyNameChange(event) {
        setAddressCompanyName(event.target.value)
    }

    function handleEmailChange(event) {
        setEmail(event.target.value)
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value)
    }

    function handleMobileChange(event) {
        setMobile(event.target.value)
    }

    function handleSubContractCodeChange(event) {
        setSubContractCode(event.target.value)
    }

    function handleAccountChange(event, account) {
        setAccount(account);
    }

    function handleContractChange(event, contract) {
        setContract(contract)
    }

    function openAddressMask() {
        setAddingAddress(true);
    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);

    }

    function handleAdminL3Change(event, adminL3) {
        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);
    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
    }

    function handlePostalCodeChange(event, postalCode) {
        setPostalCode(postalCode);
    }

    function clearData() {
        setCountry(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setFreeAdministrativeLevel1('');
        setFreeAdministrativeLevel2('');
        setFreeAdministrativeLevel3('');
        setFreeCity('');
        setFreePostalCode('');
        setStreet('');
        setBuildingNr('');
        setAddressCompanyName('');
        setEmail('');
        setMobile('');
        setPhone('');
    }

    function generateAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={admin3List}
                    disabled={!country}
                    getOptionLabel={option => option.name}
                    value={adminL3}
                    defaultValue={adminL3}
                    noOptionsText={t("no_options")}
                    onChange={handleAdminL3Change}
                    onOpen={() => fetchAdminL3(country)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={adminL3}
                            defaultValue={adminL3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!adminL3}
                    getOptionLabel={option => option.name}
                    value={city}
                    defaultValue={city}
                    noOptionsText={t("no_options")}
                    onChange={handleCityChange}
                    onOpen={() => fetchCities(adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            required
                            error={!city}
                            helperText={!adminL3 ? t("select_admin3_first") : (!city ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={postalCode}
                    defaultValue={postalCode}
                    noOptionsText={t("no_options")}
                    onChange={handlePostalCodeChange}
                    onOpen={() => fetchPostalCodes(city, adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={postalCode}
                            defaultValue={postalCode}
                            required
                            error={!postalCode}
                            helperText={!postalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel1}
                        onChange={handleFreeAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel2}
                        onChange={handleFreeAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel3}
                        onChange={handleFreeAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeCity}
                        required
                        error={!freeCity}
                        helperText={!freeCity ? t("required") : null}
                        onChange={handleFreeCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freePostalCode}
                        onChange={handleFreePostalCodeChange}
                        required
                        error={!freePostalCode}
                        helperText={!freePostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL3(country) {
        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, null, null)
            .then(data => {
                setAdmin3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {
        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {
        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result);
            })
            .catch(error => {

            })

    }

    async function fetchContracts() {

        let filters = {};

        if (account) {
            filters.accountId = account.id
        }

        await new ContractService().getContractsByFilters(filters)
            .then(result => {
                setContracts(result)
            })
            .catch(error => {

            })

    }

    async function fetchDataAndHandleEdit() {
        setLoading(true);

        if (!id) {
            setLoading(false);
            return;
        }

        // edit case

        const subContract = await new SubContractService().getSubContractById(id)
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoading(false);
                });
            })

        setAddresses(subContract.addresses);

        setSubContractCode(subContract.code);

        if (subContract.contract) {

            let accountService = new AccountService();
            const acc = await accountService.getAccounts()
            setAccounts(acc);

            for (let i = 0; i < acc.length; i++) {
                if (subContract.contract.accountId === acc[i].id) {
                    setAccount(acc[i]);

                    let contractService = new ContractService();
                    const contr = await contractService.getContractsByFilters({accountId: acc[i].id})
                    setContracts(contr);

                    for (let i = 0; i < contr.length; ++i) {
                        if (subContract.contract.code === contr[i].code) {
                            setContract(contr[i]);
                            break;
                        }
                    }
                    break;
                }
            }
        }

        setLoading(false);
    }

    function handleClose() {
        history.push('/sub-contracts');
    }

    async function handleSubmit() {
        if ('' === subContractCode ||
            null === contract ||
            null === account
        ) {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(t("void_required_fields"));
        } else {

            let subContract = {};
            if (id) {
                subContract.id = id;
            }

            subContract.addresses = addresses;

            subContract.code = subContractCode;
            subContract.contractId = contract.id;


            await new SubContractService().saveSubContract(subContract)
                .then(result => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true);
                    setTimeout(() => {
                        history.goBack();
                    }, 2000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    })
                });
        }
    }

    useEffect(() => {
        fetchCountries();
        fetchDataAndHandleEdit();
    }, []);

    useEffect(() => {
        fetchCountries();
    }, [refresh])

    return <Paper>
        {(loading) ?

            <LoadingComponent/>

            :
            <div>
                <DialogTitle>{t("new_sub_contract")}</DialogTitle>
                <Card style={{width: "60%", margin: "auto"}}>
                    <TextField
                        style={{marginTop: "3%"}}
                        required
                        error={!subContractCode}
                        helperText={!subContractCode ? t("required") : null}
                        label={t("sub_contract_code")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleSubContractCodeChange}
                        value={subContractCode}
                    />
                    <div>
                        <Autocomplete
                            id="accountId"
                            size="small"
                            style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                            options={accounts}
                            getOptionLabel={account => account.accountCorporateCode + " - " + account.companyName}
                            value={account}
                            defaultValue={account}
                            required
                            error={!account}
                            helperText={!account ? t("required") : null}
                            onOpen={fetchAccounts}
                            onChange={handleAccountChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            noOptionsText={t("no_options")}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t("account")}
                                variant="outlined"
                                margin="dense"
                                value={account}
                                defaultValue={account}
                                required
                                error={!account}
                                helperText={!account ? t("required") : null}
                            />}
                        />
                        <Autocomplete
                            id="contractId"
                            size="small"
                            style={{minWidth: "35%", width: "fit-content", marginBottom: "5%", margin: "auto"}}
                            options={contracts}
                            getOptionLabel={contract => contract.code + " - " + contract.name}
                            value={contract}
                            defaultValue={contract}
                            required
                            disabled={!account}
                            error={!contract}
                            helperText={!contract ? t("required") : null}
                            onChange={handleContractChange}
                            onOpen={fetchContracts}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            noOptionsText={t("no_options")}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t("contract")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                                required
                                error={!contract}
                                helperText={!contract ? t("required") : null}
                            />}
                        />
                    </div>
                </Card>
                <Grid item xs={12} style={{marginTop: "5%"}}>
                    <Grid container justifyContent="center" spacing={2}>
                        {addresses.map((address, index) => {
                            return <Grid item style={{width: "30%"}}>
                                <Card>
                                    <FormControl
                                        required
                                        variant={"outlined"}
                                        margin={"dense"}
                                        style={{marginBlock: "5%", width: "70%"}}
                                    >
                                        <InputLabel>{t("address_type")}</InputLabel>
                                        <Select
                                            value={address.type}
                                            required
                                            error={!address.type}
                                            label={t("address_type") + "*"}
                                            onChange={(event) => handleAddressValue(index, event, 'type')}
                                        >
                                            {types.map((type, index) => {
                                                return (
                                                    <MenuItem key={index} value={type}>
                                                        {t(type)}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <IconButton type={"button"} size={"small"} style={{float: "right"}}
                                                onClick={() => removeAddress(index)}>
                                        <CancelOutlined/>
                                    </IconButton>
                                    <div>
                                        <FormControlLabel
                                            label={<Typography fontSize={12}
                                                               variant={"button"}>{t("default_address")}</Typography>}
                                            control={
                                                <Checkbox
                                                    checked={address.default}
                                                    onChange={(event) => handleCheckboxValue(index, event, 'default')}
                                                />
                                            }
                                        />
                                    </div>


                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.companyName}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.street}
                                        {address.address.buildingNr ? ", " + address.address.buildingNr : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.postalCode}
                                        {", " + address.address.city}
                                        {" (" + address.address.administrativeLevel3 + ")"}
                                        {" - " + address.address.country}
                                        {address.address.administrativeLevel1 ? " " + address.address.administrativeLevel1 : ""}
                                        {address.address.administrativeLevel2 ? " " + address.address.administrativeLevel2 : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.email ? "Email: " + address.address.email : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.phone ? "Tel: " + address.address.phone : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"} marginBottom={"2%"}>
                                        {address.address.mobile ? "Cell: " + address.address.mobile : ""}
                                    </Typography>
                                </Card>
                            </Grid>
                        })}
                    </Grid>
                    {!addingAddress ?
                        <div>
                            <Button
                                style={{marginBlock: "2%"}}
                                endIcon={<AddCircleOutlineOutlined/>}
                                onClick={openAddressMask}
                                variant={"contained"}>
                                {t("add_address")}
                            </Button>
                        </div>
                        :

                        <Card style={{width: "60%", margin: "auto", marginTop: "5%"}}>
                            <Autocomplete
                                id="country"
                                size="small"
                                style={{
                                    minWidth: "30%",
                                    width: "fit-content",
                                    marginBlock: "4%",
                                    marginLeft: "2%"
                                }}
                                options={countries}
                                getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                value={country}
                                defaultValue={country}
                                onChange={handleCountryChange}
                                onSelect={fetchCountries}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("country")}
                                    variant="outlined"
                                    margin="dense"
                                    value={country}
                                    defaultValue={country}
                                    required
                                    error={!country}
                                    helperText={!country ? t("required") : null}
                                />}
                            />

                            {generateAddress(country)}

                            <div style={{display: "flex", marginBlock: "5%"}}>
                                {/*<div style={{display: "flex", marginBlock: "2%"}}>*/}
                                <TextField
                                    required
                                    error={!addressCompanyName}
                                    helperText={!addressCompanyName ? t("required") : null}
                                    style={{marginInline: "2%"}}
                                    label={t("address_company_name")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={addressCompanyName}
                                    onChange={handleAddressCompanyNameChange}
                                />
                                {/*</div>*/}
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("street")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={street}
                                    required
                                    error={!street}
                                    helperText={!street ? t("required") : null}
                                    onChange={handleStreetChange}
                                />
                                <TextField
                                    style={{marginInline: "2%", width: "10%"}}
                                    label={t("buildingNr")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={buildingNr}
                                    onChange={handleBuildingNrChange}
                                />
                            </div>
                            <div style={{display: "flex", marginBottom: "5%"}}>
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("email")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("phone")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("mobile")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={mobile}
                                    onChange={handleMobileChange}
                                />
                            </div>
                            <div>
                                <Button
                                    style={{margin: "4%"}}
                                    endIcon={<CancelOutlined/>}
                                    onClick={cancelAddAddress}
                                    variant={"contained"}>
                                    {t("cancel")}

                                </Button>
                                <Button
                                    style={{margin: "4%"}}
                                    endIcon={<AddCircle/>}
                                    onClick={addAddress}
                                    variant={"contained"}>
                                    {t("add")}

                                </Button>
                            </div>
                        </Card>
                    }
                </Grid>
                <CardActions style={{width: "50%", margin: "auto"}}>
                    <Button
                        disabled={addingAddress}
                        style={{margin: "auto"}}
                        endIcon={<CancelOutlined/>}
                        onClick={handleClose}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        style={{margin: "auto"}}
                        disabled={addingAddress}
                        endIcon={<CheckCircleOutlined/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>
            </div>
        }
    </Paper>


}