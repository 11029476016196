import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


const userService = new UserService();


export default class WarehouseStorageService {

    getFilteredWarehouseStorages(filters) {

        const lang = localStorage.getItem("i18nextLng");
        const apiKey = userService.getApiKey();
        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/warehouse-storages`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getCsv(filters) {

        const apiKey = userService.getApiKey();
        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    createWarehouseStorage(input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                shipment: input.shipment,
                reasonId: input.reasonId,
                warehouseCode: input.warehouseCode,
                notes: input.notes
            })
        };
        let pathVariable = `/api/v1/warehouse-storages`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getWarehouseStorageById(warehouseStorageId, key) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let queryString = "";

        if(key){
             queryString = `?key=` + key;
        }

        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}`;

        pathVariable += queryString;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    cancelWarehouseStorage(warehouseStorageId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getProvisionByWarehouseStorageId(warehouseStorageId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}/provision`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    saveNewProvision(id, guestKey, input) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                releaseModeId: input.releaseModeId,
                newDeliveryAddress: input.deliveryAddress,
                newEndUserAddress: input.endUserAddress,
                newServiceCode: input.serviceCode,
                notes: input.notes
            })
        };
        let pathVariable = `/api/v1/warehouse-storages/${id}/provision`;

        if(guestKey !== null && "" !== guestKey){
            pathVariable += `?key=${guestKey}`
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getReleaseModes(id) {

        const apiKey = userService.getApiKey();
        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/release/modes`;

        if(id){
            pathVariable += `/?warehouseStorageId=${id}`;
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getReleaseModesForWarehouseStorage(id) {

        const apiKey = userService.getApiKey();
        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/${id}/release/modes`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getWarehouseStorageLabel(warehouseId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/${warehouseId}/label`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getWarehouseStorageLabels(input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                input
            )
        };
        let pathVariable = `/api/v1/warehouse-storages/labels`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getWarehouseStorageReasons() {

        const apiKey = userService.getApiKey();
        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/warehouse-storages/reasons`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    updateWarehouseStorage(warehouseStorageId, input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                reasonId: input.reasonId,
                warehouseCode: input.warehouseCode,
                openDossierDate: input.openDossierDate,
                notes: input.notes
            })
        };
        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}/update`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    doRelease(warehouseStorageId, data) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        };
        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}/release`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getDocumentTypes() {

        const apiKey = userService.getApiKey();
        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/document-types`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getReturnAddressesForShipment(shipmentID){

        const apiKey = userService.getApiKey();
        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };

        let pathVariable = `/api/v1/returns/${shipmentID}/addresses`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCollectionDocument(warehouseStorageId){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/warehouse-storages/${warehouseStorageId}/document`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }
}