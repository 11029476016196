import React, {useEffect, useRef, useState} from "react";
import {
    Autocomplete,
    Avatar,
    Button,
    Card,
    CircularProgress,
    DialogTitle,
    Divider,
    ListItemAvatar,
    TextField,
    Typography
} from "@mui/material";
import DriverWorkListService from "./DriverWorkListService";
import DateField from "../components/fields/DateField";
import {useTranslation} from "react-i18next";
import WarehouseService from "../warehouses/WarehouseService";
import {format} from "date-fns";
import DriverService from "../drivers/DriverService";
import {ShareLocationOutlined} from "@mui/icons-material";
import theme from "../theme/theme";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatAddressFirstLine, formatAddressSecondLine} from "../utils/utils";
import {GoogleMap, InfoWindow, LoadScript, Marker, Polyline, useLoadScript} from "@react-google-maps/api";
import variables from "../configuration/variables.json";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import MapViewPolyline from "react-native-web-maps/dist/Polyline";
import {Wrapper} from "@googlemaps/react-wrapper";


function OptimizeDriverWorkList(props) {

    const {t} = useTranslation();

    const [coordinates, setCoordinates] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [date, setDate] = useState(new Date());

    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);

    const [driverWorkList, setDriverWorkList] = useState(null);
    const [driverWorkLists, setDriverWorkLists] = useState([]);

    const [optimizedWorkList, setOptimizedWorkList] = useState(null);

    const [loadingOptimize, setLoadingOptimize] = useState(false);

    const apiKey = variables.googleAPIKey;

    const mapRef = useRef(null);

    const [zoom, setZoom] = useState(5); // Initial zoom level

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        if (null == warehouse) {
            setDriverWorkList(null);
            setDriver(null);
            setDate(null)
        }
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
        if (null == driver) {
            setDriverWorkList(null);
        }
    }

    function handleDateChange(event) {
        setDate(event);
        setDriver(null)
        setDriverWorkList(null)
    }

    async function handleWorkListChange(event, workList) {
        setDriverWorkList(workList);

        if (workList == null) {

        } else {
            await getOptimizedDriverWorkList(workList.id);
        }
    }
    async function getOptimizedDriverWorkList(id) {

        setLoadingOptimize(true);

        setCoordinates([]);

        await new DriverWorkListService().getOptimizedWorkList(id)
            .then(result => {
                setOptimizedWorkList(result);

                let newCoordinates = [];

                for (let i = 0; i < result.optimizedTour.length; i++) {
                    newCoordinates.push({
                        lat: result.optimizedTour[i].address.latitude,
                        lng: result.optimizedTour[i].address.longitude
                    })
                }

                setCoordinates(newCoordinates);
                setZoom(5);

                console.log(newCoordinates);
                setLoadingOptimize(false);

            })
            .catch(error => {

                setLoadingOptimize(false);
            })


    }

    async function optimizeDriverWorkList() {

        setLoadingOptimize(true);

        if (driverWorkList) {

            await new DriverWorkListService().optimizeDriverWorkList(driverWorkList.id)
                .then(async result => {
                    await getOptimizedDriverWorkList(driverWorkList.id);
                    setLoadingOptimize(false);
                })
                .catch(error => {
                    setLoadingOptimize(false);
                })

        }

    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
        if (wares?.warehouses?.length === 1) {
            setWarehouse(wares.warehouses[0]);
        }
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }

    async function fetchDriverWorkLists() {
        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (driver) {
            filters.driverId = driver.id;
        }
        filters.date = format(date, "yyyy-MM-dd");

        let worklists = await new DriverWorkListService().getDriverWorkListsForClosure(filters);
        setDriverWorkLists(worklists.driverWorkLists);
    }

    useLoadScript({
        googleMapsApiKey: variables.googleAPIKey // ,
        // ...otherOptions
    })


    // function renderMap(){
    //     return <LoadScript googleMapsApiKey={apiKey}>
    //         <GoogleMap
    //             mapContainerStyle={{ width: '100%', height: '400px' }}
    //             zoom={zoom}
    //             defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
    //             ref={mapRef}
    //         >
    //             {coordinates.map((coord, index) => (
    //                 <Marker key={index} position={coord} />
    //             ))}
    //             {coordinates.length >= 2 && (
    //                 <Polyline
    //                     path={coordinates}
    //                     options={{
    //                         strokeColor: '#FF0000',
    //                         strokeOpacity: 1.0,
    //                         strokeWeight: 2,
    //                     }}
    //                 />
    //             )}
    //
    //         </GoogleMap>
    //     </LoadScript>
    // }

    const renderMap = () => {

        return <GoogleMap
            zoom={10}
            center={coordinates[0]}
            mapContainerStyle={{width: "100%", height: 800}}
            // ref={mapRef}
        >
            {coordinates.map((tourItem, i) => {
                return <Marker key={i}
                               position={tourItem}
                               icon={{url: '/icons/truckMarker.png', scale: 7}}
                >
                </Marker>
            })}
            {coordinates.length >= 2 && (
                <Polyline
                    path={coordinates}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    }}
                />
            )}

        </GoogleMap>

    }

    useEffect(() => {
        if (mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            mapRef.current.fitBounds(bounds);
        }
    }, [coordinates]);


    // useEffect(() => {
    //     fetchWarehouses();
    // }, []);

    return (
        <>
            <Card>
                <DialogTitle
                    style={{width: "fit-content", margin: "auto"}}>{t("optimize_driver_work_list")}</DialogTitle>
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={handleWarehouseChange}
                    onOpen={fetchWarehouses}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("date")}
                    value={date}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                />
                <Autocomplete
                    id="driver-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={drivers}
                    disabled={!warehouse || !date} F
                    getOptionLabel={option => option.name + " " + option.surname}
                    value={driver}
                    defaultValue={driver}
                    noOptionsText={t("no_options")}
                    onChange={handleDriverChange}
                    onOpen={fetchDrivers}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver")}
                            variant="outlined"
                            margin="dense"
                            value={driver}
                            defaultValue={driver}
                        />
                    )}
                />
                <Autocomplete
                    id="wrkList"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={driverWorkLists}
                    disabled={!driver}
                    getOptionLabel={option => (option.delayed ? option.id + "/D" : option.id) + " del " + new Date(option.dateTime).toLocaleDateString() + " ore " + new Date(option.dateTime).toLocaleTimeString()}
                    value={driverWorkList}
                    defaultValue={driverWorkList}
                    noOptionsText={t("no_options")}
                    onChange={handleWorkListChange}
                    onOpen={fetchDriverWorkLists}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver_work_list")}
                            variant="outlined"
                            margin="dense"
                            value={driverWorkList}
                            defaultValue={driverWorkList}
                        />
                    )}
                />

                <div style={{display: "flex"}}>
                    <Button disabled={!driverWorkList || loadingOptimize}
                            style={{
                                margin: "auto",
                                marginBlock: "4%",
                                backgroundColor: !driverWorkList ? "grey" : theme.palette.primary.main
                            }}
                            variant={"contained"}
                            startIcon={loadingOptimize ? <CircularProgress style={{color: "white"}} size={20}/> :
                                <ShareLocationOutlined/>}
                            onClick={optimizeDriverWorkList}
                    >{t("optimize")}</Button>
                </div>
                {/*<button onClick={updateCoordinates}>Update Coordinates</button>*/}
                {loadingOptimize ?
                    <div>
                        {/*<LoadingComponent/>*/}
                    </div>
                    :
                    <>
                        {optimizedWorkList ?
                            <div>
                                {renderMap()}
                            </div>
                            :
                            null
                        }
                    </>
                }

                {loadingOptimize?

                    <LoadingComponent/>

                    :

                    <div style={{width: "fit-content", margin: "auto"}}>
                        <Divider/>
                        <List>
                            {optimizedWorkList?.optimizedTour?.map((item, index) => {
                                return <>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt={index + 1}>{index + 1}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.shipmentReference ? item.shipmentReference : (item.pickupReference ? item.pickupReference : t("warehouse") + " " + optimizedWorkList.warehouseCode)}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{display: 'inline'}}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {formatAddressFirstLine(item.address)}
                                                    </Typography>
                                                    {" - " + formatAddressSecondLine(item.address)}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </>
                            })}
                        </List>
                    </div>
                }

            </Card>
        </>
    )


}

export default OptimizeDriverWorkList;