import React, {useEffect, useState} from "react";
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import {
    Card,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import ChequePaymentToCustomerService
    from "./ChequePaymentToCustomerService";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import ChequePaymentToCustomer from "./ChequePaymentToCustomer";
import {IconButton, Paper, TableRow} from "@mui/material";
import Notification from "../../components/notifications/Notification";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import StyledButton from "../../components/buttons/StyledButton";
import {FiberNew} from "@mui/icons-material";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function ChequePaymentsToCustomersTable(props) {


    const [chequePayments, setChequePayments] = useState([]);

    const [loading, setLoading] = useState(false);

    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const history = useHistory();

    const {t} = useTranslation();


    async function fetchChequePayments() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        await new ChequePaymentToCustomerService().getChequePaymentsByFilters()
            .then(response => {
                setChequePayments(response.chequePayments);
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function operationSuccessfulCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

    }
    function failureCallback(message) {

        setNotificationMessage(message.status + " - " + message.message);
        setNotifySeverity('error');
        setOpenNotify(true);

    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function goToNewPaymentToCustomer(){
        history.push("/cheque/payments/new");
    }



    useEffect(() => {
        fetchChequePayments();
    }, [page, rowsPerPage])


    return <div style={{width: '101%',
        textAlign: 'center',
        marginBottom: "15%"}}>

        <div style={{width: "100%"}}>
            <StyledButton onClick={goToNewPaymentToCustomer}
                          icon={<FiberNew/>}>{t("new_cheque_payment_to_customer")}</StyledButton>
        </div>


        {loading ?
            <LoadingComponent/>
            :
            <Paper style={{width: "90%", margin: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t("ID")}
                            </TableCell>
                            <TableCell>
                                {t("contract")}
                            </TableCell>
                            <TableCell>
                                {t("company")}
                            </TableCell>
                            <TableCell>
                                {t("shipment")}
                            </TableCell>
                            <TableCell>
                                {t("datetime")}
                            </TableCell>
                            <TableCell>
                                {t("amount")}
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chequePayments.map(payment => {

                            return <ChequePaymentToCustomer payment={payment} successCallback={operationSuccessfulCallback} failureCallback={failureCallback}/>

                        })}
                    </TableBody>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={-1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: false,
                        }}
                        labelRowsPerPage={t("rows_per_page")}
                        labelDisplayedRows={() => {
                            return t("page")
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        onPageChange={handleChangePage}/>
                </Table>
            </Paper>
        }

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </div>


}

export default ChequePaymentsToCustomersTable;