import React, {useCallback, useEffect, useRef, useState,} from 'react';
import moment from 'moment';
import {Card, DialogTitle, Modal, Typography} from "@mui/material";
import {Backdrop, Fade, Paper} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Refresh, Restore} from "@material-ui/icons";
import Cookies from "universal-cookie/es6";

function SessionTimeout(props) {

    const [events, setEvents] = useState(['click', 'load', 'scroll']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const cookies = new Cookies();

    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem('apiKey'));

    let timeStamp = moment();
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();

    const history = useHistory();
    const {t} = useTranslation();

    // start inactive check
    let timeChecker = () => {

        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = cookies.get('lastTimeStamp');
            warningInactive(storedTimeStamp);
        }, 60000);
    };

    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        cookies.remove('lastTimeStamp');
    }

    // warning timer
    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current);

        warningInactiveInterval.current = setInterval(() => {

            if (!cookies.get('lastTimeStamp')) {
                setOpen(true);
            }

        }, 1000);
    };

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if (isAuthenticated && !!cookies.get('lastTimeStamp')) {
            timeStamp = moment();
            // cookies.remove('lastTimeStamp');
            cookies.set('lastTimeStamp', timeStamp, {maxAge: 60*60});
        } else {
            clearInterval(warningInactiveInterval.current);
            cookies.remove('lastTimeStamp');
        }

        timeChecker();
        setOpen(false);
    }, [isAuthenticated]);

    // handle close popup
    const handleClose = () => {
        setOpen(false);
        logout();
        history.push("/login")
    };

    useEffect(()=>{
        if (!cookies.get('lastTimeStamp')) {
            handleClose();
        }
    }, [])

    useEffect(() => {

        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        timeChecker();

        return () => {
            clearTimeout(startTimerInterval.current);
            //   resetTimer();
        };
    }, [resetTimer, events, timeChecker]);

    if (!isOpen) {
        return null;
    }

    // change fragment to modal and handleclose func to close
    return <Modal
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        closeAfterTransition
        disableEnforceFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={isOpen}>
            <Paper elevation={2} style={{
                width: "25%",
                marginInline: "auto",
                marginTop: "20%",
                height: "15%",
                overflow: "hidden"
            }}>
                <div style={{width: "fit-content", margin: "auto", display: "flex", marginTop: "20%"}}>
                    <Restore/><Typography
                        variant={"button"}
                        style={{}}>{t("session_expired")}</Typography>
                </div>
            </Paper>
        </Fade>

    </Modal>
};

export default SessionTimeout;
