import {CircularProgress, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import TripService from "../trips/TripService";
import moment from "moment/moment";
import {Button, IconButton, Typography} from "@mui/material";
import {Autorenew, Cloud, Edit} from "@material-ui/icons";
import {Download} from "@mui/icons-material";
import MenuIcon from "@material-ui/icons/Menu";
import Notification from "../../components/notifications/Notification";
import {MinimalLoadingComponent} from "../../components/loading/MinimalLoadingComponent";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import theme from "../../theme/theme";


function SummaryItem(props) {

    let trip = props.trip;
    let warehouseCode = props.warehouseCode;

    const [summaryDate, setSummaryDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState(null);

    const [generatingSummary, setGeneratingSummary] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const {t} = useTranslation();

    async function generateSummaryOut() {

        setGeneratingSummary(true);

        let input = {};

        input.dateTime = moment(summaryDate).format('YYYY-MM-DDTHH:mm:ssZ');
        input.warehouseCode = warehouseCode;
        input.tripId = trip.id;

        await new TripService().generateSummaryOut(input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setTimeout(() => {
                    setGeneratingSummary(false);
                    props.refresh();
                }, 1500)
            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setGeneratingSummary(false);
                    }
                )
            })
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    async function downloadSummary(id, reduced) {

        await new TripService().downloadSummary(id, reduced)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    useEffect(() => {

    })


    return <TableRow>
        <TableCell>{trip.id}</TableCell>
        <TableCell>{trip.routeName}</TableCell>
        <TableCell>{trip.arrivalWarehouseCode}</TableCell>
        <TableCell>{new Date(trip.departureDateTime).toLocaleDateString()}</TableCell>
        <TableCell>
            <Typography variant={"inherit"}>{trip.supplierAcc}</Typography>
            <Typography variant={"inherit"}>{trip.supplierName}</Typography>
        </TableCell>
        <TableCell>{trip.plateNumber}</TableCell>
        <TableCell>{trip.driverName}</TableCell>
        <TableCell>
            {trip.canGenerateSummary === true ?
                <MenuItem
                    disabled={generatingSummary}
                    onClick={generateSummaryOut}>{generatingSummary? <CircularProgress style={{color: "black"}} size={20}/> : <Autorenew/>}&nbsp;{t("generate_summary_out")}</MenuItem>
                :
                null
            }
            {trip.summaries.map(summary => {
                return <div>
                    <MenuItem
                        onClick={() => downloadSummary(summary, false)}
                    ><Download/>&nbsp;{t("download_summary")}&nbsp;{"N."}&nbsp;{summary}</MenuItem>
                    <MenuItem
                        onClick={() => downloadSummary(summary, true)}
                    ><Download/>&nbsp;{t("download_reduced_summary")}&nbsp;{"N."}&nbsp;{summary}</MenuItem>
                </div>
            })}
        </TableCell>
        <Notification open={openNotify} severity={notifySeverity} duration={15000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </TableRow>


}

export default SummaryItem;