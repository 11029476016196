import {Button, Menu, MenuItem, TableCell} from "@material-ui/core";
import {CircularProgress, TableRow, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PaymentSlipsService from "../payments/PaymentSlipsService";
import MenuIcon from "@material-ui/icons/Menu";
import {Download} from "@mui/icons-material";
import ChequeForwardingService from "./ChequeForwardingService";


function ChequeForwarding(props) {

    const [loadingCSV, setLoadingCSV] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const {t} = useTranslation();

    let cheque = props.cheque;

    function calculateTotal(cods) {

        let total = 0;

        for (let i = 0; i < cods.length; i++) {
            total += cods[i].value;
        }

        return total;

    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    async function getCsv() {

        setLoadingCSV(true);

        await new ChequeForwardingService().getCsv(cheque.id)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })

        setLoadingCSV(false);
    }

    async function getPdf() {

        setLoadingPDF(true);

        await new ChequeForwardingService().getPdf(cheque.id)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingPDF(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.failureCallback(response);
                })
            })

        setLoadingPDF(false);
    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }

    return <TableRow>
        <TableCell>
            {cheque.id}
        </TableCell>
        <TableCell>
            {cheque.warehouseCode}
        </TableCell>
        <TableCell>
            <Typography style={{
                cursor: "pointer",
                transition: "all 0.2s",
                color: "#144dff",
                '& > *': {
                    borderBottom: 'unset',
                }
            }} onClick={() => goToAboutMe(cheque.shipmentId)}>
                {cheque.shipmentId}
            </Typography>

        </TableCell>
        <TableCell>
            {new Date(cheque.createdAt).toLocaleDateString() + " - " + new Date(cheque.createdAt).toLocaleTimeString()}
        </TableCell>
        <TableCell>
            {calculateTotal(cheque.cods).toFixed(2)}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={getCsv}>{loadingCSV ? <CircularProgress size={24}/> :
                    <Download/>}&nbsp;{t("download_csv")}</MenuItem>
                <MenuItem onClick={getPdf}>{loadingPDF ? <CircularProgress size={24}/> :
                    <Download/>}&nbsp;{t("download_pdf")}</MenuItem>

            </Menu>
        </TableCell>
    </TableRow>


}

export default ChequeForwarding;