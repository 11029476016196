import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DialogTitle, TableRow} from "@mui/material";
import {TableCell, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

export default function RateServiceParcelCategoryModelSlot(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.slot);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    function onChangeMaxWeight(event, value) {
        const newState = {...state, maxWeight: value};
        setNewState(newState);
    }

    function onChangeMaxHeight(event, value) {
        const newState = {...state, maxHeight: value};
        setNewState(newState);
    }

    function onChangeMaxLength(event, value) {
        const newState = {...state, maxLength: value};
        setNewState(newState);
    }

    function onChangeMaxDepth(event, value) {
        const newState = {...state, maxDepth: value};
        setNewState(newState);
    }

    function onChangeFee(event, value) {
        const newState = {...state, fee: value};
        setNewState(newState);
    }

    return <div style={{width: "100%"}}>
        <TableRow>
            <TableCell>
                <CurrencyTextField
                    variant="outlined"
                    margin="normal"
                    required
                    label={t("max_weight")}
                    value={state.maxWeight}
                    error={!state.maxWeight}
                    currencySymbol={"Kg"}
                    textAlign={"left"}
                    decimalPlaces={2}
                    minimumValue={0}
                    outputFormat="string"
                    digitGroupSeparator={"."}
                    decimalCharacter={","}
                    onChange={onChangeMaxWeight}
                />
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    variant="outlined"
                    margin="normal"
                    required
                    label={t("max_height")}
                    value={state.maxHeight}
                    error={!state.maxHeight}
                    currencySymbol={"cm"}
                    textAlign={"left"}
                    decimalPlaces={0}
                    minimumValue={0}
                    outputFormat="string"
                    digitGroupSeparator={"."}
                    decimalCharacter={","}
                    onChange={onChangeMaxHeight}
                />
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    variant="outlined"
                    margin="normal"
                    required
                    label={t("max_length")}
                    value={state.maxLength}
                    error={!state.maxLength}
                    currencySymbol={"cm"}
                    textAlign={"left"}
                    decimalPlaces={0}
                    minimumValue={0}
                    outputFormat="string"
                    digitGroupSeparator={"."}
                    decimalCharacter={","}
                    onChange={onChangeMaxLength}
                />
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    variant="outlined"
                    margin="normal"
                    required
                    label={t("max_depth")}
                    value={state.maxDepth}
                    error={!state.maxDepth}
                    currencySymbol={"cm"}
                    textAlign={"left"}
                    decimalPlaces={0}
                    minimumValue={0}
                    outputFormat="string"
                    digitGroupSeparator={"."}
                    decimalCharacter={","}
                    onChange={onChangeMaxDepth}
                />
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    variant="outlined"
                    margin="normal"
                    required
                    label={t("fee")}
                    value={state.fee}
                    error={!state.fee}
                    currencySymbol={null}
                    textAlign={"left"}
                    minimumValue={0}
                    outputFormat="string"
                    digitGroupSeparator={"."}
                    decimalCharacter={","}
                    onChange={onChangeFee}
                />
            </TableCell>
        </TableRow>
    </div>;
}