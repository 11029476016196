import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import DeleteCodEventRequestsService from "./DeleteCodEventRequestsService";
import {AppStyles} from "../../theme/AppStyles";
import {
    Button,
    Card, Menu,
    MenuItem,
    Paper,
    Select,
    TableBody,
    TableCell,
    TablePagination,
    useTheme
} from "@material-ui/core";
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    Table,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {formatDateTime} from "../../utils/utils";
import CodService from "../../shipments/services/CodService";
import CodEventService from "../../shipments/services/CodEventService";
import Notification from "../../components/notifications/Notification";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import StyledButton from "../../components/buttons/StyledButton";
import IconButton from "@material-ui/core/IconButton";
import {
    CancelOutlined,
    CheckCircleOutlined,
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    SearchOutlined
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import theme from "../../theme/theme";
import InfoChip from "../../components/chips/InfoChip";
import CheckChip from "../../components/chips/CheckChip";
import WarehouseService from "../../warehouses/WarehouseService";
import {Download} from "@mui/icons-material";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";


function DeleteCodEventRequestTable(props) {

    const usePaginationStyles = makeStyles((theme) => ({
        root: {
            margin: "auto",
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
        menuPaper: {
            maxHeight: 250
        }
    }));

    const {t} = useTranslation();

    const classes = AppStyles();

    const [fetching, setFetching] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [showAlsoCompleted, setShowAlsoCompleted] = useState(false);


    const [pages, setPages] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    const [refresh, setRefresh] = useState(false);

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');


    const [requests, setRequests] = useState([]);

    function handleWarehouseChange(event, warehouse){
        setWarehouse(warehouse);
    }

    function handleShowAlsoCompletedChange(event) {
        setShowAlsoCompleted(event.target.checked);
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchRequests() {

        setFetching(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if (!showAlsoCompleted) {
            filters.completed = false;
        }

        if(warehouse){
            filters.warehouseId = warehouse.id;
        }

        await new DeleteCodEventRequestsService().getRequestsByFilters(filters)
            .then(results => {
                setRequests(results.deleteCodEventRequests);
                setPages(Array.from(Array(Math.ceil(results.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setFetching(false);
                })
            })

    }

    async function confirmRequest(requestId) {

        await new DeleteCodEventRequestsService().confirmDeleteCodEventIdRequest(requestId)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setRefresh(!refresh)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }

    async function cancelRequest(requestId) {

        await new DeleteCodEventRequestsService().cancelDeleteCodEventIdRequest(requestId)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setRefresh(!refresh)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }

    function search() {
        setRefresh(!refresh)
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function clearFilters() {

        setShowAlsoCompleted(false);

        setRefresh(!refresh);

    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    useEffect(() => {
        fetchRequests();
    }, [refresh, page, rowsPerPage])


    return <div className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <Card>
            <DialogTitle>{t("delete_cod_events_requests")}</DialogTitle>
            <ConfirmDialog open={openConfirm}
                           title={"ATTENZIONE!"}
                           confirmText={t("confirm")}
                           cancelText={t("cancel")}
                           onConfirm={confirmRequest}
                           onCancel={() => setOpenConfirm(false)}>
                {t("confirm_delete")}
            </ConfirmDialog>

            <Autocomplete
                id="warehouse-select"
                size="small"
                options={warehouses}
                style={{marginTop: "4%", width: "25%", margin: "auto"}}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <FormControlLabel
                style={{marginTop: "5%"}}
                label={<Typography fontSize={12}
                                   variant={"button"}>{t("show_completed_requests_too")}</Typography>}
                control={
                    <Checkbox
                        style={{color: theme.palette.primary.main}}
                        checked={showAlsoCompleted}
                        onChange={handleShowAlsoCompletedChange}
                    />
                }
                labelPlacement={"right"}
            />
            <div style={{marginTop: "5%"}}>
                <IconButton onClick={search}
                            disabled={fetching}
                            style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{fetching ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>

        {fetching ?
            <LoadingComponent/>

            :
            <Paper>
            {requests.length > 0?
                <Table style={{marginTop: "10%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("shipment_id")}</Typography>
                            <Typography variant={"subtitle2"}>{t("reference")}</Typography>
                            <Typography variant={"subtitle2"}>{t("contract")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("request_type")}</Typography>
                            <Typography variant={"subtitle2"}>{t("request_date_time")}</Typography>
                            <Typography variant={"subtitle2"}>{t("request_user")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("warehouse")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("amount")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("event_code")}</Typography>
                            <Typography variant={"subtitle2"}>{t("new_event_code")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("completion_dateTime")}</Typography>
                            <Typography variant={"subtitle2"}>{t("completion_user")}</Typography>
                        </TableCell>
                        <TableCell/>
                    </TableRow>

                </TableHead>
                <TableBody>
                    {requests?.map(request => {
                        return <TableRow>
                            <TableCell>
                                <Typography>{request.shipmentId}</Typography>
                                <Typography>{request.shipmentReference}</Typography>
                                <Typography>{request.contractName}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{request.newCodEventCodeRequested? t("cod_collection_variation") : t("delete_event")}</Typography>
                                <Typography>{formatDateTime(request.createdAt)}</Typography>
                                <Typography>{request.user}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{request.warehouseName}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{request.codValue?.toFixed(2)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{"• " + request.codEventCode?.name}</Typography>
                                {request.newCodEventCodeRequested ?
                                    <Typography>{"• " + request.newCodEventCodeRequested?.name}</Typography>
                                    :
                                    null
                                }
                            </TableCell>
                            <TableCell>
                                <Typography>{formatDateTime(request.completionDateTime)}</Typography>
                                <Typography>{request.completionUser}</Typography>
                            </TableCell>
                            <TableCell>
                                {request.completionUser ?
                                    <CheckChip>{t("request_completed")}</CheckChip>
                                    :

                                    <div style={{display: "flex"}}>
                                        <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                                            <MenuIcon/>
                                        </Button>
                                        <Menu
                                            id="role-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => confirmRequest(request.id)}><CheckCircleOutlined/>{t("execute")}</MenuItem>
                                            <MenuItem onClick={() => cancelRequest(request.id)}><CancelOutlined/>{t("cancel")}</MenuItem>
                                        </Menu>
                                        {/*<Button*/}
                                        {/*    style={{margin: "auto"}}*/}
                                        {/*    endIcon={<CheckCircleOutlined/>}*/}
                                        {/*    onClick={() => confirmRequest(request.id)}>{t("execute")}</Button>*/}
                                        {/*<Button*/}
                                        {/*    style={{margin: "auto"}}*/}
                                        {/*    endIcon={<CancelOutlined/>}*/}
                                        {/*    onClick={() => cancelRequest(request.id)}>{t("cancel")}</Button>*/}
                                    </div>

                                }
                            </TableCell>
                        </TableRow>

                    })}
                </TableBody>
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    count={-1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // SelectProps={{
                    //     inputProps: {'aria-label': 'rows per page'},
                    //     variant: "outlined"
                    //
                    // }}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        variant: "outlined",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        classes: {paper: classes.menuPaper}
                    }}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={() => {
                        return t("page")
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Table>
                :
                <InfoChip>{t("no_data")}</InfoChip>
            }
            </Paper>

        }


    </div>


}

export default DeleteCodEventRequestTable;
