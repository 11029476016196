import theme from "../../theme/theme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Button} from "@material-ui/core";
import React from "react";


function StyledButton(props){


    return <Button endIcon={props.endIcon} disabled={props.disabled} style={{margin: "5%", backgroundColor: props.disabled? "grey" : theme.palette.primary.main, color: "white"}}
            onClick={props.onClick}>{props.icon}&nbsp;{props.children}</Button>



} export default StyledButton;