import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import UserService from "./UserService";
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardActions,
    Chip,
    Container,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel, Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem, Paper,
    Select,
    Switch, Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import RoleService from "../roles/RoleService";
import IconButton from "@material-ui/core/IconButton";
import {
    ArrowBackRounded,
    CancelOutlined,
    CheckCircleOutlined,
    SaveOutlined,
    Visibility,
    VisibilityOff
} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";
import AccountService from "../accounts/AccountService";
import {Autocomplete} from "@material-ui/lab";
import ContractService from "../accounts/contracts/ContractService";
import SubContractService from "../accounts/contracts/sub-contracts/SubContractService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Typography} from "@mui/material";
import WarehouseService from "../warehouses/WarehouseService";
import theme from "../theme/theme";


function UserForm(props) {

    const user = props.user;
    const {id} = useParams();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [rolesList, setRolesList] = useState([]);

    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [contractList, setContractList] = useState([]);
    const [contractSelected, setContractSelected] = useState(null);
    const [subContractList, setSubContractList] = useState([]);
    const [subContractSelected, setSubContractSelected] = useState(null);
    const [contracts, setContracts] = useState([]);
    let inputContracts = [];

    const [warehouses, setWarehouses] = useState([]);
    const [warehousesList, setWarehousesList] = useState([]);
    const [warehouseSelected, setWarehouseSelected] = useState(null);
    let inputWarehouses = [];

    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [newUserRoles, setNewUserRoles] = useState([]);
    const [enabled, setEnabled] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [fieldShow, setFieldShow] = useState(false);
    const [contractShow, setContractShow] = useState(false);
    const [warehouseShow, setWarehouseShow] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);

    let permissions = localStorage.getItem("permissions");

    const location = useLocation();

    const theme = useTheme();
    const history = useHistory();
    const {t} = useTranslation();

    let userId = props.userId;

    if (!userId) {
        userId = id;
    }

    function handleAccountChange(event, account) {
        setSelectedAccount(account);
    }

    function handleContractChange(event, contract) {
        setContractSelected(contract);
    }

    function handleSubContractChange(event, subContract) {
        setSubContractSelected(subContract);
    }

    function cancelContractSelection() {
        setContractShow(false);
    }

    function cancelWarehouseSelection() {
        setWarehouseShow(false);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouseSelected(warehouse)
    }

    function saveTempWarehouseSelection() {
        let newSelection = {};

        if (null != warehouseSelected) {
            newSelection.warehouse = warehouseSelected;
        }

        console.log(warehouses);

        if (checkWarehousesAlreadySelected(warehouses, newSelection)) {
            setNotificationMessage(t("selection_conflict"));
            setNotifySeverity("error")
            setOpenNotify(true);
        } else {
            warehouses.push(newSelection);
            setWarehouseShow(false);
            setRefresh(!refresh);
        }

    }


    function saveTempContractSelection() {
        let newSelection = {};

        if (null != selectedAccount) {
            newSelection.account = selectedAccount;
        }
        if (null != contractSelected) {
            newSelection.contract = contractSelected;
        }
        if (null != subContractSelected) {
            newSelection.subContract = subContractSelected;
        }

        if (checkContractsAlreadySelected(contracts, newSelection)) {
            setNotificationMessage(t("selection_conflict"));
            setNotifySeverity("error")
            setOpenNotify(true);
        } else {
            contracts.push(newSelection);
            setContractShow(false);
            setRefresh(!refresh);
        }

    }

    function createContractsInputs(contracts) {


        contracts.map((contract) => {
            let newContract = {};
            newContract.accountId = null;
            if (null != contract.account) {
                newContract.accountId = contract.account.id;
            }
            newContract.contractId = null;
            if (null != contract.contract) {
                newContract.contractId = contract.contract.id;
            }
            newContract.subContractId = null;
            if (null != contract.subContract) {
                newContract.subContractId = contract.subContract.id;
            }

            inputContracts.push(newContract);
        })

    }

    function createWarehousesInputs(warehouses) {


        warehouses.map((warehouse) => {
            let newWarehouse = {};
            newWarehouse.warehouseId = null;
            if (null != warehouse.warehouse) {
                newWarehouse.warehouseId = warehouse.warehouse.id;
            }

            inputWarehouses.push(newWarehouse);
        })

    }

    async function getUserApiKey() {
        if (id && permissions.includes("su:admin")) {
            await new UserService().getUserApiKey(id)
                .then(response => {
                    setApiKey(response.apiKey)
                })
        }
    }

    function minLength(obj1, obj2) {
        if (obj1.length < obj2.length) {
            return obj1;
        } else {
            return obj2;
        }
    }

    function deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        const minKeys = minLength(keys1, keys2);

        for (const key of minKeys) {
            const val1 = object1[key];
            const val2 = object2[key];

            const areObjects = isObject(val1) && isObject(val2);
            if(!isObject(val1) && isObject(val2) && val1 === null){
                return true;
            }
            if (
                areObjects && !deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }
        return true;
    }

    function isObject(object) {
        return object != null && typeof object === 'object';
    }


    function checkContractsAlreadySelected(selectedContracts, newContract) {

        for (let i = 0; i < selectedContracts.length; i++) {
            let compareContract = selectedContracts[i];

            if (deepEqual(compareContract, newContract)) {
                return true;
            }

        }

        return false;

    }

    function checkWarehousesAlreadySelected(selectedWarehouses, newWarehouse) {

        for (let i = 0; i < selectedWarehouses.length; i++) {
            let compareWarehouse = selectedWarehouses[i];

            if (deepEqual(compareWarehouse, newWarehouse)) {
                return true;
            }

        }

        return false;
    }


    let useStyles = makeStyles((theme) => ({
        root: {
            width: '100%'
        },
        field: {
            display: "flex",
            margin: "auto",
            marginBottom: "3%",
            marginTop: "3%",
            width: '25%',
            minWidth: 250
        },

        label: {
            width: "15%",
            marginTop: "5%",
            margin: "auto",
            display: "flex"
        },
        formControl: {
            margin: theme.spacing(1),
            width: "30%",
            marginBottom: "3%",
            marginTop: "3%",
        },
        button_container: {
            margin: "auto",
            display: "block"
        },
        accountForm: {
            width: "75%",
            margin: "auto",
            marginBottom: "3%",
            marginTop: "3%",
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        button: {
            backgroundColor: "primary",
            display: "block",
            margin: "auto"
        }

    }));

    const classes = useStyles();

    function goBack(event) {
        history.push('/users');
    }

    function handleUsernameChange(event) {
        setUsername(event.target.value)
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value)
    }

    function handleChangeMultiple(event) {
        setNewUserRoles(event.target.value);
    }

    function handleEnabled(event) {
        setEnabled(event.target.checked);
    }

    function handleSubmit() {

        createContractsInputs(contracts);
        createWarehousesInputs(warehouses)

        let userService = new UserService();

        if (!userId) {

            userService.createUser(username, password, enabled, newUserRoles, inputContracts, inputWarehouses)
                .then((response) => {
                    setSubmitSuccess(true);
                    setTimeout(() => {
                        history.push({
                            pathname: '/users',
                            state: {detail: ''}
                        });
                    }, 2000)

                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setSubmitError(response.message);
                    });
                })

        } else {

            userService.editUser(userId, username, password, enabled, newUserRoles, inputContracts, inputWarehouses)
                .then((response) => {
                    setSubmitSuccess(true);
                    setTimeout(() => {
                        history.push('/users');
                    }, 2000)

                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setSubmitError(response.message);
                    });
                })
        }
    }

    function togglePasswordVisibility() {
        setPasswordShown(!passwordShown);
    }

    function togglePasswordField() {
        setFieldShow(!fieldShow);
        setPassword('');
    }

    function getStyles(role, userRoles, theme) {
        return {
            fontWeight:
                userRoles.indexOf(role) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }


    function createPasswordField() {
        if (location.pathname === '/users/new') {
            return (<TextField
                    label={t("password")}
                    variant="outlined"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    onChange={handlePasswordChange}
                    value={password}
                    margin="dense"
                    className={classes.field}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{backgroundColor: 'transparent'}}
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    onMouseDown={togglePasswordVisibility}
                                >
                                    {passwordShown ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}

                />

            )
        } else {
            return (
                <div>
                    <Button className={classes.button} type="button" onClick={togglePasswordField}>
                        {fieldShow ? t("change_password") : t("change_password")}
                    </Button>
                    {fieldShow ? (
                        <TextField
                            label={t("password")}
                            variant="outlined"
                            type={passwordShown ? "text" : "password"}
                            onChange={handlePasswordChange}
                            value={password}
                            margin="dense"
                            className={classes.field}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            onMouseDown={togglePasswordVisibility}
                                        >
                                            {passwordShown ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    ) : null}

                </div>
            )
        }

    }

    async function fetchWarehouses() {
        await new WarehouseService().getWarehousesByFilters()
            .then(data => {
                setWarehousesList(data.warehouses);
            })
            .catch(error => {

            })
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts()
            .then(data => {
                setAccountList(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (selectedAccount != null) {
            filters.accountId = selectedAccount.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContractList(response);
            })

    }

    function fetchSubContracts() {
        let subContractService = new SubContractService();
        let filters = {}
        if (contractSelected != null) {
            filters.contractId = contractSelected.id
        }

        subContractService.getSubContractsByFilters(filters)
            .then(response => {
                setSubContractList(response);
            })

    }

    function fetchAllRoles() {
        let roleService = new RoleService();
        roleService.getAllRoles()
            .then(roles => {
                setRolesList(roles);

            })
            .catch(error => {

            })
    }

    function fetchUser() {
        let userService = new UserService();
        userService.getUserById(userId)
            .then((userData) => {
                setUsername(userData.username)
                setEnabled(userData.enabled)
            })
            .catch(error => {

            })
    }

    function removeContractSelection(selec) {
        let array = contracts;
        let index = array.indexOf(selec)
        if (index !== -1) {
            array.splice(index, 1);
            setContracts(array);
        }
        setRefresh(!refresh)
    }

    function removeWarehouseSelection(selec) {
        let array = warehouses;
        let index = array.indexOf(selec)
        if (index !== -1) {
            array.splice(index, 1);
            setWarehouses(array);
        }
        setRefresh(!refresh)
    }

    function fetchUserRoles() {
        let userService = new UserService();

        userService.getUserRole(userId)
            .then(userRoles => {
                let roleIds = [];
                for (let i = 0; i < userRoles.length; ++i) {
                    roleIds.push(userRoles[i].id);
                }
                setNewUserRoles(roleIds);
            })
    }

    function fetchUserContracts() {
        let userService = new UserService();
        userService.getUserContracts(userId)
            .then(response => {
                formatContracts(response);
            })


    }

    function formatContracts(contracts) {

        let formattedContracts = [];

        const accountService = new AccountService();
        let contractService = new ContractService();
        let subContractService = new SubContractService();

        contracts.map(contract => {

            let formattedContract = {}

            if (contract.accountId != null) {
                accountService.getById(contract.accountId)
                    .then(response => {
                        formattedContract.account = response;
                    })
                    .catch(error => {

                    })

            } else {
                formattedContract.account = null;
            }

            if (contract.contractId != null) {
                contractService.getContractById(contract.contractId)
                    .then(response => {
                        formattedContract.contract = response;
                    })
            } else {
                formattedContract.contract = null;
            }


            if (contract.subContractId != null) {
                subContractService.getSubContractById(contract.subContractId)
                    .then(response => {
                        formattedContract.subContract = response;
                    })
            } else {
                formattedContract.subContract = null;
            }


            formattedContracts.push(formattedContract);
        })

        setContracts(formattedContracts);

    }

    function fetchUserWarehouses() {
        let userService = new UserService();
        userService.getUserWarehouses(userId)
            .then(response => {
                formatWarehouses(response);
            })
    }

    function formatWarehouses(warehouses) {

        let formattedWarehouses = [];

        let warehouseService = new WarehouseService();

        warehouses.map(warehouse => {

            let formattedWarehouse = {}

            if (warehouse.warehouseId != null) {
                warehouseService.getWarehouseById(warehouse.warehouseId)
                    .then(response => {
                        formattedWarehouse.warehouse = response;
                    })
                    .catch(error => {

                    })

            } else {
                formattedWarehouse.warehouse = null;
            }

            formattedWarehouses.push(formattedWarehouse);
        })

        setWarehouses(formattedWarehouses);

    }


    useEffect(() => {
        fetchAllRoles();
        fetchWarehouses();
        fetchAccounts();
        fetchContracts();
        fetchSubContracts();

        if (userId) {
            fetchUser();
            fetchUserRoles();
            fetchUserContracts();
            fetchUserWarehouses();
            getUserApiKey();
        }

        setTimeout(() => {
            setLoading(false);
        }, 1500);

    }, [userId]);


    return (
        <div>
            {loading ? <LoadingComponent/> :
                <div style={{width: "100%"}}>
                    <Button
                        style={{margin: "2%", background: "transparent"}}
                        onClick={goBack}
                        startIcon={<ArrowBackRounded/>}
                        variant={"filled"}
                    >
                        {t("back")}
                    </Button>
                    <DialogTitle id="form-dialog-title">{t("user_data")}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label={t("username")}
                            variant="outlined"
                            name="username"
                            margin="dense"
                            onChange={handleUsernameChange}
                            value={username}
                            className={classes.field}/>

                        {permissions.includes("su:admin") && (id) ?

                            <div style={{marginBlock: "3%"}}><Typography variant={"button"} fontWeight={"bold"}>
                                {"ApiKey: "}
                            </Typography>
                                <Typography variant={"body2"}>
                                    {apiKey}
                                </Typography>
                            </div>

                            :

                            null

                        }

                        {createPasswordField()}


                        <FormControl className={classes.formControl}>
                            <InputLabel id="multiple-roles">{t("roles")}</InputLabel>
                            <Select
                                labelId="roles-select"
                                disabled={!permissions.includes("su:admin")}
                                multiple
                                value={newUserRoles}
                                variant={"outlined"}
                                onChange={handleChangeMultiple}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((roleId) => {
                                            let role = {};
                                            for (let i = 0; i < rolesList.length; ++i) {
                                                if (rolesList[i].id === roleId) {
                                                    role = rolesList[i];
                                                }
                                            }
                                            return (
                                                <Chip key={role.id} label={role.name} className={classes.chip}/>
                                            )
                                        })}
                                    </div>
                                )}
                                input={<Input id="select-multiple-chip"/>}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >

                                {rolesList.map((role, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={role.id}
                                            style={getStyles(role, newUserRoles, theme)}>

                                            {role.name}

                                        </MenuItem>
                                    )
                                })}

                            </Select>


                        </FormControl>
                        <Grid container spacing={1} columns={16} style={{width: "100%", marginBottom: "2%"}}>
                            <Grid item xs={6}>
                                {contracts.length !== 0 ?
                                    <Paper style={{width: "100%", margin: "auto", marginBottom: "5%"}} elevation={3}>
                                        <DialogTitle>{t("selected_contracts")}</DialogTitle>
                                        <Table stickyHeader={true}>
                                            <TableHead>
                                                <TableCell>{t("account")}</TableCell>
                                                <TableCell>{t("contract")}</TableCell>
                                                <TableCell>{t("sub-contract")}</TableCell>
                                                <TableCell/>
                                            </TableHead>
                                            <TableBody>
                                                {contracts.map((formContracts, index) => {
                                                    return <TableRow>
                                                        <TableCell
                                                            style={{width: "30%"}}>{formContracts.account ? formContracts.account.accountCorporateCode : "*"}</TableCell>
                                                        <TableCell
                                                            style={{width: "30%"}}>{formContracts.contract ? formContracts.contract.code : "*"}</TableCell>
                                                        <TableCell
                                                            style={{width: "30%"}}>{formContracts.subContract ? formContracts.subContract.code : "*"}</TableCell>

                                                        <TableCell>
                                                            <IconButton disabled={!permissions.includes("su:admin")} color={"primary"}
                                                                        onClick={() => removeContractSelection(formContracts)}>
                                                                <CancelOutlined/>
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                })}


                                            </TableBody>

                                        </Table>
                                    </Paper>
                                    :
                                    null
                                }

                                <div>
                                    <Button disabled={!permissions.includes("su:admin")} className={classes.button} onClick={() => setContractShow(true)}>
                                        {t("add_contract")}
                                    </Button>

                                </div>


                                {contractShow ?
                                    <Card className={classes.accountForm}>
                                        <DialogTitle>{t("select_contracts")}</DialogTitle>
                                        <DialogContentText>{t("select_contracts_msg")}</DialogContentText>

                                        <Autocomplete
                                            id="account"

                                            className={classes.field}
                                            options={accountList}
                                            getOptionLabel={option => option.companyName}
                                            value={selectedAccount}
                                            defaultValue={selectedAccount}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchAccounts}
                                            onChange={handleAccountChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("account")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={selectedAccount}
                                                    defaultValue={selectedAccount}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            id="contract"
                                            disabled={!selectedAccount}
                                            className={classes.field}
                                            options={contractList}
                                            getOptionLabel={option => option.code}
                                            value={contractSelected}
                                            defaultValue={contractSelected}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchContracts}
                                            onChange={handleContractChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("contract")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={contractSelected}
                                                    defaultValue={contractSelected}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            id="subContract"
                                            disabled={!contractSelected}
                                            className={classes.field}
                                            options={subContractList}
                                            getOptionLabel={option => option.code}
                                            value={subContractSelected}
                                            defaultValue={subContractSelected}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchSubContracts}
                                            onChange={handleSubContractChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("sub-contract")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={subContractSelected}
                                                    defaultValue={subContractSelected}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <CardActions className={classes.button_container}>
                                            <Button
                                                className={classes.cancel_button}
                                                endIcon={<CancelOutlined/>}
                                                onClick={cancelContractSelection}
                                                variant={"contained"}>
                                                {t("cancel")}
                                            </Button>
                                            <Button
                                                className={classes.confirm_button}
                                                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                                onClick={saveTempContractSelection}
                                                color="primary"
                                                variant={"contained"}>
                                                {t("add_contract")}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {warehouses.length !== 0 ?
                                    <Paper style={{width: "100%", margin: "auto", marginBottom: "5%"}} elevation={3}>
                                        <DialogTitle>{t("selected_warehouses")}</DialogTitle>
                                        <Table stickyHeader={true}>
                                            <TableHead>
                                                <TableCell>{t("warehouse_code")}</TableCell>
                                                <TableCell>{t("warehouse_name")}</TableCell>
                                                <TableCell/>
                                            </TableHead>
                                            <TableBody>
                                                {warehouses.map((formWarehouse, index) => {
                                                    return <TableRow>
                                                        <TableCell
                                                            style={{width: "50%"}}>{formWarehouse.warehouse ? formWarehouse.warehouse.code : "*"}</TableCell>
                                                        <TableCell
                                                            style={{width: "45%"}}>{formWarehouse.warehouse ? formWarehouse.warehouse.name : "*"}</TableCell>
                                                        <TableCell>
                                                            <IconButton disabled={!permissions.includes("su:admin")} color={"primary"}
                                                                        onClick={() => removeWarehouseSelection(formWarehouse)}>
                                                                <CancelOutlined/>
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                })}


                                            </TableBody>

                                        </Table>
                                    </Paper>
                                    :
                                    null
                                }

                                <div>
                                    <Button disabled={!permissions.includes("su:admin")} className={classes.button} onClick={() => setWarehouseShow(true)}>
                                        {t("add_warehouse")}
                                    </Button>

                                </div>

                                {warehouseShow ?
                                    <Card className={classes.accountForm}>
                                        <DialogTitle>{t("select_warehouse")}</DialogTitle>
                                        <DialogContentText>{t("select_warehouse_msg")}</DialogContentText>

                                        <Autocomplete
                                            id="warehouse"
                                            className={classes.field}
                                            options={warehousesList}
                                            getOptionLabel={option => option.code + " - " + option.name}
                                            value={warehouseSelected}
                                            defaultValue={warehouseSelected}
                                            noOptionsText={t("no_options_available")}
                                            onOpen={fetchWarehouses}
                                            onChange={handleWarehouseChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("warehouse")}
                                                    InputLabelProps={{shrink: true}}
                                                    placeholder={t("any")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={warehouseSelected}
                                                    defaultValue={warehouseSelected}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <CardActions className={classes.button_container}>
                                            <Button
                                                className={classes.cancel_button}
                                                endIcon={<CancelOutlined/>}
                                                onClick={cancelWarehouseSelection}
                                                variant={"contained"}>
                                                {t("cancel")}
                                            </Button>
                                            <Button
                                                className={classes.confirm_button}
                                                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                                onClick={saveTempWarehouseSelection}
                                                color="primary"
                                                variant={"contained"}>
                                                {t("add_warehouse")}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>


                        <FormControlLabel className={classes.label} control={<Switch
                            disabled={!permissions.includes("su:admin")}
                            checked={enabled}
                            onChange={handleEnabled}
                            color={"primary"}

                        />} label={<Typography variant={"subtitle2"} fontWeight={"bold"}>{t("enable_user")}</Typography>}
                                          labelPlacement="bottom"
                        />


                    </DialogContent>

                    <DialogActions>
                        <Button  style={{
                            margin: "auto", marginBlock: "2%", backgroundColor: theme.palette.primary.main,
                            color: "white",
                        }} onClick={handleSubmit} endIcon={<SaveOutlined/>}>{t("save")}</Button>
                        {/*<Button onClick={handleSubmit} color="primary">*/}
                        {/*    {t("confirm")}*/}
                        {/*</Button>*/}
                    </DialogActions>
                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                  onClose={closeNotification}>{notificationMessage}</Notification>
                </div>
            }
        </div>
    )

}

export default UserForm;