import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SlotService from "./SlotService";


function Slot(props){

    let slot = props.slot;
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '75%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    function handleMenuClick(event){
        setAnchorEl(event.currentTarget);
    }
    function handleMenuClose(){
        setAnchorEl(null);
    }

    function editSlot(slotId){
        // history.push(`/slots/${slotId}/edit`)
    }

    function deleteSlot(slotId){
        let slotService = new SlotService();
        slotService.deleteSlot(slotId)
            .then(()=>{
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                    });
            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    useEffect(()=>{

    }, [])

    return <TableRow>
        <TableCell>
            {slot.id}
        </TableCell>
        <TableCell>
            {slot.fromTime}
        </TableCell>
        <TableCell>
            {slot.toTime}
        </TableCell>
        <TableCell>
            {slot.weekDays.map(day => {
                return <Typography>{t(`${day.toLowerCase()}`)}</Typography>
            })}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={() => editSlot(slot.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>*/}

            <MenuItem onClick={() => deleteSlot(slot.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>
    </TableRow>


}export default Slot;