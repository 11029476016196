import React, {useEffect, useState} from "react";
import PudoService from "./PudoService";
import {
    Button,
    Card, FormControlLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    Table,
    TableBody,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {
    CircularProgress,
    DialogTitle,
    IconButton,
    Paper,
    TableCell, TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Pudo from "./Pudo";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {Autocomplete} from "@material-ui/lab";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import InfoChip from "../components/chips/InfoChip";
import WarehouseService from "../warehouses/WarehouseService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function PudoTable(props) {

    const history = useHistory();
    const {t} = useTranslation();

    const [pudos, setPudos] = useState([]);

    const [code, setCode] = useState('');
    const [delivery, setDelivery] = useState('');
    const [pickup, setPickup] = useState('');
    const [cod, setCod] = useState('');

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [adminL1List, setAdminL1List] = useState([]);
    const [adminL1, setAdminL1] = useState(null);
    const [adminL2List, setAdminL2List] = useState([]);
    const [adminL2, setAdminL2] = useState(null);
    const [adminL3List, setAdminL3List] = useState([]);
    const [adminL3, setAdminL3] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [postalCodes, setPostalCodes] = useState([]);
    const [postalCode, setPostalCode] = useState(null);

    const [warehouse, setWarehouse] = useState(null)
    const [warehouses, setWarehouses] = useState([])

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [pages, setPages] = useState([]);

    function handleCodeChange(event){
        setCode(event.target.value);
    }
    
    function handleDeliveryChange(event){
        setDelivery(event.target.value)
    }

    function handlePickupChange(event){
        setPickup(event.target.value)
    }

    function handleCodChange(event){
        setCod(event.target.value)
    }

    function handleWarehouseChange(event, warehouse){
        setWarehouse(warehouse);
    }


    function handleCountryChange(event, country) {
        setCountry(country);
        setAdminL1(null);
        setAdminL2(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setAdminL1List([]);
        setAdminL2List([]);
        setAdminL3List([]);
        setCities([]);
        setPostalCodes([])
    }

    function handleAdminL1Change(event, adminL1) {
        setAdminL1(adminL1);
        setAdminL2(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setAdminL2List([]);
        setAdminL3List([]);
        setCities([]);
        setPostalCodes([])
    }

    function handleAdminL2Change(event, adminL2) {
        setAdminL2(adminL2);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setAdminL3List([]);
        setCities([]);
        setPostalCodes([])
    }

    function handleAdminL3Change(event, adminL3) {
        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);
        setCities([]);
        setPostalCodes([])
    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
        setPostalCodes([])
    }

    function handlePostalCodeChange(event, postalCode) {
        setPostalCode(postalCode);
    }

    async function fetchCountries() {

        setLoadingData(true);

        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
            setLoadingData(false);
        })

            .catch(error => {
                setLoadingData(false);
            })
    }

    async function fetchAdminL1List() {

        setLoadingData(true);

        let adminL1Service = new AdministrativeLevel1Service();
        let filters = {}

        if (country) {
            filters.countryId = country.id;
        }

        await adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL1List(data.admins1);
                setLoadingData(false);
            })
            .catch(error => {
                setLoadingData(false);
            })


    }

    async function fetchAdminL2List() {

        setLoadingData(true);

        let adminL2Service = new AdministrativeLevel2Service();
        let filters = {}
        if (adminL1) {

            filters.adminL1 = adminL1.id;
        }

        await adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL2List(data.admins2);
                setLoadingData(false);
            })
            .catch(error => {
                setLoadingData(false);
            })
    }

    async function fetchAdminL3List() {

        setLoadingData(true);

        let adminL3Service = new AdministrativeLevel3Service();

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }

        if (adminL2) {
            filters.adminL2 = adminL2.id;
        }

        await adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL3List(data.admins3);
                setLoadingData(false);
            })
            .catch(error => {
                setLoadingData(false);
            })

    }


    async function fetchCities() {

        setLoadingData(true);

        let filters = {};

        if (adminL3) {
            filters.level3Id = adminL3.id;
        }

        await new CityService().getCities(null, null, filters, null)
            .then(result => {
                setCities(result.cities);
                setLoadingData(false);
            })
            .catch(error => {
                setLoadingData(false);
            })
    }


    async function fetchPostalCodes() {

        setLoadingData(true);

        let filters = {};

        if (city) {
            filters.cityId = city.id
        }

        await new PostalCodeService().getAllPostalCodes(null, null, filters, null)
            .then(result => {
                setPostalCodes(result.postalCodes);
                setLoadingData(false);
            })
            .catch(error => {
                setLoadingData(false);
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    async function fetchPudos() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if(warehouse){
            filters.warehouseCode = warehouse?.code;
        }

        if('' !== code){
            filters.code = code;
        }

        if('' !== delivery){
            filters.delivery = delivery === "yes";
        }

        if('' !== pickup){
            filters.pickup = pickup === "yes";
        }

        if('' !== cod){
            filters.cod = cod === "yes";
        }

        if(country){
            filters.country = country.iso3166Alpha3;
        }

        if (adminL2) {
            filters.administrativeLevel2 = adminL2.name;
        }

        if (adminL3) {
            filters.administrativeLevel3 = adminL3.code;
        }

        if(city){
            filters.city = city.name;
        }

        if(postalCode){
            filters.postalCode = postalCode.postalCode;
        }

        await new PudoService().getPudos(filters)
            .then(response => {
                setPudos(response.pudos)
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
            })
            .catch(error => {

            })


        setLoading(false);
        setFetched(true);
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses);
    }

    useEffect(() => {
        fetchPudos();
    }, [page, rowsPerPage])


    return <div>

        <Card style={{width: "90%", margin: "auto", marginBottom: "5%"}}>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("filters")}</DialogTitle>
            <div style={{width: "100%", display: "flex"}}>
                <TextField
                    style={{width: "15%", margin: "auto", marginBlock: "2%"}}
                    variant={"outlined"}
                    size={"small"}
                    label={t("code")}
                    value={code}
                    defaultValue={code}
                    onChange={handleCodeChange}
                />
            </div>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <div style={{display: "flex", width: "80%", margin: "auto"}}>
                <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                    <Typography disabled={true}
                                style={{color: "black"}}>{t("delivery")}</Typography>
                </TableCell>
                <TableCell style={{borderBottom: "none"}}>
                    <RadioGroup
                        row
                        value={delivery}
                        onChange={handleDeliveryChange}
                        style={{margin: "auto"}}
                    >
                        <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                          label={t("yes")}/>
                        <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                          label={t("no")}/>
                        <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                          label={t("any")}/>
                    </RadioGroup>
                </TableCell>
            </div>
            <div style={{display: "flex", width: "80%", margin: "auto"}}>
                <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                    <Typography disabled={true}
                                style={{color: "black"}}>{t("pickup")}</Typography>
                </TableCell>
                <TableCell style={{borderBottom: "none"}}>
                    <RadioGroup
                        row
                        value={pickup}
                        onChange={handlePickupChange}
                        style={{margin: "auto"}}
                    >
                        <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                          label={t("yes")}/>
                        <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                          label={t("no")}/>
                        <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                          label={t("any")}/>
                    </RadioGroup>
                </TableCell>
            </div>
            <div style={{display: "flex", width: "80%", margin: "auto"}}>
                <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                    <Typography disabled={true}
                                style={{color: "black"}}>{t("cod")}</Typography>
                </TableCell>
                <TableCell style={{borderBottom: "none"}}>
                    <RadioGroup
                        row
                        value={cod}
                        onChange={handleCodChange}
                        style={{margin: "auto"}}
                    >
                        <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                          label={t("yes")}/>
                        <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                          label={t("no")}/>
                        <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                          label={t("any")}/>
                    </RadioGroup>
                </TableCell>
            </div>
            <div style={{display: "flex", width: "100%"}}>
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="country"
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    value={country}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    defaultValue={country}
                    onOpen={fetchCountries}
                    onChange={handleCountryChange}
                    renderOption={(option) => (
                        <React.Fragment>
                            {option.name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("country")}
                            variant="outlined"
                            margin="dense"
                            value={option => option.iso3166Alpha3}
                            defaultValue={country}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="administrative1"
                    disabled={!country}
                    options={adminL1List}
                    getOptionLabel={option => option.name}
                    value={adminL1}
                    defaultValue={adminL1}
                    onOpen={fetchAdminL1List}
                    onChange={handleAdminL1Change}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative1")}
                            variant="outlined"
                            margin="dense"
                            value={adminL1}
                            defaultValue={adminL1}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="administrative2"
                    disabled={!adminL1}
                    options={adminL2List}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={adminL2}
                    onOpen={fetchAdminL2List}
                    onChange={handleAdminL2Change}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("administrative2")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={adminL2}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", width: "100%"}}>
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="administrative3"
                    disabled={!country || !adminL2}
                    options={adminL3List}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={adminL3}
                    onOpen={fetchAdminL3List}
                    onChange={handleAdminL3Change}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={adminL3}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="city"
                    disabled={!adminL3}
                    options={cities}
                    getOptionLabel={option => option.name}
                    value={city}
                    defaultValue={city}
                    onOpen={fetchCities}
                    onChange={handleCityChange}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{width: "35%", marginInline: "1%"}}
                    id="postalCode"
                    disabled={!city}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={postalCode}
                    loading={loadingData}
                    loadingText={<CircularProgress size={24}/>}
                    onOpen={fetchPostalCodes}
                    onChange={handlePostalCodeChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            defaultValue={postalCode}
                        />
                    )}
                />
            </div>
            <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                <Button startIcon={<SearchOutlined/>} variant="contained"
                        color="primary" type="button" onClick={fetchPudos}>
                    {t("search")}
                </Button>
            </div>

        </Card>

        {pudos.length > 0 ?
            <Paper>
                {loading ?
                    <LoadingComponent/>
                    :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("ID")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("code")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("type")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("warehouse_code")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("company_name")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("address")}</Typography>
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    <Typography>{t("coordinates")}</Typography>*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("services")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("pudo_schedule")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pudos.map(pudo => {
                                return <Pudo pudo={pudo}/>
                            })}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[10, 30, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'}
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Table>
                }
            </Paper>
            :
            <>
            {fetched?

                <InfoChip>{t("no_data")}</InfoChip>

                :
                null
            }
            </>
        }


    </div>

}

export default PudoTable;