import React, {useEffect, useState} from "react";
import {Paper, TableCell, TableRow} from "@material-ui/core";
import {Autocomplete, Table, TableHead, TextField, Typography} from "@mui/material";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import DateField from "../components/fields/DateField";
import WarehouseService from "../warehouses/WarehouseService";
import {format} from "date-fns";
import DriverService from "../drivers/DriverService";
import DriverWorkListService from "./DriverWorkListService";
import ParcelService from "../shipments/services/ParcelService";
import PickupService from "../pickups/PickupService";
import ArrivalService from "../arrivals/ArrivalService";
import ShipmentService from "../shipments/ShipmentService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import theme from "../theme/theme";
import InfoChip from "../components/chips/InfoChip";


function AddToDriverWorkListPage(props) {

    const {t} = useTranslation();

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [date, setDate] = useState(null)
    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState(null);

    const [barcode, setBarcode] = useState('');

    const [greenBarcodes, setGreenBarcodes] = useState([]);
    const [unknownBarcodes, setUnknownBarcodes] = useState([]);

    const [shipmentData, setShipmentData] = useState([]);

    const [workList, setWorkList] = useState(null);
    const [workLists, setWorkLists] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [parcelQueue, setParcelQueue] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [loading, setLoading] = useState(false);


    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        setDriver(null);
        setWorkList(null);
        setGreenBarcodes([]);
    }

    function handleDriverChange(event, driver) {
        setDriver(driver);
        setWorkList(null);
        setGreenBarcodes([]);
    }

    function handleDateChange(event) {

        setDate(event);
        setDriver(null);
        setWorkList(null);
        setGreenBarcodes([]);
    }

    function handleWorkListChange(event, workList) {
        setWorkList(workList);
        if (null !== workList) {
            fetchWorkList(workList);
        } else {
            setGreenBarcodes([]);
        }
        console.log(greenBarcodes);
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
        if (wares?.warehouses?.length === 1) {
            setWarehouse(wares.warehouses[0]);
        }
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }

    async function fetchDriverWorkLists() {

        let filters = {};
        filters.driverId = driver.id;
        filters.date = format(date, "yyyy-MM-dd");

        let worklists = await new DriverWorkListService().getDriverWorkLists(filters);
        setWorkLists(worklists.driverWorkLists);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value.trim());
    }

    function handleKeyDown(event) {
        if (event.code === "Enter" && barcode !== '') {

            if (!warehouse || !driver) {
                setNotificationMessage(t("Selezionare una filiale e un'autista"));
                setNotifySeverity('error');
                setOpenNotify(true);
            } else {
                if (greenBarcodes.some(greenBarcode => greenBarcode.barcode?.toUpperCase() === barcode?.toUpperCase() || greenBarcode.customerBarcode?.toUpperCase() === barcode?.toUpperCase())) {
                    setNotificationMessage(t("Collo già in giornaliera"));
                    setNotifySeverity('warning');
                    setOpenNotify(true);
                    setBarcode('');
                } else {
                    let queue = parcelQueue;
                    queue.push(barcode)
                    setParcelQueue(queue);
                    setBarcode('');

                    if (parcelQueue.length >= 1) {
                        let barcode = parcelQueue.pop();
                        fetchData(barcode);
                        console.log(greenBarcodes);
                    }
                }
            }
        }
    }

    async function fetchWorkList(workList) {
        let workListParcels = workList.parcelIds;
        let pickupIds = workList.pickupIds;

        setLoading(true);

        let greens = [];

        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = null;

            pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })

            let greenData = {};
            greenData.barcode = pickup.barcode;
            greenData.customerBarcode = null;
            greens.push(greenData);

        }

        for (let i = 0; i < workListParcels?.length; i++) {
            let parcelService = new ParcelService();
            let parcel = null;
            parcel = await parcelService.getParcelById(workListParcels[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
            let greenData = {};
            greenData.barcode = parcel.barcode;
            greenData.customerBarcode = parcel.customerBarcode;
            greens.push(greenData);
            setRefresh(!refresh);
        }

        setRefresh(!refresh);
        setTrigger(!trigger);

        setGreenBarcodes(greens);
        setTimeout(() => {
            setLoading(false);
        }, 1000)

    }

    async function fetchData(barcode) {
        setLoading(true);
        let parcelService = new ParcelService();
        let parcel = null;
        parcel = await parcelService.getParcelByBarcode(barcode)
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);

                    let errbarcodes = unknownBarcodes;
                    if (!errbarcodes.some(brco => brco === barcode)) {
                        errbarcodes.push(barcode);
                    }
                    setUnknownBarcodes(errbarcodes);

                    setLoading(false);
                })
            })

        if (shipmentData.some(data => {
            return data.parcels.some(parcel => {
                return (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase());
            })
        })) {
            shipmentData.map(data => {
                data.parcels.map(async parcel => {
                    if (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase()) {

                        let barcodes = [barcode];

                        await new DriverWorkListService().addParcelToExistingWorkList(workList.id, barcodes)
                            .then(response => {
                                response.barcodesInError.map(barcode => {
                                    setNotificationMessage("Si è verificato un errore nella trasmissione del barcode: " + barcode.barcode + "-" + barcode.errorMessage);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })

                                response.parsedBarcodes.map(_barcode => {
                                    if (_barcode.barcode === barcode) {

                                        parcel.checked = true;
                                        let greens = greenBarcodes;
                                        let greenData = {};
                                        greenData.barcode = barcode;
                                        greenData.customerBarcode = null;
                                        greens.push(greenData);
                                        setGreenBarcodes(greens);
                                        setNotificationMessage(_barcode.barcode + " caricato correttamente");
                                        setNotifySeverity('success');
                                        setOpenNotify(true);
                                        setRefresh(!refresh);

                                        // console.log(pickupData);
                                    }
                                })

                            })
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage("Si è verificato un errore: " + response.status + "-" + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })
                            });
                    }
                })
            })
        } else {
            let shipmentService = new ShipmentService();
            let shipmentId = parcel?.shipmentId;
            if (parcel) {
                let shipment = await shipmentService.getShipmentById(shipmentId, null)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                if (shipment) {
                    let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let data = {};
                    data.shipment = shipment;
                    data.parcels = [];
                    for (let i = 0; i < parcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = parcels[i];
                        if (parcels[i].barcode.toUpperCase() === barcode.toUpperCase() || parcels[i].customerBarcode.toUpperCase() === barcode.toUpperCase()) {

                            let barcodes = [barcode];

                            await new DriverWorkListService().addParcelToExistingWorkList(workList.id, barcodes)
                                .then(response => {
                                    response.barcodesInError.map(barcode => {
                                        setNotificationMessage("Si è verificato un errore nella trasmissione del barcode: " + barcode.barcode + "-" + barcode.errorMessage);
                                        setNotifySeverity('error');
                                        setOpenNotify(true);
                                    })

                                    response.parsedBarcodes.map(_barcode => {
                                        if (_barcode.barcode === barcode) {

                                            parcelData.checked = true;
                                            let greens = greenBarcodes;
                                            let greenData = {};
                                            greenData.barcode = barcode;
                                            greenData.customerBarcode = null;
                                            greens.push(greenData);
                                            setGreenBarcodes(greens);
                                            setNotificationMessage(_barcode.barcode + " caricato correttamente");
                                            setNotifySeverity('success');
                                            setOpenNotify(true);
                                            setRefresh(!refresh);

                                            // console.log(pickupData);
                                        }
                                    })

                                })
                                .catch(error => {
                                    error.response.json().then(response => {
                                        setNotificationMessage("Si è verificato un errore: " + response.status + "-" + response.message);
                                        setNotifySeverity('error');
                                        setOpenNotify(true);
                                    })
                                });

                        } else {
                            parcelData.checked = false;
                        }

                        data.parcels.push(parcelData);
                    }

                    setShipmentData([...shipmentData, data]);
                }

                // } else {
                //     let barcodes = [barcode];
                //
                //     await new DriverWorkListService().addParcelToExistingWorkList(workList.id, barcodes)
                //         .then(response => {
                //             response.barcodesInError.map(barcode => {
                //                 setNotificationMessage("Si è verificato un errore nella trasmissione del barcode: " + barcode.barcode + "-" + barcode.errorMessage);
                //                 setNotifySeverity('error');
                //                 setOpenNotify(true);
                //             })
                //
                //             response.parsedBarcodes.map(_barcode => {
                //                 if (_barcode.barcode === barcode) {
                //                     // let data = {};
                //                     //
                //                     // data.pickup = pickup;
                //                     // data.barcode = barcode;
                //                     // data.checked = true;
                //                     //
                //                     // setPickupData([...pickupData, data]);
                //                     let greens = greenBarcodes;
                //                     let greenData = {};
                //                     greenData.barcode = barcode;
                //                     greenData.customerBarcode = null;
                //                     greens.push(greenData);
                //                     setGreenBarcodes(greens);
                //                     setNotificationMessage("Ritiro " + _barcode.barcode + " caricato correttamente");
                //                     setNotifySeverity('success');
                //                     setOpenNotify(true);
                //                     setRefresh(!refresh);
                //
                //                     // console.log(pickupData);
                //                 }
                //             })
                //
                //         })
                //         .catch(error => {
                //             error.response.json().then(response => {
                //                 setNotificationMessage("Si è verificato un errore: " + response.status + "-" + response.message);
                //                 setNotifySeverity('error');
                //                 setOpenNotify(true);
                //             })
                //         });
            }

        }

        setRefresh(!refresh);
        setTrigger(!trigger);
        setLoading(false);

    }

    function calcSumOfParcels(parNum) {

        shipmentData.map(shipmentData => {
            shipmentData.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum += 1;
                }
            })
        })

        return parNum;

    }

    useEffect(() => {
        fetchWarehouses();
    }, [refresh])

    return <Paper>
        <div style={{paddingBlock: "5%"}}>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onChange={handleWarehouseChange}
                onOpen={fetchWarehouses}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <DateField
                style={{width: "20%", margin: "auto"}}
                label={t("date")}
                value={date}
                onChange={handleDateChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
            />
            <Autocomplete
                id="driver-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={drivers}
                disabled={!warehouse || !date} F
                getOptionLabel={option => option.name + " " + option.surname}
                value={driver}
                defaultValue={driver}
                noOptionsText={t("no_options")}
                onChange={handleDriverChange}
                onOpen={fetchDrivers}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("driver")}
                        variant="outlined"
                        margin="dense"
                        value={driver}
                        defaultValue={driver}
                    />
                )}
            />
            <Autocomplete
                id="wrkList"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={workLists}
                disabled={!driver}
                getOptionLabel={option => option.id + " del " + new Date(option.dateTime).toLocaleDateString() + " ore " + new Date(option.dateTime).toLocaleTimeString()}
                value={workList}
                defaultValue={workList}
                noOptionsText={t("no_options")}
                onChange={handleWorkListChange}
                onOpen={fetchDriverWorkLists}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("driver_work_list")}
                        variant="outlined"
                        margin="dense"
                        value={workList}
                        defaultValue={workList}
                    />
                )}
            />
        </div>
        <div style={{width: "100%", marginBottom: "3%", display: "flex"}}>
            <TextField
                disabled={!warehouse || !driver || !date || !workList}
                style={{width: "20%", margin: "auto", marginBlock: "2%"}}
                label={t("barcode")}
                variant="outlined"
                margin="dense"
                value={barcode}
                defaultValue={barcode}
                onChange={handleBarcodeChange}
                // onPaste={handleBarcodePaste}
                onKeyDown={handleKeyDown}
            />
        </div>
        <div>
            {loading ?

                <LoadingComponent/>

                :
                <div>

                    {shipmentData.length > 0 || unknownBarcodes.length > 0 ?
                        <div>
                            <Table style={{width: "20%", margin: "auto"}}>
                                <TableHead>
                                    <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                            variant={"button"}
                                            alignSelf={"center"}
                                            fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                            variant={"button"}
                                            alignSelf={"center"}
                                            fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                                        {unknownBarcodes.length > 0 ?
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("unknown_parcels")}</Typography></TableCell>
                                            : null}
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                        variant={"button"}
                                        style={{
                                            fontWeight: 'bold',
                                            margin: "auto",
                                            fontSize: 30
                                        }}>{shipmentData.length}</Typography></TableCell>
                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                        <Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>
                                            {calcSumOfParcels(0)}</Typography>
                                    </TableCell>
                                    {unknownBarcodes.length > 0 ?
                                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{unknownBarcodes.length}</Typography></TableCell>
                                        : null
                                    }
                                </TableRow>
                            </Table>
                            <div style={{paddingBottom: "5%"}}>
                                <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography variant={"button"} style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                }}>{t("shipment_details")}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography variant={"button"} style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                }}> {t("barcode")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {shipmentData?.map(data => {
                                        return <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"subtitle2"}>{t("shipmentId")} : {data.shipment?.id}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("reference")} : {data.shipment?.reference}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("contract_code")} : {data.shipment?.contract.code}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("destination")} : {data.shipment?.deliveryAddress.postalCode} {data.shipment?.deliveryAddress.city} {data.shipment?.deliveryAddress.administrative_level_3}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                {data.parcels.map(parcel => {
                                                    return <div style={{display: "flex", alignItems: "center"}}>
                                                        <Typography
                                                            color={parcel?.checked ? "green" : "darkgrey"}>{parcel.parcel?.barcode + (parcel.parcel?.customerBarcode ? " - " + parcel.parcel?.customerBarcode : null)}</Typography>
                                                    </div>
                                                })}
                                            </TableCell>

                                        </TableRow>
                                    })}
                                    {unknownBarcodes?.length > 0 ?
                                        <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"subtitle2"}>{t("unknown_parcels")}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                {unknownBarcodes.map(barcode => {
                                                    return <div style={{display: "flex", alignItems: "center"}}>
                                                        <Typography
                                                            color={"darkgrey"}>{barcode}</Typography>
                                                    </div>
                                                })}
                                            </TableCell>

                                        </TableRow>
                                        :
                                        null
                                    }
                                </Table>
                            </div>
                        </div>
                        :
                        <InfoChip>{t("no_parcels")}</InfoChip>}

                </div>
            }
        </div>


        <Notification open={openNotify} severity={notifySeverity} duration={4000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </Paper>


}

export default AddToDriverWorkListPage;