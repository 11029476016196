import RatingService from "./RatingService";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card, Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    Table,
    TableCell,
    TableHead,
    TablePagination
} from "@material-ui/core";
import {
    Autocomplete,
    CircularProgress,
    IconButton,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Rating from "./Rating";
import {CloudDownload, Info, StarOutlined} from "@mui/icons-material";
import WarehouseService from "../warehouses/WarehouseService";
import ContractService from "../accounts/contracts/ContractService";
import DateField from "../components/fields/DateField";
import FormControl from "@mui/material/FormControl";
import theme from "../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import moment from "moment";
import Notification from "../components/notifications/Notification";


function RatingPage(props) {

    const {t} = useTranslation();

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);

    const [ratingDateFrom, setRatingDateFrom] = useState(null);
    const [ratingDateTo, setRatingDateTo] = useState(null);

    const [deliveryDateFrom, setDeliveryDateFrom] = useState(null);
    const [deliveryDateTo, setDeliveryDateTo] = useState(null);

    const [loadingCSV, setLoadingCSV] = useState(false);
    const [loading, setLoading] = useState(false);

    const [scores, setScores] = useState([
        {score: 1, checked: false},
        {score: 2, checked: false},
        {score: 3, checked: false},
        {score: 4, checked: false},
        {score: 5, checked: false},
        {score: 6, checked: false},
        {score: 7, checked: false},
        {score: 8, checked: false},
        {score: 9, checked: false},
        {score: 10, checked: false}
    ]);


    const [averageScore, setAverageScore] = useState(0);
    const [ratingsCount, setRatingsCount] = useState(0);

    const [ratings, setRatings] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [pages, setPages] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses);
    }

    async function fetchContracts() {

        let contracts = await new ContractService().getContractsByFilters()
        setContracts(contracts)
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleRatingDateFromChange(event) {
        setRatingDateFrom(event);
    }

    function handleRatingDateToChange(event) {
        setRatingDateTo(event);
    }

    function handleDeliveryDateFromChange(event) {
        setDeliveryDateFrom(event);
    }

    function handleDeliveryDateToChange(event) {
        setDeliveryDateTo(event)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function handleScoresChange(event, index){

        let newScores = [...scores];
        newScores[index].checked = event.target.checked;
        setScores(newScores);

    }

    async function downloadCsv() {

        setLoadingCSV(true);

        let filters = {};

        filters.scores = [];

        for (let i = 0; i < scores.length; i++) {
            if (scores[i].checked) {
                filters.scores.push(scores[i].score);
            }
        }

        if (ratingDateFrom) {
            filters.ratingDateFrom = moment(ratingDateFrom).format('yyyy-MM-DD');
        }
        if (ratingDateTo) {
            filters.ratingDateTo = moment(ratingDateTo).format('yyyy-MM-DD');
        }
        if (deliveryDateFrom) {
            filters.deliveryDateFrom = moment(deliveryDateFrom).format('yyyy-MM-DD');
        }
        if (deliveryDateTo) {
            filters.deliveryDateTo = moment(deliveryDateTo).format('yyyy-MM-DD');
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (warehouse) {
            filters.deliveryWarehouseId = warehouse.id;
        }


        await new RatingService().getCSV(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCSV(false);
                })
            });


    }

    async function getRatings() {

        setLoading(true);

        let filters = {};

        filters.scores = [];

        for (let i = 0; i < scores.length; i++) {
            if(scores[i].checked){
                filters.scores.push(scores[i].score);
            }
        }

        if(ratingDateFrom){
            filters.ratingDateFrom = moment(ratingDateFrom).format('yyyy-MM-DD');
        }
        if(ratingDateTo){
            filters.ratingDateTo = moment(ratingDateTo).format('yyyy-MM-DD');
        }
        if(deliveryDateFrom){
            filters.deliveryDateFrom = moment(deliveryDateFrom).format('yyyy-MM-DD');
        }
        if(deliveryDateTo){
            filters.deliveryDateTo = moment(deliveryDateTo).format('yyyy-MM-DD');
        }

        if(contract){
            filters.contractId = contract.id;
        }

        if(warehouse){
            filters.deliveryWarehouseId = warehouse.id;
        }

        await new RatingService().getRatings(filters)
            .then(result => {
                setRatings(result.ratings);
                setAverageScore(result.averageScore);
                setRatingsCount(result.ratings.length);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })
    }

    useEffect(() => {
        getRatings();
    }, [])

    return <div>
        <Notification open={openNotify} severity={notifySeverity} duration={10000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <Card style={{marginBottom: "2%"}}>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("delivery_warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={contracts}
                getOptionLabel={option => option.code + " - " + option.name}
                value={contract}
                defaultValue={contract}
                noOptionsText={t("no_options")}
                onOpen={fetchContracts}
                onChange={handleContractChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        value={contract}
                        defaultValue={contract}
                    />
                )}
            />

            <div style={{display: "flex", margin: "auto", width: "70%"}}>
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("rating_date_from")}
                    value={ratingDateFrom}
                    onChange={handleRatingDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("rating_date_to")}
                    value={ratingDateTo}
                    onChange={handleRatingDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", margin: "auto", width: "70%"}}>
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("delivery_date_from")}
                    value={deliveryDateFrom}
                    onChange={handleDeliveryDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("delivery_date_to")}
                    value={deliveryDateTo}
                    onChange={handleDeliveryDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <FormControl style={{margin: "auto"}} component="fieldset">
                    <FormGroup aria-label="position" row>
                        {scores.map((score, index) => {
                            return <FormControlLabel
                                value={score.checked}
                                control={<Checkbox
                                    color={"primary"}
                                    checked={score.checked}
                                    onChange={event => handleScoresChange(event, index)}
                                />}
                                label={<Typography>{score.score}</Typography>}
                                labelPlacement="top"
                            />
                        })}
                    </FormGroup>
                </FormControl>
            </div>
            <div style={{display: "flex"}}>
                <IconButton onClick={getRatings}
                            disabled={loading}
                            style={{
                                backgroundColor: 'transparent',
                                margin: "auto",
                                marginBlock: "3%"
                            }}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined
                        style={{color: loading? "grey" : theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>

        <div style={{display: "flex", marginBottom: "2%"}}>
            <Chip style={{margin: "auto"}}
                  label={<Typography variant={"subtitle2"}>{t("shipment_count") + ratingsCount}</Typography>}
                  icon={<Info color={'info'}/>}/>
            <Chip style={{margin: "auto"}} label={<Typography
                variant={"subtitle2"}>{t("average_score") + ": " + averageScore.toFixed(2)}</Typography>}
                  icon={<StarOutlined color={"warning"}/>}/>
        </div>
        <Paper>
            {ratings.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t("ID")}
                                </TableCell>
                                <TableCell>
                                    {t("datetime")}
                                </TableCell>
                                <TableCell>
                                    {t("shipmentId")}
                                </TableCell>
                                <TableCell>
                                    {t("score")}
                                </TableCell>
                                <TableCell>
                                    {t("comment")}
                                </TableCell>
                                <TableCell>
                                    {t("selected_option")}
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {ratings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rating => {

                            return <Rating rating={rating}/>
                        })}
                    </Table>
                    <TablePagination
                        style={{width: "80%", margin: "auto"}}
                        rowsPerPageOptions={[30, 50, 100, {label: t("all"), value: -1}]}
                        labelRowsPerPage={t("rows_per_page")}
                        component="div"
                        count={ratings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

                :
                null
            }
        </Paper>

        <div style={{display: "flex", marginTop: "2%"}}>
            <Button style={{
                width: "fit-content",
                margin: "auto",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                marginBottom: "5%"
            }} disabled={loadingCSV || ratings.length < 1} onClick={downloadCsv}
                    endIcon={loadingCSV ?
                        <CircularProgress size={24} style={{color: "white"}}/> :
                        <CloudDownload/>}>{loadingCSV ? t("please_wait") : t("download_csv")}</Button>
        </div>
    </div>

}

export default RatingPage;