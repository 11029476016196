import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import WarehouseService from "../warehouses/WarehouseService";
import {Button, CircularProgress, DialogTitle, IconButton, Paper, TableRow, TextField} from "@mui/material";
import {Card, Table, TableCell, TableHead} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import DateField from "../components/fields/DateField";
import theme from "../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import LoadingListsService from "./LoadingListsService";
import {FiberNew} from "@mui/icons-material";
import StyledButton from "../components/buttons/StyledButton";
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import NewShipmentForm from "../shipments/NewShipmentForm";
import LoadingListForm from "./LoadingListForm";
import LoadingList from "./LoadingList";
import moment from "moment";


function LoadingListTable(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [departureWarehouses, setDepartureWarehouses] = useState([]);
    const [destinationWarehouse, setDestinationWarehouse] = useState(null);
    const [destinationWarehouses, setDestinationWarehouses] = useState([]);

    const [date, setDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [loadingLists, setLoadingLists] = useState([]);


    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
    }

    function handleDestinationWarehouseChange(event, warehouse) {
        setDestinationWarehouse(warehouse);
    }

    function handleDateChange(event) {
        setDate(event);
    }

    async function fetchDepartureWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setDepartureWarehouses(result.warehouses);
            })

    }

    async function fetchDestinationWarehouses() {
        let filters = {showAll: true};

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setDestinationWarehouses(result.warehouses);
            })
    }

    async function fetchLoadingLists() {

        let filters = {};

        if (departureWarehouse) {
            filters.warehouseId = departureWarehouse.id;
        }

        if (destinationWarehouse) {
            filters.destinationWarehouseId = destinationWarehouse.id;
        }

        filters.date = moment(date).format('yyyy-MM-DD');

        await new LoadingListsService().getLoadingLists(filters)
            .then(result => {
                setLoadingLists(result.loadingLists);
            })
    }

    async function callback() {
        await fetchLoadingLists();
    }

    useEffect(() => {
        fetchLoadingLists();
    }, [])

    return <Paper style={{width: "95%"}}>

        <Switch>
            <PrivateRoute exact path={"/loading-lists"}>
                <Card style={{width: "90%", margin: "auto"}}>
                    <div style={{display: "flex"}}>
                        <DialogTitle style={{margin: "auto"}}>{t("loading_lists")}</DialogTitle>
                    </div>
                    <div style={{display: "flex"}}>
                        <Autocomplete
                            id="warehouse"
                            options={departureWarehouses}
                            size={"small"}
                            style={{width: "20%", margin: "auto"}}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={departureWarehouse}
                            defaultValue={departureWarehouse}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchDepartureWarehouses}
                            onChange={handleDepartureWarehouseChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("departure_warehouse")}
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    margin="dense"
                                    size={"small"}
                                    value={departureWarehouse}
                                    defaultValue={departureWarehouse}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{display: "flex"}}>
                        <Autocomplete
                            id="warehouse"
                            options={destinationWarehouses}
                            size={"small"}
                            style={{width: "20%", margin: "auto"}}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={destinationWarehouse}
                            defaultValue={destinationWarehouse}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchDestinationWarehouses}
                            onChange={handleDestinationWarehouseChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("destination_warehouse")}
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    margin="dense"
                                    size={"small"}
                                    value={destinationWarehouse}
                                    defaultValue={destinationWarehouse}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <DateField
                            style={{margin: "auto", width: "20%"}}
                            label={t("date")}
                            value={date}
                            onChange={handleDateChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                    </div>
                    <div style={{display: "flex", paddingBottom: "5%", marginTop: "2%"}}>
                        <IconButton onClick={fetchLoadingLists}
                                    disabled={loading}
                                    style={{backgroundColor: 'transparent', margin: "auto"}}>{loading ?
                            <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                            <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                    </div>

                </Card>
                <div style={{display: "flex"}}>
                    <Button style={{
                        margin: "auto",
                        marginBlock: "5%",
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }} onClick={() => history.push("/loading-lists/new")}
                            startIcon={<FiberNew/>}>{t("new_loading_list")}</Button>
                </div>

                {loadingLists.length > 0 ?

                        <Table style={{width: "95%", margin: "auto"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t("ID")}
                                    </TableCell>
                                    <TableCell>
                                        {t("opening_date")}
                                    </TableCell>
                                    <TableCell>
                                        {t("closing_date")}
                                    </TableCell>
                                    <TableCell>
                                        {t("departure_warehouse")}
                                    </TableCell>
                                    <TableCell>
                                        {t("destination_warehouse")}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {loadingLists.map(loadingList => {
                                return <LoadingList
                                    loadingList={loadingList}
                                    callback={callback}
                                    />
                            })}


                        </Table>


                    :
                    null
                }

            </PrivateRoute>
            <PrivateRoute exact path={"/loading-lists/new"}>
                <LoadingListForm/>
            </PrivateRoute>
        </Switch>


    </Paper>


}

export default LoadingListTable;