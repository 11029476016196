import {IconButton, TableRow, Typography} from "@mui/material";
import {Box, Collapse, Table, TableCell, TableHead} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Delete, Edit, Save} from "@material-ui/icons";
import InfoChip from "../../components/chips/InfoChip";
import ParcelEvent from "../ParcelEvent";
import {useTranslation} from "react-i18next";
import ShipmentService from "../ShipmentService";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ParcelEventTabItem from "./ParcelEventTabItem";
import ParcelService from "../services/ParcelService";
import Notification from "../../components/notifications/Notification";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";


function ParcelsTabItem(props) {

    const {t} = useTranslation();
    let parcel = props.parcel;
    const eventCodes = props.eventCodes
    const warehouses = props.warehouses
    const drivers = props.drivers;
    const [events, setEvents] = useState([]);
    const [openRows, setOpenRows] = useState(false);
    const [editing, setEditing] = useState(false);

    const [length, setLength] = useState(parcel.length);
    const [height, setHeight] = useState(parcel.height);
    const [depth, setDepth] = useState(parcel.depth);
    const [weight, setWeight] = useState(parcel.weight);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

    function fetchEvents() {
        let shipmentService = new ShipmentService();
        shipmentService.getEventsOfParcel(parcel.id)
            .then(response => {
                setEvents(response);
            })
    }

    function handleLengthChange(event, length) {
        setLength(length);
    }

    function handleHeightChange(event, height) {
        setHeight(height);
    }

    function handleDepthChange(event, depth) {
        setDepth(depth);
    }

    function handleWeightChange(event, weight) {
        setWeight(weight);
    }

    async function deleteParcel() {
        let input = {};

        input.parcels = [];
        input.parcels.push(parcel.id);

        await new ShipmentService().deleteParcels(input, parcel.shipmentId)
            .then(result => {
                setEditing(false);
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.trigger();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function updateParcel() {

        let input = {};

        input.weight = weight;
        input.length = length;
        input.depth = depth;
        input.height = height;

        await new ParcelService().updateParcel(input, parcel.id)
            .then(result => {
                setEditing(false);
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.trigger();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    useEffect(() => {
        fetchEvents();
    }, [])


    return <>
        <TableRow>
            <TableCell width={"10%"} button onClick={() => setOpenRows(!openRows)}
                       style={{cursor: "pointer"}}>  <Typography variant={"subtitle2"}>{parcel.id}</Typography></TableCell>
            <TableCell width={"15%"} button onClick={() => setOpenRows(!openRows)}
                       style={{cursor: "pointer"}}>  <Typography variant={"subtitle2"}>{parcel.barcode}</Typography></TableCell>
            <TableCell width={"15%"} button onClick={() => setOpenRows(!openRows)}
                       style={{cursor: "pointer"}}>  <Typography variant={"subtitle2"}>{parcel.customerBarcode}</Typography></TableCell>
            {editing ?
                <TableCell width={"15%"}>
                    <CurrencyTextField
                        label={t("length")}
                        variant={"outlined"}
                        margin={"dense"}
                        style={{width: "100%"}}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={length}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleLengthChange}
                    />
                </TableCell>
                :
                <TableCell button onClick={() => setOpenRows(!openRows)}
                           style={{cursor: "pointer"}}>
                    <Typography variant={"subtitle2"}>
                        {parcel.length}{" "}{"cm"}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        {parcel.measuredLength ?
                            <Typography variant={"subtitle2"}>
                                {parcel.measuredLength}{" "}{"cm"}
                            </Typography>
                            :
                            <Typography variant={"subtitle2"}>
                                {t("not_measured")}
                            </Typography>
                        }
                    </Typography>

                </TableCell>
            }
            {editing ?
                <TableCell width={"15%"}>
                    <CurrencyTextField
                        label={t("height")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={height}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleHeightChange}
                    />
                </TableCell>
                :
                <TableCell button onClick={() => setOpenRows(!openRows)} style={{cursor: "pointer"}}>
                    <Typography variant={"subtitle2"}>
                        {parcel.height}{" "}{"cm"}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        {parcel.measuredHeight ?
                            <Typography variant={"subtitle2"}>
                                {parcel.measuredHeight}{" "}{"cm"}
                            </Typography>
                            :
                            <Typography variant={"subtitle2"}>
                                {t("not_measured")}
                            </Typography>
                        }
                    </Typography>

                </TableCell>
            }
            {editing ?
                <TableCell width={"15%"}>
                    <CurrencyTextField
                        label={t("depth")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={depth}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleDepthChange}
                    />
                </TableCell>
                :
                <TableCell button onClick={() => setOpenRows(!openRows)}
                           style={{cursor: "pointer"}}>
                    <Typography variant={"subtitle2"}>
                        {parcel.depth}{" "}{"cm"}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        {parcel.measuredDepth ?
                            <Typography variant={"subtitle2"}>
                                {parcel.measuredDepth}{" "}{"cm"}
                            </Typography>
                            :
                            <Typography variant={"subtitle2"}>
                                {t("not_measured")}
                            </Typography>
                        }
                    </Typography>
                </TableCell>
            }
            {editing ?
                <TableCell width={"15%"}>
                    <CurrencyTextField
                        label={t("weight")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={2}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={weight}
                        currencySymbol={<Typography variant={"caption"}>{"kg"}</Typography>}
                        onChange={handleWeightChange}
                    />
                </TableCell>
                :
                <TableCell button onClick={() => setOpenRows(!openRows)}
                           style={{cursor: "pointer"}}>
                    <Typography variant={"subtitle2"}>
                        {parcel.weight.toFixed(2)}{" "}{"Kg"}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        {parcel.measuredWeight ?
                            <Typography variant={"subtitle2"}>
                                {parcel.measuredWeight.toFixed(2)}{" Kg"}
                            </Typography>
                            :
                            <Typography variant={"subtitle2"}>
                                {t("not_measured")}
                            </Typography>
                        }
                    </Typography>
                </TableCell>
            }
            {editing ?
                <TableCell width={"15%"}>
                    <IconButton onClick={updateParcel}><Save/></IconButton>
                </TableCell>
                :
                <TableCell width={"15%"}>
                    <IconButton onClick={() => setEditing(true)}><Edit/></IconButton>
                    <IconButton onClick={() => setConfirmMessageOpen(true)}><Delete/></IconButton>
                </TableCell>
            }
        </TableRow>
        <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                <Collapse in={openRows} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <div style={{display: "flex", width: "100%"}}>
                            {events.length === 0 ?
                                <InfoChip>{t("no_events")}</InfoChip>
                                :

                                <Table size="small" aria-label="details" style={{width: "100%", margin: "auto"}}>
                                    <TableHead>
                                        <TableRow style={{border: "2px solid"}}>
                                            <TableCell>{t("datetime")}</TableCell>
                                            <TableCell>{t("event_code")}</TableCell>
                                            <TableCell>{t("departure_warehouse")}</TableCell>
                                            <TableCell>{t("notes")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <ParcelEventTabItem warehouses={warehouses} eventCodes={eventCodes} events={events}
                                                 drivers={drivers}/>
                                </Table>
                            }
                        </div>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>
        <ConfirmDialog open={confirmMessageOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteParcel}
                       onCancel={() => setConfirmMessageOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
    </>


}

export default ParcelsTabItem;