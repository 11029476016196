import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, {useEffect, useState} from "react";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import RoutesForm from "./RoutesForm";
import RouteService from "./RouteService";
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    useTheme
} from "@material-ui/core";
import Notification from "../../components/notifications/Notification";
import IconButton from "@material-ui/core/IconButton";
import {Delete, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import Route from "./Route";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: 'fit-content',
        minWidth: "40%",
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function RoutesTable(props) {

    const [routes, setRoutes] = useState([]);

    const [fetching, setFetching] = useState(false);
    const [pages, setPages] = useState([]);

    const location = useLocation();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();


    const history = useHistory();

    const {t} = useTranslation();

    function goToRouteForm() {
        history.push("/routes/new")
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };

    function closeNotification() {
        setOpenNotify(false);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    async function fetchRoutes() {

        setFetching(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        await new RouteService().getRoutes(filters)
            .then(data => {
                setRoutes(data.routes);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                setFetching(false);

                setNotificationMessage(error.status + " - " + error.message);
                setNotifySeverity('error');
                setOpenNotify(true);
            })
    }


    async function deleteRoute(id) {

        await new RouteService().deleteRoute(id)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
            })
            .catch(error => {
                setNotificationMessage(error.status + " - " + error.message);
                setNotifySeverity('error');
                setOpenNotify(true);
            })

        setOpenConfirmDelete(false);

        setRefreshTable(!refreshTable);

    }

    useEffect(() => {
        fetchRoutes();
    }, [refreshTable, location, page, rowsPerPage])


    return <div className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/routes`}>
                <Button className={classes.button} startIcon={<AddCircleIcon/>} onClick={goToRouteForm}
                        variant="contained" color="primary">
                    {t("create_new_route")}
                </Button>
                {fetching ?

                    <LoadingComponent/>

                    :

                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableCell>{t("ID")}</TableCell>
                            <TableCell>{t("name")}</TableCell>
                            <TableCell>{t("warehouses")}</TableCell>
                            <TableCell/>
                        </TableHead>

                        <TableBody>


                            {routes.map((route, index) => {

                                return <Route route={route} delete={deleteRoute}/>

                            })}
                        </TableBody>

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{notificationMessage}</Notification>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: false,
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Table>
                }
            </PrivateRoute>
            <PrivateRoute exact path={`/routes/new`}>
                <RoutesForm/>
            </PrivateRoute>
        </Switch>


    </div>


}

export default RoutesTable;