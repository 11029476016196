import React, {useEffect, useState} from "react";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Button, CardActions, InputAdornment, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {DialogTitle} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {CancelOutlined, CheckCircleOutlined, Info, Visibility, VisibilityOff} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import Notification from "../components/notifications/Notification";
import AccountService from "../accounts/AccountService";
import WarehouseService from "../warehouses/WarehouseService";
import CostCenterService from "../accounting/cost_center/CostCenterService";
import DriverService from "./DriverService";
import ContractService from "../accounts/contracts/ContractService";
import InfoChip from "../components/chips/InfoChip";

const useStyles = makeStyles(() => ({
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "4%",
        paddingBottom: "2%"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    button: {
        marginBottom: "2%"
    }
    }
));

function DriverForm(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    const {id} = useParams();

    const [loading, setLoading] = useState(true);

    const [passwordShown, setPasswordShown] = useState(false);
    const [showField, setShowField] = useState(false);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [warehouse, setWarehouse] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [contract, setContract] = useState(null);

    const [suppliers, setSuppliers] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [contracts, setContracts] = useState([]);


    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function handleMobileNumberChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setMobileNumber(event.target.value);
        }
    }

    async function fetchContracts() {
        let filters = {};

        filters.direction = "BUY"

        if (supplier) {
            filters.accountId = supplier.id;
        }
        let contracts = await new ContractService().getContractsByFilters(filters);
        setContracts(contracts);
    }

    async function fetchDataAndHandleEdit() {

        setLoading(true);

        let accountService = new AccountService();
        let filters = {};
        filters.isSupplier = true;
        const acc = await accountService.getAccounts(filters)
        setSuppliers(acc);

        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null)
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);

        if (!id) {

            if(wares?.warehouses?.length === 1){
                setWarehouse(wares.warehouses[0]);
            }

            setLoading(false);
            return;
        }


        try {
            let driverService = new DriverService();
            const driver = await driverService.getDriverById(id);

            setName(driver.name);
            setSurname(driver.surname);
            setMobileNumber(driver.mobileNumber);

            for (let i = 0; i < acc.length; ++i) {
                if (driver.accountID === acc[i].id) {
                    setSupplier(acc[i]);
                    break;
                }
            }

            for (let i = 0; i < wares.length; ++i) {
                if (driver.warehouseID === wares[i].id) {
                    setWarehouse(wares[i]);
                    break;
                }
            }

           let contracts = await new ContractService().getContractsByFilters();

            for (let i = 0; i < contracts.length; ++i) {
                if (driver.contractCode === contracts[i].code) {
                    setContract(contracts[i]);
                    break;
                }
            }

        } catch (error) {
            error.response.json().then((response) => {
                setNotifySeverity('error');
                setNotificationMessage(response.message);
                setOpenNotify(true);
            });
        }

        setLoading(false);

    }

    function handleClose() {
        history.goBack();
    }

    function handleSubmit(event) {

        event.preventDefault();

        let driver = {};

        if(name !== ''){
            driver.name = name;
        }
        if(surname !== ''){
            driver.surname = surname;
        }
        if(mobileNumber !== ''){
            driver.mobileNumber = mobileNumber;
        }
        if(password !== ''){
            driver.password = password;
        }
        if(warehouse !== null){
            driver.warehouseId = warehouse.id;
        }
        if(supplier !== null){
            driver.supplierId = supplier.id;
        }
        if(contract !== null){
            driver.contractId = contract.id;
        }

        let driverService = new DriverService();

        if (id) {
            driverService.updateDriver(id, driver)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/drivers`);
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            driverService.createDriver(driver)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/drivers`);
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }


    }

    useEffect(() => {
        fetchDataAndHandleEdit();
    }, [])

    function closeNotification() {
        setOpenNotify(false);
    }

    function createPasswordField() {
        if (!id) {
            return (<TextField
                    label={t("password")}
                    variant="outlined"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    required={true}
                    error={!password}
                    margin="dense"
                    className={classes.field}
                    helperText={!password ? t("required") : null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{backgroundColor: 'transparent'}}
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordShown(!passwordShown)}
                                >
                                    {passwordShown ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}

                />

            )
        } else {
            return (
                <div>
                    <Button className={classes.button} type="button" onClick={() => setShowField(!showField)}>
                        {showField ? t("change_password") : t("change_password")}
                    </Button>
                    {showField ? (
                        <TextField
                            label={t("new_password")}
                            variant="outlined"
                            type={passwordShown ? "text" : "password"}
                            onChange={event => setPassword(event.target.value)}
                            value={password}
                            margin="dense"
                            className={classes.field}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordShown(!passwordShown)}
                                        >
                                            {passwordShown ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    ) : null}

                </div>
            )
        }
    }


    return <div>

        {loading ?

            <LoadingComponent/>

            :

            <div>
                <DialogTitle>{t("driver_data")}</DialogTitle>
                <TextField
                    required
                    label={t("name")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setName(event.target.value)}
                    value={name}
                    error={!name}
                    className={classes.field}
                    helperText={!name ? t("required") : null}

                />
                <TextField
                    required
                    label={t("surname")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setSurname(event.target.value)}
                    value={surname}
                    error={!surname}
                    className={classes.field}
                    helperText={!surname ? t("required") : null}
                />
                <TextField
                    label={t("mobile_number")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={handleMobileNumberChange}
                    value={mobileNumber}
                    className={classes.field}
                />
                {createPasswordField()}
                <div>
                    <Autocomplete
                        id="supplier"
                        size="small"
                        style={{minWidth: "25%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                        options={suppliers}
                        getOptionLabel={supplier => supplier.accountCorporateCode + " - " + supplier.companyName}
                        value={supplier}
                        defaultValue={supplier}
                        required
                        error={!supplier}
                        // onOpen={fetchSuppliers}
                        helperText={!supplier ? t("required") : null}
                        onChange={(event, account) => setSupplier(account)}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        noOptionsText={t("no_options")}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("supplier")}
                            variant="outlined"
                            margin="dense"
                            value={supplier}
                            defaultValue={supplier}
                            required
                            error={!supplier}
                            helperText={!supplier ? t("required") : null}
                        />}
                    />
                    <Autocomplete
                        id="contract"
                        size="small"
                        disabled={!supplier}
                        options={contracts}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={contract}
                        defaultValue={contract}
                        noOptionsText={t("no_options")}
                        onChange={(event, contract) => setContract(contract)}
                        onOpen={fetchContracts}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.field}
                                label={t("contract")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                                helperText={!supplier? t("select_supplier") : null}
                            />
                        )}
                    />
                    <Autocomplete
                        id="warehouse"
                        size="small"
                        style={{minWidth: "25%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                        options={warehouses}
                        getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        required
                        // onOpen={fetchWarehouses}
                        error={!warehouse}
                        helperText={!warehouse ? t("required") : null}
                        onChange={(event, warehouse) => setWarehouse(warehouse)}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        noOptionsText={t("no_options")}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                            required
                            error={!warehouse}
                            helperText={!warehouse ? t("required") : null}
                        />}
                    />
                </div>
                <CardActions className={classes.button_container}>
                    <Button
                        className={classes.cancel_button}
                        endIcon={<CancelOutlined/>}
                        onClick={handleClose}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>


            </div>
        }

    </div>

}

export default DriverForm;