import React, {useEffect, useState} from "react";
import {Button, Checkbox, Divider, Input, Paper, TableCell, TableHead} from "@material-ui/core";
import BankTransactionService from "../bank_transactions/BankTransactionService";
import {useTranslation} from "react-i18next";
import {Upload} from "@mui/icons-material";
import {Autocomplete, DialogTitle, Table, TableRow, TextField, Typography} from "@mui/material";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import moment from "moment";
import {Check, Error, SaveOutlined} from "@material-ui/icons";
import theme from "../theme/theme";
import CompanyService from "../companies/CompanyService";
import BankAccountService from "../bank_accounts/BankAccountService";


function ImportFromCbiPage(props){

    const {t} = useTranslation();

    const [movements, setMovements] = useState([]);

    const [allChecked, setAllChecked] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);

    const [bankAccount, setBankAccount] = useState(null);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleAllChecked(event){
        setAllChecked(event.target.checked);


        for (let i = 0; i < movements.length; i++) {
            if (movements[i].imported !== true) {
                movements[i].checked = !!event.target.checked;
            }
        }
    }

    function handleChecked(event, key) {

        movements[key].checked = event.target.checked;

        if (movements.some(movement => movement.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }


    async function readFile(event) {

        setLoading(true);

        let _file = event.target.files[0];

        let dataURI = await get_file_array(_file);

        if(null == dataURI){
            setLoading(false);
            return;
        }

        setMovements([]);

        let indexOfComma = dataURI.indexOf(',');

        let base64;

        if (indexOfComma === -1) {
            //TODO gestire errore
        } else {
            base64 = dataURI.substring(indexOfComma + 1);

            await new BankTransactionService().importFromCbi(base64)
                .then(response => {

                    let data = [];

                    for (let i = 0; i < response.length; i++) {
                        let statusData = {};
                        statusData = response[i];
                        statusData.checked = true;
                        statusData.imported = false;

                        if(!statusData.description){
                            statusData.description = statusData.bankReason;
                        }

                        data.push(statusData);
                    }

                    setMovements(data);
                })
                .catch(error => {

                })
        }

        setLoading(false);

        setRefresh(!refresh);


    }

    function get_file_array(file) {

        if(file){
            return new Promise(resolve => {

                let baseURL = "";

                const reader = new FileReader();
                // reader.onload = (event) => { acc(event.target.result) };
                reader.onload = () => {
                    // Make a fileInfo Object

                    baseURL = reader.result;
                    resolve(baseURL);
                };
                reader.onerror = (err) => {
                    err(err)
                };
                reader.readAsDataURL(file);
            });
        } else {
            return null;
        }

    }

    async function saveTransactions() {

        let inputs = [];

        for (let i = 0; i < movements.length; i++) {

            if (movements[i].checked) {

                let transaction = {

                    idReference: i,
                    valueDate: moment(movements[i].valueDate).format('yyyy-MM-DD'),
                    accountingDate: moment(movements[i].accountingDate).format('yyyy-MM-DD'),
                    controlInternalNumber: null,
                    bankCode: null,
                    branchCode: null,
                    accountNumber: null,
                    reason: movements[i].description,
                    reference: null,
                    chequeNumber: null,
                    amount: movements[i].value,
                    bankAccountId: bankAccount?.id
                };

                inputs.push(transaction);

            }

        }

        await new BankTransactionService().saveTransaction(inputs)
            .then(results => {

                for (let i = 0; i < movements.length; i++) {
                    if(movements[i].checked){

                        if(results.length > 1 && results.some(result => result.id === i)){
                            movements[i].error = true;
                            movements[i].errorMessage = results.filter(result => result.id === i)[0].warning?.message;
                        } else {
                            movements[i].imported = true;
                        }
                    }
                }

            })
            .catch(error => {

            })

        setRefresh(!refresh);
    }

    async function fetchCompanies() {


        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchBankAccounts() {

        let filters = {};

        if (company) {
            filters.companyId = company.id;
        }

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }




    useEffect(()=>{

    }, [refresh])

    return  <Paper style={{
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
    }}>
        <div>

        </div>

            <input style={{marginBlock: "5%"}} accept="text/plain" id={"txt-import-button"} type="file" onInput={readFile}/>

        {loading ?
            <LoadingComponent/>
            :
            <div>
                {movements.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox color="primary"
                                              onChange={handleAllChecked}
                                              checked={allChecked}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("bank_reason")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("value_date")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("accounting_date")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("description")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("value")}</Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {movements.map((movement, key) => {
                            return <TableRow>
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        disabled={movement.imported}
                                        onChange={event => handleChecked(event, key)}
                                        checked={movement.checked}/>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{movement.bankReason}</Typography>
                                </TableCell>
                                <TableCell>
                                    {new Date(movement.valueDate).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(movement.accountingDate).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {movement.description}
                                </TableCell>
                                <TableCell>
                                    <Typography color={movement.value > 0? "green" : "red"} variant={"subtitle2"}> {movement.value.toFixed(2)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color={movement.imported? "green" : "red"} variant={"subtitle2"}>{movement.imported? <Check/> : <>{movement.error?movement.errorMessage : null}</>}</Typography>
                                </TableCell>
                            </TableRow>
                        })}
                    </Table>
                    :
                    null
                }

                {movements.length > 0?
                    <div>
                        <div style={{marginBlock: "2%", height: "20%", backgroundColor: theme.palette.primary.main, display: "flex"}}>
                            <DialogTitle><Typography variant={"button"} color={"white"}>{t("company_data")}</Typography></DialogTitle></div>
                        <div style={{marginTop: "2%", display: "flex", marginBottom: "2%"}}>
                            <Autocomplete
                                id="companies"
                                size="small"
                                style={{width: "30%", margin: "auto"}}
                                options={companies}
                                getOptionLabel={option => option.companyName}
                                value={company}
                                defaultValue={company}
                                noOptionsText={t("no_options")}
                                onOpen={fetchCompanies}
                                onChange={(event, value) => {
                                    setCompany(value);
                                    setBankAccount(null);
                                }}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("company")}
                                        variant="outlined"
                                        margin="dense"
                                        value={company}
                                        defaultValue={company}
                                        required
                                        error={!company}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="bank-account"
                                size="small"
                                disabled={!company}
                                options={bankAccounts}
                                style={{width: "30%", margin: "auto"}}
                                getOptionLabel={option => option.bankName + " - " + option.iban}
                                value={bankAccount}
                                defaultValue={bankAccount}
                                noOptionsText={t("no_options")}
                                onOpen={fetchBankAccounts}
                                onChange={(event, value) => {
                                    setBankAccount(value);
                                }}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("bank_account")}
                                        variant="outlined"
                                        margin="dense"
                                        value={bankAccount}
                                        defaultValue={bankAccount}
                                        required
                                        error={!bankAccount}
                                    />
                                )}
                            />
                        </div>

                        <Button endIcon={<SaveOutlined/>} style={{
                            width: "fit-content",
                            margin: "auto",
                            marginBlock: "5%",
                            backgroundColor: theme.palette.primary.main,
                            color: "white"
                        }}
                                onClick={saveTransactions}>{t("save")}</Button>
                    </div>
                    :
                    null
                }

            </div>
        }




    </Paper>




} export default ImportFromCbiPage;