import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import ParcelEvent from "./ParcelEvent";
import ShipmentService from "./ShipmentService";
import InfoChip from "../components/chips/InfoChip";

const useStyles = makeStyles({
    root: {
        cursor: "pointer",
        transition: "all 0.2s",

        "&:hover": {

            background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'


        },
        '& > *': {
            borderBottom: 'unset',
        },
    },
    iconCell: {
        justifyContent: "end",
        margin: "auto",
        textAlign: 'end',
        display: "block",
    },

});


function Parcel(props) {

    const parcel = props.parcel;
    const eventCodes = props.eventCodes
    const warehouses = props.warehouses
    const drivers = props.drivers;
    const {t} = useTranslation();
    const [events, setEvents] = useState([]);
    const [openRows, setOpenRows] = useState(false);
    let classes = useStyles();

    function fetchEvents() {
        let shipmentService = new ShipmentService();
        shipmentService.getEventsOfParcel(parcel.id)
            .then(response => {
                setEvents(response);
            })
    }

    function formatMeasures() {
        let measures = '';
        measures += parcel.weight + " kg";
        measures += " x " + parcel.length + " cm";
        measures += " x " + parcel.height + " cm";
        measures += " x " + parcel.depth + " cm";

        return measures;
    }

    useEffect(() => {
        fetchEvents()
    }, [parcel])

    return <React.Fragment>
        <TableRow button onClick={() => setOpenRows(!openRows)} className={classes.root}>
            <TableCell component="th" scope="row">
                {parcel.id}
            </TableCell>
            <TableCell align="left">
                {parcel.barcode}
            </TableCell>
            <TableCell>
                {parcel.customerBarcode}
            </TableCell>
            <TableCell>
                {formatMeasures()}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                <Collapse in={openRows} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        {events.length === 0 ?
                            <InfoChip>{t("no_events")}</InfoChip>
                            :
                            <Table size="small" aria-label="details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("datetime")}</TableCell>
                                        <TableCell>{t("event_code")}</TableCell>
                                        <TableCell/>
                                        <TableCell>{t("warehouse")}</TableCell>
                                        <TableCell>{t("notes")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {events.map((event, index) => {
                                    return <ParcelEvent warehouses={warehouses} eventCodes={eventCodes} event={event}
                                                        drivers={drivers} destination={props.destination}/>
                                })}
                            </Table>
                        }


                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>


}

export default Parcel;