import {
    Button,
    ClickAwayListener,
    Icon,
    Menu,
    MenuItem,
    Popover,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {Dangerous, DoNotDisturb} from "@mui/icons-material";
import {BlockOutlined, Edit, MoneyOff, SpeakerNotesOutlined, Warning} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {IconButton} from "@mui/material";


function Account(props) {
    let account = props.account;

    const history = useHistory();

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    let permissions = localStorage.getItem("permissions");

    const [openTooltipAdmin, setOpenTooltipAdmin] = useState(false)
    const [openTooltipCredit, setOpenTooltipCredit] = useState(false)

    function handleTooltipAdminClose() {
        setOpenTooltipAdmin(false);
    }

    function handleTooltipAdminOpen() {
        setOpenTooltipAdmin(true);
    }

    function handleTooltipCreditClose() {
        setOpenTooltipCredit(false);
    }

    function handleTooltipCreditOpen() {
        setOpenTooltipCredit(true);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToAccountUpdateForm() {
        history.push(`/accounts/${account.id}/edit`);
    }

    useEffect(() => {

    })

    return <TableRow>
        <TableCell>
            {account.id}
        </TableCell>
        <TableCell>
            {account.accountCorporateCode}
        </TableCell>
        <TableCell>
            {account.companyName}
        </TableCell>
        <TableCell>
            {account.vatNumber}
        </TableCell>
        <TableCell>
            {account.fiscalCode}
        </TableCell>
        <TableCell>
            {account.contracts.map(contract => {
                return <Typography>{contract.code + " "}</Typography>
            })
            }
        </TableCell>
        <TableCell >
            <div style={{display: "flex"}}>
                <ClickAwayListener style={{display: account.administrativeBlocked ? null : "none"}} onClickAway={handleTooltipAdminClose}>
                    <div>
                        <Tooltip
                            style={{display: account.administrativeBlocked ? null : "none"}}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipAdminClose}
                            open={openTooltipAdmin}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={<React.Fragment>
                                {/*<Card style={{width: 300}}>*/}
                                <Typography variant={"subtitle2"}>{t("administrative_block")}</Typography>
                                {/*</Card>*/}

                            </React.Fragment>}

                        >
                            <Icon onMouseEnter={handleTooltipAdminOpen} onMouseLeave={handleTooltipAdminClose}><DoNotDisturb
                                style={{display: account.administrativeBlocked ? null : "none"}}/></Icon>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
                <ClickAwayListener style={{display: account.creditBlocked ? null : "none"}} onClickAway={handleTooltipCreditClose}>
                    <div>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            style={{display: account.creditBlocked ? null : "none"}}
                            onClose={handleTooltipCreditClose}
                            open={openTooltipCredit}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={<React.Fragment>
                                {/*<Card style={{width: 300}}>*/}
                                <Typography variant={"subtitle2"}>{t("credit_block")}</Typography>
                                {/*</Card>*/}

                            </React.Fragment>}

                        >
                            <Icon onMouseEnter={handleTooltipCreditOpen} onMouseLeave={handleTooltipCreditClose}><MoneyOff
                                style={{display: account.creditBlocked ? null : "none"}}/></Icon>

                        </Tooltip>
                    </div>
                </ClickAwayListener>
            </div>

        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem disabled={!permissions.includes("accounts:write")}
                          onClick={goToAccountUpdateForm}><Edit/>&nbsp;{t("edit")}</MenuItem>
                {/*<MenuItem disabled={!permissions.includes("su:admin")} onClick={openConfirmDialog}><Warning*/}
                {/*    color={"error"}/>{t("force_delete")}</MenuItem>*/}

            </Menu>
        </TableCell>
    </TableRow>
}

export default Account;