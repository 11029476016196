import {Card, CardActions, Paper, Table, TableHead} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import MessageTemplateService from "../accounts/contracts/message_templates/MessageTemplateService";
import MessageTemplate from "../accounts/contracts/message_templates/MessageTemplate";
import LanguageService from "../accounts/contracts/message_templates/LanguageService";
import {Box, Button, DialogTitle, Tab, TableCell, TableRow, Tabs, TextField, Typography} from "@mui/material";
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import MessageTemplateForm from "../accounts/contracts/message_templates/MessageTemplateForm";
import {ArrowBackRounded, ClearAll, Edit, Save, SearchOutlined} from "@material-ui/icons";
import StyledButton from "../components/buttons/StyledButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import theme from "../theme/theme";
import {Autocomplete} from "@mui/lab";
import PropTypes from "prop-types";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import PasswordChangePage from "./PasswordChangePage";
import PrimaedicolaSettings from "./PrimaedicolaSettings";


function SettingsPage(props) {


    const {t} = useTranslation();

    const history = useHistory();

    const [templates, setTemplates] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [language, setLanguage] = useState(null)
    const [notificationType, setNotificationType] = useState(null)
    const [messageType, setMessageType] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [refreshAll, setRefreshAll] = useState(false);

    const [loading, setLoading] = useState(false);

    const [tabNumber, setTabNumber] = useState('1');

    let permissions = localStorage.getItem("permissions");

    const handleTabChange = (event, newValue) => {
        setTabNumber(newValue);
    };

    let notificationTypes = [
        'SHIPMENT_REGISTERED',
        'SHIPMENT_TAKEN_IN_CHARGE',
        'IN_DELIVERY',
        'DELIVERED',
        'DELIVERY_KO',
        'WAREHOUSE_STORAGE',
        'PIN_CODE',
        'RETURN_REGISTERED',
        'RETURN_COLLECTED',
        'RETURN_DELIVERED',
        'CUSTOMER_SERVICE_NEW_RETURN',
        'OPERATIONS_ORDER_LOCK',
        'OPERATIONS_BOOKING_UPDATE',
        'OPERATIONS_NEW_SC',
        'PUDO_CHECK_IN'
    ]

    let messageTypes = [
        'EMAIL', 'TEXT_MESSAGE'
    ]

    function resetFilters() {
        setLanguage(null);
        setNotificationType(null);
        setMessageType(null);
        setRefreshAll(!refreshAll);
    }

    function applyFilters() {
        setRefreshAll(!refreshAll);
    }

    async function fetchLanguages() {

        let lang = await new LanguageService().getLanguages()
            .catch(error => {

            });

        setLanguages(lang);
    }

    async function fetchTemplates() {
        setLoading(true);

        let filters = {};

        if (language) {
            filters.language = language.iso639Alpha2;
        }

        if (messageType) {
            filters.messageType = messageType;
        }

        if (notificationType) {
            filters.notificationType = notificationType;
        }


        await new MessageTemplateService().getTemplates(filters)
            .then(result => {
                setTemplates(result);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }

    function goToForm(id) {
        if (id) {
            history.push(`/settings/templates/${id}`)
        } else {
            history.push(`/settings/templates/new`)
        }

    }

    useEffect(() => {
        fetchLanguages();
        fetchTemplates();
    }, [refreshAll])

    return <Paper>
        <Switch>
            <PrivateRoute exact path={"/settings"}>
                <TabContext value={tabNumber}>
                    <Box sx={{
                        width: "fit-content",
                        borderBottom: 0,
                        borderColor: theme.palette.primary.main,
                        margin: "auto"
                    }}>
                        <TabList onChange={handleTabChange} aria-label="tabs-about-me">
                            <Tab label={t("change_password")} value="1"/>
                            {permissions.includes("su:admin") ?
                                <Tab label={t("message_templates")} value="2"/>
                                :
                                <></>
                            }
                            {permissions.includes("su:admin") ?
                                <Tab label={t("primaedicola_settings")} value="3"/>
                                :
                                <></>
                            }
                            {/*<Tab label={t("annotations")} value="3"/>*/}
                            {/*<Tab label={t("administration")} value="4"/>*/}
                        </TabList>
                    </Box>
                    <TabPanel style={{width: "100%"}} value="1">
                        <PasswordChangePage/>
                    </TabPanel>
                    {permissions.includes("su:admin") ?
                        <TabPanel style={{width: "100%"}} value="2">
                            {loading ?
                                <LoadingComponent/>
                                :
                                <div style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    justifyContent: "center",
                                    display: "block"
                                }}>
                                    {/*<DialogTitle>{t("message_templates")}</DialogTitle>*/}
                                    <Card style={{width: "50%", margin: "auto", marginBlock: "3%"}}>
                                        <Autocomplete
                                            size="small"
                                            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
                                            options={languages}
                                            getOptionLabel={option => option.name}
                                            value={language}
                                            defaultValue={language}
                                            noOptionsText={t("no_options")}
                                            onChange={(event, lang) => setLanguage(lang)}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("language")}
                                                    size={"small"}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={language}
                                                    defaultValue={language}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            size="small"
                                            style={{width: "35%", margin: "auto", marginBlock: "1%"}}
                                            options={notificationTypes}
                                            getOptionLabel={option => t(option)}
                                            value={notificationType}
                                            defaultValue={notificationType}
                                            noOptionsText={t("no_options")}
                                            onChange={(event, type) => setNotificationType(type)}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("notification_type")}
                                                    size={"small"}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={notificationType}
                                                    defaultValue={notificationType}
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            size="small"
                                            style={{width: "35%", margin: "auto", marginBlock: "1%"}}
                                            options={messageTypes}
                                            getOptionLabel={option => t(option)}
                                            value={messageType}
                                            defaultValue={messageType}
                                            noOptionsText={t("no_options")}
                                            onChange={(event, messType) => setMessageType(messType)}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("message_type")}
                                                    size={"small"}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={messageType}
                                                    defaultValue={messageType}
                                                />
                                            )}
                                        />
                                        <CardActions style={{
                                            width: "70%",
                                            margin: "auto",
                                            marginBlock: "5%"
                                        }}>
                                            <Button
                                                style={{
                                                    marginTop: "5%",
                                                    marginBottom: "5%",
                                                    margin: "auto",
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                startIcon={<ClearAll/>}
                                                onClick={resetFilters}
                                                variant={"contained"}>
                                                {t("clear")}
                                            </Button>
                                            <Button
                                                style={{
                                                    marginTop: "5%",
                                                    marginBottom: "5%",
                                                    margin: "auto",
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                endIcon={<SearchOutlined/>}
                                                onClick={applyFilters}
                                                color="primary"
                                                variant={"contained"}>
                                                {t("search")}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {t("notification_type")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("message_type")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("body_text")}
                                                </TableCell>
                                                <TableCell>
                                                    {t("language")}
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {templates.map((template, index) => {
                                            return <TableRow>
                                                <TableCell>
                                                    {t(template.notificationType)}
                                                </TableCell>
                                                <TableCell>
                                                    {t(template.messageType)}
                                                </TableCell>
                                                <TableCell>
                                                    {template.bodyText}
                                                </TableCell>
                                                <TableCell>
                                                    {t(template.language)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button onClick={() => goToForm(template.id)}><Edit
                                                        style={{color: theme.palette.primary.main}}/></Button>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </Table>
                                    <div style={{display: "flex"}}>
                                        <Button
                                            style={{
                                                margin: "auto",
                                                marginBlock: "2%",
                                                backgroundColor: theme.palette.primary.main
                                            }}
                                            startIcon={<AddCircleIcon/>} variant="contained" onClick={() => goToForm()}
                                            color="primary">
                                            {t("add_template")}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </TabPanel>
                        :
                        null
                    }

                    {permissions.includes("su:admin") ?
                        <TabPanel style={{width: "100%"}} value="3">
                            <PrimaedicolaSettings/>
                        </TabPanel>
                        :
                        null
                    }
                </TabContext>
            </PrivateRoute>
            <PrivateRoute exact path={'/settings/templates/:templateId'}>
                <MessageTemplateForm/>
            </PrivateRoute>
        </Switch>
    </Paper>


}

export default SettingsPage;