import {Chip} from "@material-ui/core";
import {Info, WarningOutlined} from "@mui/icons-material";
import React from "react";


function InfoChip(props){

    return <div style={{width: "100%", display: "flex", margin: "auto", marginBlock: "5%", paddingBlock: "2%"}}>
        {/*<Typography style={{margin: "auto"}}><WarningOutlined color={"warning"}/>{t("no_events")}</Typography>*/}
        <Chip style={{margin: "auto"}}
              icon={<Info color={"info"}/>} label={props.children}/>
    </div>


} export default InfoChip;