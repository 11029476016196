import React, {useEffect, useState} from "react";
import PostalCodeService from "./PostalCodeService";
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import {makeStyles} from "@material-ui/core/styles";
import CityService from "../cities/CityService";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import {PrivateRoute} from "../../routes/PrivateRoute";
import Notification from "../../components/notifications/Notification";
import PostalCodeForm from "./PostalCodeForm";
import Typography from "@material-ui/core/Typography";
import PostalCode from "./PostalCode";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    container: {
        width: '100%',
    },
    hidden: {
        display: "none"
    }
});


function PostalCodesTable(props) {


    const [postalCodes, setPostalCodes] = useState([]);
    const {t} = useTranslation();
    const [fetching, setFetching] = useState(true);
    const [refreshTable, setRefreshTable] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const classes = useStyles();
    const [cities, setCities] = useState([]);
    const [pages, setPages] = useState([]);
    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function closeNotification() {
        setOpenNotify(false);
    }


    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");
        setRefreshTable(!refreshTable);
    }

    function fetchPostalCodes() {
        setFetching(true);
        const postalCodeService = new PostalCodeService();

        postalCodeService.getAllPostalCodes(page, rowsPerPage, null, null)
            .then(async data => {
                setPostalCodes(data.postalCodes)
                setPages(Array.from(Array(Math.ceil(data.recordCount / rowsPerPage)).keys()));

                let filters = {};
                filters.idList = [];

                setFetching(false)
            })
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    useEffect(() => {
        fetchPostalCodes();
    }, [page, rowsPerPage, refreshTable])


    return <Container className={classes.root}>


        <PrivateRoute exact path={"/postal-codes"}>
            <TableContainer className={classes.container}>
                {(fetching) ?
                    <Table>
                        <LoadingComponent/>
                    </Table>

                    :

                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableCell>{t("postalCode")}</TableCell>
                            <TableCell>{t("cities")}/{t("hamlets")}</TableCell>
                            <TableCell></TableCell>

                        </TableHead>
                        <TableBody>
                            {postalCodes.map((postalCode, index) => {
                                return <PostalCode postalCode = {postalCode} deleteCallback={deleteCallback}/>

                            })}
                        </TableBody>
                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{notificationMessage}</Notification>


                    </Table>
                }
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}

                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                labelRowsPerPage={t("rows_per_page")}
                labelDisplayedRows={() => {
                    return t("page")
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </PrivateRoute>
        <PrivateRoute strict path={`/postal-codes/:id/edit/`} children={<PostalCodeForm/>}>
        </PrivateRoute>
        <PrivateRoute exact path={`/postal-codes/new/`}>
            <PostalCodeForm city={null}/>
        </PrivateRoute>

    </Container>

}

export default PostalCodesTable;
