import React, {useEffect, useState} from "react";
import AccountService from "./AccountService";
import {
    Button, Card, FormControlLabel,
    Paper, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import AccountForm from "./AccountForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Account from "./Account";
import {DialogTitle, TextField, Typography} from "@mui/material";
import {SearchOutlined} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";

function AccountsTable(props) {
    const [accounts, setAccounts] = useState([]);
    const [address, setAddress] = useState({});
    const [fetchCustomersError, setFetchCustomersError] = useState(null);
    const location = useLocation();
    const [refreshTable, setRefreshTable] = useState(true);
    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [accountCorporateCode, setAccountCorporateCode] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [isCustomer, setIsCustomer] = useState('');
    const [isSupplier, setIsSupplier] = useState('');
    const [isCreditBlocked, setIsCreditBlocked] = useState('');
    const [isAdminBlocked, setIsAdminBlocked] = useState('');

    function handleAccountCorporateCodeChange(event) {
        setAccountCorporateCode(event.target.value);
    }

    function handleCompanyNameChange(event) {
        setCompanyName(event.target.value)
    }

    function handleIsCustomerChange(event) {
        setIsCustomer(event.target.value);
    }

    function handleIsSupplierChange(event) {
        setIsSupplier(event.target.value);
    }

    function handleIsCreditBlockedChange(event) {
        setIsCreditBlocked(event.target.value);
    }

    function handleIsAdminBlockedChange(event) {
        setIsAdminBlocked(event.target.value);
    }

    function applyFilters() {
        setPage(0);
        setRefreshTable(!refreshTable);
    }

    function clearFilters() {
        setAccountCorporateCode('');
        setCompanyName('');
        setIsCustomer('');
        setIsSupplier('');
        setIsCreditBlocked('');
        setIsAdminBlocked('');
        setPage(0);
        setRefreshTable(!refreshTable);
    }


    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center'
        },
        container: {
            maxHeight: 840,
        },

        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function goToNewAccountForm() {
        history.push("/accounts/new");
    }

    function fetchAccounts() {

        setLoading(true);

        let filters = {};

        if (accountCorporateCode !== '') {
            filters.accountCorporateCode = accountCorporateCode;
        }
        if (companyName !== '') {
            filters.companyName = companyName;
        }

        if (isSupplier !== '') {
            filters.isSupplier = isSupplier === "yes";
        }

        if (isCustomer !== '') {
            filters.isCustomer = isCustomer === "yes";
        }

        if (isCreditBlocked !== '') {
            filters.isCreditBlocked = isCreditBlocked === "yes";
        }

        if (isAdminBlocked !== '') {
            filters.isAdministrativeBlocked = isAdminBlocked === "yes";
        }


        const accountService = new AccountService();
        accountService.getAccounts(filters)
            .then(data => {
                setAccounts(data);
                setLoading(false);
            })
            .catch((err) => {
                setFetchCustomersError(err.message);
                setLoading(false);
            })
    }


    useEffect(() => {
        fetchAccounts();
    }, [rowsPerPage, page, refreshTable, location]);

    return <Paper className={classes.root}>
        <Switch>
            <PrivateRoute exact path={"/accounts"}>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("accounts")}</DialogTitle>
                <Card style={{width: "80%", margin: "auto"}}>
                    <div>
                        <TextField
                            style={{width: "30%", margin: "auto", marginBlock: "2%"}}
                            variant={"outlined"}
                            size={"small"}
                            label={t("account_corporate_code")}
                            value={accountCorporateCode}
                            defaultValue={accountCorporateCode}
                            onChange={handleAccountCorporateCodeChange}
                        />
                    </div>
                    <div>
                        <TextField
                            style={{width: "30%", margin: "auto", marginBlock: "2%"}}
                            variant={"outlined"}
                            size={"small"}
                            label={t("company_name")}
                            value={companyName}
                            defaultValue={companyName}
                            onChange={handleCompanyNameChange}
                        />
                    </div>
                    <div style={{display: "flex", width: "70%", margin: "auto"}}>
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("supplier")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={isSupplier}
                                onChange={handleIsSupplierChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>
                    </div>

                    <div style={{display: "flex", width: "70%", margin: "auto"}}>
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("customer")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={isCustomer}
                                onChange={handleIsCustomerChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>
                    </div>


                    <div style={{display: "flex", width: "70%", margin: "auto"}}>
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("credit_block")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={isCreditBlocked}
                                onChange={handleIsCreditBlockedChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>
                    </div>


                    <div style={{display: "flex", width: "70%", margin: "auto"}}>
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("administrative_block")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={isAdminBlocked}
                                onChange={handleIsAdminBlockedChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"no"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
                    </div>

                </Card>
                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToNewAccountForm}
                        className={classes.button}>
                    {t("create_new_account")}
                </Button>
                {loading ?
                    <LoadingComponent/>
                    :
                    <div>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{t("account_corporate_code")}</TableCell>
                                        <TableCell>{t("company_name")}</TableCell>
                                        <TableCell>{t("vat_number")}</TableCell>
                                        <TableCell>{t("fiscal_code")}</TableCell>
                                        <TableCell>{t("contracts")}</TableCell>
                                        <TableCell>{t("status")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((account, index) => {
                                        return <Account account={account}/>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={accounts.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={
                                ({from, to, count}) => {
                                    return '' + from + '-' + to + ' di ' + count
                                }
                            }
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                }
            </PrivateRoute>
            <PrivateRoute exact path={`/accounts/:id/edit/`} children={<AccountForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/accounts/new/`}>
                <AccountForm customer={null}/>
            </PrivateRoute>
        </Switch>
    </Paper>

}

export default AccountsTable;