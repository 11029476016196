import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    DialogTitle,
    IconButton,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import DateField from "../components/fields/DateField";
import {Autocomplete} from "@mui/lab";
import ContractService from "../accounts/contracts/ContractService";
import WarehouseService from "../warehouses/WarehouseService";
import DriverService from "../drivers/DriverService";
import VehicleService from "../vehicles/VehicleService";
import ShipmentService from "../shipments/ShipmentService";
import theme from "../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import ParcelEventService from "../shipments/ParcelEventService";
import {Button, Checkbox, Slide, Table, TablePagination, TableRow, Typography} from "@material-ui/core";
import moment from "moment";
import Status from "./Status";
import Notification from "../components/notifications/Notification";


function DeleteStatusPage(props) {

    const [warehousesMap, setWarehousesMap] = useState([]);
    const [driversMap, setDriversMap] = useState([]);
    const [codesMap, setCodesMap] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [allChecked, setAllChecked] = useState(false);

    const [statuses, setStatuses] = useState([]);
    const [date, setDate] = useState(null);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [shipmentID, setShipmentID] = useState('');
    const [senderReference, setSenderReference] = useState('');
    const [senderParcelReference, setSenderParcelReference] = useState('');
    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);

    const [parcelEventCodes, setParcelEventCodes] = useState([]);
    const [eventCode, setEventCode] = useState(null);

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const {t} = useTranslation();

    async function deleteStatus() {

        for (let i = 0; i < statuses.length; i++) {
            if (statuses[i].checked === true) {

                let newStatus = [...statuses];
                newStatus[i].pending = true;
                setStatuses(newStatus);

                setRefresh(!refresh);

                await new ParcelEventService().deleteEvent(statuses[i].event.id)
                    .then(success => {
                        let newStatus = [...statuses];
                        newStatus[i].pending = false;
                        newStatus[i].deleted = true;

                        setStatuses(newStatus);

                        setTimeout(()=>{

                            let newStatus = [...statuses];
                            newStatus[i].fadeOut = true;

                            setStatuses(newStatus);

                            setRefresh(!refresh);

                        }, 1000)

                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            let newStatus = [...statuses];
                            newStatus[i].pending = false;
                            newStatus[i].deleted = false;
                            newStatus[i].errorMessage = response.message;

                            setStatuses(newStatus);
                        })

                    })

            }

            statuses[i].checked = false;
        }

        // await deleteRows();


    }


    function deleteRows(){

        for (let i = statuses.length -1; i >= 0; i--) {

            if(statuses[i].deleted === true){
                statuses.splice(i, 1)
            }
        }

    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);


        for (let i = 0; i < statuses.length; i++) {
            if(statuses[i].deleted !== true){
                statuses[i].checked = !!event.target.checked;
            }
        }

    }

    function handleChecked(event, key) {

        statuses
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[key].checked = event.target.checked;

        if (statuses.some(accountingRow => accountingRow.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleDateChange(event) {
        setDate(event);
    }

    function handleContractChange(event, contract) {
        setContract(contract)
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
    }

    function handleShipmentIDChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentID(event.target.value);
        }
    }

    function handleSenderReferenceChange(event) {
        setSenderReference(event.target.value);
    }

    function handleSenderParcelReferenceChange(event) {
        setSenderParcelReference(event.target.value);
    }

    function handleEventCodeChange(event, eventCode) {
        setEventCode(eventCode);
    }

    async function fetchContracts() {

        let filters = {};

        let contracts = await new ContractService().getContractsByFilters(filters)

        setContracts(contracts)
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    function fetchDrivers() {

        let filters = {};
        if (null !== warehouse) {

            filters.warehouseCode = warehouse.code;
        }

        let driverService = new DriverService();
        driverService.getDriversByFilters(filters)
            .then(response => {
                setDrivers(response.drivers)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage("Si è verificato un errore nel recupero degli autisti: " + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchParcelEventCodes() {
        await new ParcelEventService().getParcelEventCodes()
            .then(data => {
                setParcelEventCodes(data);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                });
            })
    }

    async function search() {

        let filters = {};
        if (date !== null) {
            filters.date = moment(date).format('yyyy-MM-DD');
        }

        if (null !== contract) {
            filters.contractCode = contract.code;
        }

        if ('' !== shipmentID) {
            filters.shipmentId = shipmentID;
        }

        if ('' !== senderReference) {
            filters.customerReference = senderReference;
        }

        if ('' !== senderParcelReference) {
            filters.customerBarcode = senderParcelReference;
        }

        if (null !== warehouse) {
            filters.warehouseCode = warehouse.code;
        }

        if (null !== driver) {
            filters.driverId = driver.id;
        }

        if (null != eventCode) {
            filters.statusCodeId = eventCode.id;
        }

        let events = await new ParcelEventService().getParcelEvents(filters)
            .catch(error => {
                error.response.json().then((response) => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                });
            })

        if (events) {
            let data = [];

            for (let i = 0; i < events.length; i++) {
                let statusData = {};
                statusData.checked = false;
                statusData.pending = false;
                statusData.deleted = null;
                statusData.event = events[i];

                data.push(statusData);
            }

            setStatuses(data);
            setAllChecked(false);
        }


    }

    async function setData() {

        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehousesMap(wares.warehouses)

        let drivers = await new DriverService().getDriversByFilters();
        setDriversMap(drivers);

        let parcelEvents = await new ParcelEventService().getParcelEventCodes();
        setCodesMap(parcelEvents);
    }


    useEffect(() => {
        setData();
    }, [refresh])

    return <Paper>


        <DialogTitle>{t("delete_status")}</DialogTitle>

        <DateField
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            label={t("date")}
            value={date}
            onChange={handleDateChange}
            format={"dd/MM/yyyy"}
            disableFuture={true}
        />
        <TextField
            style={{display: "flex", width: "20%", margin: "auto", marginBlock: "1%"}}
            label={t("shipmentId")}
            size={"small"}
            variant="outlined"
            margin="dense"
            value={shipmentID}
            defaultValue={shipmentID}
            onChange={handleShipmentIDChange}
        />
        <Autocomplete
            id="contracts"
            size="small"
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            options={contracts}
            getOptionLabel={option => option.name}
            value={contract}
            defaultValue={contract}
            onOpen={fetchContracts}
            noOptionsText={t("no_options")}
            onChange={handleContractChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("sender")}
                    InputLabelProps={{shrink: true}}
                    placeholder={t("any")}
                    variant="outlined"
                    margin="dense"
                    value={contract}
                    defaultValue={contract}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <TextField
            style={{display: "flex", width: "20%", margin: "auto", marginBlock: "1%"}}
            label={t("sender_reference")}
            size={"small"}
            variant="outlined"
            margin="dense"
            value={senderReference}
            defaultValue={senderReference}
            onChange={handleSenderReferenceChange}
        />
        <TextField
            style={{display: "flex", width: "20%", margin: "auto", marginBlock: "1%"}}
            label={t("sender_parcel_reference")}
            size={"small"}
            variant="outlined"
            margin="dense"
            value={senderParcelReference}
            defaultValue={senderParcelReference}
            onChange={handleSenderParcelReferenceChange}
        />
        <Autocomplete
            id="warehouse"
            size={"small"}
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            options={warehouses}
            getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
            value={warehouse}
            defaultValue={warehouse}
            onOpen={fetchWarehouses}
            onChange={handleWarehouseChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            noOptionsText={t("no_options")}
            renderInput={(params) => <TextField
                {...params}
                label={t("warehouse")}
                InputLabelProps={{shrink: true}}
                placeholder={t("any")}
                variant="outlined"
                margin="dense"
                value={warehouse}
                defaultValue={warehouse}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off', // disable autocomplete and autofill
                }}
            />}
        />
        <Autocomplete
            id="driver-select"
            size="small"
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            options={drivers}
            getOptionLabel={option => option.name + " " + option.surname}
            value={driver}
            defaultValue={driver}
            onOpen={fetchDrivers}
            noOptionsText={t("no_options")}
            onChange={handleDriverChange}
            ListboxProps={{style: {maxHeight: '15rem'},}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("driver")}
                    InputLabelProps={{shrink: true}}
                    placeholder={t("any")}
                    variant="outlined"
                    margin="dense"
                    value={driver}
                    defaultValue={driver}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <Autocomplete
            id="eventCode"
            size={"small"}
            options={parcelEventCodes}
            style={{width: "20%", margin: "auto", marginBlock: "1%"}}
            getOptionLabel={option => option.id + " - " + option.name}
            value={eventCode}
            defaultValue={eventCode}
            noOptionsText={t("no_options_available")}
            onOpen={fetchParcelEventCodes}
            onChange={handleEventCodeChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("event_code")}
                    InputLabelProps={{shrink: true}}
                    placeholder={t("any")}
                    variant="outlined"
                    margin="dense"
                    value={eventCode}
                    defaultValue={eventCode}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
        <IconButton onClick={search}
                    disabled={loading}
                    style={{
                        display: "flex",
                        backgroundColor: 'transparent',
                        margin: "auto",
                        marginBottom: "2%"
                    }}>{loading ?
            <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
            <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
        {statuses.length > 0 ?
            <div>
                <Table stickyHeader style={{width: "80%", margin: "auto"}}>

                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox color="primary"
                                          onChange={handleAllChecked}
                                          checked={allChecked}/>
                            </TableCell>
                            <TableCell>
                                {t("datetime")}
                            </TableCell>
                            <TableCell>
                                {t("parcel_id")}
                            </TableCell>
                            <TableCell>
                                {t("warehouse")}
                            </TableCell>
                            <TableCell>
                                {t("notes")}
                            </TableCell>
                            <TableCell>
                                {t("event")}
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statuses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, key) => {
                            return <Slide direction="left" in={!data.fadeOut} appear={false} unmountOnExit>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            onChange={event => handleChecked(event, key)}
                                            checked={data.checked}/>
                                    </TableCell>
                                    <Status data={data} warehousesMap={warehousesMap} codesMap={codesMap}/>
                                </TableRow>
                            </Slide>

                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    style={{width: "80%", margin: "auto"}}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={() => {
                        return (t("total_selected") + ": " + statuses.filter(acc => !!acc.checked)?.length)
                    }}
                    component="div"
                    count={statuses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Button variant={"contained"} style={{display: "flex", margin: "auto", backgroundColor: theme.palette.primary.main, color: "white"}} onClick={deleteStatus}>{t("delete_statuses")}</Button>
                <div style={{height: 50}}/>
            </div>
            :
            null
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>


    </Paper>


}

export default DeleteStatusPage;