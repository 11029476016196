import {Button, Checkbox, FormControlLabel, Paper, Radio, RadioGroup, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DriverWorkListService from "./DriverWorkListService";
import ParcelService from "../shipments/services/ParcelService";
import ShipmentService from "../shipments/ShipmentService";
import WarehouseService from "../warehouses/WarehouseService";
import DriverService from "../drivers/DriverService";
import VehicleService from "../vehicles/VehicleService";
import {
    Autocomplete,
    CircularProgress,
    DialogTitle,
    Table,
    TableBody,
    TableHead,
    TextField,
    Typography
} from "@mui/material";
import theme from "../theme/theme";
import {SaveOutlined} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import moment, {now} from "moment";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import {WarningOutlined} from "@mui/icons-material";
import WarningChip from "../components/chips/WarningChip";
import PickupService from "../pickups/PickupService";
import DateField from "../components/fields/DateField";
import MyTimeField from "../components/fields/MyTimeField";
import {useHistory} from "react-router-dom";


function NewDriverWorkListPage(props) {

    const {t} = useTranslation();

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [vehicle, setVehicle] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [shipments, setShipments] = useState([]);
    const [mapConfirmedParcelsByParcelBarcode, setMapConfirmedParcelsByParcelBarcode] = useState({});
    const [includeOnlyConfirmedParcels, setIncludeOnlyConfirmedParcels] = useState("false");
    const [delayed, setDelayed] = useState(false);

    const [pickups, setPickups] = useState([]);
    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);

    const [loadingSave, setLoadingSave] = useState(false);

    const [refresh, setRefresh] = useState(false);

    function handleDelayedChange(event) {
        setDelayed(event.target.checked);
    }

    function handleDateChange(event){
        setDate(event);
    }

    function handleTimeChange(event){
        setTime(event);
    }


    async function handleDriverChange(event, driver) {
        setDriver(driver);

        setLoading(true);
        if (null !== driver) {
            let driverWorkListService = new DriverWorkListService();
            let workList = await driverWorkListService.getDriverTruckLoad(driver.id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
            ;
            await fetchWorkList(workList);
        } else {
            setShipments([]);
            setPickups([]);
        }

        setLoading(false);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);

        setVehicle(null);
        setDriver(null);
    }

    function handleVehicleChange(event, vehicle) {
        setVehicle(vehicle);
    }

    const newPickups = [];

    async function fetchWorkList(workList) {
        let workListParcels = workList.parcels;
        let pickupIds = workList.pickupIds;
        let wrklst = [];

        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = null;

            pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })

            let data = {};
            data.pickup = pickup;
            data.barcode = pickup.barcode;
            data.checked = true;

            //setPickups([...pickups, data]);
            newPickups.push(data);
        }
        setPickups(newPickups);

        const newMapConfirmedParcels = {};
        for (let i = 0; i < workListParcels?.length; i++) {
            newMapConfirmedParcels[workListParcels[i].parcel.id] = workListParcels[i].confirmed;

            //let parcelService = new ParcelService();
            //let parcel = null;
            let parcel = workListParcels[i].parcel;
            //parcel = await parcelService.getParcelById(workListParcels[i].parcelId)
            //    .catch(error => {
            //        error.response.json().then(response => {
            //            setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
            //            setNotifySeverity('error');
            //            setOpenNotify(true);
            //        })
            //    })


            if (wrklst.some(data => {
                return data.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode === parcel.barcode);
                })
            })) {
                wrklst.map(data => {
                    data.parcels.map(async _parcel => {
                        if (_parcel.parcel.barcode === parcel.barcode) {
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                })
            } else {
                let shipmentService = new ShipmentService();
                let shipmentId = parcel?.shipmentId;
                if (parcel) {
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let data = {};
                    data.shipment = shipment;
                    data.parcels = [];
                    for (let i = 0; i < parcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = parcels[i];
                        if (parcels[i].barcode === parcel.barcode) {
                            parcelData.checked = true;
                            setRefresh(!refresh);
                        }

                        data.parcels.push(parcelData);
                    }

                    wrklst = ([...wrklst, data]);
                }
            }

            setRefresh(!refresh);
        }
        setMapConfirmedParcelsByParcelBarcode(newMapConfirmedParcels);

        setShipments(wrklst);
    }

    function fetchWarehouses() {
        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters(0, 1000, null)
            .then(response => {
                setWarehouses(response.warehouses);
                if(response.warehouses?.length === 1){
                    setWarehouse(response.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(t("an_error_has_occurred") + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function fetchDriversAndVehicles(warehouse) {


        if (null !== warehouse) {
            let filters = {};
            filters.warehouseCode = warehouse.code;

            let driverService = new DriverService();
            driverService.getDriversByFilters(filters)
                .then(response => {
                    setDrivers(response.drivers)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero degli autisti: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })


            let vehicleService = new VehicleService();
            vehicleService.getVehiclesByFilters(filters)
                .then(response => {
                    setVehicles(response.vehicles)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero dei mezzi: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }

    }

    function calcSumOfParcels(parNum) {

        shipments.map(data => {
            data.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum += 1;
                }
            })
        })

        return parNum;

    }


    function checkWorkList() {

        if (shipments.some(item => {
            return item.parcels.some(parcel => !parcel.checked)
        })) {
            setOpenConfirmDialog(true);
        } else {
            saveNewDriverWorkList();
        }

    }

    function saveNewDriverWorkList() {
        if (!driver || !warehouse || !vehicle || (delayed && (!date || !time))) {
            setNotificationMessage("select_required_fields");
            setNotifySeverity('error');
            setOpenNotify(true);
        } else {

            setLoadingSave(true);

            let localDateTime;
            if(delayed){
                console.log(date.toLocaleDateString());
                console.log(time.toLocaleTimeString());
                localDateTime = moment(date.toLocaleDateString() + ' ' + time.toLocaleTimeString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');
            } else {
                localDateTime = moment(now()).format('YYYY-MM-DDTHH:mm:ssZ');
            }


            let request = {};
            request.driverId = driver.id;
            request.vehicleId = vehicle.id;
            request.dateTime = localDateTime;
            request.onlyConfirmed = "true" === includeOnlyConfirmedParcels;
            request.delayed = delayed;

            let dwService = new DriverWorkListService();
            dwService.createDriverWorkList(request)
                .then(response => {
                    setNotificationMessage(t("successful"));
                    setNotifySeverity('success');
                    setOpenNotify(true);

                    setWarehouse(null);
                    setDriver(null);
                    setVehicle(null);
                    setOpenConfirmDialog(false);
                    setShipments([]);
                    setPickups([]);

                    history.push(`/driver-work-lists/${response.id}/document`);

                    // dwService.getDriverWorkListDocument(response.id)
                    //     .then(data => {
                    //         let tempLink = document.createElement('a');
                    //         tempLink.href = "data:application/pdf;base64," + data.content;
                    //         tempLink.setAttribute('download', data.fileName);
                    //         tempLink.click();
                    //     })
                    //     .catch(error => {
                    //         error.response.json().then(response => {
                    //             setNotificationMessage(response.status + "-" + response.message);
                    //             setNotifySeverity('error');
                    //             setOpenNotify(true);
                    //         })
                    //     });
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }
        setLoadingSave(false);
    }

    function calculateAndRenderNumOfConfirmedParcels() {
        const parcelIds = Object.keys(mapConfirmedParcelsByParcelBarcode);

        let numOfConfirmedParcels = 0;
        let numOfParcels = parcelIds.length;
        for (let i = 0; i < parcelIds.length; ++i) {
            if (mapConfirmedParcelsByParcelBarcode[parcelIds[i]]) {
                ++numOfConfirmedParcels;
            }
        }

        return numOfConfirmedParcels + "/" + numOfParcels;
    }

    function handleChangeIncludeOnlyConfirmedParcels(event) {
        setIncludeOnlyConfirmedParcels(event.target.value);
    }

    useEffect(()=>{
        fetchDriversAndVehicles(warehouse);
    }, [warehouse])

    useEffect(() => {
        fetchWarehouses();
    }, [])

    useEffect(()=>{

    }, [refresh])

    return <Paper>
        <div>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("driver_work_lists")}</DialogTitle>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        required
                        error={!warehouse}
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <Autocomplete
                id="driver-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={drivers}
                disabled={!warehouse}
                getOptionLabel={option => option.name + " " + option.surname}
                value={driver}
                defaultValue={driver}
                noOptionsText={t("no_options")}
                onChange={handleDriverChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("driver")}
                        variant="outlined"
                        margin="dense"
                        value={driver}
                        required
                        error={!driver}
                        defaultValue={driver}
                    />
                )}
            />
            <Autocomplete
                id="vehicle-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={vehicles}
                disabled={!warehouse}
                getOptionLabel={option => option.model + " - " + option.plate}
                value={vehicle}
                defaultValue={vehicle}
                noOptionsText={t("no_options")}
                onChange={handleVehicleChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("vehicle")}
                        variant="outlined"
                        margin="dense"
                        required
                        error={!vehicle}
                        value={vehicle}
                        defaultValue={vehicle}
                    />
                )}
            />
        </div>
        <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
            <FormControlLabel
                control={<Checkbox
                checked={delayed}
                onChange={handleDelayedChange}
                inputProps={{'aria-label': 'controlled'}}
            />}
                label={t("delayed_driver_work_list")}/>
        </div>
        {delayed ?
            <div style={{width: "fit-content", margin: "auto"}}>
                <DateField
                    style={{margin: "auto", width: "100%"}}
                    label={t("date")}
                    value={date}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    disablePast
                />
                <MyTimeField
                    value={time}
                    onChange={handleTimeChange}
                    defaultValue={time}
                />
            </div>
            :
            null
        }
        <div>
            <Table style={{width: "20%", margin: "auto", marginTop: "5%"}}>
                <TableHead>
                    <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography variant={"button"}
                                                                                           alignSelf={"center"}
                                                                                           fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography variant={"button"}
                                                                                           alignSelf={"center"}
                                                                                           fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                        {pickups.length > 0 ?
                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                variant={"button"}
                                alignSelf={"center"}
                                fontWeight={"bold"}>{t("pickups")}</Typography></TableCell>
                            : null}
                    </TableRow>
                </TableHead>
                <TableRow>
                    <TableCell style={{border: "2px solid", width: "50%"}}><Typography variant={"button"} style={{
                        fontWeight: 'bold',
                        margin: "auto",
                        fontSize: 30
                    }}>{shipments.length}</Typography></TableCell>
                    <TableCell style={{border: "2px solid", width: "50%"}}><Typography variant={"button"} style={{
                        fontWeight: 'bold',
                        margin: "auto",
                        fontSize: 30
                    }}>{calcSumOfParcels(0)}</Typography></TableCell>
                    {pickups.length > 0 ?
                        <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                            variant={"button"}
                            style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                fontSize: 30
                            }}>{pickups.length}</Typography></TableCell>
                        : null}

                </TableRow>
            </Table>
        </div>
        <div style={{paddingBottom: "5%"}}>
            {loading ?

                <LoadingComponent/>

                :
                <div>
                    {shipments.length > 0 || pickups.length > 0 ?
                        <div style={{marginBlock: "5%"}}>
                            <div style={{textAlign: 'center', margin: "auto",}}>
                                <Typography style={{
                                    fontWeight: 'bold',
                                }}>
                                    {t("confirmed_parcels") + ": " + calculateAndRenderNumOfConfirmedParcels()}
                                </Typography>
                                <RadioGroup
                                    row
                                    value={includeOnlyConfirmedParcels}
                                    onChange={handleChangeIncludeOnlyConfirmedParcels}
                                    style={{alignItems: "center", justifyContent: "center"}}
                                >
                                    <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                      label={t("include_only_confirmed_parcels")}/>
                                    <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                      label={t("include_all_parcels")}/>
                                </RadioGroup>
                            </div>
                            <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                                <TableHead>
                                    <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                        <TableCell style={{border: "2px solid", width: "50%"}}>
                                            <Typography variant={"button"} style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                            }}>{t("shipment_details")}</Typography>
                                        </TableCell>
                                        <TableCell style={{border: "2px solid", width: "50%"}}>
                                            <Typography variant={"button"} style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                            }}> {t("barcode")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shipments.map(data => {
                                        return <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("destination")} : {data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                {data.parcels.map(parcel => {
                                                    return <div style={{display: "flex", alignItems: "center"}}>
                                                        <Typography
                                                            color={parcel.checked ? "green" : "darkgrey"}>{parcel.parcel.barcode + (parcel.parcel.customerBarcode ? " - " + parcel.parcel.customerBarcode : null)}</Typography>
                                                        {/*<IconButton style={{display: parcel.checked ? null : "none"}} color={'error'}*/}
                                                        {/*            size={"small"}*/}
                                                        {/*            onClick={() => cancelParcel(parcel)}><CancelRounded/></IconButton>*/}
                                                    </div>
                                                })}
                                            </TableCell>

                                        </TableRow>
                                    })}
                                    {pickups.map(data => {
                                        return <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"subtitle2"}>{t("pickupId")} : {data.pickup.id}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("warehouseCode")} : {data.pickup.warehouseCode}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("contract_code")} : {data.pickup.contractCode}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("pickup_date")} : {new Date(data.pickup.date).toLocaleDateString()}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{t("pickup_address")} : {data.pickup.address?.postalCode} {data.pickup.address?.city} {data.pickup.address?.administrative_level_3}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <Typography
                                                        color={data.checked ? "green" : "darkgrey"}>{data.barcode}</Typography>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                        :

                        <div>
                            {driver ?
                                <WarningChip>{t("no_parcels_in_truck_load")}</WarningChip>
                                :
                                null}
                        </div>
                    }
                </div>
            }
        </div>
        <div style={{display: "flex"}}>
            <Button
                disabled={loading || !driver || !vehicle || !warehouse || (shipments.length < 1 && pickups.length < 1) || loadingSave}
                style={{
                    margin: "auto", marginBlock: "2%", backgroundColor: theme.palette.primary.main,
                    color: "white",
                }} onClick={checkWorkList} endIcon={loadingSave? <CircularProgress size={23}/> : <SaveOutlined/>}>{t("save_and_print")}</Button>

        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={4000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <ConfirmDialog open={openConfirmDialog}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={saveNewDriverWorkList}
                       onCancel={() => setOpenConfirmDialog(false)}
        >
            <div style={{width: "100%", display: "block"}}>
                <WarningOutlined style={{width: "100%", margin: "auto"}} color={"warning"}/> <Typography color={"black"}
                                                                                                         variant={"body1"}>{t("partial_driver_worklist_message")}</Typography>
            </div>
        </ConfirmDialog>
    </Paper>


}

export default NewDriverWorkListPage;