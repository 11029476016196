import SummaryInService from "../../shipments/services/SummaryInService";
import React, {useEffect, useState} from "react";
import WarehouseService from "../../warehouses/WarehouseService";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import moment from "moment";
import {
    Autocomplete,
    Card,
    CircularProgress, DialogTitle,
    IconButton,
    TableBody, TableCell,
    TableHead,
    TableRow,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import {Table} from "@material-ui/core";
import InfoChip from "../../components/chips/InfoChip";
import SummaryOutService from "./SummaryOutService";
import RouteService from "../../departures/routes/RouteService";
import {AppStyles} from "../../theme/AppStyles";


function WarehouseMissingShipmentsPage(props) {

    const classes = AppStyles();

    const {t} = useTranslation();

    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    const [shipments, setShipments] = useState([]);

    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [destinationWarehouse, setDestinationWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [route, setRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [summary, setSummary] = useState(null);
    const [summaries, setSummaries] = useState([]);
    const [departureDate, setDepartureDate] = useState(new Date());


    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    function handleSummaryChange(event, summary) {
        setSummary(summary)
    }

    function handleRouteChange(event, route) {
        setRoute(route)
        setSummaries([]);
        setSummary(null);
    }

    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse)
        setRoutes([]);
        setRoute(null);
        setSummaries([]);
        setSummary(null);
    }

    function handleDestinationWarehouseChange(event, warehouse) {
        setDestinationWarehouse(warehouse)
        setRoutes([]);
        setRoute(null);
        setSummaries([]);
        setSummary(null);
    }

    function handleDepartureDateChange(event) {
        setDepartureDate(event)
        setSummaries([]);
        setSummary(null);
    }

    async function fetchWarehouses(all) {

        setWarehouses([]);

        let filters = {};

        if(all){
            filters.showAll = all;
        }

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setWarehouses(result.warehouses)
                if(result.warehouses?.length === 1){
                    setDestinationWarehouse(result.warehouses[0]);
                }
            })
            .catch(error => {

            })
    }

    async function fetchRoutes() {

        let filters = {};

        if(departureWarehouse){
            filters.departureWarehouseCode = departureWarehouse.code;
        }

        if(destinationWarehouse){
            filters.destinationWarehouseCode = destinationWarehouse.code;
        }

        await new RouteService().getRoutes(filters)
            .then(result => {
                setRoutes(result.routes)
            })
            .catch(error => {

            })

    }

    async function fetchSummaries() {

        let filters = {};
        if (route) {
            filters.routeId = route.id;
        }

        if(departureWarehouse){
            filters.warehouseId = departureWarehouse?.id;
        }
        filters.departureDate = moment(departureDate).format('YYYY-MM-DD');

        await new SummaryOutService().getByFilters(filters)
            .then(result => {
                setSummaries(result);
            })
            .catch(error => {

            })
    }


    async function getMissingShipments() {

        setLoading(true);

        let filters = {};

        if (departureDate) {
            filters.departureDate = moment(departureDate).format('YYYY-MM-DD');
        }

        if (route) {
            filters.routeId = route.id;
        }

        if (summary) {
            filters.summaryOutId = summary.id;
        }

        if (departureWarehouse) {
            filters.departureWarehouseId = departureWarehouse.id;
        }
        
        if (destinationWarehouse) {
            filters.destinationWarehouseId = destinationWarehouse.id;
        }

        await new SummaryOutService().getMissingShipments(filters)
            .then(result => {
                setShipments(result);
                setFetched(true);
            })
            .catch(error => {

            })

        setLoading(false);
    }

    function calculateTotalParcels(shipments) {
        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {
            for (let parcelsKey in shipments[i].parcels) {
                counter += 1;
            }
        }

        return counter;
    }

    function calculateMissingParcels(shipments) {
        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            let shipment = shipments[i];

            for (let j = 0; j < shipment.parcels.length; j++) {
                if (shipment.parcels[j].missing) {
                    counter += 1;
                }
            }
        }

        return counter;
    }

    function calculateTotalShipments(shipments) {

        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            counter += 1;
        }

        return counter
    }

    function calculateCheckInParcels(shipments) {

        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            let shipment = shipments[i];

            for (let j = 0; j < shipment.parcels.length; j++) {
                if (!shipment.parcels[j].missing) {
                    counter += 1;
                }
            }
        }

        return counter
    }

    function backgroundColorCheck(shipment) {

        let missingCount = 0;

        for (let i = 0; i < shipment.parcels.length; i++) {
            if(shipment.parcels[i].missing){
                missingCount++;
            }
        }

        if(missingCount === 0){
            return '#56bb42'
        }
        if(missingCount === shipment.parcels.length){
            return "#fc2f2f"
        } else {
            return "#ffc21d"
        }

    }

    useEffect(() => {
        fetchWarehouses(false);
    }, [])

    return <div style={{
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block"
    }}>
        <DialogTitle>{t("warehouse_missing_parcels")}</DialogTitle>
        <Card style={{
            width: "80%",
            margin: "auto",
            marginBottom: "3%"
        }}>
            <DateField
                style={{width: "15%", margin: "auto"}}
                label={t("date")}
                value={departureDate}
                onChange={handleDepartureDateChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
                clearable={false}
            />
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={departureWarehouse}
                defaultValue={departureWarehouse}
                noOptionsText={t("no_options")}
                onOpen={() => fetchWarehouses(true)}
                onChange={handleDepartureWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("departure_warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={departureWarehouse}
                        defaultValue={departureWarehouse}
                    />
                )}
            />
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={destinationWarehouse}
                defaultValue={destinationWarehouse}
                noOptionsText={t("no_options")}
                onOpen={() => fetchWarehouses(false)}
                onChange={handleDestinationWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("destination_warehouse")}
                        variant="outlined"
                        margin="dense"
                        required={true}
                        error={!destinationWarehouse}
                        value={destinationWarehouse}
                        defaultValue={destinationWarehouse}
                    />
                )}
            />
            <Autocomplete
                id="route-select"
                size="small"
                // disabled={!departureWarehouse}
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={routes}
                getOptionLabel={option => option.name}
                value={route}
                defaultValue={route}
                noOptionsText={t("no_options")}
                onOpen={fetchRoutes}
                onChange={handleRouteChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("route")}
                        variant="outlined"
                        margin="dense"
                        value={route}
                        defaultValue={route}
                    />
                )}
            />
            <Autocomplete
                id="summary-select"
                size="small"
                disabled={!departureWarehouse}
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={summaries}
                getOptionLabel={option => t("summary") + " " + option.id + " " + t("of") + new Date(option.dateTime).toLocaleDateString()}
                value={summary}
                defaultValue={summary}
                noOptionsText={t("no_options")}
                onOpen={fetchSummaries}
                onChange={handleSummaryChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("summary")}
                        variant="outlined"
                        margin="dense"
                        value={summary}
                        defaultValue={summary}
                    />
                )}
            />
            <div style={{margin: "auto"}}>
                <IconButton onClick={getMissingShipments}
                            disabled={loading || !destinationWarehouse}
                            style={{backgroundColor: 'transparent', margin: "auto", marginBottom: "2%"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>

        </Card>

        {shipments.length > 0 && fetched ?
            <Card style={{maxWidth: "30%", margin: "auto", marginBottom: "5%"}}>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("total_shipment_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateTotalShipments(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("total_parcel_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateTotalParcels(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("missing_parcels_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateMissingParcels(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("checked_in_parcels_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateCheckInParcels(shipments)}</Typography>
            </Card>
            :
            null
        }


        {fetched ?
            <>
                {shipments.length > 0 ?
                    <div>
                        <Table>
                            <TableHead>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("account")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("contract")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("shipment_id")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("references")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("manifest_datetime")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("destination")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("weight")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("volume")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("parcels")}</Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {shipments.map(shipment => {

                                    return <TableRow style={{backgroundColor: backgroundColorCheck(shipment)}}>
                                        <TableCell style={{color: "white"}}>
                                            <Typography>{shipment.accountCorporateCode}</Typography>
                                            <Typography>{shipment.contractCode}</Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            <Typography onClick={() => goToAboutMe(shipment.id)}
                                                        className={classes.dark_blue_link}> {shipment.id} </Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            <Typography>{shipment.reference}</Typography>
                                            <Typography>{shipment.customerReference}</Typography>
                                            <Typography>{shipment.orderNumber}</Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            <Typography>{new Date(shipment.manifestDateTime).toLocaleDateString() + " - " + new Date(shipment.manifestDateTime).toLocaleTimeString()}</Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            <Typography>{shipment.deliveryAddress.postalCode + " " + shipment.deliveryAddress.city + " " + shipment.deliveryAddress.administrative_level_3}</Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            <Typography>{shipment.totalWeight} {"Kg"}</Typography>
                                            <Typography>{shipment.totalVolume} {"m\u00B3"}</Typography>
                                        </TableCell>
                                        <TableCell style={{color: "white"}}>
                                            {shipment.parcels.map(parcel => {
                                                return <Typography
                                                    style={{color: parcel.missing ? "#ffffff" : "#000000", opacity: parcel.missing ? "70%" : null}}>{parcel.barcode}</Typography>

                                            })}
                                        </TableCell>
                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }
            </>

            :

            null
        }

    </div>


}

export default WarehouseMissingShipmentsPage;