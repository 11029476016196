import React, {useEffect, useState} from "react";
import {
    Card,
    CircularProgress, FormControlLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {DialogTitle, Paper, Typography} from "@mui/material";
import DriverWorkListService from "./DriverWorkListService";
import DriverWorkList from "./DriverWorkList";
import {Autocomplete} from "@material-ui/lab";
import DateField from "../components/fields/DateField";
import theme from "../theme/theme";
import WarehouseService from "../warehouses/WarehouseService";
import DriverService from "../drivers/DriverService";
import moment from "moment";
import InfoChip from "../components/chips/InfoChip";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '95%',
        margin: "auto"

    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function DriverWorkListsTable(props) {


    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);

    const [isOpen, setIsOpen] = useState('');

    const [date, setDate] = useState(null);

    const [driverWorkLists, setDriverWorkLists] = useState([]);

    const [fetching, setFetching] = useState(true);
    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse)
        setDriver(null);
        setDrivers([]);
    }

    function handleIsOpenChange(event) {
        setIsOpen(event.target.value);
    }

    function handleDriverChange(event, driver) {
        setDriver(driver);
    }

    function handleDateChange(event) {
        setDate(event);
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function fetchDriverWorkLists() {

        setFetching(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (driver) {
            filters.driverId = driver.id;
        }

        if (date) {
            filters.date = moment(date).format('yyyy-MM-DD');
        }

        if ("" !== isOpen) {
            filters.open = isOpen === "true"
        }

        let driverWorkListService = new DriverWorkListService();
        driverWorkListService.getDriverWorkLists(filters) // page, rowsPerPage
            .then(data => {
                setDriverWorkLists(data.driverWorkLists);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
                setFetching(false);
            })
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)

        if(wares?.warehouses.length === 1){
            setWarehouse(wares.warehouses[0]);
        }

    }

    function fetchDrivers() {

        let filters = {};
        if (null !== warehouse) {

            filters.warehouseCode = warehouse.code;
        }

        let driverService = new DriverService();
        driverService.getDriversByFilters(filters)
            .then(response => {
                setDrivers(response.drivers)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function deleteCallback() {
        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

        setRefreshTable(!refreshTable);
    }

    function failedDeleteCallback(response) {
        setOpenNotify(true);
        setNotifySeverity('error');
        setNotificationMessage(response.message);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    useEffect(()=>{
        fetchWarehouses();
    }, [])

    useEffect(() => {

        fetchDriverWorkLists(); //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, page, rowsPerPage, refreshTable])

    return <div className={classes.root}>
        <Card style={{width: "95%", margin: "auto", marginBottom: "5%"}}>
            <DialogTitle><Typography variant={"button"}>{t("filters")}</Typography></DialogTitle>
            <div style={{display: "flex"}}>
                <Autocomplete
                    id="warehouse"
                    options={warehouses}
                    size={"small"}
                    style={{width: "20%", margin: "auto"}}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchWarehouses}
                    onChange={handleWarehouseChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={warehouse}
                            defaultValue={warehouse}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="driver"
                    options={drivers}
                    disabled={!warehouse}
                    size={"small"}
                    style={{width: "20%", margin: "auto"}}
                    getOptionLabel={option => option.name + " - " + option.surname}
                    value={driver}
                    defaultValue={driver}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchDrivers}
                    onChange={handleDriverChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver")}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={driver}
                            defaultValue={driver}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex"}}>
                <DateField
                    style={{width: "20%", margin: "auto"}}
                    label={t("trip_date")}
                    value={date}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", width: "70%", margin: "auto"}}>
                <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                    <Typography disabled={true}
                                style={{color: "black"}}>{t("status")}</Typography>
                </TableCell>

                <TableCell style={{borderBottom: "none"}}>
                    <RadioGroup
                        row
                        value={isOpen}
                        onChange={handleIsOpenChange}
                        style={{margin: "auto"}}
                    >
                        <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                          label={t("open")}/>
                        <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                          label={t("closed")}/>
                        <FormControlLabel value={""} control={<Radio color={"primary"}/>}
                                          label={t("any")}/>
                    </RadioGroup>
                </TableCell>
            </div>
            <div>
                <IconButton onClick={fetchDriverWorkLists}
                            disabled={fetching}
                            style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{fetching ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>
        <Paper className={classes.container}>

            {(fetching) ?
                <Table>
                    <LoadingComponent/>
                </Table>

                :

                <>
                    {driverWorkLists.length > 0 ?
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableCell style={{width: "3%"}}>{t("progressive")}</TableCell>
                                <TableCell style={{width: "2%"}}/>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("driver")}</TableCell>
                                <TableCell>{t("warehouse")}</TableCell>
                                <TableCell>{t("status")}</TableCell>
                                <TableCell style={{width: "10%"}}/>
                                {/*<TableCell/>*/}
                            </TableHead>

                            <TableBody>
                                {driverWorkLists.map((driverWorkList, index) => {
                                    return <DriverWorkList deleteCallback={deleteCallback}
                                                           failedDeleteCallback={failedDeleteCallback}
                                                           driverWorkList={driverWorkList}/>
                                })}
                            </TableBody>

                            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                          onClose={closeNotification}>{notificationMessage}</Notification>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={-1}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: false,
                                }}
                                labelRowsPerPage={t("rows_per_page")}
                                labelDisplayedRows={() => {
                                    return t("page")
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Table>
                        :
                        <InfoChip>{t("no_data")}</InfoChip>
                    }
                </>

            }
        </Paper>
    </div>

}

export default DriverWorkListsTable;