import SummaryInService from "../../shipments/services/SummaryInService";
import React, {useEffect, useState} from "react";
import WarehouseService from "../../warehouses/WarehouseService";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import moment from "moment";
import {
    Autocomplete,
    Card,
    CircularProgress, DialogTitle,
    IconButton,
    TableBody, TableCell,
    TableHead,
    TableRow,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import theme from "../../theme/theme";
import {SearchOutlined} from "@material-ui/icons";
import {FormControlLabel, FormLabel, Radio, RadioGroup, Table} from "@material-ui/core";
import InfoChip from "../../components/chips/InfoChip";
import FormControl from "@mui/material/FormControl";
import {value} from "lodash/seq";
import {AppStyles} from "../../theme/AppStyles";


function CustomersMissingShipmentsPage(props) {

    const classes = AppStyles();

    const {t} = useTranslation();

    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    const [shipments, setShipments] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [summary, setSummary] = useState(null);
    const [summaries, setSummaries] = useState([]);
    const [summaryDate, setSummaryDate] = useState(new Date());

    const [excludeBdx, setExcludeBdx] = useState("false");

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    function handleSummaryChange(event, summary) {
        setSummary(summary)
    }

    function handleAccountChange(event, account) {
        setAccount(account)

        if (null === account) {
            setContract(null);
            setSummary(null);
        }


    }

    function handleContractChange(event, contract) {
        setContract(contract)
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse)
    }

    function handleSummaryDateChange(event) {
        setSummaryDate(event)

        setSummary(null);
    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses)
                if(result.warehouses?.length === 1){
                    setWarehouse(result.warehouses[0]);
                }
            })
            .catch(error => {

            })
    }

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {

            })
    }

    async function fetchContracts() {

        let filters = {};

        filters.accountId = account.id;

        await new ContractService().getContractsByFilters(filters)
            .then(result => {
                setContracts(result)
            })
            .catch(error => {

            })

    }

    async function fetchSummaries() {

        let filters = {};

        filters.accountId = account.id;
        filters.contractId = contract.id;
        filters.warehouseId = warehouse.id;
        filters.summaryDate = moment(summaryDate).format('YYYY-MM-DD');

        await new SummaryInService().getByFilters(filters)
            .then(result => {
                setSummaries(result);
            })
            .catch(error => {

            })
    }


    async function getMissingShipments() {

        setShipments([]);

        setLoading(true);

        let filters = {};

        if (summaryDate) {
            filters.summaryDate = moment(summaryDate).format('YYYY-MM-DD');
        }

        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if(excludeBdx === "true"){
            filters.excludeBdx = true;
        }

        await new SummaryInService().getMissingShipments(filters)
            .then(result => {
                setShipments(result);
                setFetched(true);
            })
            .catch(error => {

            })

        setLoading(false);
    }

    function calculateTotalParcels(shipments) {
        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {
            for (let parcelsKey in shipments[i].parcels) {
                counter += 1;
            }
        }

        return counter;
    }

    function calculateMissingParcels(shipments) {
        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            let shipment = shipments[i];

            for (let j = 0; j < shipment.parcels.length; j++) {
                if (shipment.parcels[j].missing) {
                    counter += 1;
                }
            }
        }

        return counter;
    }

    function calculateTotalShipments(shipments) {

        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            counter += 1;
        }

        return counter
    }

    function calculateCheckInParcels(shipments) {

        let counter = 0;

        for (let i = 0; i < shipments.length; i++) {

            let shipment = shipments[i];

            for (let j = 0; j < shipment.parcels.length; j++) {
                if (!shipment.parcels[j].missing) {
                    counter++;
                }
            }
        }

        return counter
    }

    function backgroundColorCheck(shipment) {

        let missingCount = 0;

        for (let i = 0; i < shipment.parcels.length; i++) {
            if(shipment.parcels[i].missing){
                missingCount++;
            }
        }

        if(missingCount === 0){
            return '#56bb42'
        }
        if(missingCount === shipment.parcels.length){
            return "#fc2f2f"
        } else {
            return "#ffc21d"
        }

    }

    function handleRadioChange(event){
        setExcludeBdx(event.target.value);
        if(event.target.value === "true"){
            setSummary(null);
        }
    }

    useEffect(() => {
        fetchWarehouses();
    }, [])

    return <div style={{
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block"
    }}>
        <DialogTitle>{t("customers_missing_parcels")}</DialogTitle>
        <Card style={{
            width: "90%",
            margin: "auto",
            marginBottom: "3%"
        }}>
            <DateField
                style={{width: "15%", margin: "auto"}}
                label={t("date")}
                value={summaryDate}
                onChange={handleSummaryDateChange}
                format={"dd/MM/yyyy"}
                disableFuture={true}
                clearable={false}
            />
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <Autocomplete
                id="account-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={accounts}
                getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                value={account}
                defaultValue={account}
                noOptionsText={t("no_options")}
                onOpen={fetchAccounts}
                onChange={handleAccountChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("account")}
                        variant="outlined"
                        margin="dense"
                        value={account}
                        defaultValue={account}
                    />
                )}
            />
            <Autocomplete
                id="contract-select"
                size="small"
                disabled={!account}
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={contracts}
                getOptionLabel={option => option.code + " - " + option.name}
                value={contract}
                defaultValue={contract}
                noOptionsText={t("no_options")}
                onOpen={fetchContracts}
                onChange={handleContractChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        helperText={!account ? t("select_account_first") : null}
                        value={contract}
                        defaultValue={contract}
                    />
                )}
            />
            <FormControl sx={{ m: 5, width: "30%" }} variant={"outlined"}>
                <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={excludeBdx}
                    onChange={handleRadioChange}
                >
                    <div style={{display: "flex"}}>
                        <FormControlLabel value="false" control={<Radio />} label={""}/> <Autocomplete
                        id="summary-select"
                        size="small"
                        disabled={!account || !contract || !warehouse || excludeBdx === "true"}
                        style={{width: "100%"}}
                        options={summaries}
                        getOptionLabel={option => option.reference + " - " + new Date(option.createdAt).toLocaleDateString()}
                        value={summary}
                        defaultValue={summary}
                        noOptionsText={t("no_options")}
                        onOpen={fetchSummaries}
                        onChange={handleSummaryChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("summary")}
                                variant="outlined"
                                margin="dense"
                                helperText={!account || !contract || !warehouse ? t("select_required_fields") : null}
                                value={summary}
                                defaultValue={summary}
                            />
                        )}
                    />
                    </div>

                    <FormControlLabel value="true" control={<Radio />} label={<Typography style={{marginLeft: "12%", width: "100%"}} variant={"subtitle2"}>{t("search_by_manifest_date")}</Typography>} />
                </RadioGroup>
            </FormControl>
            <div style={{margin: "auto"}}>
                <IconButton onClick={getMissingShipments}
                            disabled={loading}
                            style={{backgroundColor: 'transparent', margin: "auto", marginBottom: "2%"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>

        </Card>

        {shipments.length > 0 && fetched ?
            <Card style={{maxWidth: "30%", margin: "auto", marginBottom: "5%"}}>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("total_shipment_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateTotalShipments(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("total_parcel_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateTotalParcels(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("missing_parcels_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateMissingParcels(shipments)}</Typography>
                <Typography style={{marginBlock: "1%"}}
                            variant={"subtitle2"}>{t("checked_in_parcels_number") + ": "}</Typography><Typography
                variant={"button"}>{calculateCheckInParcels(shipments)}</Typography>
            </Card>
            :
            null
        }


        {fetched ?
            <>
                {shipments.length > 0 ?
                    <div>
                        <Table>
                            <TableHead>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("account")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("contract")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("shipment_id")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("references")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("manifest_datetime")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("destination")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("weight")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("volume")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("parcels")}</Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {shipments.map(shipment => {

                                    return <TableRow style={{backgroundColor: backgroundColorCheck(shipment)}}>
                                        <TableCell>
                                            <Typography>{shipment.accountCorporateCode}</Typography>
                                            <Typography>{shipment.contractCode}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontWeight={"bold"} onClick={() => goToAboutMe(shipment.id)}
                                                        className={classes.dark_blue_link}> {shipment.id} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment.reference}</Typography>
                                            <Typography>{shipment.customerReference}</Typography>
                                            <Typography>{shipment.orderNumber}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{new Date(shipment.manifestDateTime).toLocaleDateString() + " - " + new Date(shipment.manifestDateTime).toLocaleTimeString()}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{(shipment.deliveryAddress.companyName? shipment.deliveryAddress.companyName : "") + (shipment.deliveryAddress.referencePerson? " " + shipment.deliveryAddress.referencePerson : "")}</Typography>
                                            <Typography>{shipment.deliveryAddress.postalCode + " " + shipment.deliveryAddress.city + " " + shipment.deliveryAddress.administrative_level_3}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment.totalWeight} {"Kg"}</Typography>
                                            <Typography>{shipment.totalVolume} {"m\u00B3"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {shipment.parcels.map(parcel => {
                                                return <Typography
                                                    style={{color: parcel.missing ? "#ffffff" : "#000000", opacity: parcel.missing ? "70%" : null}}>{parcel.barcode}</Typography>

                                            })}
                                        </TableCell>
                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }
            </>

            :

            null
        }

    </div>


}

export default CustomersMissingShipmentsPage;