import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, DialogTitle, InputAdornment, Paper, styled, TextField} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {Save, Visibility, VisibilityOff} from "@material-ui/icons";
import StyledButton from "../components/buttons/StyledButton";
import theme from "../theme/theme";
import Notification from "../components/notifications/Notification";
import UserService from "../users/UserService";


function PasswordChangePage(props) {


    const {t} = useTranslation();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleOldPasswordChange(event) {
        setOldPassword(event.target.value);
    }

    function handleNewPasswordChange(event) {
        if (event.target.value.length < 16) {
            setNewPassword(event.target.value);
        }
    }

    function handleNewPasswordRepeatChange(event) {
        if (event.target.value.length < 16) {
            setNewPasswordRepeat(event.target.value);
        }
    }

    function checkValidPassword(password) {

        let regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{6,15}$/

        return !regexp.test(password);

    }

    function toggleOldPasswordVisibility() {
        setShowOldPassword(!showOldPassword);
    }

    function toggleNewPasswordVisibility() {
        setShowNewPassword(!showNewPassword);
    }

    function toggleRepeatPasswordVisibility() {
        setShowRepeatPassword(!showRepeatPassword);
    }

    async function updatePassword() {

        if (oldPassword.trim() === '') {
            setNotificationMessage(t("password_cannot_be_empty"));
            setNotifySeverity("error")
            setOpenNotify(true);
            return;
        }

        if (newPassword.trim() === '') {
            setNotificationMessage(t("password_cannot_be_empty"));
            setNotifySeverity("error")
            setOpenNotify(true);
            return;
        }

        if (newPasswordRepeat.trim() === '') {
            setNotificationMessage(t("password_cannot_be_empty"));
            setNotifySeverity("error")
            setOpenNotify(true);
            return;
        }

        if (newPassword !== newPasswordRepeat) {
            setNotificationMessage(t("passwords_does_not_match"));
            setNotifySeverity("error")
            setOpenNotify(true);
            return;
        }

        if (checkValidPassword(newPassword)) {
            setNotificationMessage(t("invalid_password"));
            setNotifySeverity("error")
            setOpenNotify(true);
            return;
        }


        let input = {};

        input.oldPassword = oldPassword;
        input.newPassword = newPassword;
        input.repeatPassword = newPasswordRepeat;

        await new UserService().updatePassword(input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity("success")
                setOpenNotify(true);

                setNewPassword('');
                setOldPassword('');
                setNewPasswordRepeat('');
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity("error")
                    setOpenNotify(true);
                })
            })

    }

    return <div>
        {/*<DialogTitle>*/}
        {/*    {t("change_password")}*/}
        {/*</DialogTitle>*/}
        <TextField
            style={{
                display: "flex",
                margin: "auto",
                width: "25%",
                marginBlock: "2%",

            }}
            type={showOldPassword ? "text" : "password"}
            label={t("current_password")}
            variant="outlined"
            margin="dense"
            value={oldPassword}
            required
            onChange={handleOldPasswordChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            style={{backgroundColor: 'transparent'}}
                            onClick={toggleOldPasswordVisibility}
                        >
                            {!showOldPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        <TextField
            style={{
                display: "flex",
                margin: "auto",
                width: "25%",
                marginBlock: "2%",

            }}
            type={showNewPassword ? "text" : "password"}
            label={t("new_password")}
            variant="outlined"
            margin="dense"
            value={newPassword}
            required
            onCopy={(e: any) => {
                e.preventDefault();
                return false;
            }}
            error={checkValidPassword(newPassword)}
            onChange={handleNewPasswordChange}
            helperText={t("password_clauses")}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            style={{backgroundColor: 'transparent'}}
                            onClick={toggleNewPasswordVisibility}
                        >
                            {!showNewPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        <TextField
            style={{
                display: "flex",
                margin: "auto",
                width: "25%",
                marginBlock: "2%",

            }}
            type={showRepeatPassword ? "text" : "password"}
            label={t("repeat_password")}
            variant="outlined"
            margin="dense"
            value={newPasswordRepeat}
            required
            onPaste={(e: any) => {
                e.preventDefault();
                return false;
            }}
            error={newPassword !== newPasswordRepeat}
            onChange={handleNewPasswordRepeatChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            style={{backgroundColor: 'transparent'}}
                            onClick={toggleRepeatPasswordVisibility}
                        >
                            {!showRepeatPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        <div style={{marginBlock: "5%", paddingBottom: "2%"}}>
            <Button
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    display: "flex",
                    margin: "auto",
                    width: "15%",
                    marginBlock: "2%"
                }}
                endIcon={<Save/>}
                onClick={updatePassword}
            >{t("save")}</Button>
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

    </div>


}

export default PasswordChangePage;