import React, {useEffect, useState} from "react";
import {Backdrop, Button, Checkbox, Fade, Menu, MenuItem, Modal, Paper, TableCell, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ShipmentService from "./ShipmentService";
import MenuIcon from "@material-ui/icons/Menu";
import {DeliveryDining, Download, GppBad, Key, StopCircleOutlined, WarningOutlined} from "@mui/icons-material";
import {
    CancelOutlined,
    CheckCircleOutlined,
    Delete,
    DirectionsBike,
    EuroSymbolOutlined,
    LocalShipping,
    Map
} from "@material-ui/icons";
import WarehouseService from "../warehouses/WarehouseService";
import ZoneService from "../zones/ZoneService";
import {Autocomplete} from "@mui/lab";
import {useHistory} from "react-router-dom";
import PudoService from "../pudos/PudoService";
import {id} from "date-fns/locale";


const useStyles = makeStyles({
    root: {
        cursor: "pointer",
        transition: "all 0.2s",

        "&:hover": {

            background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'


        },
        '& > *': {
            borderBottom: 'unset',
        },
    },
    iconCell: {
        justifyContent: "end",
        margin: "auto",
        textAlign: 'end',
        display: "block",
    },
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }

});


function Shipment(props) {
    let shipment = props.shipment;

    const history = useHistory();

    const [openAssignZone, setOpenAssignZone] = useState(false);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState(null);

    const [lockerChangeRequest, setLockerChangeRequest] = useState(false);
    const [updateToStandard, setUpdateToStandard] = useState(false);

    const classes = useStyles();
    let deliveryAddress = shipment.deliveryAddress;
    let pickupAddress = shipment.pickupAddress;
    let contract = shipment.contract;
    let subContract = shipment.subContract;
    let manifestDate = new Date(shipment.createdAt).toLocaleDateString() + " - " + new Date(shipment.createdAt).toLocaleTimeString();

    const [anchorEl, setAnchorEl] = useState(null);

    const [confirmDialog, setConfirmDialog] = useState(false);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    async function updateServiceToStandard(){

        await new ShipmentService().updateUrbaneToStandard(shipment.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    async function activateUrbane(){

        let input = {};
        input.shipmentId = shipment?.id;
        input.pudoType = "URBANE";

        await new ShipmentService().deliverToPudo(input)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    async function fetchWarehouses() {

        let filters = {};

        filters.showAll = true;

        let wares = await new WarehouseService().getWarehousesByFilters(null,  null, filters);
        setWarehouses(wares.warehouses);

        return wares.warehouses;
    }

    async function fetchZones() {
        let filters = {}

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        let zones = await new ZoneService().getZonesByFilters(filters);
        setZones(zones.zones);
    }

    async function startAssignNewZone() {
        let zone;
        try {
            zone = await new ShipmentService().getSuggestedDeliveryZone(shipment.id);
        } catch (e) {
            console.error(e);
        }

        let wares = await fetchWarehouses();

        if (zone) {
            let warehouse = wares.find(warehouse => warehouse.code === zone.warehouseCode)

            setWarehouse(warehouse);
            setZone(zone);
        }

        setOpenAssignZone(true);
    }

    function assignNewZone() {

        let input = {};

        input.deliveryZoneId = zone.id;

        new ShipmentService().assignNewDeliveryZone(shipment.id, input)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        setZone(null);
    }

    function handleZoneChange(event, zone) {
        setZone(zone);
    }

    let permissions = localStorage.getItem("permissions");

    const {t} = useTranslation();

    async function cancelShipment() {

        await new ShipmentService().cancelShipment(shipment.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    function deleteShipment() {
        let shipmentService = new ShipmentService();
        shipmentService.deleteShipment(shipment.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })

    }

    function suspendShipment() {
        let shipmentService = new ShipmentService();
        shipmentService.suspendShipment(shipment.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    function openConfirmDialog() {
        setConfirmDialog(true);
    }

    function goToLabelPage(){
        history.push(`/shipments/${shipment.id}/label`)
    }


    function fetchLabel(labelType) {
        let shipmentService = new ShipmentService();
        shipmentService.getLabel(shipment.id, labelType)
            .then(data => {
                // window.open('data:application/pdf;base64,' + data.content)
                // setHref('data:application/pdf;base64,' + data.content);
                // setFileName(data.fileName + "." + data.contentType);
                let tempLink = document.createElement('a');
                tempLink.href = "data:" + data[0].mimeType + ";base64," + data[0].content;
                tempLink.setAttribute('download', data[0].fileName);
                tempLink.click();
            })
            .catch(error => {

            })


    }

    function deleteShipmentSU() {

        let shipmentService = new ShipmentService();
        shipmentService.deleteShipmentSU(shipment.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.failedDeleteCallback(response);
                });
            })
    }

    function handleChecked(event) {
        const isChecked = event.target.checked;

        if ("function" === typeof props.onCheck) {
            props.onCheck(isChecked);
        }
    }

    function fontColor(){
        return shipment.lastEvent?.eventCode === 90? "grey" : null;
    }

    return <TableRow>
        <TableCell style={{color: fontColor()}}>
            <Typography align={"center"}>
                <Checkbox color="primary"
                          onChange={handleChecked}
                          checked={!!props.checked}/>
            </Typography>
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            {shipment.codId?
                <EuroSymbolOutlined fontSize={"small"} style={{color: "green"}}/>
                :
                null
            }
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            <Typography onClick={() => props.goToAboutMe(shipment.id)}
                        className={classes.link}> {shipment.id}</Typography>
            <Typography variant={"subtitle2"}>{shipment.service.code}</Typography>
            <Typography variant={"subtitle1"} fontSize={14} fontStyle={"italic"}>{manifestDate} </Typography>
        </TableCell>
        {/*<TableCell>*/}
        {/*    <Typography>{shipment.service.code} </Typography>*/}
        {/*</TableCell>*/}
        <TableCell style={{color: fontColor()}}>
            <Typography>{shipment.reference ? shipment.reference : "---"} </Typography>
            <Typography>{shipment.customerReference ? shipment.customerReference : "---"} </Typography>
            <Typography>{shipment.orderNumber ? shipment.orderNumber : "---"} </Typography>
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            <Typography>{shipment.departureWarehouseCode ? shipment.departureWarehouseCode : "---"} </Typography>
            <Typography>{shipment.pickupWarehouseCode ? shipment.pickupWarehouseCode : "---"} </Typography>
            <Typography>{shipment.deliveryWarehouseCode ? shipment.deliveryWarehouseCode : "---"} </Typography>
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            <Typography>{shipment.account ? shipment.account.accountCorporateCode : "---"} </Typography>
            <Typography>{contract ? contract.code : ""} </Typography>
            <Typography>{subContract ? subContract.code : "---"} </Typography>
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            <Typography>{shipment.account ? shipment.account.companyName : "---"} </Typography>
            <Typography>{shipment.senderAddress ? shipment.senderAddress.companyName : "---"} </Typography>
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            {pickupAddress ?
                <>
                    <Typography>{(pickupAddress.companyName ? pickupAddress.companyName : "") + (pickupAddress.referencePerson ? " " + pickupAddress.referencePerson : "")} </Typography>
                    <Typography>{(pickupAddress.street ? pickupAddress.street : "") + (pickupAddress.buildingNr ? ", " + pickupAddress.buildingNr : "")} </Typography>
                    <Typography>{(pickupAddress.postalCode ? pickupAddress.postalCode : "") + (pickupAddress.city ? " " + pickupAddress.city : "") + (pickupAddress.administrativeLevel3 ? " (" + pickupAddress.administrativeLevel3 + ")" : "")}</Typography>
                </>
                :
                null
            }
        </TableCell>
        <TableCell style={{color: fontColor()}}>
            {deliveryAddress ?
                <>
                    <Typography>{(deliveryAddress.companyName ? deliveryAddress.companyName : "") + (deliveryAddress.referencePerson ? " " + deliveryAddress.referencePerson : "")} </Typography>
                    <Typography>{(deliveryAddress.street ? deliveryAddress.street : "") + (deliveryAddress.buildingNr ? ", " + deliveryAddress.buildingNr : "")} </Typography>
                    <Typography>{(deliveryAddress.postalCode ? deliveryAddress.postalCode : "") + (deliveryAddress.city ? " " + deliveryAddress.city : "") + (deliveryAddress.administrativeLevel3 ? " (" + deliveryAddress.administrativeLevel3 + ")" : "")}</Typography>
                </>
                :
                null
            }
        </TableCell>
        {/*<TableCell>*/}
        {/*    {shipment.booking ?*/}
        {/*        <Typography>{new Date(shipment.booking.bookingDate).toLocaleDateString() + " : " + shipment.booking.slotTimeRange}</Typography>*/}

        {/*        :*/}

        {/*        t("no_booking")*/}
        {/*    }*/}
        {/*</TableCell>*/}
        <TableCell style={{color: fontColor()}}>
            {shipment.lastEvent ?
                <div>
                    <Typography>{shipment.lastEvent.eventCode + " - " + shipment.lastEvent.eventName} </Typography>
                    <Typography>{(shipment.lastEvent.warehouseCode ? shipment.lastEvent.warehouseCode + " " : "") + new Date(shipment.lastEvent.dateTime).toLocaleDateString() + " - " + new Date(shipment.lastEvent.dateTime).toLocaleTimeString()} </Typography>
                    {shipment.booking ?
                        <Typography>{new Date(shipment.booking.bookingDate).toLocaleDateString() + " : " + shipment.booking.slotTimeRange}</Typography>

                        :

                        t("no_booking")
                    }
                </div>

                :

                <Typography>{t("no_events")} </Typography>
            }

        </TableCell>
        <TableCell style={{color: fontColor()}}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                style={{color: fontColor()}}
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem
                    disabled={!permissions.includes("shipments:write") || shipment.lastEvent?.eventCode === 52 || shipment.lastEvent?.eventCode === 1}
                    onClick={suspendShipment}><StopCircleOutlined color={"error"}/>&nbsp;{t("suspend")}</MenuItem>
                <MenuItem disabled={!permissions.includes("shipments:write") || (shipment.lastEvent?.eventCode !== 50 && shipment.lastEvent?.eventCode !== 2)}
                          onClick={cancelShipment}><CancelOutlined/>&nbsp;{t("cancel")}</MenuItem>
                <MenuItem disabled={!permissions.includes("shipments:write") || (shipment.lastEvent?.eventCode !== 50 && shipment.lastEvent?.eventCode !== 2)}
                          onClick={deleteShipment}><Delete/>&nbsp;{t("delete")}</MenuItem>
                <MenuItem disabled={!permissions.includes("su:admin")} onClick={openConfirmDialog}><GppBad
                    color={"error"}/>&nbsp;{t("force_delete")}</MenuItem>
                <MenuItem onClick={goToLabelPage}><Download/>&nbsp;{t("download_label_pdf")}</MenuItem>
                <MenuItem onClick={() => fetchLabel("ZPL")}><Download/>&nbsp;{t("download_label_zpl")}</MenuItem>
                <MenuItem onClick={startAssignNewZone}><Map/>&nbsp;{t("assign_new_zone")}</MenuItem>
                <MenuItem disabled={!shipment.isUrbaneViable} onClick={()=>setLockerChangeRequest(true)}><DirectionsBike/>&nbsp;{t("activate_urbane_service")}</MenuItem>
                <MenuItem disabled={shipment.service?.code !== "U"} onClick={()=>setUpdateToStandard(true)}><LocalShipping/>&nbsp;{t("update_to_standard_service")}</MenuItem>

            </Menu>
            <Modal open={openAssignZone}
                   onClose={() => setOpenAssignZone(false)}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{
                       timeout: 500,
                   }}
            >
                <Fade in={openAssignZone}>
                    <Paper variant={"outlined"} elevation={2} style={{
                        width: "30%",
                        marginInline: "auto",
                        marginTop: "10%",
                        height: "fit-content",
                        minHeight: 450,
                        minWidth: 550
                        // overflow: "hidden",
                        // border: `1px solid black`
                    }}>
                        <div style={{width: "85%", margin: "auto", marginTop: "10%", display: "block"}}>
                            <div style={{margin: "auto", marginBottom: "10%"}}>
                                <Typography variant={"subtitle2"} style={{
                                    width: "fit-content",
                                    margin: "auto",
                                    marginBottom: "5%"
                                }}>{t("actual_zone")}</Typography>
                                <Typography variant={"h5"} style={{
                                    width: "fit-content",
                                    margin: "auto"
                                }}>{(shipment.deliveryZone ? shipment.deliveryZone : t("none"))}</Typography>

                            </div>
                            <div>
                                <Typography variant={"subtitle2"} style={{
                                    width: "fit-content",
                                    margin: "auto"
                                }}>{t("suggested_delivery_zone")}</Typography>
                                <Autocomplete
                                    id="warehouse"
                                    size="small"
                                    style={{minWidth: "80%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                    options={warehouses}
                                    getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                                    value={warehouse}
                                    defaultValue={warehouse}
                                    onChange={handleWarehouseChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("warehouse")}
                                        variant="outlined"
                                        margin="dense"
                                        value={warehouse}
                                        defaultValue={warehouse}
                                    />}
                                />
                                <Autocomplete
                                    id="zone"
                                    size={"small"}
                                    style={{width: "80%", margin: "auto", marginBlock: "1%"}}
                                    options={zones}
                                    disabled={!warehouse}
                                    loadingText={<CircularProgress size={18}/>}
                                    getOptionLabel={zone => zone.code}
                                    value={zone}
                                    defaultValue={zone}
                                    onChange={handleZoneChange}
                                    onOpen={fetchZones}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("zone")}
                                        InputLabelProps={{shrink: true}}
                                        // placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={zone}
                                        defaultValue={zone}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />}
                                />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBlock: "4%",
                            paddingBottom: "2%"
                        }}>
                            <Button
                                style={{
                                    marginInline: "4%",
                                    width: "25%"
                                }}
                                endIcon={<CancelOutlined/>}
                                onClick={() => setOpenAssignZone(false)}
                                variant={"contained"}>
                                {t("cancel")}
                            </Button>
                            <Button
                                style={{
                                    marginInline: "4%",
                                    width: "25%"
                                }}
                                endIcon={<CheckCircleOutlined/>}
                                onClick={assignNewZone}
                                color="primary"
                                variant={"contained"}>
                                {t("confirm")}
                            </Button>
                        </div>
                    </Paper>
                </Fade>

            </Modal>
        </TableCell>
        <Modal open={confirmDialog}
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               className={classes.modal}
               onClose={() => setConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={confirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "fit-content",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "85%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        <WarningOutlined color={"warning"}/>
                        <Typography variant={"button"}>{t("delete_su_dialog")}</Typography>
                        <WarningOutlined color={"warning"}/>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteShipmentSU}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
        <Modal open={lockerChangeRequest}
               className={classes.modal}
               onClose={() => setLockerChangeRequest(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={lockerChangeRequest}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "fit-content",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{display: "flex"}}>
                        <Typography style={{margin: "auto", width: "fit-content", marginTop: "2%"}} variant={"button"}>{t("urbane_service_confirm") + " " + shipment?.id + "?"}</Typography>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setLockerChangeRequest(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={activateUrbane}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
        <Modal open={updateToStandard}
               className={classes.modal}
               onClose={() => setUpdateToStandard(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={updateToStandard}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "fit-content",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{display: "flex"}}>
                        <Typography style={{margin: "auto", width: "fit-content", marginTop: "2%"}} variant={"button"}>{t("standard_service_confirm") + " " + shipment?.id + "?"}</Typography>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setUpdateToStandard(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={updateServiceToStandard}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
    </TableRow>


}

export default Shipment;