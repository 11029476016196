import React, {useEffect, useState} from "react";
import ShipmentService from "./ShipmentService";
import {
    Backdrop,
    Button,
    Checkbox,
    Fade,
    FormControlLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Shipment from "./Shipment";
import {PrivateRoute} from "../routes/PrivateRoute";
import {Switch, useHistory, useLocation} from "react-router-dom";
import AboutMe from "./AboutMe";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import NewShipmentForm from "./NewShipmentForm";
import {Card, CircularProgress, Dialog, DialogTitle, Divider, FormGroup, Tooltip, Typography} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import SubContractService from "../accounts/contracts/sub-contracts/SubContractService";
import {ArrowUpwardOutlined, CloudDownload, Download, FiberNew} from "@mui/icons-material";
import WarehouseService from "../warehouses/WarehouseService";
import ShipmentLabelPage from "./ShipmentLabelPage";
import ServiceService from "./services/ServiceService";
import Notification from "../components/notifications/Notification";
import StyledButton from "../components/buttons/StyledButton";
import DateField from "../components/fields/DateField";
import {compareAsc} from "date-fns";
import moment from "moment";
import InfoChip from "../components/chips/InfoChip";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CountryService from "../geo/countries/CountryService";
import ChipInput from "material-ui-chip-input";
import CloseWorkListStepper from "../driver_work_list/CloseWorkListStepper";
import ParcelService from "./services/ParcelService";
import CodService from "./services/CodService";

let permissions = localStorage.getItem("permissions");


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    paper: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBlock: "1%",
        width: "30%"
    },
    barcodes: {
        margin: "auto",
        marginBottom: "2%",
        width: "96%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));

function ShipmentsTable() {
    const [shipments, setShipments] = useState([]);
    const {t} = useTranslation();
    const history = useHistory();

    let permissions = localStorage.getItem("permissions");

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [deliveryWarehouse, setDeliveryWarehouse] = useState(null);
    const [shipmentEventCodes, setShipmentEventCodes] = useState([]);
    const [eventCode, setEventCode] = useState(null);
    const [barcodes, setBarcodes] = useState([]);
    const [shipmentIds, setShipmentIds] = useState([]);

    const [landing, setLanding] = useState(true);


    const [loadingLabels, setLoadingLabels] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const location = useLocation();

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [pages, setPages] = useState([]);

    const [showButton, setShowButton] = useState(false);

    const [shipmentId, setShipmentId] = useState('');
    const [reference, setReference] = useState('');
    const [customerReference, setCustomerReference] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [subContract, setSubContract] = useState(null);
    const [phone, setPhone] = useState('');
    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryCap, setDeliveryCap] = useState('');
    const [deliveryCity, setDeliveryCity] = useState('');
    const [deliveryCountry, setDeliveryCountry] = useState(null);
    const [deliveryAdminL3, setDeliveryAdminL3] = useState(null);
    const [deliveryAdminL2, setDeliveryAdminL2] = useState(null);
    const [deliveryAdminL1, setDeliveryAdminL1] = useState(null);

    const [senderName, setSenderName] = useState('');
    const [senderCap, setSenderCap] = useState('');
    const [senderCity, setSenderCity] = useState('');


    const [countries, setCountries] = useState([]);
    const [adminL1List, setAdminL1List] = useState([]);
    const [adminL2List, setAdminL2List] = useState([]);
    const [adminL3List, setAdminL3List] = useState([]);

    const today = moment();
    const aMonthAgo = moment().subtract(1, 'months');
    const [manifestFromDate, setManifestFromDate] = useState(aMonthAgo);
    const [manifestToDate, setManifestToDate] = useState(today);

    const [checkInFromDate, setCheckInFromDate] = useState(null);
    const [checkInToDate, setCheckInToDate] = useState(null);

    const [hasBooking, setHasBooking] = useState("any");
    const [hasMandatoryBooking, setHasMandatoryBooking] = useState(false);
    const [bookingFromDate, setBookingFromDate] = useState(null);
    const [bookingToDate, setBookingToDate] = useState(null);

    const [includeRelease, setIncludeRelease] = useState("false");
    const [excludeEol, setExcludeEol] = useState("false");

    const [count, setCount] = useState(0);


    const [accountList, setAccountList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [subContractList, setSubContractList] = useState([]);


    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [openMenu, setOpenMenu] = useState(false);
    const [allShipmentChecked, setAllShipmentChecked] = useState(false);
    const [checkedShipments, setCheckedShipments] = useState({});
    const [isAddEventModalOpened, setIsAddEventModalOpened] = useState(false);
    const [addEventModalShipments, setAddEventModalShipments] = useState([]);

    const [hasCod, setHasCod] = useState(false);
    const [isUrbaneViable, setIsUrbaneViable] = useState(false);

    function handleHasCodChange(event) {
        setHasCod(event.target.checked)
    }

    function handleIsUrbaneViableChange(event){
        setIsUrbaneViable(event.target.checked)
    }

    const handleOpen = () => {
        setOpenMenu(true);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    function handleDeliveryWarehouseChange(event, deliveryWarehouse) {
        setDeliveryWarehouse(deliveryWarehouse);
    }

    function handleShipmentIdChange(event) {

        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleIncludeReleaseChange(event) {
        setIncludeRelease(event.target.value)
    }

    function handleExcludeEolChange(event) {
        setExcludeEol(event.target.value)
    }

    function handleReferenceChange(event) {
        setReference(event.target.value);
    }

    function handleCustomerReferenceChange(event) {
        setCustomerReference(event.target.value);
    }

    function handleOrderNumberChange(event) {
        setOrderNumber(event.target.value);
    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        setSubContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
        setSubContract(null);
    }

    function handleSubContractChange(event, subContract) {
        setSubContract(subContract);
    }

    function handleEventCodeChange(event, eventCode) {
        setEventCode(eventCode);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value);
    }

    function handleCountryChange(event, country) {
        setDeliveryCountry(country);
        setDeliveryAdminL1(null);
        setDeliveryAdminL2(null);
        setDeliveryAdminL3(null);
    }

    function handleAdminL1Change(event, adminL1) {
        setDeliveryAdminL1(adminL1);
        setDeliveryAdminL2(null);
        setDeliveryAdminL3(null);
    }

    function handleAdminL2Change(event, adminL2) {
        setDeliveryAdminL2(adminL2);
        setDeliveryAdminL3(null);
    }

    function handleAdminL3Change(event, adminL3) {
        setDeliveryAdminL3(adminL3);
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts()
            .then(data => {
                setAccountList(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContractList(response);
            })

    }

    function fetchSubContracts() {
        let subContractService = new SubContractService();
        let filters = {}
        if (contract != null) {
            filters.contractId = contract.id
        }

        subContractService.getSubContractsByFilters(filters)
            .then(response => {
                setSubContractList(response);
            })

    }

    function fetchShipmentEventCodes() {
        let shipmentService = new ShipmentService();
        shipmentService.getShipmentEventCodes()
            .then(data => {
                setShipmentEventCodes(data);
            })
            .catch(error => {

            })
    }

    function fetchShipmentServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data);
            })
            .catch(error => {

            })
    }

    function fetchWarehouses() {

        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters()
            .then(data => {
                setWarehouses(data.warehouses);
            })
            .catch(error => {

            })

    }

    function handleManifestFromDate(event) {
        if (manifestToDate == null || compareAsc(event, manifestToDate) === 1) {
            setManifestFromDate(event);
            setManifestToDate(event);
        } else {
            setManifestFromDate(event)
        }
    }

    function handleManifestToDate(event) {
        if (manifestFromDate == null || compareAsc(manifestFromDate, event) === 1) {
            setManifestFromDate(event);
            setManifestToDate(event);
        } else {
            setManifestToDate(event);
        }
    }

    function handleCheckInFromDate(event) {
        if (checkInToDate == null || compareAsc(event, checkInToDate) === 1) {
            setCheckInFromDate(event);
            setCheckInToDate(event);
        } else {
            setCheckInFromDate(event)
        }
    }

    function handleCheckInToDate(event) {
        if (setCheckInFromDate == null || compareAsc(setCheckInFromDate, event) === 1) {
            setCheckInFromDate(event);
            setCheckInToDate(event);
        } else {
            setCheckInToDate(event);
        }
    }

    function handleBookingFromDate(event) {
        if (compareAsc(event, bookingToDate) === 1) {
            setBookingFromDate(event);
            setBookingToDate(event);
        } else {
            setBookingFromDate(event);
        }
    }

    function handleBookingToDate(event) {
        if (compareAsc(bookingFromDate, event) === 1) {
            setBookingFromDate(event);
            setBookingToDate(event);
        } else {
            setBookingToDate(event);
        }
    }

    function handleHasBookingChange(event) {

        setHasBooking(event.target.value)
        setBookingFromDate(null);
        setBookingToDate(null);

    }

    function handleDeliveryNameChange(event) {
        setDeliveryName(event.target.value)
    }

    function handleDeliveryCapChange(event) {
        setDeliveryCap(event.target.value)
    }

    function handleDeliveryCityChange(event) {
        setDeliveryCity(event.target.value)
    }

    function handleSenderNameChange(event) {
        setSenderName(event.target.value)
    }

    function handleSenderCapChange(event) {
        setSenderCap(event.target.value)
    }

    function handleSenderCityChange(event) {
        setSenderCity(event.target.value)
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();
        let filters = {}

        if (deliveryCountry) {
            filters.countryId = deliveryCountry.id;
        }

        adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL1List(data.admins1);
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        let filters = {}
        if (deliveryAdminL1) {

            filters.adminL1 = deliveryAdminL1.id;
        }

        adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL2List(data.admins2);
            })

    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();

        let filters = {};

        if (deliveryCountry) {
            filters.countryId = deliveryCountry.id;
        }

        if (deliveryAdminL2) {
            filters.adminL2 = deliveryAdminL2.id;
        }

        adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdminL3List(data.admins3);
            })

    }


    function fetchCSV() {
        setLoadingCsv(true);

        let filters = getFilters();

        const shipmentService = new ShipmentService();
        shipmentService.getCSV(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCsv(false);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setLoadingCsv(false);
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            });
    }

    function fetchReducedCsv() {
        setLoadingCsv(true);

        let filters = getFilters();

        const shipmentService = new ShipmentService();
        shipmentService.getReducedCSV(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCsv(false);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setLoadingCsv(false);
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            });
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>

                {permissions.includes("parcel-events:write") ? <Button onClick={openAddEventModal}>
                    {t("add_event")}
                </Button> : null}
            </div>
        );
    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
        // history.push(`/shipments/${id}/aboutMe`)
    }

    function openAddEventModal() {
        // aaa1
        fetchWorkListData().then(value => setIsAddEventModalOpened(true));
    }

    function getFilters() {
        let filters = {};
        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;
        if (reference !== '') {
            filters.reference = reference;
        }
        if ('' !== customerReference) {
            filters.customerReference = customerReference;
        }
        if ('' !== orderNumber) {
            filters.orderNumber = orderNumber;
        }
        if (null !== account) {
            filters.accountCorporateCode = account.accountCorporateCode;
        }
        if (null !== contract) {
            filters.contractCode = contract.code;
        }
        if (null !== subContract) {
            filters.subContractCode = subContract.code;
        }
        if (eventCode !== null) {
            filters.lastStatusCode = eventCode.id;
        }
        if (service !== null) {
            filters.serviceCode = service.code;
        }
        if (warehouse !== null) {
            filters.departureWarehouseCode = warehouse.code;
        }

        if (deliveryWarehouse !== null) {
            filters.deliveryWarehouseCode = deliveryWarehouse.code;
        }

        if (phone !== '') {
            filters.phone = phone;

            filters.phone = encodeURIComponent(phone);
        }
        // if ('' !== shipmentId) {
        //     filters.shipmentId = shipmentId;
        // }

        if (shipmentIds.length > 0) {
            const re = /^[0-9\b]+$/;
            if (shipmentIds.every(ship => re.test(ship))) {
                filters.idList = shipmentIds;
            } else {
                setNotificationMessage(t("shipmentId_not_a_number"));
                setOpenNotify(true);
                setNotifySeverity('error');
            }

        }

        if (barcodes.length > 0) {
            filters.barcodes = barcodes;
        }

        if (manifestFromDate) {
            filters.manifestFromDate = moment(manifestFromDate).format("yyyy-MM-DD");
        }

        if (manifestToDate) {
            filters.manifestToDate = moment(manifestToDate).format("yyyy-MM-DD");
        }

        if (checkInFromDate) {
            filters.checkInFromDate = moment(checkInFromDate).format("yyyy-MM-DD");
        }

        if (checkInToDate) {
            filters.checkInToDate = moment(checkInToDate).format("yyyy-MM-DD");
        }

        if (hasBooking === "true") {
            filters.hasBooking = true;
        } else if (hasBooking === "false") {
            filters.hasBooking = false;
        }

        if (hasMandatoryBooking) {
            filters.hasMandatoryBooking = hasMandatoryBooking;
        }

        if (bookingFromDate) {
            filters.bookingFromDate = moment(bookingFromDate).format("yyyy-MM-DD");
        }

        if (bookingToDate) {
            filters.bookingToDate = moment(bookingToDate).format("yyyy-MM-DD");
        }

        if (deliveryAdminL3) {
            filters.deliveryAdminL3 = deliveryAdminL3.code;
        } else if (deliveryAdminL2) {
            filters.deliveryAdminL2 = deliveryAdminL2.code;
        } else if (deliveryAdminL1) {
            filters.deliveryAdminL1 = deliveryAdminL1.code;
        } else if (deliveryCountry) {
            filters.deliveryCountry = deliveryCountry.iso3166Alpha3;
        }

        if ('' !== deliveryName) {
            filters.deliveryName = deliveryName;
        }
        if ('' !== deliveryCap) {
            filters.deliveryCap = deliveryCap;
        }
        if ('' !== deliveryCity) {
            filters.deliveryCity = deliveryCity;
        }

        if ('' !== senderName) {
            filters.senderName = senderName;
        }
        if ('' !== senderCap) {
            filters.senderCap = senderCap;
        }
        if ('' !== senderCity) {
            filters.senderCity = senderCity;
        }

        switch (includeRelease) {
            case "true":
                filters.includeRelease = true;
                break;
            case "false":
                filters.includeRelease = false;
                break;
            default:
                break;
        }

        switch (excludeEol) {
            case "true":
                filters.excludeEol = true;
                break;
            case "false":
                filters.excludeEol = false;
                break;
            default:
                break;
        }

        if (hasCod) {
            filters.hasCod = true;
        }

        if (isUrbaneViable) {
            filters.isUrbaneViable = true;
        }

        return filters;
    }

    function fetchShipments() {
        setFetching(true);
        const shipmentService = new ShipmentService();
        let filters = getFilters();

        shipmentService.getAllShipment(filters)
            .then(response => {
                setShipments(response.shipments);
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setFetching(false)
                setCount(response.count)
                setFetched(true);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setCount(0);
                        setShipments([]);
                        setOpenNotify(true);
                        setNotificationMessage(response.message)
                        setNotifySeverity('error')
                        setFetching(false);
                        setFetched(true);
                    })

            });
    }


    async function fetchLabelsByFilters() {

        setLoadingLabels(true);

        /*let filters = {};

        if (reference !== '') {
            filters.reference = reference;
        }
        if ('' !== customerReference) {
            filters.customerReference = customerReference;
        }
        if ('' !== orderNumber) {
            filters.orderNumber = orderNumber;
        }
        if (null !== account) {
            filters.accountCorporateCode = account.accountCorporateCode;
        }
        if (null !== contract) {
            filters.contractCode = contract.code;
        }
        if (null !== subContract) {
            filters.subContractCode = subContract.code;
        }
        if (eventCode !== null) {
            filters.lastStatusCode = eventCode.id;
        }
        if (service !== null) {
            filters.serviceCode = service.code;
        }
        if (warehouse !== null) {
            filters.departureWarehouseCode = warehouse.code;
        }
        if (phone !== '') {
            filters.phone = phone;

            filters.phone = encodeURIComponent(phone);
        }
        if ('' !== shipmentId) {
            filters.shipmentId = shipmentId;
        }

        if (manifestFromDate) {
            filters.manifestFromDate = moment(manifestFromDate).format("yyyy-MM-DD");
        }

        if (manifestToDate) {
            filters.manifestToDate = moment(manifestToDate).format("yyyy-MM-DD");
        }

        if (bookingFromDate) {
            filters.bookingFromDate = moment(bookingFromDate).format("yyyy-MM-DD");
        }

        if (bookingToDate) {
            filters.bookingToDate = moment(bookingToDate).format("yyyy-MM-DD");
        }

        if (deliveryAdminL3) {
            filters.deliveryAdminL3 = deliveryAdminL3.name;
        } else if (deliveryAdminL2) {
            filters.deliveryAdminL2 = deliveryAdminL2.code;
        } else if (deliveryAdminL1) {
            filters.deliveryAdminL1 = deliveryAdminL1.code;
        } else if (deliveryCountry) {
            filters.deliveryCountry = deliveryCountry.iso3166Alpha3;
        }

        switch (includeRelease) {
            case "true":
                filters.includeRelease = true;
                break;
            default:
                break;
        }*/

        let filters = getFilters();

        let shipmentService = new ShipmentService();
        await shipmentService.getShipmentLabelsByFilters(filters, "PDF")
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:" + data[0].mimeType + ";base64," + data[0].content;
                tempLink.setAttribute('download', data[0].fileName);
                tempLink.click();
                setLoadingLabels(false);
            })
            .catch(error => {
                error.response.json()
                    .then((response) => {
                        setLoadingLabels(false);
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            })


    }

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);
        setLanding(false);

    }

    function clearFilters() {
        setReference('');
        setCustomerReference('');
        setOrderNumber('');
        setAccount(null);
        setContract(null);
        setSubContract(null);
        setEventCode(null);
        setService(null);
        setWarehouse(null);
        setDeliveryWarehouse(null);
        setManifestFromDate(aMonthAgo);
        setManifestToDate(today);
        setBookingFromDate(null);
        setBookingToDate(null);
        setShipmentId('');
        setPhone('');
        setBarcodes([]);
        setShipmentIds([]);
        setDeliveryCountry(null);
        setDeliveryAdminL1(null);
        setDeliveryAdminL2(null);
        setDeliveryAdminL3(null);
        setDeliveryName('');
        setDeliveryName('');
        setDeliveryCap('');
        setDeliveryCity('');
        setSenderName('');
        setSenderCap('');
        setSenderCity('');
        setHasCod(false);
        setIsUrbaneViable(false);

        setIncludeRelease("false");
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");
        setPage(0);
        setRefresh(!refresh)

    }

    function failedDeleteCallback(response) {

        setOpenNotify(true);
        setNotifySeverity('error');
        setNotificationMessage(response.message);

        // setRefresh(!refresh)

    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleEnter(event) {

        if (event.code === "Enter") {
            fetchShipments();
        }

    }

    function addBarcode(barcode) {

        let codes = barcode.split(" ");

        for (let i = 0; i < codes.length; i++) {
            if (barcodes[i] !== "") {
                barcodes.push(codes[i])
            }
        }
    }

    function removeBarcode(barcode, index) {
        barcodes.splice(index, 1);
        setRefresh(!refresh)
    }

    function addShipmentId(id) {

        const re = /^[0-9\b]+$/;

        let ids = id.split(" ");

        for (let i = 0; i < ids.length; i++) {
            if (re.test(ids[i]) && ids[i] !== "") {
                shipmentIds.push(ids[i])
            }
        }


    }

    function removeShipmentId(id, index) {
        shipmentIds.splice(index, 1);
        setRefresh(!refresh)
    }

    function handleAllShipmentsChecked(event) {
        const isChecked = event.target.checked;
        setAllShipmentChecked(isChecked);

        const newCheckedShipments = {...checkedShipments};
        for (let i = 0; i < shipments.length; ++i) {
            newCheckedShipments[shipments[i].id] = isChecked;
        }
        setCheckedShipments(newCheckedShipments);
    }

    function onShipmentCheck(shipment, index) {
        return (isChecked) => {
            const newCheckedShipments = {...checkedShipments};
            newCheckedShipments[shipment.id] = isChecked;
            setCheckedShipments(newCheckedShipments);
        }
    }

    async function fetchWorkListData() {
        let workListShipments = [];
        for (let i = 0; i < shipments.length; ++i) {
            const shipment = shipments[i];
            if (!!checkedShipments[shipment.id]) {
                workListShipments.push(shipment);
            }
        }

        for (let i = 0; i < workListShipments.length; i++) {
            const shipment = workListShipments[i];

            const parcelService = new ParcelService();
            const shipmentService = new ShipmentService();

            let shipmentParcels = await shipmentService.getParcelsOfShipment(shipment.id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            let cod = null;
            if (shipment.codId) {
                const codService = new CodService();
                cod = await codService.getCodById(shipment.codId)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage("Si è verificato un errore nel recupero del contrassegno: " + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    });
            }

            // let warehouseStorage = null;
            // const parcelThatCauseWarehouseStorage = shipmentParcels.find(tmpParcel => !!deliveryEventCodesThatOpensWarehouseStorages[tmpParcel.lastEvent.eventCodeId]);
            // if (parcelThatCauseWarehouseStorage) {
            //     let needToOpenWarehouseStorage = true;
            //     let warehouseStorageReasonId = deliveryEventCodesThatOpensWarehouseStorages[parcelThatCauseWarehouseStorage.lastEvent.eventCodeId].openingReasonCodeId;
            //     if (deliveryEventCodesWithMultipleAttempts.some(value => value === parcelThatCauseWarehouseStorage.lastEvent.eventCodeId)) {
            //         // got an event code with multiple attempts
            //         warehouseStorageReasonId = 2; // Done multiple attempts
            //         needToOpenWarehouseStorage = shipmentParcels.some(shipmentParcel => {
            //             return shipmentParcel.numOfDeliveryAttempts >= shipmentParcel.numOfAllowedDeliveryAttempts;
            //         });
            //     }
            //
            //     if (needToOpenWarehouseStorage) {
            //         warehouseStorage = {
            //             checked: true,
            //             reasonId: warehouseStorageReasonId
            //         };
            //
            //         const warehouseStorageService = new WarehouseStorageService();
            //         const restWarehouseStorages = await warehouseStorageService.getFilteredWarehouseStorages({
            //             shipmentId: shipment.id
            //         })
            //             .catch(error => {
            //                 error.response.json().then(response => {
            //                     setNotificationMessage("Si è verificato un errore nel recupero delle giacenze : " + response.status + "-" + response.message);
            //                     setNotifySeverity('error');
            //                     setOpenNotify(true);
            //                 })
            //             });
            //
            //         if (restWarehouseStorages && 0 < restWarehouseStorages.warehouseStorages.length) {
            //             warehouseStorage = restWarehouseStorages.warehouseStorages[0];
            //         }
            //     }
            // }

            //let data = {};
            //data.shipment = shipment;
            shipment.cod = cod;
            //data.warehouseStorage = warehouseStorage;

            shipment.parcels = [];
            //data.driverWorkListParcels = driverWorkListParcels;
            for (let i = 0; i < shipmentParcels?.length; i++) {
                let parcelData = {};
                parcelData.parcel = shipmentParcels[i];
                //if (shipmentParcels[i].barcode === parcel.barcode) {
                parcelData.checked = true;
                //}

                shipment.parcels.push(parcelData);
            }

            workListShipments[i] = shipment;
        }

        setAddEventModalShipments(workListShipments);
    }

    async function onWorkListStepperSave(results) {
        //let parcelService = new ParcelService();
//
        //if (state.shipments && 0 < state.shipments.length) {
        //    for (let i = 0; i < state.shipments.length; i++) {
        //        let shipmentState = state.shipments[i];
        //        let shipment = addEventModalShipments
        //            .filter((tmpShipment) => tmpShipment.id === shipmentState.shipmentId)[0];
//
        //        if (shipmentState.status) {
        //            for (let j = 0; j < shipment.parcels.length; j++) {
        //                let parcel = shipment.parcels[j];
//
        //                if (parcel.checked) {
        //                    let request = {};
        //                    //let tmp = new Date(date);
        //                    //let newDate = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), shipmentState.time.getHours(), shipmentState.time.getMinutes(), 0, 0);
        //                    request.dateTime = moment(shipmentState.time).format('YYYY-MM-DDTHH:mm:ssZ');
        //                    request.eventCodeId = shipmentState?.status?.id;
        //                    request.driverId = shipmentState?.driver?.id;
        //                    request.warehouseId = shipmentState?.warehouse?.id;
//
        //                    await parcelService.addEventToParcel(parcel.parcel.id, request)
        //                        .then(async result => {
        //                            if (shipment.codId && shipmentState.codMode) {
        //                                const codService = new CodService();
        //                                await codService.collectCod(
        //                                    shipment.codId,
        //                                    {
        //                                        dateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        //                                        modeId: shipmentState.codMode.id,
        //                                        verified: shipmentState.verified
        //                                    }
        //                                ).catch(error => {
        //                                    error.response.json().then(response => {
        //                                        setNotificationMessage(response.status + "-" + response.message);
        //                                        setNotifySeverity('error');
        //                                        setOpenNotify(true);
        //                                    })
        //                                });
        //                            }
        //                        })
        //                        .catch(error => {
        //                            error.response.json().then(response => {
        //                                setNotificationMessage(response.status + "-" + response.message);
        //                                setNotifySeverity('error');
        //                                setOpenNotify(true);
        //                            })
        //                        });
        //                }
        //            }
        //        }
//
        //        if (shipmentState.warehouseStorage && !shipmentState.warehouseStorage.id && shipmentState.warehouseStorage.checked) {
        //            const warehouseStorageService = new WarehouseStorageService();
        //            await warehouseStorageService.createWarehouseStorage({
        //                    shipment: {id: shipmentState.shipment.id},
        //                    openingDateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        //                    reasonId: shipmentState.warehouseStorage.reasonId,
        //                    warehouseCode: warehouse.code,
        //                    notes: shipmentState.warehouseStorage.note,
        //                }
        //            ).catch(error => {
        //                error.response.json().then(response => {
        //                    setNotificationMessage(response.status + "-" + response.message);
        //                    setNotifySeverity('error');
        //                    setOpenNotify(true);
        //                })
        //            });
        //        }
        //    }
        //}

        // setIsAddEventModalOpened(false);
        // setRefresh(!refresh);
    }

    useEffect(() => {
        if (!landing) {
            fetchShipments();
        }

        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, [rowsPerPage, page, refresh, location]);
    //
    // if (fetchShipmentError) {
    //     return <Alert severity="error">{fetchShipmentError}</Alert>
    // } else if (shipments.length <= 0) {
    //     return "No data";
    // }


    return (
        <div className={classes.root}>


            <Switch>
                <PrivateRoute exact path={"/shipments"}>
                    <Card elevation={1} defaultExpanded={true} onKeyPress={handleEnter}
                          style={{width: "95%", margin: "auto", marginBottom: "2%"}}>
                        <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                            <Typography variant={"button"}>{t("filters")}</Typography>
                        </DialogTitle>
                        <ChipInput
                            className={classes.filter}
                            key={"shipmentId"}
                            label={t("shipmentId")}
                            value={shipmentIds}
                            onAdd={(cID) => addShipmentId(cID)}
                            onDelete={((cID, index) => removeShipmentId(cID, index))}
                            variant={"outlined"}
                            margin={"dense"}
                            size={"small"}
                            blurBehavior="clear"
                            newChipKeys={KeyboardEvent.ENTER}
                        />
                        <Divider style={{width: "95%", margin: "auto"}}/>
                        <ChipInput
                            className={classes.filter}
                            key={"barcodes"}
                            label={t("barcode")}
                            value={barcodes}
                            onAdd={(cBarcode) => addBarcode(cBarcode)}
                            onDelete={((cBarcode, index) => removeBarcode(cBarcode, index))}
                            variant={"outlined"}
                            margin={"dense"}
                            size={"small"}
                            blurBehavior="clear"
                            newChipKeys={KeyboardEvent.ENTER}
                        />
                        <Divider style={{width: "95%", margin: "auto"}}/>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <TextField
                                className={classes.filter}
                                label={t("reference")}
                                value={reference}
                                onChange={handleReferenceChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("customerReference")}
                                value={customerReference}
                                onChange={handleCustomerReferenceChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("orderNumber")}
                                value={orderNumber}
                                onChange={handleOrderNumberChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                        </div>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="account"
                                options={accountList}
                                className={classes.filter}
                                getOptionLabel={option => option.companyName}
                                value={account}
                                defaultValue={account}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchAccounts}
                                onChange={handleAccountChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("account")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={account}
                                        defaultValue={account}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="contract"
                                disabled={!account}
                                options={contractList}
                                className={classes.filter}
                                getOptionLabel={option => option.code}
                                value={contract}
                                defaultValue={contract}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchContracts}
                                onChange={handleContractChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("contract")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={contract}
                                        defaultValue={contract}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="subContract"
                                disabled={!contract}
                                options={subContractList}
                                className={classes.filter}
                                getOptionLabel={option => option.code}
                                value={subContract}
                                defaultValue={subContract}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchSubContracts}
                                onChange={handleSubContractChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("sub-contract")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={subContract}
                                        defaultValue={subContract}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="eventCode"
                                options={shipmentEventCodes}
                                className={classes.filter}
                                getOptionLabel={option => option.id + " - " + option.name}
                                value={eventCode}
                                defaultValue={eventCode}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchShipmentEventCodes}
                                onChange={handleEventCodeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("last_event_code")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={eventCode}
                                        defaultValue={eventCode}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="services"
                                options={services}
                                className={classes.filter}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={service}
                                defaultValue={service}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchShipmentServices}
                                onChange={handleServiceChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("service_code")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={service}
                                        defaultValue={service}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="departureWarehouse"
                                options={warehouses}
                                className={classes.filter}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={warehouse}
                                defaultValue={warehouse}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchWarehouses}
                                onChange={handleWarehouseChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("departure_warehouse_code")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={warehouse}
                                        defaultValue={warehouse}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="deliveryWarehouse"
                                options={warehouses}
                                className={classes.filter}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={deliveryWarehouse}
                                defaultValue={deliveryWarehouse}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchWarehouses}
                                onChange={handleDeliveryWarehouseChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("delivery_warehouse_code")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={deliveryWarehouse}
                                        defaultValue={deliveryWarehouse}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div style={{display: "flex", marginBottom: "1%"}}>
                            <DateField
                                style={{margin: "auto", width: "25%"}}
                                label={t("manifest_from_date")}
                                value={manifestFromDate}
                                onChange={handleManifestFromDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                            <DateField
                                style={{margin: "auto", width: "25%"}}
                                label={t("manifest_to_date")}
                                value={manifestToDate}
                                onChange={handleManifestToDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                        </div>
                        <div style={{display: "flex", marginBottom: "1%"}}>
                            <DateField
                                style={{margin: "auto", width: "25%"}}
                                label={t("check_in_from_date")}
                                value={checkInFromDate}
                                onChange={handleCheckInFromDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                            <DateField
                                style={{margin: "auto", width: "25%"}}
                                label={t("check_in_to_date")}
                                value={checkInToDate}
                                onChange={handleCheckInToDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                        </div>
                        <Typography variant={"subtitle2"} fontSize={16}
                                    style={{color: "black", margin: "auto"}}>{t("booking")}</Typography>
                        <FormGroup>
                            <FormControlLabel
                                style={{margin: "auto"}}
                                label={<Typography fontSize={12}
                                                   variant={"button"}>{t("mandatory_booking_only")}</Typography>}
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={hasMandatoryBooking}
                                        onChange={event => setHasMandatoryBooking(event.target.checked)}
                                    />
                                }
                            />
                        </FormGroup>
                        <div style={{display: "flex", width: "60%", margin: "auto"}}>
                            <Typography variant={"subtitle2"}
                                        style={{
                                            margin: "auto"
                                        }}>{t("has_booking")}</Typography>
                            <RadioGroup
                                row
                                value={hasBooking}
                                onChange={handleHasBookingChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"any"} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                            </RadioGroup>
                        </div>
                        <div style={{display: "flex", marginBottom: "3%"}}>
                            <DateField
                                disabled={hasBooking !== "true"}
                                style={{margin: "auto", width: "25%"}}
                                label={t("booking_from_date")}
                                value={bookingFromDate}
                                onChange={handleBookingFromDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={false}
                                clearable={true}
                            />
                            <DateField
                                disabled={hasBooking !== "true"}
                                style={{margin: "auto", width: "25%"}}
                                label={t("booking_to_date")}
                                value={bookingToDate}
                                onChange={handleBookingToDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={false}
                                clearable={true}
                            />
                        </div>
                        <Divider style={{width: "95%", margin: "auto"}}/>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <TextField
                                className={classes.filter}
                                label={t("phone")}
                                value={phone}
                                onChange={handlePhoneChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                        </div>
                        <Divider style={{width: "95%", margin: "auto"}}/>
                        <Typography variant={"subtitle2"} fontSize={16}
                                    style={{
                                        color: "black",
                                        margin: "auto",
                                        marginTop: "1%"
                                    }}>{t("delivery_address")}</Typography>
                        <div style={{display: "flex", width: "100%"}}>
                            <Autocomplete
                                style={{width: "25%", marginInline: "1%"}}
                                id="country"
                                className={classes.field}
                                options={countries}
                                classes={{
                                    option: classes.option,
                                }}
                                getOptionLabel={(option) => option.name}
                                value={deliveryCountry}
                                defaultValue={deliveryCountry}
                                onOpen={fetchCountries}
                                onChange={handleCountryChange}
                                onBlur={fetchAdminL1}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("country")}
                                        variant="outlined"
                                        margin="dense"
                                        value={option => option.iso3166Alpha3}
                                        defaultValue={deliveryCountry}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                style={{width: "25%", marginInline: "1%"}}
                                id="administrative1"
                                disabled={!deliveryCountry}
                                className={classes.field}
                                options={adminL1List}
                                getOptionLabel={option => option.name}
                                value={deliveryAdminL1}
                                defaultValue={deliveryAdminL1}
                                onOpen={fetchAdminL1}
                                onChange={handleAdminL1Change}
                                // loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("administrative1")}
                                        variant="outlined"
                                        margin="dense"
                                        value={deliveryAdminL1}
                                        defaultValue={deliveryAdminL1}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                style={{width: "25%", marginInline: "1%"}}
                                id="administrative2"
                                disabled={!deliveryAdminL1}
                                className={classes.field}
                                options={adminL2List}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={deliveryAdminL2}
                                onOpen={fetchAdminL2}
                                onChange={handleAdminL2Change}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative2")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={deliveryAdminL2}
                                    />
                                )}
                            />
                            <Autocomplete
                                style={{width: "25%", marginInline: "1%"}}
                                id="administrative3"
                                disabled={!deliveryCountry}
                                className={classes.field}
                                options={adminL3List}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={deliveryAdminL3}
                                onOpen={fetchAdminL3}
                                onChange={handleAdminL3Change}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative3")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={deliveryAdminL3}
                                    />
                                )}
                            />
                        </div>
                        <div style={{alignItems: 'center', display: "flex", marginBlock: "1%"}}>
                            <TextField
                                className={classes.filter}
                                label={t("delivery_name")}
                                value={deliveryName}
                                onChange={handleDeliveryNameChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("delivery_cap")}
                                value={deliveryCap}
                                onChange={handleDeliveryCapChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("delivery_city")}
                                value={deliveryCity}
                                onChange={handleDeliveryCityChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                        </div>
                        <Typography variant={"subtitle2"} fontSize={16}
                                    style={{color: "black", margin: "auto"}}>{t("sender_address")}</Typography>
                        <div style={{alignItems: 'center', display: "flex", marginBlock: "1%"}}>
                            <TextField
                                className={classes.filter}
                                label={t("sender_name")}
                                value={senderName}
                                onChange={handleSenderNameChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("sender_cap")}
                                value={senderCap}
                                onChange={handleSenderCapChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                            <TextField
                                className={classes.filter}
                                label={t("sender_city")}
                                value={senderCity}
                                onChange={handleSenderCityChange}
                                margin={"dense"}
                                variant={"outlined"}
                            />
                        </div>
                        <div style={{display: "block", marginTop: "2%"}}>
                            <Typography variant={"subtitle2"} fontSize={16}
                                        style={{color: "black", margin: "auto"}}>{t("include_release")}</Typography>
                            <div style={{display: "flex", width: "100%"}}>
                                <RadioGroup
                                    row
                                    value={includeRelease}
                                    onChange={handleIncludeReleaseChange}
                                    style={{margin: "auto"}}
                                >
                                    <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                      label={t("yes")}/>
                                    <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                      label={t("no")}/>
                                </RadioGroup>
                            </div>

                        </div>
                        <div style={{display: "block", marginTop: "2%"}}>
                            <Typography variant={"subtitle2"} fontSize={16}
                                        style={{
                                            color: "black",
                                            margin: "auto"
                                        }}>{t("exclude_eol_shipments")}</Typography>
                            <div style={{display: "flex", width: "100%"}}>
                                <RadioGroup
                                    row
                                    value={excludeEol}
                                    onChange={handleExcludeEolChange}
                                    style={{margin: "auto"}}
                                >
                                    <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                      label={t("yes")}/>
                                    <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                      label={t("no")}/>
                                </RadioGroup>
                            </div>
                            <div style={{display: "block", marginTop: "2%"}}>
                                <Typography variant={"subtitle2"} fontSize={16}
                                            style={{
                                                color: "black",
                                                margin: "auto"
                                            }}>{t("cod")}</Typography>
                                <div style={{display: "flex", width: "100%"}}>
                                    <FormControlLabel
                                        style={{margin: "auto"}}
                                        label={<Typography fontSize={12}
                                                           variant={"button"}>{t("has_cod_only")}</Typography>}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={hasCod}
                                                onChange={handleHasCodChange}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{display: "block", marginTop: "2%"}}>
                                <Typography variant={"subtitle2"} fontSize={16}
                                            style={{
                                                color: "black",
                                                margin: "auto"
                                            }}>{t("urbane")}</Typography>
                                <div style={{display: "flex", width: "100%"}}>
                                    <FormControlLabel
                                        style={{margin: "auto"}}
                                        label={<Typography fontSize={12}
                                                           variant={"button"}>{t("is_urbane_viable_only")}</Typography>}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={isUrbaneViable}
                                                onChange={handleIsUrbaneViableChange}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                            <Button style={{margin: "5%"}} onClick={applyFilters}>{t("search")}</Button>
                        </div>
                    </Card>
                    <div style={{width: "100%"}}>
                        <StyledButton onClick={() => history.push("/shipments/new")}
                                      icon={<FiberNew/>}>{t("create_shipment")}</StyledButton>
                        <StyledButton disabled={loadingCsv || shipments.length < 1} onClick={fetchCSV}
                                      icon={loadingCsv ? <CircularProgress size={24} style={{color: "white"}}/> :
                                          <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_csv")}</StyledButton>
                        <StyledButton disabled={loadingCsv || shipments.length < 1} onClick={fetchReducedCsv}
                                      icon={loadingCsv ? <CircularProgress size={24} style={{color: "white"}}/> :
                                          <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_reduced_csv")}</StyledButton>
                        <StyledButton disabled={loadingLabels || shipments.length < 1} onClick={fetchLabelsByFilters}
                                      icon={loadingLabels ? <CircularProgress size={24} style={{color: "white"}}/> :
                                          <Download/>}>{loadingLabels ? t("please_wait") : t("download_labels")}</StyledButton>
                    </div>
                    <div style={{width: "100%", marginBottom: "-5%"}}>
                        {fetched ?
                            <InfoChip><Typography
                                variant={"subtitle2"}>{t("shipment_count")} {count}</Typography></InfoChip>
                            :
                            null
                        }
                    </div>

                    <div>
                        {(fetching) ?
                            <Table>
                                <LoadingComponent/>
                            </Table>

                            :
                            <>
                                {shipments.length > 0 ?
                                    <Paper className={classes.paper}>
                                        <Table stickyHeader aria-label="sticky table">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography align={"center"}>
                                                            <Checkbox color="primary"
                                                                      onChange={handleAllShipmentsChecked}
                                                                      checked={allShipmentChecked}
                                                            />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell/>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("shipment_id_short")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("service")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("manifest_datetime")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("reference_short")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("customerReference_short")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("orderNumber")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("departure_warehouse_short")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("pickup_warehouse_short")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("delivery_warehouse_short")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("account_corporate_code")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("contract")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("sub-contract")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("account_company_name")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("sender_company_name")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("pickup_address")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("delivery_address")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("last_event")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("booking_datetime")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {shipments.map((shipment, index) => {
                                                    return <Shipment key={index}
                                                                     shipment={shipment}
                                                                     checked={!!checkedShipments[shipment.id]}
                                                                     onCheck={onShipmentCheck(shipment, index)}
                                                                     goToAboutMe={goToAboutMe}
                                                                     deleteCallback={deleteCallback}
                                                                     failedDeleteCallback={failedDeleteCallback}
                                                    />
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={-1}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            // SelectProps={{
                                            //     inputProps: {'aria-label': 'rows per page'},
                                            //     variant: "outlined"
                                            //
                                            // }}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                variant: "outlined",
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                classes: {paper: classes.menuPaper}
                                            }}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return t("page")
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Paper>

                                    :

                                    <div style={{height: 100}}>

                                        {fetched ?
                                            <InfoChip style={{marginTop: "5%"}}>{t("no_shipments_found")}</InfoChip>
                                            :
                                            null
                                        }


                                    </div>
                                }

                            </>
                        }
                        <Dialog open={isAddEventModalOpened}
                                TransitionComponent={Fade}
                                fullWidth={true}
                                maxWidth="lg"
                            // className={classes.modal}
                                onClose={() => {
                                    setIsAddEventModalOpened(false);
                                    setRefresh(!refresh)
                                }}
                            // closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                        >
                            {/*<Fade in={isAddEventModalOpened}>*/}
                            <Paper variant={"outlined"} elevation={2} style={{
                                // width: "30%",
                                // marginInline: "auto",
                                // marginTop: "20%",
                                // height: "20%",
                            }}>
                                {/*<div style={{width: "85%", margin: "auto", marginTop: "10%", display: "flex"}}>*/}
                                {/*{0 < addEventModalShipments.length ?*/}
                                <CloseWorkListStepper
                                    onSave={onWorkListStepperSave}
                                    // driverWorkListId={workList.id}
                                    // workListData={workListData}
                                    // pickups={pickupsData.map(data => data.pickup)}
                                    shipments={addEventModalShipments}
                                    date={new Date()}
                                />
                                {/*: null}*/}
                                {/*</div>*/}
                            </Paper>
                            {/*</Fade>*/}
                        </Dialog>

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{t(notificationMessage)}</Notification>

                    </div>
                </PrivateRoute>
                <PrivateRoute exact path={"/shipments/:id/aboutMe"}>
                    <AboutMe/>
                </PrivateRoute>
                <PrivateRoute exact path={"/shipments/new"}>
                    <NewShipmentForm/>
                </PrivateRoute>
                <PrivateRoute exact path={"/shipments/:id/label"}>
                    <ShipmentLabelPage/>
                </PrivateRoute>
            </Switch>
            {showButton && (
                <Tooltip title={t("back_to_top")}>
                    <IconButton onClick={scrollToTop} className={classes.backToTop}>
                        <ArrowUpwardOutlined/>
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );

}

export default ShipmentsTable;