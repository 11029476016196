import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StyledButton from "../buttons/StyledButton";
import {grey} from "@mui/material/colors";
import {Dialog, DialogActions, DialogContent} from "@mui/material";

export default function ConfirmDialog(props) {
    return (
        <div>
            <Dialog
                style={{width: "100%"}}
                open={props?.open || false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props?.title || ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props?.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props?.onCancel} color={"default"}>
                        {props?.cancelText || "No"}
                    </Button>
                    <StyledButton onClick={props?.onConfirm} color="primary">
                        {props?.confirmText || "Si"}
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}